import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Modal from '../../ModalComponent';
import FileDownload from '@material-ui/icons/FileDownload';
import FileUpload from '@material-ui/icons/FileUpload';
import Visibility from '@material-ui/icons/Visibility';
import useUpdateUserLimitRequest from '../../../hooks/useUpdateUserLimitRequest';

export const ShowUserLimitRequest = (props) => (
    <div style={styles.container}>
        <div style={styles.title}>
            Solicitud de nuevo límite
        </div>
        <div style={styles.dataContainer}>
            <div style={styles.dataItem}>
                <div style={styles.dataLabel}>
                    Monto solicitado USD
                </div>
                <div style={styles.dataValue}>
                    {props.requested_limit}
                </div>
            </div>
            <div style={styles.dataItem}>
                <div style={styles.dataLabel}>
                    Monto límite actual USD
                </div>
                <div style={styles.dataValue}>
                    {props.current_limit}
                </div>
            </div>
            <div style={styles.dataItem}>
                <div style={styles.dataLabel}>
                    Fecha solicitd
                </div>
                <div style={styles.dataValue}>
                    {props.created_at}
                </div>
            </div>
            <div style={styles.dataItem}>
                <div style={styles.dataLabel}>
                    Monto límite del país USD
                </div>
                <div style={styles.dataValue}>
                    {props.country_global_limit}
                </div>
            </div>
            <div style={styles.dataItem}>
                <div style={styles.dataLabel}>
                    Monto aprobación USD
                </div>
                <div style={styles.dataValue}>
                    <input
                        style={styles.input}
                        value={props.approveAmount}
                        onChange={props.changeApproveAmount}
                        disabled={!props.isAmountEdit}
                    />
                </div>
            </div>
        </div>
        <div style={styles.subtitle}>
            Actividad
        </div>
        <div style={styles.dataContainer}>
            <div>
                <div style={styles.dataLabel}>
                    Descripción de actividad
                </div>
                <div style={styles.dataValueWaring}>
                    {props.economic_activity}
                </div>
            </div>
        </div>
        <div style={styles.documentContainer}>
            <div style={styles.documentItem}>
                <div style={styles.subtitleSmall}>
                    Comprobante de ingresos
                </div>
                <div style={styles.documentDataContent}>
                    <div 
                        title="Cargar documento de ingresos" 
                        onClick={
                            props.isIncomeVerificationFile ? 
                                props.onCloseIncomeFile : 
                                null
                        }
                    >
                        <label htmlFor="file-income-input" className="file-income-label">
                            <div style={props.isIncomeVerificationFile ? styles.documentInputSelect : styles.documentInput}>
                                {props.incomeVerificationFile ? props.incomeVerificationFile.name : `Comprobante de ingresos ${props.full_name}` }
                            </div>
                        </label>
                        {!props.isIncomeVerificationFile && <input
                            id="file-income-input"
                            style={styles.hiddenInput} 
                            type="file"
                            accept=".jpg,.jpeg,.png,.pdf"
                            onChange={props.changeIncomeVerificationFile}
                        />}
                    </div>
                    <div style={styles.iconContent} title="Ver">
                        <div
                            title="Ver documento de ingresos"
                            style={
                                props.isIncomeVerification && props.isImageIncomeVerification ? 
                                    styles.iconButton : 
                                    styles.iconButtonDisabled
                            }
                            onClick={
                                props.isIncomeVerification && props.isImageIncomeVerification ? 
                                    props.onIncomeVerificationView :
                                    null
                            }
                        >
                            <Visibility style={styles.icon} />
                        </div>
                        <div
                            title="Descargar documento de ingresos"
                            style={
                                props.isIncomeVerification ? 
                                    styles.iconButton : 
                                    styles.iconButtonDisabled
                            }
                            onClick={
                                props.isIncomeVerification ? 
                                    props.onIncomeVerificationDownload : 
                                    null
                            }
                        >
                            <FileDownload style={styles.icon} />
                        </div>
                        <div 
                            title="Subir documento de ingresos"
                            style={
                                !props.isIncomeVerificationFile ? 
                                    styles.iconButtonDisabled : 
                                    styles.iconButton
                            }
                            onClick={
                                props.isIncomeVerificationFile ? 
                                    props.onUploadIncomeFile :
                                    null   
                            }
                        >
                            <FileUpload style={props.isIncomeVerificationFile ? styles.iconCursor : styles.icon}/>
                        </div>
                    </div>
                </div>
                <div style={styles.dateLabel}>
                    Fecha de documento: {props.income_verification_at}
                </div>
            </div>
            <div style={styles.documentItem}>
                <div style={styles.subtitleSmall}>
                    Comprobante de domicilio
                </div>
                <div style={styles.documentDataContent}>
                    <div 
                        title="Cargar documento de residencia"
                        onClick={
                            props.isAddressProofFile ? 
                                props.onCloseAddressFile : 
                                null
                        }
                    >
                        <label htmlFor="file-address-input" className="file-address-label">
                            <div style={props.isAddressProofFile ? styles.documentInputSelect : styles.documentInput}>
                                {props.addressProofFile ? props.addressProofFile.name : `Comprobante de domicilio ${props.full_name}` }
                            </div>
                        </label>
                        {!props.isAddressProofFile && <input
                            id="file-address-input"
                            style={styles.hiddenInput} 
                            type="file"
                            accept=".jpg,.jpeg,.png,.pdf"
                            onChange={props.changeAddressProopFile}
                        />}
                    </div>
                    <div style={styles.iconContent} title="Ver">
                        <div
                            title="Ver documento de residencia"
                            style={
                                props.isAddressProof && props.isImageAddressProof ? 
                                    styles.iconButton : 
                                    styles.iconButtonDisabled
                            }
                            onClick={
                                props.isAddressProof && props.isImageAddressProof ? 
                                    props.onAddressProofView :
                                    null
                            }
                        >
                            <Visibility style={styles.icon} />
                        </div>
                        <div 
                            title="Descargar documento de residencia"
                            style={
                                props.isAddressProof ? 
                                    styles.iconButton : 
                                    styles.iconButtonDisabled
                            } 
                            onClick={
                                props.isAddressProof ? 
                                    props.onAddressProofDownload :
                                    null
                            }
                        >
                            <FileDownload style={styles.icon} />
                        </div>
                        <div 
                            title="Subir documento de residencia"
                            style={
                                !props.isAddressProofFile ? 
                                    styles.iconButtonDisabled : 
                                    styles.iconButton
                            }
                            onClick={
                                props.isAddressProofFile ? 
                                    props.onUploadAddressFile :
                                    null   
                            }
                        >
                            <FileUpload style={props.isAddressProofFile ? styles.iconCursor : styles.icon}/>
                        </div>
                    </div>
                </div>
                <div style={styles.dateLabel}>
                    Fecha de documento: {props.address_proof_at}
                </div>
            </div>
        </div>
        <div style={styles.buttonContainer}>
            <div style={styles.buttonItem}>
                <Button
                    style={
                        props.isApproved ? 
                            styles.button : 
                            styles.disabledButton
                    }  
                    onClick={props.onApprove}
                    disabled={!props.isApproved}
                >
                    Aprobar
                </Button>
            </div>
            <div style={styles.buttonItem}>
                <Button
                    style={
                        props.isDeclined ? 
                            styles.buttonCancel : 
                            styles.disabledButtonCancel
                    } 
                    onClick={props.onDecline}
                    disabled={!props.isDeclined}
                >
                    Rechazar
                </Button>
            </div>
        </div>
    </div>
);

ShowUserLimitRequest.propTypes = {
    requested_limit: PropTypes.number,
    current_limit: PropTypes.number,
    country_global_limit: PropTypes.number,
    created_at: PropTypes.string,
    economic_activity: PropTypes.string,
    onApprove: PropTypes.func,
    onDecline: PropTypes.func,
    isApproved: PropTypes.bool,
    isDeclined: PropTypes.bool,
    approveAmount: PropTypes.number,
    changeApproveAmount: PropTypes.func,
    onAddressProofDownload: PropTypes.func,
    onAddressProofView: PropTypes.func,
    onIncomeVerificationDownload: PropTypes.func,
    onIncomeVerificationView: PropTypes.func,
    isAmountEdit: PropTypes.bool,
    isIncomeVerification: PropTypes.bool,
    isAddressProof: PropTypes.bool,
    isImageAddressProof: PropTypes.bool,
    isImageIncomeVerification: PropTypes.bool,
    full_name: PropTypes.string,
    address_proof_at: PropTypes.string,
    income_verification_at: PropTypes.string,
    changeAddressProopFile: PropTypes.func,
    changeIncomeVerificationFile: PropTypes.func,
    isAddressProofFile: PropTypes.bool,
    isIncomeVerificationFile: PropTypes.bool,
    addressProofFile: PropTypes.any,
    incomeVerificationFile: PropTypes.any,
    onCloseIncomeFile: PropTypes.func,
    onCloseAddressFile: PropTypes.func,
    onUploadAddressFile: PropTypes.func,
    onUploadIncomeFile: PropTypes.func,
};

ShowUserLimitRequest.defaultProps = {
    requested_limit: 0,
    current_limit: 0,
    country_global_limit: 0,
    created_at: '',
    economic_activity: '',
    onApprove: () => {},
    onDecline: () => {},
    isApproved: false,
    isDeclined: false,
    approveAmount: 0,
    changeApproveAmount: () => {},
    onAddressProofDownload: () => {},
    onAddressProofView: () => {},
    onIncomeVerificationDownload: () => {},
    onIncomeVerificationView: () => {},
    isAmountEdit: false,
    isIncomeVerification: false,
    isAddressProof: false,
    isImageAddressProof: false,
    isImageIncomeVerification: false,
    full_name: '',
    address_proof_at: '',
    income_verification_at: '',
    changeAddressProopFile: () => {},
    changeIncomeVerificationFile: () => {},
    isAddressProofFile: false,
    isIncomeVerificationFile: false,
    addressProofFile: null,
    incomeVerificationFile: null,
    onCloseIncomeFile: () => {},
    onCloseAddressFile: () => {},
    onUploadAddressFile: () => {},
    onUploadIncomeFile: () => {},
};

const styles = {
    container: {
        paddingTop: 70,
        paddingLeft: 50,
        paddingBottom: 70,
    },
    title: {
        color: '#FFFFFF',
        fontWeight: 'bold',
        paddingBottom: 30,
    },
    subtitle: {
        color: '#FFFFFF',
        fontWeight: 'bold',
        paddingTop: 50,
        paddingBottom: 30,
        fontSize: 12,
    },
    subtitleSmall: {
        color: '#FFFFFF',
        fontWeight: 'bold',
        paddingTop: 50,
        paddingBottom: 20,
        fontSize: 12,
    },
    dataContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingRight: 50,
    },
    dataItem: {
        flex: 1,
    },
    dataLabel: {
        fontSize: 11,
        color: '#FFFFFF',
    },
    dateLabel: {
        fontSize: 11,
        color: '#FFFFFF',
        paddingTop: 8,
    },
    documentInput: {
        fontSize: 11,
        color: '#FFFFFF',
        padding: 10,
        paddingLeft: 5,
        paddingRight: 5,
        border: '1px dashed #4b4b4b',
        borderRadius: 5,
        width: 220,
        cursor: 'pointer',
    },
    documentInputSelect: {
        fontSize: 11,
        color: '#FFFFFF',
        padding: 10,
        paddingLeft: 5,
        paddingRight: 5,
        border: '1px dashed #00b6ff',
        borderRadius: 5,
        width: 220,
        backgroundColor: '#0068a7',
        cursor: 'pointer',
    },
    dataValue: {
        fontSize: 12,
        color: '#FFFFFF',
    },
    dataValueWaring: {
        fontSize: 12,
        color: '#FFFFFF',
        borderBottom: '2px solid #4b4b4b',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingTop: 50,
    },
    documentContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    documentItem: {
        flex: 1,
    },
    button: {
        backgroundColor: '#009bff',
        width: 120,
        height: 35,
        fontSize: 12,
    },
    disabledButton: {
        backgroundColor: '#009bff',
        width: 120,
        height: 35,
        fontSize: 12,
        opacity: 0.25,
    },
    buttonItem: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonCancel: {
        backgroundColor: '#FF0000',
        width: 120,
        height: 35,
        fontSize: 12,
    },
    disabledButtonCancel: {
        backgroundColor: '#FF0000',
        width: 120,
        height: 35,
        fontSize: 12,
        opacity: 0.25,
    },
    icon: {
        color: '#FFFFFF',
        fontSize: '1rem',
    },
    iconCursor: {
        color: '#FFFFFF',
        fontSize: '1rem',
        cursor: 'pointer',
    },
    iconButton: {
        paddingLeft: 10,
        paddingRight: 10,
        cursor: 'pointer',
    },
    iconButtonDisabled: {
        paddingLeft: 10,
        paddingRight: 10,
        opacity: 0.25,
    },
    iconContent: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 10,
    },
    documentDataContent: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    input: {
        textDecoration: 'none',
        border: 0,
        borderBottom: '1px solid #FFFFFF',
        backgroundColor: 'transparent',
        color: '#FFFFFF',
    },
    hiddenInput: {
        display: 'none',
    },
    cardStyle: {
        width: 910,
        position: 'fixed',
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)',
    },
    backdropStyle: {
        backgroundColor: 'rgba(0,0,0,0.85)',
    },
};

const ShowUserLimitRequestHook = (props) => {
    const hook = useUpdateUserLimitRequest(props);
    return <ShowUserLimitRequest {...hook} />;
};

const ShowUserLimitRequestHoc = (props) => (
    <Modal 
        open={props.open}
        onClose={props.hiddeModal}
        backgroundColor={'#1e1e1e'}
        backdropStyle={styles.backdropStyle}
        cardStyle={styles.cardStyle}
    >
        <ShowUserLimitRequestHook {...props} />
    </Modal>
);

ShowUserLimitRequestHoc.propTypes = {
    open: PropTypes.bool,
    hiddeModal: PropTypes.func,
};

ShowUserLimitRequestHoc.defaultProps = {
    open: false,
    hiddeModal: () => {},
};

export default ShowUserLimitRequestHoc;