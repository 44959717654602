import {useState, useCallback, useRef} from 'react';

const useModal = () => {
  const [open, setOpen] = useState(false);
  const [messageState, setMessageState] = useState('');
  const callbackRef = useRef(() => {});

  const showModal = useCallback((message = '', callback = () => {}) => {
    setOpen(true);
    callbackRef.current = callback;
    setMessageState(message);
  }, [setOpen]);

  const hiddeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return {
    open,
    action: callbackRef.current,
    message: messageState,
    setOpen: hiddeModal,
    showModal,
    hiddeModal,
  };
};

export default useModal;
