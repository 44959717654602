import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import { Tabs, Tab, AppBar } from '@material-ui/core';

import { Recharge, Payment } from './CustomComponents';
import LinksPayment from './CustomComponents/LinksPayment';

import useAccessRole from '../../../../../hooks/useAccessRole';
const ProvidersRecharge = (props) => {
  const {
    isRechargeStatus,
    editHide,
    tabState,
    changeTab
  } = props;  
  const { isAccess } = useAccessRole();

  useEffect(() => {
    if (tabState == 1) {
      editHide(true);
    }
  }, []);

  return (
    <>
        <AppBar position="static" color="default">
            <Tabs
                value={tabState}
                onChange={changeTab}
                indicatorColor="primary"
                textColor="secundary"
                scrollable
                scrollButtons="auto"
            >
                <Tab key="Pagos" label="Pagos" onClick={() => editHide(false)} />
                {
                  isAccess('get_recharge_providers') && isRechargeStatus &&
                  <Tab key="Recargas" label="Recargas" onClick={() => editHide(true)} />
                }
            {
              isAccess('show_payment_order_providers') &&
              <Tab key="LinksPayment" label="Enlaces de pago" onClick={() => editHide(true)} />
            }
            </Tabs>
        </AppBar>
        {tabState === 0 && <Payment {...props} />}
        {tabState === 1 && <Recharge {...props} />}
        {tabState === 2 && <LinksPayment {...props} />}
    </>
  )
};

ProvidersRecharge.propTypes = {
    tabState: PropTypes.number,
    changeTab: PropTypes.func,
    isRechargeStatus: PropTypes.bool,
    editHide: PropTypes.func,
};

ProvidersRecharge.defaultProps = {
    tabState: 0,
    changeTab: () => { },
    isRechargeStatus: false,
    editHide: () => { },
};

export default ProvidersRecharge;