import { apiCall }    from '../../Middlewares';
import { ENDPOINTS }  from '../../Config/env';

const getFiatDepositInstructions = async (payload) =>
    await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.FORTRESS.GET_DEPOSIT_INSTRUCTIONS,
        data: payload,
    });

const getBalancesCustodialAccount = async (payload) =>
    await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.FORTRESS.GET_BALANCES_CUSTODIAL_ACCOUNT,
        data: payload,
});

const getCustodialAccounts = async () =>
    await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.FORTRESS.GET_CUSTODIAL_ACCOUNTS,
});

const createCustodialAccount = async (payload) => 
    await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.FORTRESS.CREATE_CUSTODIAL_ACCOUNTS,
        data: payload,
});

export default {
    getFiatDepositInstructions,
    getBalancesCustodialAccount,
    getCustodialAccounts,
    createCustodialAccount,
}