import useAlerts from './useAlerts';
import useAccessRole from './useAccessRole';
import fortressProvider from '../Providers/fortress';
import { generateTableFromJson } from '../Resources/Fortress/utils';

const useCreateCustodialAccount = (props = {}) => {
	const { record = {} } = props;
    const { kycLevel = 'L0', type, id, documents = [] } = record;
    const { isAccess } = useAccessRole();
    const { hiddenLoading, showLoading, errorMessage, showHTML, showModal } = useAlerts();

    const onCreate = async () => {
        try {
            const { isConfirmed = false } = await showModal({
                title: '¡Atención!',
                text: '¿Esta seguro de crear un nueva cuenta?',
            });

            if (isConfirmed) {
                showLoading();

                const data = { type };
                data[`${type}_identity_id`] = id;
                const response = await fortressProvider.createCustodialAccount(data);
                
                showHTML({ html: generateTableFromJson(response.data) });
            }
        } catch(error){
            errorMessage(error.data.error.message);
        }

        hiddenLoading();
    };

	return {
        onCreate,
        isCreateAccount: isAccess('create_custodial_account_fortress') && kycLevel !== 'L0' && (documents.length > 0 || type === 'business'),
	}
};

const styles = {
	button: {
		backgroundColor: '#00aaff',
        color: "#FFFFFF",
        marginTop: 30,
	},
};

export default useCreateCustodialAccount;