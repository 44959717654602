import React, { useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { TextInput, SelectInput } from 'react-admin';
import { connect } from 'react-redux';
import { BalanceField } from '../../../../../Components';
import {
  toMoney,
  TO_NUMBER
} from '../../../../../Helpers/MoneyHelper';

const EditBalance = (props) => {
  const {
    record,
  } = props;
  let {
    recordLiveValues: {
      attributes: {
        currency_to_add,
      }
    }
  } = props;
  let { attributes: { default_currency, is_business_api, balances, active_cryptos } } = record;
  default_currency = default_currency.toLowerCase();

  const userCurrencies = useMemo(() => {
    return Object.keys(balances).filter((f) => {
      return !active_cryptos[f];
    }).map(m => {
      return { id: m, name: m.toUpperCase() }
    });
  }, [balances, active_cryptos]);

  const formatValue = (value) =>
    toMoney(
      value,
      default_currency
    );

  const parseValue = (value) =>
    TO_NUMBER(
      value,
      default_currency
    );

  if (is_business_api) {
    return (
      <div style={{ color: '#FFFFFF', paddingTop: 30, paddingBottom: 30 }}>
        Los usuarios business no están habilitados para esta acción
      </div>
    );
  }

  return (
    <>
      <Grid
        alignItems='center'
        spacing={16}
        container
      >
        <Grid item xs={2}>
          <SelectInput
            label={"Moneda"}
            source="attributes.currency_to_add"
            choices={userCurrencies}
            fullWidth
          />
        </Grid>
        {
          !!currency_to_add && (
            <>
              <Grid item xs={2}>
                <BalanceField
                  source={currency_to_add}
                  record={record}
                  label={currency_to_add?.toUpperCase()}
                  isLabel
                />
              </Grid>
              <Grid item xs={2}>
                <TextInput
                  format={formatValue}
                  parse={parseValue}
                  record={record}
                  defaultValue={0}
                  source={`attributes.balances.${currency_to_add}_add`}
                  label={`Agregar al ${currency_to_add?.toUpperCase()}`}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  type="text"
                  label="Descripción"
                  source={`attributes.description`}
                  resettable
                  fullWidth
                />
              </Grid>
            </>
          )
        }
      </Grid>
    </>
  );
};

EditBalance.propTypes = {
  record: PropTypes.object
};

const mapStateToProps = ({ form }) => ({
  recordLiveValues: form['record-form'].values || {}
});

export default connect(mapStateToProps)(EditBalance);