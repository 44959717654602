import {Settings} from '@material-ui/icons';

import PricesList from './PricesList';
import PricesEdit from './PricesEdit';
import PricesCreate from './PricesCreate';
import useAccessRole from "../../hooks/useAccessRole";

const {isAccess} = useAccessRole();

export default {
    name: 'prices',
    icon: Settings,
    list: isAccess('show_prices_configuration') ? PricesList : false,
    edit: isAccess('edit_prices_configuration') ? PricesEdit : false,
    create: isAccess('create_prices_configuration') ? PricesCreate : false,
};
