import React from "react";
import { Filter, TextInput } from "react-admin";

import useClearFilters from "../../../../../hooks/useClearFilters";

const CountriesFilter = (props) => {
  useClearFilters(props.setFilters);

  return (
    <Filter {...props}>
      <TextInput label={"Nombre"} source={"country_name"} alwaysOn />
      <TextInput label={"Código ISO"} source={"iso_code"} alwaysOn />
    </Filter>
  );
};

export default CountriesFilter;
