import { apiCall }   from '../../Middlewares';
import { ENDPOINTS } from '../../Config/env';

const getFavoriteAccountList = async (account_type, user_id, page, count) => {
    const url = `q[s]=id+DESC&q[account_type]=${account_type}&q[user_id]=${user_id}&page=${page}&count=${count}`;
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.FAVORITE_ACCOUNT.GET_LIST(url)
    });
};

const createAccount = async (data) =>
  await apiCall({
      authorization: true,
      ...ENDPOINTS.ADMIN.FAVORITE_ACCOUNT.CREATE,
      data,
  });

const updateAccount = async (id, data) =>
  await apiCall({
      authorization: true,
      ...ENDPOINTS.ADMIN.FAVORITE_ACCOUNT.UPDATE(id),
      data,
  });

const deleteAccount = async (id) =>
  await apiCall({
      authorization: true,
      ...ENDPOINTS.ADMIN.FAVORITE_ACCOUNT.DELETE(id)
  });

export default {
  getFavoriteAccountList,
  createAccount,
  updateAccount,
  deleteAccount
};
