import { useEffect } from "react";

const useClearFilters = (setFilters) => {
  useEffect(() => {
    setFilters && setFilters({});

    return () => {
      setFilters && setFilters({});
    };
  }, []);
};

export default useClearFilters;
