import { UPDATE } from 'react-admin';

import { API_URL } from '../../../../Config/env';

const requestHandler = (type, params) => {
  switch (type) {
    case UPDATE: {
      let {
        data: {
          attributes
        }
      } = params;

      return {
        url: `${API_URL}/admin/prices_providers/${params.id}`,
        method: 'put',
        data: attributes,
      };
    }
    default: {
      return {

      }
    }
  }
}

export default requestHandler;