import {GET_LIST} from 'react-admin';

const responseHandler = (response, type) => {
    switch (type) {
        case GET_LIST: {
            return {
                data: response.data.requests.data,
                total: response.data['total'],
            };
        }
        default: {
            return {
                data: response.data.requests.data,
                total: response.data['total'],
            }
        }
    }
};

export default responseHandler;