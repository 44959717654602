import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';

import useAccessRole from '../../../hooks/useAccessRole';
import useModal from '../../../hooks/useModal';
import ModalExport from '../../../Components/ModalExport';
import { TransactionProfitabilityFilter } from '../Filters';
import { Pagination } from '../../../Components';

const TransactionProfitabilityList = (props) => {
	const { isAccess } = useAccessRole();
	const { open, showModal, hiddeModal } = useModal();

	return (
		<>
			<List
				{...props}
				title={'Lista de rentabilidad de transacciones'}
				filters={<TransactionProfitabilityFilter />}
				exporter={isAccess('show_export_transaction_profitability') ? showModal : false}
				pagination={<Pagination />}
				bulkActionButtons={false}
			>
				<Datagrid>
					<TextField
						source={'id'}
						label={'Id'}
					/>
					<TextField
						source={'source_currency'}
						label={'Moneda Origen'}
					/>
					<TextField
						source={'destination_currency'}
						label={'Moneda Destino'}
					/>
					<TextField
						source={'source_amount'}
						label={'Monto Origen'}
					/>
					<TextField
						source={'destination_amount'}
						label={'Monto Destino'}
					/>
					<TextField
						source={'transaction_id'}
						label={'Id transacción'}
					/>
					<TextField
						source={'profitability'}
						label={'Rentabilidad Moneda Origen'}
					/>
				</Datagrid>
			</List>
			<ModalExport
				open={open}
				hiddeModal={hiddeModal}
			/>
		</>
	);
}

export default TransactionProfitabilityList;