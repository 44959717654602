import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";

import { style } from "./styles";

const ShowBannerDetails = (props) => {
  const {
    sceneShowBanner,
    textBanner,
    btText,
    btColorBg,
    btColorText,
    fontColor1,
    fontColor2,
    imageDesktop,
    imageMobile,
    hiddenImageDetails,
    validateFields,
  } = props;

  const styles = style({
    sceneShowBanner,
    fontColor1,
    fontColor2,
    btColorBg,
    btColorText,
  });

  return (
    <>
      {validateFields ? (
        <div style={styles.container}>
          {sceneShowBanner === "desktop" ? (
            <div style={styles.background_container}>
              <div style={{ zIndex: 2 }}>
                <p style={styles.banner_title_angie} data-testid='textBanner'>
                  {textBanner}
                </p>
                <div style={styles.container_button}>
                  <p style={styles.button_title} data-testid='btText'>
                    {btText}
                  </p>
                </div>
              </div>
              <div style={styles.container_image}>
                <img
                  style={styles.banner_image}
                  data-testid='imageDesktop'
                  src={imageDesktop}
                  alt='Error img'
                />
              </div>
            </div>
          ) : (
            <div style={styles.banner_mobile}>
              <p
                style={styles.banner_title_alejandra}
                data-testid='btTextMobile'
              >
                {textBanner}
              </p>
              <img
                style={styles.banner_card_image_alejandra}
                data-testid='imageMobile'
                src={imageMobile}
                alt='Error img'
              />
            </div>
          )}
        </div>
      ) : (
        <div style={styles.text_information}>
          Complete todos los campos para visualizar el banner.
        </div>
      )}
      <div style={styles.container_button_close}>
        <Button
          variant='contained'
          onClick={hiddenImageDetails}
          style={{ backgroundColor: "#005FEE" }}
        >
          Ocultar
        </Button>
      </div>
    </>
  );
};

ShowBannerDetails.propTypes = {
  sceneShowBanner: PropTypes.string,
  textBanner: PropTypes.string,
  btText: PropTypes.string,
  btColorBg: PropTypes.string,
  btColorText: PropTypes.string,
  fontColor1: PropTypes.string,
  fontColor2: PropTypes.string,
  imageDesktop: PropTypes.string,
  imageMobile: PropTypes.string,
  hiddenImageDetails: PropTypes.func,
  validateFields: PropTypes.bool,
};

ShowBannerDetails.defaultProps = {
  sceneShowBanner: "desktop",
  textBanner: "Recarga tu Wallet",
  btText: "Recargar billetera",
  btColorBg: "#000000",
  btColorText: "#FFFFFF",
  fontColor1: "#07C999",
  fontColor2: "#05A27C",
  imageDesktop: "",
  imageMobile: "",
  hiddenImageDetails: () => {},
  validateFields: false,
};

export default ShowBannerDetails;
