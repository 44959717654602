import { GET_LIST, CREATE } from 'react-admin';

import { API_URL } from '../../../../Config/env';

const requestHandler = (type, params) => {
  switch (type) {
    case GET_LIST: {
      const { type = 'accounts' } = params.filter || {};
      const { page, perPage } = params.pagination;

      return {
        url: `${API_URL}/admin/fortress/${type === 'accounts' ? 'get_custodial_accounts' : 'get_identities'
          }?page=${page}&page_size=${perPage}`,
        method: 'get',
      };
    }
    case CREATE: {
      let data = params.data;
      let isWithdrawal = data.formulary_type === 'withdrawal';
      let isAssignWebhookUrl = data.formulary_type === 'assign_webhook_url';

      if (!isAssignWebhookUrl) {
        if (isWithdrawal) {
          const tokens = data.custodial.split("****");

          data.identity_id = tokens[1];
          data.custodial_account_id = tokens[0];
        } else {
          data.document_front = data.document_front.rawFile;

          if (data.document_front_business) {
            data.document_front_business = data.document_front_business.rawFile;
          }

          data.type = data.formulary_type === 'create_account' ? 'personal' : 'business';

          const formData = new FormData();
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              formData.append(key, data[key]);
            }
          }

          data = formData;
        }

        data.document_front = null;
        if (data.document_front_business) {
          data.document_front_business = null;
        }
      }


      return {
        url: `${API_URL}/admin/fortress/${isAssignWebhookUrl ?
            'assign_webhook_url' :
            isWithdrawal ?
              'create_payment' :
              'create_account'
          }`,
        method: 'post',
        data,
      };
    }
    default: {
      return {

      }
    }
  }
}

export default requestHandler;