import React from 'react';
import moment from 'moment'
import { List, Datagrid, TextField, FunctionField } from 'react-admin';

import DoneAllIcon from '@material-ui/icons/DoneAll';
import BlockIcon from '@material-ui/icons/Block';
import GeolocationFilter from '../../Filters/GeolocationFilter';
import { Pagination } from '../../../../Components';

const postRowStyle = (record, index) => {
  if (record.attributes && record.attributes.location_diff) {
    return {
      backgroundColor: '#ffbb00',
    }
  }  
};

const RenderList = (props) => {
  if(props.total === 0) {
    return <div>
      <h2 style={{paddingLeft: 24, color: 'white'}}>SELECCIONA <i>Target de ubicación</i> PARA BUSCAR RESULTADOS</h2>
    </div>;
  }

  return (
    <Datagrid {...props} rowStyle={postRowStyle}>
        <TextField
          source={'id'}
          label={'Id'}
        />
        <TextField
          source={'location_diff'}
          label={'change'}
        />
        <FunctionField
          label={'Fecha de ejecución'}
          source={'attributes.created_at'}
          render={(record) => {
            return `${moment.utc(record.attributes.created_at).format('DD/MM/YYYY HH:mm:ss')} UTC`;
          }}
        />
        <FunctionField
          label={'Agente de sesión'}
          render={(record) => {
            return `${record.attributes.user_agent}`;
          }}
        />
        <TextField
          source={'attributes.ip_address'}
          label={'IP'}
        />
        <TextField
          source={'attributes.country_name'}
          label={'País'}
        />
        <TextField
          source={'attributes.state_name'}
          label={'Estado'}
        />
        <TextField
          source={'attributes.city_name'}
          label={'Ciudad'}
        />
        <FunctionField
          label={'Latitud - Longitud'}
          render={(record) => {
            return `${record.attributes.location_latitude} - ${record.attributes.location_longitude}`;
          }}
        />
        {
          props.filterValues.geolocatable_type === 'Transaction' && (
            <TextField
              source={'attributes.geolocatable.category'}
              label={'Categoria'}
            />
          )
        }
        {
          props.filterValues.geolocatable_type === 'Transaction' && (
            <TextField
              source={'attributes.geolocatable.id'}
              label={'TRX ID'}
            />
          )
        }
        {
          props.filterValues.geolocatable_type === 'LoginLog' && (
            <TextField
              source={'attributes.geolocatable.email'}
              label={'Email'}
            />
          )
        }
        {
          props.filterValues.geolocatable_type === 'LoginLog' && (
            <TextField
              source={'attributes.geolocatable.type_login'}
              label={'Tipo de sesión'}
            />
          )
        }
        {
          props.filterValues.geolocatable_type === 'LoginLog' && (
            <FunctionField
              label={'Acceso'}
              source={'attributes.success_login'}
              render={(record) => {
                return record.attributes.geolocatable.success_login ? <DoneAllIcon /> : <BlockIcon />
              }}
            />
          )
        }
      </Datagrid>
  )
}

const GeolocationList = (props) => {
  return (
    <List
      {...props}
      title={'Geolocalizaciones'}
      exporter={false}
      filters={<GeolocationFilter />}
      pagination={<Pagination />}
      bulkActionButtons={false}
      debounce={1000}
    >
      <RenderList/>
    </List>
  );
};

export default GeolocationList;