import colors from '../../Assets/Colors';

const styles = {
    header: {
        color: colors.white,
    },
    subTitle: {
        color: colors.white,
        paddingBottom: 40,
        paddingTop: 40,
    },
    itemsTitle: {
        color: colors.white,
        textAlign: 'center',
        marginTop: 50
    },
    flag: {
        fontSize: '2em',
    },
    bigFlag: {
        fontSize: '3em',
    },
    flagSmall: {
        fontSize: '1.5em',
    },
    amountCurrency: {
        paddingTop: 20,
    },
    container: {
        flexGrow: 1,
    },
    chartBox: {
        width: '100%',
        height: '70px',
    },
    divisor: {
        paddingTop: 60,
        paddingBottom: 60,
    },
};

export default styles;
