import React                                  from 'react';
import Typography                             from '@material-ui/core/Typography';
import { withStyles }                         from '@material-ui/core/styles';
import { Layout as LayoutReactAdmin, AppBar } from 'react-admin';

import { MyNotification, Menu } from '../index';

import styles from './styles';

const MyAppBar = withStyles(styles)(({ classes, ...props }) => {
        return (
            <AppBar
                {...props}
                style={styles.colorBlack}
            >
                <Typography
                    variant={'title'}
                    color={'inherit'}
                    className={classes.title}
                    id={'react-admin-title'}
                />
                <span className={classes.spacer}/>
            </AppBar>
        );
    }
);

const MyLayout = (props) => (
    <LayoutReactAdmin
        {...props}
        notification={MyNotification}
        appBar={MyAppBar}
        menu={Menu}
    />
);

export default MyLayout;
