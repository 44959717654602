import { apiCall }    from '../../Middlewares';
import { ENDPOINTS }  from '../../Config/env';

const deleteVitaPrice = async (id) => {
    const url = ENDPOINTS.ADMIN.VITA_PRICES.DELETE_VITA_PRICE.url.replace(':id', id)
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.VITA_PRICES.DELETE_VITA_PRICE,
        url,
    });
};

const getCurrencies = async () => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.VITA_PRICES.GET_CURRENCIES,
    });
};

export default {
    deleteVitaPrice,
    getCurrencies,
};