import { apiCall } from '../../Middlewares';
import { ENDPOINTS } from '../../Config/env';

const automaticWithdrawal = async (data) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.USDC_WITHDRAWALS.AUTOMATIC_WITHDRAWAL,
        data: { ...data }
    });
};

const completeWithdrawal = async (data) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.USDC_WITHDRAWALS.COMPLETE_WITHDRAWAL,
        data: { ...data }
    });
};

const rejectWithdrawal = async (data) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.USDC_WITHDRAWALS.REJECT_WITHDRWAL,
        data: { ...data }
    });
};

const circleBalance = async () => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.USDC_WITHDRAWALS.BALANCE
    })
};

const masterAdminBalances = async () => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.USDC_WITHDRAWALS.MASTER_ADMIN_BALANCES
    })
};

const userCircleBalances = async (id) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.USDC_WITHDRAWALS.USER_CIRCLE_BALANCES(id)
    })
};

const getCircleNetworks = async () => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.USDC_WITHDRAWALS.GET_CIRCLE_NETWORKS
    })
};

const circleWithdrawal = async (data) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.USDC_WITHDRAWALS.CIRCLE_WITHDRAWAL,
        data: { ...data }
    });
};

export default {
    automaticWithdrawal,
    completeWithdrawal,
    rejectWithdrawal,
    circleBalance,
    masterAdminBalances,
    userCircleBalances,
    getCircleNetworks,
    circleWithdrawal,
}
