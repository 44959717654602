import React, { useEffect, useState } from 'react';
import CheckIcon from '@material-ui/icons/Check'

import { Button, Table, TableBody, TableCell, TableHead, TableRow, Paper, IconButton, Grid, Typography } from '@material-ui/core';

import { UserProvider } from '../../../../../Providers';
import FileDialog from './Modals/index';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DownloadIcon from '@material-ui/icons/FileDownload';
import Swal from 'sweetalert2';
import Axios from 'axios';
import fileDownload from 'js-file-download';
import { INTERNAL_DOCUMENT_TYPES_READING } from './utils';
import styles from "./styles";

const AttachmentsPanel = (props) => {
  const { record, readOnly, showTitle } = props;
  const [items, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState({});
  const [action, setAction] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const handleCreate = (event) => {
    setAction('create');
    setCurrentItem({});
    setDialogIsOpen(true);
  };

  const handleEdit = (item) => {
    setAction('edit');
    setCurrentItem(item);
    setDialogIsOpen(true);
  };

  const handleShow = (item) => {
    setAction('show');
    setCurrentItem(item);
    setDialogIsOpen(true);
  };

  const handleDelete = async (element_id, element_name) => {
    const confirmation = await Swal.fire({
      title: `¿Estás seguro que deseas eliminar el documento “${element_name}”?`,
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
    });

    if (confirmation.isConfirmed) {
      try {
        await UserProvider.deleteAttachmentFile(record.id, element_id);
        Swal.fire({
          title: '¡Documento eliminado!',
          text: '',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Entendido'
        });
        const filteredItems = items.filter((item) => item.id !== element_id);
        setItems(filteredItems);
      } catch (error) {
        Swal.fire({
          title: 'No hemos podido eliminar el documento',
          text: error.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'Reintentar',
        });
      }
    }
  };

  const handleDownload = async (file_url) => {
    try {
      const response = await Axios.get(file_url, { 
        responseType: 'blob',
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      });
      fileDownload(response.data, file_url.substring(file_url.lastIndexOf('/') + 1));
      } catch (error) {
        Swal.fire({
          title: 'No hemos podido descargar el documento',
          text: error.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
        });
      }
  };

  const getList = async (user_id) => {
    try {
      const response = await UserProvider.listAttachmentFiles(user_id);
      setItems(response.data.data);
    } catch (error) {
      Swal.fire({
        title: 'No hemos podido cargar la lista de documentos de este usuario',
        text: error.message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonText: 'Aceptar',
      });
    }
  };

  const BToKB = (b) => {
    return Math.round(b / 1024);
  };

  const formatSize = (kb) => {
    return kb >= 1 ? `${kb} KB` : '<1 KB';
  };

  const formatDate = (date_str) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };
    const date = new Date(date_str);
    return date.toLocaleString('es-ES', options);
  };

  const formatInternalDocumentType = (originalInternalDocumentType) => {
    if(originalInternalDocumentType !== null){
      const document_type = INTERNAL_DOCUMENT_TYPES_READING.find(obj => obj['id'] === originalInternalDocumentType)
      return document_type ? document_type['name'] : 'Otro';
    }else{
      return '';
    }
  };

  const formatIsDefault = (originalIsDefault) => {
    if(originalIsDefault){
      return <CheckIcon />
    }else{
      return '';
    }
  }

  useEffect(() => {
    if(record.id){
      getList(record.id);
    }
  }, [record.id]);

  return (
    <div>
      <FileDialog 
      open={dialogIsOpen}
      handleClose={() => setDialogIsOpen(false)}
      handleRefresh={()=> getList(record.id)}
      handleDownload={handleDownload}
      record={record}
      action={action}
      item={currentItem}
      />

      <div component={Paper} style={{ marginTop: '10px' }}>
        { showTitle &&
          <Grid item xs={6}>
            <Typography variant="h4" gutterBottom style={styles.titleWhite}>
              Documentación
            </Typography>
          </Grid>
        }
        { !readOnly &&
          <Button variant='contained' color='secondary' onClick={handleCreate}>
            Cargar archivo
          </Button>
        }
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre del documento</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Tamaño</TableCell>
              <TableCell>Fecha de última modificación</TableCell>
              <TableCell>Tipo de documento interno</TableCell>
              <TableCell>Por defecto</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => {
              const isUboFile = item.attributes.attached_to_type === 'UltimateBusinessOwner';
              return (
                <TableRow key={item.id}>
                  <TableCell>{item.attributes.name}</TableCell>
                  <TableCell>{item.attributes.description}</TableCell>
                  <TableCell>{formatSize(BToKB(item.attributes.file_size))}</TableCell>
                  <TableCell>{formatDate(item.attributes.updated_at)}</TableCell>
                  <TableCell>{formatInternalDocumentType(item.attributes.internal_document_type)}</TableCell>
                  <TableCell>{formatIsDefault(item.attributes.is_default)}</TableCell>
                  <TableCell>
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}>
                      <IconButton onClick={() => handleShow(item)}>
                        <VisibilityIcon />
                      </IconButton>
                      { !readOnly &&
                        <IconButton onClick={() => handleEdit(item)}>
                          <EditIcon />
                        </IconButton>
                      }
                      <IconButton onClick={() => handleDownload(item.attributes.file_url)}>
                        <DownloadIcon />
                      </IconButton>
                      {!readOnly && !isUboFile &&
                        <IconButton onClick={() => handleDelete(item.id, item.attributes.name)}>
                          <DeleteIcon />
                        </IconButton>
                      }
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default AttachmentsPanel;
