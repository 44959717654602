const responseHandler = (response, type) => {
    switch (type) {
        default: {
            const transactions_status = response.data["transaction_status"].data.map((transaction_status) => ({
                ...transaction_status,
                unique: response.data['unique'],
                transactions_status_url: `/transacciones?filter=%7B"id"%3A"${transaction_status.attributes.original_transaction.id}"%7D`,
            }));

            return {
                data: transactions_status,
                total: response.data['total'],
            }
        }
    }
};

export default responseHandler;
