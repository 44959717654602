import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from 'react-admin';
import { Col } from 'react-grid-system';

import styles from '../../../Users/UserEdit/styles';

export const Input = (props = {}) => (
    <>
        {
            props.isVisible && (
                <Col xs={3} style={styles.box}>
                    <TextInput
                        value={props.value}
                        label={props.label}
                        source={props.source}
                        disabled={props.disabled}
                    />
                </Col>
            )
        }
    </>
);

Input.propTypes = {
    isVisible: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    label: PropTypes.string,
    source: PropTypes.string,
};

Input.defaultProps = {
    isVisible: false,
    disabled: false,
    value: '',
    label: '',
    source: '',
};

export default Input;