const styles = {
    buttonRed: {
        backgroundColor: '#FF0000',
        color: '#FFFFFF',
    },
    buttonGreen: {
        backgroundColor: '#1ade9c',
        color: '#000000',
    },
    buttonBlue: {
        backgroundColor: '#4887de',
        color: '#000000',
    },
    buttonPurple: {
        backgroundColor: '#8F00FF',
        color: '#000000',
    },
    buttonDisabled: {
        backgroundColor: '#63666b',
    },
    buttonYellow: {
        backgroundColor: '#F1C807',
        color: '#000000',
    },
    separator: {
        padding: 10,
    },
};

export default styles;
