const styles = {
    item: {
        color: '#FFFFFF',
    },
    flag: {
        fontSize: '3em',
    },
    button: {
        backgroundColor: '#00ccff',
        color: '#FFFFFF',
        paddingTop: 10,
        paddingBottom: 10,
    },
    styleLink: {
        display: "flex",
        marginTop: 10,
        color: '#54CDFD',
        fontWeight: 'bold',
        marginBottom: 5,
    },
    emojiContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    emoji: {
        margin: 7,
        fontSize: 22,
    },
    cotainer_with_flag: {
        display: 'flex',
        flex: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    flag: {
        fontSize: 20,
        marginRight: 8
    }
};

export default styles;
