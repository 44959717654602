import { CREATE, GET_LIST, GET_ONE, UPDATE } from "react-admin";

const responseHandler = (response, type) => {
  switch (type) {
    case GET_LIST: {
      return {
        data: response.data.snackbars.data,
        total: response.data["total"],
      };
    }
    case CREATE: {
      return {
        data: response.data.message,
      };
    }
    case UPDATE: {
      return {
        data: response.data.data,
      };
    }
    case GET_ONE: {
      if (response.data.is_details){
        return {
          data: response.data.data.data,
        };
      }

      return {
        data: response.data.snackbars.data,
      };
    }
    default: {
      break;
    }
  }
};

export default responseHandler;
