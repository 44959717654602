import { CREATE, GET_ONE, GET_MANY } from 'react-admin';

const responseHandler = (response, type, resource) => {
  switch (type) {
    case GET_ONE: {
      return {
        data: response.data.administrators.data[0],
      };
    }
    case GET_MANY: {
      return {
        data: response.data.administrators.data,
        total: response.data['total'],
      }        
    }
    case CREATE: {
      return {
        data: response.data.data,
      }
    }
    default: {
      return {
        data: response.data.administrators.data,
        total: response.data['total'],
      }
    }
  }
};

export default responseHandler;