import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import useAlerts from "../../../hooks/useAlerts"

import Rest from "../../../Providers/Rest";
import styles from "./styles";
import HeaderMenu from "../../CouponSegmentList/CouponList/Components/HeaderMenu";

const SegmentEdit = (props) => {
  const { history } = props;
  const { id } = props;
  const [name, setName] = useState("");
  const [rawFile, setRawFile] = useState([]);
  const [hidden, setHidden] = useState(false);
  const [render, setRender] = useState({
    status: false,
    dataNotSave: [],
  });
  const { errorMessage } = useAlerts()
  const [finished, setFinished] = useState(true);
  const [isInformationCompleted, setIsInformationCompleted] = useState(false);

  const getInfo = async () => {
    try {
      const response = await Rest("GET_ONE", "segments", { id });

      if (response) {
        const {
          data: {
            attributes: { name },
          },
        } = response;
        setName(name);
      }
    } catch (e) {
      errorMessage()

    }
  };

  useEffect(() => {
    getInfo();
  }, [id]);

  const sendInfo = async () => {
    let data = {
      id,
      data: {
        attributes: {
          name: name.trim(),
          document_file: rawFile[0],
        },
      },
    };

    try {
      const response = await Rest("UPDATE", "segments", data);
      if (response.data) {
        if (rawFile.length === 0) return history.push("/segments");

        const { attributes } = response.data;

        const { user_not_found } = attributes;

        setFinished(false);

        setRender({
          status: true,
          dataNotSave: user_not_found,
        });
        setHidden(true);
        return setName(attributes.name);
      }
    } catch (e) {

      if (typeof e.data.error.message === 'string') {
        errorMessage(e.data.error.message)
        document.getElementById("fileLoading").value = "";
        setRawFile([]);
        return;
      }
      
      if (e.data.error.message.document_file) {
        errorMessage(e.data.error.message.document_file[0])
        document.getElementById("fileLoading").value = "";
        setRawFile([]);
        return;
      }

      if (e.data.error.message.name[0]) {
        errorMessage(e.data.error.message.name[0])
        document.getElementById("fileLoading").value = "";
        setRawFile([]);
        return;
      }
      errorMessage()
      document.getElementById("fileLoading").value = "";
      setRawFile([]);
      return;
    }
  };

  const changeName = (event) => {
    setName(event.target.value);
  };

  useEffect(() => {
    if (name !== "" && rawFile.length !== 0) {
      setIsInformationCompleted(true);
    }

    if (name === "" || rawFile.length === 0) {
      setIsInformationCompleted(false);
    }
  }, [rawFile, name]);

  const showUserSaved = () => {
    return history.push(`/segmentListUsers?filter=%7B%22id%22%3A%22${id}%22%7D&order=DESC&page=1&perPage=10&sort=id`)
  }

  const RenderData = (props) => {
    return (
      <CardContent>
        <Table>
          <TableHead>
            <Grid item xs={12} style={styles.subTitle}>
              {props.title}
            </Grid>
            <TableRow>
              <TableCell align={"right"} style={{ width: 300 }}>
                Usuario #
              </TableCell>
              <TableCell align={"right"}>Email</TableCell>
            </TableRow>
          </TableHead>

          {Object.entries(props.data).length > 0 ? (
            <TableBody>
              {props.data.map((user, index) => {
                return (
                  <TableRow>
                    <TableCell align={"right"}>{index + 1}</TableCell>

                    {props.title === "Usuarios no guardados" ? (
                      <TableCell align={"right"}>{user}</TableCell>
                    ) : (
                      <TableCell align={"right"}>{user.email}</TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <Grid item style={styles.notFound}>
              No existen coincidencias
            </Grid>
          )}
        </Table>
      </CardContent>
    );
  };

  return (
    <>
      <HeaderMenu history={history} />
      <Card>
        <CardContent>
          <Grid container className={styles.container}>
            {!hidden ? (
              <Grid item xs={12} style={styles.header}>
                Editar Segmento
              </Grid>
            ) : (
              <Grid item xs={12} style={styles.header}>
                Editado Correctamente
              </Grid>
            )}

            <Grid item xs={12} style={styles.boxCenter}>
              <TextField
                label='Nombre del segmento'
                value={name}
                onChange={changeName}
              />
            </Grid>
            {!hidden && (
              <Grid item xs={12} style={styles.boxCenter}>
                <Button
                  variant='contained'
                  component='label'
                  style={styles.inputFile}
                >
                  Elige el archivo .csv
                  <input
                    type='file'
                    name='files'
                    accept='.csv'
                    id='fileLoading'
                    hidden
                    onChange={(e) => setRawFile(e.target.files)}
                  />
                </Button>
              </Grid>
            )}
            <Grid item xs={12} style={styles.boxCenter}>
              {rawFile.length > 0 && (
                <Grid item xs={12} style={styles.header}>
                  <Grid>{rawFile.length > 0 && "Archivo Cargado"}</Grid>
                  <Grid> {rawFile.length > 0 && rawFile[0].name}</Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} style={styles.header}>
              <Button
                variant='contained'
                color='primary'
                onClick={() => history.push("/segments")}
              >
                <div style={{ color: "darkslategray" }}>Cerrar </div>
              </Button>

              {finished && (
                <Button
                  variant='contained'
                  color='primary'
                  onClick={sendInfo}
                  style={{ margin: 10 }}
                  disabled={isInformationCompleted === false}
                >
                  <div style={{ color: "#000000" }}>Continuar</div>
                </Button>
              )}
            </Grid>
          </Grid>
        </CardContent>

        {render.status && (
          <Grid style={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={showUserSaved}
              variant='contained'
              color='primary'
            >
              <div style={{ color: "darkslategray" }}>Usuarios guardados </div>

            </Button>
          </Grid>
        )}

        {render.status && (
          <RenderData title={"Usuarios no guardados"} data={render.dataNotSave} />
        )}
      </Card>
    </>
  );
};

SegmentEdit.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default SegmentEdit;
