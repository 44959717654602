import { apiCall } from "../../Middlewares";
import { ENDPOINTS } from "../../Config/env";

const fetchProvidersRecharge = async (id) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.PROVIDER_RECHARGE.PROVIDERS_RECHARGE(id),
  });
};

const updateProviderRecharge = async (id, data) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.PROVIDER_RECHARGE.UPDATE_PROVIDER_RECHARGE(id),
    data,
  });
};

export default {
  fetchProvidersRecharge,
  updateProviderRecharge,
};
