import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@material-ui/core";
import PropTypes from "prop-types";

import { COLUMS_TITLES } from "../utils";

import { styles } from "../styles";

const KeyPixs = ({ handleDeleteKeyPix, handleOpenModal, data }) => (
  <>
    <div style={styles.container_show_pix}>
      <Button
        variant="contained"
        style={styles.botton_create}
        onClick={handleOpenModal}
      >
        Crear llave pix
      </Button>
    </div>
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            {COLUMS_TITLES.map((colum, index) => {
              return <TableCell key={index}>{colum}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 ? (
            data.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.key_id}</TableCell>
                <TableCell>{item.key_type_id}</TableCell>
                <TableCell>{item.key_value}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDeleteKeyPix(item.id)}
                    data-testid="delete-button"
                  >
                    Eliminar
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={COLUMS_TITLES.length}>
                No hay datos disponibles
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  </>
);

KeyPixs.propTypes = {
  handleDeleteKeyPix: PropTypes.func,
  handleOpenModal: PropTypes.func,
  data: PropTypes.array,
};

KeyPixs.defaultProps = {
  handleDeleteKeyPix: () => {},
  handleOpenModal: () => {},
  data: [],
};

export default KeyPixs;
