import { UPDATE, CREATE, GET_ONE } from "react-admin";

import { API_URL } from "../../../../Config/env";

const requestHandler = (type, params) => {
  const formData = new FormData();
  switch (type) {
    case CREATE: {
      formData.append("image_desktop", params.imageDesktop[0]);
      formData.append("image_mobile", params.imageMobile[0]);
      formData.append("identifier", params.identifier.toUpperCase());
      formData.append("text_banner", params.textBanner);
      formData.append("background_color_1", params.fontColor1);
      formData.append("background_color_2", params.fontColor2);
      formData.append("bt_backgroud_color", params.btColorBg);
      formData.append("bt_text_color", params.btColorText);
      formData.append("bt_text", params.btText);
      formData.append("type_route", params.typeRoute);
      formData.append("url_route", params.urlRoute);
      formData.append("is_global", params.isGlobal);
      formData.append("is_active", params.isActive);
      formData.append("type_user", params.typeUser);
      formData.append("time_transition", params.timeTransition);

      formData.append(
        "list_countries",
        JSON.stringify(params.bannersCountries)
      );
      return {
        url: `${API_URL}/admin/banners`,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
    }

    case UPDATE: {
      if (params.is_change_status) {
        formData.append("is_active", params.is_active);

        return {
          url: `${API_URL}/admin/banners/${params.id}`,
          method: "put",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
      } else {
        formData.append("image_desktop", params.imageDesktop && params.imageDesktop[0]);
        formData.append("image_mobile", params.imageMobile && params.imageMobile[0]);
        formData.append("identifier", params.identifier);
        formData.append("text_banner", params.textBanner);
        formData.append("background_color_1", params.fontColor1);
        formData.append("background_color_2", params.fontColor2);
        formData.append("bt_backgroud_color", params.btColorBg);
        formData.append("bt_text_color", params.btColorText);
        formData.append("bt_text", params.btText);
        formData.append("type_route", params.typeRoute);
        formData.append("url_route", params.urlRoute);
        formData.append("type_user", params.typeUser);
        formData.append("time_transition", params.timeTransition);

        return {
          url: `${API_URL}/admin/banners/${params.id}`,
          method: "put",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
      }
    }

    case GET_ONE: {
      if (params.isShowDetails) {
        return {
          url: `${API_URL}/admin/banners/show_details/${params.id}`,
          method: "get",
        };
      }

      return {
        url: `${API_URL}/admin/banners/?q[id_eq]=${params.id}`,
        method: "get",
      };
    }
    
    default: {
      return {};
    }
  }
};

export default requestHandler;
