import React from 'react';
import { Grid, TextField, Card, Typography, CardContent } from '@material-ui/core';

import styles from "./styles";
import { FilterAmount } from '../../../CustomComponents';
import { NeitcomStatusLogTable } from '..';
import { availableCountriesBeneficiariesTruora } from '../../../../../Config/constants';

const Show = (props) => {
  const { record, isLoading } = props;

  if (isLoading || !record || !record.attributes) return null;

  const {
    id,
    attributes: {
      company_name,
      first_name,
      last_name,
      city,
      address,
      email,
      document_number,
      document_type,
      phone,
      beneficiary_type,
      country,
      criminal_verification,
    },
  } = record;

  const RenderField = (props) => {
    const { label, value, readOnly, xs, sm } = props
    return (
      <Grid
        style={styles.divisor}
        item
        xs={xs}
        sm={sm}
      >
        <TextField
          label={label}
          value={value}
          fullWidth
          InputProps={{
            readOnly: readOnly,
          }}
        />
      </Grid>
    );
  }

  return (
      <Card style={styles.card}>
        <CardContent>
          <Typography variant="h4" gutterBottom style={styles.titleWhite}>
            Datos personales
          </Typography>
          <Grid container direction="row" justify="center" alignItems="center" style={styles.divisorTop}>
            <RenderField
              label={"ID del beneficiario"}
              value={id}
              readOnly={true}
              xs={12}
              sm={5}
            />
            {
              company_name && (
                <RenderField
                  label={"Empresa"}
                  value={company_name}
                  readOnly={true}
                  xs={12}
                  sm={5}
                />
              )
            }
            {
              first_name && (
                <RenderField
                  label={"Nombre"}
                  value={first_name}
                  readOnly={true}
                  xs={12}
                  sm={5}
                />
              )
            }
            {
              last_name && (
                <RenderField
                  label={"Apellido"}
                  value={last_name}
                  readOnly={true}
                  xs={12}
                  sm={5}
                />
              )
            }
            {
              document_type && (
                <RenderField
                  label={"Tipo de documento"}
                  value={document_type}
                  readOnly={true}
                  xs={12}
                  sm={5}
                />
              )
            }
            <RenderField
              label={"Número de documento"}
              value={document_number}
              readOnly={true}
              xs={12}
              sm={5}
            />
            <RenderField
              label={"Correo"}
              value={email}
              readOnly={true}
              xs={12}
              sm={5}
            />
            {
              beneficiary_type && (
                <RenderField
                  label={"Tipo de beneficiario"}
                  value={beneficiary_type}
                  readOnly={true}
                  xs={12}
                  sm={5}
                />
              )
            }
            {
              phone && (
                <RenderField
                  label={"Teléfono"}
                  value={phone}
                  readOnly={true}
                  xs={12}
                  sm={5}
                />
              )
            }
            <RenderField
              label={"País"}
              value={country.name}
              readOnly={true}
              xs={12}
              sm={5}
            />
            {
              city && (
                <RenderField
                  label={"Ciudad"}
                  value={city}
                  readOnly={true}
                  xs={12}
                  sm={5}
                />
              )
            }
            {
              address && (
                <RenderField
                  label={"Dirección"}
                  value={address}
                  readOnly={true}
                  xs={12}
                  sm={5}
                />
              )
            }
          </Grid>
        </CardContent>
        <FilterAmount id={id} document_number={document_number} />
        <NeitcomStatusLogTable
          verification={criminal_verification?.['neitcom']}
          beneficiary_id={id}
          title={'Verificación Neitcom'}
          provider={'neitcom'}
        /> <br />
        {
          availableCountriesBeneficiariesTruora.includes(country?.iso_code) &&
          <NeitcomStatusLogTable
            verification={criminal_verification?.['truora']}
            beneficiary_id={id}
            title={'Verificación Truora'}
            provider={'truora'}
          />
        }
      </Card>
  );
};

Show.defaultProps = {
  record: {
    id: '',
    attributes: {
      company_name: '',
      first_name: '',
      last_name: '',
      city: '',
      address: '',
      email: '',
      document_number: '',
      document_type: '',
      phone: '',
      beneficiary_type: '',
      country: {
        attributes: {
          name: '',
        },
      },
    }
  }
};

export default Show;