import { createBrowserHistory as createHistory } from 'history';
import { parseAPIMessage } from '../Utils/index'
import Swal from "sweetalert2";

const history = createHistory();

const getMessage = (data) => {
    if(data.user){
        return data.user;
    }else if(data.errors){
        return data.errors.map((e) => parseAPIMessage(e)[1]).join('\n');
    }else{
        // It must not happen, it is just in case.
        return data;
    }
}

const goToLogin = (data) => {
    if (data) {
        Swal.fire({
            title: "Error!",
            text: getMessage(data),
            icon: "error"
        });
    }

    history.replace('/login');
}

const Navigation = {
    nav: (function() {
        let singleton;
        return () => {
            if (!singleton) {
                singleton = {
                    history: history,
                    goToUsers: () => history.push('/usuarios'),
                    goToTransaction: () => history.push('/transacciones'),
                    goToWithdrawal: () => history.push('/retiros'),
                    goToLogin: (message) => goToLogin(message)
                }
            }
            return singleton;
        };
    }())
};

export default Navigation;
