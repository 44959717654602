import React from "react";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import WarningIcon from "@material-ui/icons/Warning";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import PropTypes from "prop-types";

import styles from "./styles";

const NeitcomStatus = (props) => {
  const { veredict, max_percentage, has_ignored_items, provider } = props;

  const criminalVerificationVeredicts = {
    verified_ok: {
      message: "verified_ok",
      icon: (
        <VerifiedUserIcon style={{ ...styles.containerIcon, color: "lime" }} />
      ),
      extra: `[${provider?.toUpperCase()}] Verificación exitosa${has_ignored_items ? "*" : ""}`,
    },
    verified_alert: {
      message: "verified_alert",
      icon: <WarningIcon style={{ ...styles.containerIcon, color: "red" }} />,
      extra: `[${provider?.toUpperCase()}] Verificación con alerta${
        has_ignored_items ? "*" : ""
      } (${max_percentage} %)`,
    },
    not_verified: {
      message: "not_verified",
      icon: (
        <IndeterminateCheckBoxIcon
          style={{ ...styles.containerIcon, color: "white" }}
        />
      ),
      extra: `[${provider?.toUpperCase()}] Sin verificación`,
    },
  };

  return (
    <>
      {criminalVerificationVeredicts[veredict].icon}
      {criminalVerificationVeredicts[veredict].extra}
    </>
  );
};

NeitcomStatus.propTypes = {
  veredict: PropTypes.string.isRequired,
  max_percentage: PropTypes.number.isRequired,
  has_ignored_items: PropTypes.bool.isRequired,
};

NeitcomStatus.defaultProps = {
  veredict: "not_verified",
  max_percentage: 0,
  has_ignored_items: false,
};

export default NeitcomStatus;
