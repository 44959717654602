import React from "react";
import { Filter, TextInput, SelectInput } from "react-admin";

import { status } from "./utils";
import useClearFilters from "../../../../hooks/useClearFilters";

const CouponFilter = (props) => {
  useClearFilters(props.setFilters);

  return (
    <Filter {...props}>
      <TextInput
        label={"Buscar"}
        source={"search_key"}
        helperText={"Busca por identificador o campaña"}
        alwaysOn
      />
      <SelectInput
        label={"Estado"}
        source={"status"}
        choices={status}
        helperText={"Filtra el usuario por estado de solicitud"}
        alwaysOn
      />
    </Filter>
  );
};

export default CouponFilter;
