import React from 'react';
import moment from 'moment';
import styles from '../styles';
import { Show, SimpleShowLayout } from 'react-admin';
import { Grid, TextField } from '@material-ui/core';

import { frequencyMaintenance, maintenanceProvider, maintenanceType } from '../../../Config/constants';

const AdminShow = (props) => {

  const RenderFields = (propsRecord) => {
    const getValue = (arr, value) => arr.find(a => a.id === value).name;
    const { record: {
      attributes: {
        provider,
        frequency,
        country: {
          name
        },
        amount,
        amount_to_business,
        type_charge
      }
    } } = propsRecord;

    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        xs={12}
        sm={8}
        item
        style={styles.container}
      >
        <Grid
          xs={12}
          sm={4}
          item
          style={styles.items}
        >
          <TextField
            value={getValue(maintenanceProvider, provider)}
            label={'Proveedor'}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid
          xs={12}
          sm={4}
          item
          style={styles.items}
        >
          <TextField
            value={getValue(frequencyMaintenance, frequency)}
            label={'Frecuencia'}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid
          xs={12}
          sm={4}
          item
          style={styles.items}
        >
          <TextField
            value={name}
            label={'País'}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid
          xs={12}
          sm={4}
          item
          style={styles.items}
        >
          <TextField
            value={amount}
            label={'Monto (natural) en USD'}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid
          xs={12}
          sm={4}
          item
          style={styles.items}
        >
          <TextField
            value={amount_to_business}
            label={'Monto (business) en USD'}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid
          xs={12}
          sm={4}
          item
          style={styles.items}
        >
          <TextField
            value={getValue(maintenanceType, type_charge)}
            label={'Tipo'}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Show 
      title={`Cuota de mantenimiento #${props.id}`} 
      {...props}
    >
      <SimpleShowLayout>
        <RenderFields />
      </SimpleShowLayout>
    </Show>
  );
};

export default AdminShow;
