export const styles = {
  container: {
    backgroundColor: "#242424",
    minHeight: 756,
    maxHeight: 756,
    marginTop: 60,
    position: "relative",
    borderRadius: 10,
    paddingLeft: 20,
    paddingRight: 20,
    overflowY: "auto",
    overflowX: "auto",
  },
  buttonClose: {
    position: "absolute",
    top: 5,
    right: 0,
    border: "none",
    backgroundColor: "transparent",
    fontSize: 16,
    color: "#DFDFDF",
  },
};
