import React, {useState, useEffect} from 'react';
import { Filter, TextInput, SelectInput,} from 'react-admin';

import { withStyles } from '@material-ui/core';
import Countries from '../../../../Providers/Countries';
import useClearFilters from '../../../../hooks/useClearFilters';

const styles = {
    helperText: {
        color:' rgba(255, 255, 255, 0.7)',
        margin: '8px 0',
        fontSize: '0.75rem',
        textAlign: 'left',
        lineHeight: '1em'
    },
};

const BusinessFilter = (props) => {
    useClearFilters(props.setFilters);

    const [birtCountries, setBirtCountries] = useState([]);
    const [residenceCountries, setResidenceCountries] = useState([]);

    useEffect(() => {
        let isFetch = true;
        (async () => {
            if (isFetch) {
                try {
                    const response = await Countries.fetchCountriesAdmin(true);
                    setBirtCountries(response.data.countries.data);
                } catch (error) {
                }
            }
        })();
        return () => {
            isFetch = false;
        }
    }, []);

    useEffect(() => {
        let isFetch = true;
        (async () => {
            if (isFetch) {
                try {
                    const response = await Countries.fetchFilterCountriesAdmin("q[is_register_eq]=true");
                    setResidenceCountries(response.data.countries.data);
                } catch (error) {
                }
            }
        })();
        return () => {
            isFetch = false;
        }
    }, []);
    
    return (
        <Filter {...props}>
            <TextInput
                label={'Buscar'}
                source={'search_key'}
                helperText={"Busca un usuario por nombre, apellido o email"}
                alwaysOn
            />
            <SelectInput
                label={'País de nacimiento'}
                source={'residence_country'}
                choices={birtCountries}
                optionValue="id"
                optionText="attributes.name"
                helperText={"Paises"}
            />
            <SelectInput
                label={'País de residencia'}
                source={'country'}
                choices={residenceCountries}
                optionValue="id"
                optionText="attributes.name"
                helperText={"Paises"}
            />
        </Filter>
    );
};

export default withStyles(styles)(BusinessFilter);
