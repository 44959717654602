import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import { apiCall } from "../../Middlewares";
import { API_URL } from "../../Config/env";

import styles from "./styles";

const BtcInformationUser = (props) => {
  const { user } = props;
  const [adminBtc, setAdminBtc] = useState([]);
  const [totalBtc, setTotalBtc] = useState(0);

  useEffect(() => {
    let isFetch = true;

    (async () => {
      try {
        if (isFetch) {
          const config = {
            url: `${API_URL}/admin/transactions/btc_addresses`,
            method: "get",
          };

          const res = await apiCall({
            authorization: true,
            ...config,
          });

          setTotalBtc(res.data.total_btc_admin);
          setAdminBtc(res.data.addresses.data);
        }
      } catch (e) {}
    })();

    return () => {
      isFetch = false;
    };
  }, [user]);

  const renderizador = () => {
    return (
      <div style={styles.containerTab}>
        <Paper style={styles.paper}>
          <Table style={styles.table}>
            <caption style={styles.title}>Billeteras Bitcoin</caption>
            <TableHead>
              <TableRow>
                <TableCell align={"right"} style={styles.textTitle}>
                  Id
                </TableCell>
                <TableCell align={"right"} style={styles.textTitle}>
                  Direcciones Wallet
                </TableCell>
                <TableCell align={"right"} style={styles.textTitle}>
                  Balance Wallet
                </TableCell>
                <TableCell align={"right"} style={styles.textTitle}>
                  Balance Administrador
                </TableCell>
              </TableRow>
            </TableHead>
            {adminBtc.map((UserInformation) => {
              const {
                attributes: {
                  total_balance,
                  multisig_address,
                  id,
                  admin_balance,
                },
              } = UserInformation;
              return (
                <TableBody key={id}>
                  <TableRow key={id}>
                    <TableCell align={"right"} style={styles.textBody}>
                      {id}
                    </TableCell>
                    <TableCell align={"right"} style={styles.textBody}>
                      {multisig_address}
                    </TableCell>
                    <TableCell align={"right"} style={styles.textBody}>
                      {total_balance}
                    </TableCell>
                    <TableCell align={"right"} style={styles.textBody}>
                      {admin_balance}
                    </TableCell>
                  </TableRow>
                </TableBody>
              );
            })}
            <TableBody>
              <TableCell align={"right"}></TableCell>
              <TableCell align={"right"} style={styles.textTitle}>
                Balance total
              </TableCell>
              <TableCell align={"right"} style={styles.textTitle}></TableCell>
              <TableCell align={"right"} style={styles.textTitle}>
                {totalBtc}
              </TableCell>
            </TableBody>
          </Table>
        </Paper>
        <br />
        <br />
      </div>
    );
  };

  return (
    <div style={styles.container}>
      <div style={{ color: "#FFFFFF" }}>
        {setAdminBtc.length !== 0 ? (
          renderizador()
        ) : (
          <div style={styles.textNoWallets}>
            "No hay direcciones bitcoin asociadas"
          </div>
        )}
      </div>
    </div>
  );
};

export default BtcInformationUser;
