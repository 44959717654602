import { apiCall } from "../../Middlewares";
import { ENDPOINTS } from "../../Config/env";

const getStatus = async (utility_number) => 
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.SERVICES.GET_STATUS(utility_number),
  });

export default {
  getStatus,
};