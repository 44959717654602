import {useState, useCallback, useMemo} from 'react';

import {TRACK_URL} from '../Config/env';

const useCopyUrl = (props = {}) => {
    const {record = {}} = props;
    const {attributes = {}} = record;
    const {encrypt_id = ''} = attributes;

    const [isCopied, setCopied] = useState(false);

    const onCopy = useCallback(() => {
      navigator.clipboard.writeText(`${TRACK_URL}/${encrypt_id}`);
      setCopied(true);
    }, [encrypt_id]);

    const text = useMemo(() => {
        return isCopied ? '¡Copiado!' : 'Copiar';
    }, [isCopied]);

    return {
        text,
        isVisible: !!encrypt_id,
        onCopy,
    };
};

export default useCopyUrl;