export const RESOURCES = {
  usuarios: 'users',
  transacciones: 'transactions',
  retiros: 'withdrawals',
  retiros_label: 'retiros',
  vita: 'users',
  vita_label: 'vita',
  configuraciones: 'configuration',
  "países": 'countries',
  balance: 'balance',
  btc_addresses: 'btc_addresses',
  dlocal: 'dlocals',
  negocios: 'businesses',
  bitcoin: 'bitcoin',
  binance: 'binance',
  circle: 'circle',
  roles: 'roles',
  administrators: 'administrators',
  fintoc: 'fintoc',
  prices: 'prices_configuration',
  settingsHeaders: 'settings_headers',
  card_transactions: 'card_transactions',
  wallets: 'wallets',
  "business_transactions": "business_transactions",
  marketing_message: 'marketing_message',
  exported_files: 'exported_files',
  "estados de las transacciones": 'transaction_status',
  services: 'services',
  categories: 'categories',
  currencies: 'currencies',
  requests: 'solicitudes',
  bank_movements: 'bank_movements',
  bank_account_accesses: 'bank_account_accesses',
  card_accounts: 'card_accounts',
  segments: "segments",
  coupons: "coupons",
  couponsSegmentList: 'couponsList',
  segmentListUsers: 'segmentListUsers',
  binanceNetworks: 'binance_networks',
  beneficiaries: 'beneficiaries',
  prices_providers: 'prices_providers',
  cryptos: 'cryptos',
  preferential_prices: 'preferential_prices',
  fortress: 'fortress',
  banners: 'banners',
  snackbars: 'snackbars',
  general_alerts: 'general_alerts',
  vita_prices: 'vita_prices',
  maintenance_charges: 'maintenance_charges',
  custody_error_crypto_wallets: 'crypto_addresses'
};

export const resolveNameResource = (name) => {
  return RESOURCES[name] ? RESOURCES[name] : name;
};

export const RESOLVE_STATUS_TRANSACTIONS = (transaction) => {
  const { attributes: { status = '' } } = transaction;

  switch (status) {
    case 'started': {
      return 'Iniciada';
    }
    case 'completed': {
      return 'Completada';
    }
    case 'pending': {
      return 'Pendiente';
    }
    case 'denied': {
      return 'Rechazada';
    }
    case 'processed': {
      return 'Procesada';
    }
    case 'error': {
      return 'Error';
    }
    case 'failed': {
      return 'Fallido';
    }
    case 'time_out': {
      return 'Solicitud caducada'
    }
    case 'checking': {
      return 'Revisando'
    }
    default: {
      return 'Iniciada';
    }
  }

};

export const RESOLVE_CATEGORY_TRANSACTIONS = (transaction = {}) => {
  const { attributes: { category = '' } } = transaction;

  switch (category) {
    case 'sent': {
      return 'Envío';
    }
    case 'received': {
      return 'Recibo';
    }
    case 'deposit': {
      return 'Recarga';
    }
    case 'withdrawal': {
      return 'Retiro';
    }
    case 'transfer': {
      return 'Transferencia internacional';
    }
    case 'exchange': {
      return 'Intercambio';
    }
    case 'fee': {
      return 'Comisión';
    }
    case 'payment': {
      return 'Pago';
    }
    case 'service_payment': {
      return 'Pago de servicio';
    }
    case 'tax': {
      return 'Impuesto';
    }
    case 'recharge': {
      return 'Recarga';
    }
    case 'purchase': {
      return 'Compra';
    }
    case 'vita_sent': {
      return 'Envío a Vita Wallet';
    }
    case 'adjustment': {
      return 'Ajuste';
    }
    default: {
      return 'Envío';
    }
  };
};

export const replaceSymbols = value => value.replace('+', '%2B');