import React from 'react';
import {Datagrid, EditButton, List, TextField} from 'react-admin';

import {Pagination} from '../../../Components';
import useAccessRole from "../../../hooks/useAccessRole";
import useExportFiles from '../../../hooks/useExportFiles';
import { FIELDS_EXPORTS_FILES } from '../../utils';

const SettingsHeadersList = (props) => {
    const {isAccess} = useAccessRole();
    const { exportFilesSimple } = useExportFiles();

    const exporter = posts => {
        exportFilesSimple(posts, FIELDS_EXPORTS_FILES.SETTINGS_HEADERS)
    }

    return (
        <List
            {...props}
            title={'Lista de cabeceras'}
            pagination={<Pagination/>}
            bulkActionButtons={false}
            exporter={exporter}
            debounce={1000}
        >
            <Datagrid>
                <TextField
                    source={'id'}
                    label={'Id'}
                />
                <TextField
                    source={'x_rules'}
                    label={'Reglas'}
                />
                <TextField
                    source={'x_residence_config'}
                    label={'Configuración de residencia'}
                />
                <TextField
                    source={'x_settings'}
                    label={'Configuraciones'}
                />
                {isAccess('edit_settings_headers') && (
                    <EditButton label="Editar" />
                )}
            </Datagrid>
        </List>
    );
};

export default SettingsHeadersList;
