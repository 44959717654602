import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Modal, TextField } from "@material-ui/core";

import { useAccessRole } from "../../../../../../hooks";
import styles from "../styles";

const ModalManualWithdrawal = (props) => {
  const {
    isCompleteWitdrawal,
    setCompleteWithdrawal,
    hash,
    changeHash,
    completeWithdrawal
  } = props;

  const handleClose = () => setCompleteWithdrawal(false);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isCompleteWitdrawal}
    >
      <div
        style={styles.centerAlert}
      >
        <div
          style={styles.centerColumn}
        >
          <div
            style={styles.alertTitle}
          >
            HASH RESULTANTE
          </div>
          <div
            style={{ ...styles.alertBody, width: "100%", maxWidth: 300 }}
          >
            Por favor ejecute la transacción manualmente y al finalizar
            complete el hash resultante
          </div>
          <div
            style={{ paddingBottom: 30, width: "100%", maxWidth: 500 }}
          >
            <TextField
              style={{ width: "100%" }}
              value={hash}
              onChange={changeHash}
              placeholder={"Hash"}
            />
          </div>
          <div
            style={styles.alertButtom}
          >
            <Button
              onClick={completeWithdrawal}
              style={{ ...styles.buttonBlue, opacity: hash ? 1 : 0.5 }}
              disabled={!hash}
            >
              PAGAR
            </Button>
            <Button
              onClick={handleClose}
              style={styles.buttonRed}
            >
              CANCELAR
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

ModalManualWithdrawal.defaultProps = {
  isCompleteWitdrawal: false,
  setCompleteWithdrawal: () => {},
  hash: '',
  changeHash: () => {},
  completeWithdrawal: () => {}
};

ModalManualWithdrawal.propTypes = {
  isCompleteWitdrawal: PropTypes.bool.isRequired,
  setCompleteWithdrawal: PropTypes.func.isRequired,
  hash: PropTypes.string.isRequired,
  changeHash: PropTypes.func.isRequired,
  completeWithdrawal: PropTypes.func.isRequired
};

export default ModalManualWithdrawal;