import {OWNER} from '../Config/constants';

const useAccessRole = () => {

  const role = JSON.parse(localStorage.getItem('role_access'));

  const getListPermissions = () => {
    return role ? role["permissions"] : null;
  }

  const getRole = () => {
    return role ? role["name"] : null;
  }

  const isAccess = (toAccess) => {
    const list = getListPermissions();

    if(list) {
      if(getRole() === OWNER) {
        return true;
      } else {
        return list.find(data => {return data.name === toAccess}) ? true : false;
      }
    } else {
      return false;
    }
  }

  return {
    getListPermissions,
    getRole,
    isAccess,
  };
};

export default useAccessRole;