import React, {useEffect, useState} from 'react';
import {Filter, SelectInput, TextInput} from 'react-admin';

import BusinessProvider from '../../../Providers/Businesses';

const BusinessFilter = (props) => {
    const {isWalletSearch} = props;
    const [business, setBusiness] = useState([]);

    useEffect(() => {
        let isFetch = true;

        (async () => {
            try {
                if (isFetch) {
                    const response = await BusinessProvider.getBusiness();
                    isFetch = false;
                    setBusiness(response.data.businesses.data.filter((item) => (item && item.attributes && item.attributes.first_name && item.attributes.last_name)).map((item) => ({
                        id: item.id,
                        name: `${item.attributes.first_name.toUpperCase()} ${item.attributes.last_name.toUpperCase()}`,
                    })))
                }
            } catch {}
        })();

        return () => {
            isFetch = false;
        }
    }, []);

    return (
        <Filter {...props}>
            {business.length > 0 && <SelectInput
                alwaysOn
                label={'Negocio'}
                source={'business_id'}
                choices={business}
            />}
            {isWalletSearch && (
                <TextInput
                    label={'Wallet que envía'}
                    source={'sender_uuid'}
                />
            )}
            {isWalletSearch && (
                <TextInput
                    label={'Wallet que recibe'}
                    source={'recipient_uuid'}
                />
            )}
        </Filter>
    );
};

export default BusinessFilter;
