import React       from 'react';
import { Announcement }  from '@material-ui/icons';

import AlertsList  from './AlertsList';
import useAccessRole from "../../hooks/useAccessRole";

const { isAccess } = useAccessRole();

export default {
    name: 'general_alerts',
    icon: Announcement,
    list: isAccess('show_general_alerts') ? AlertsList : false,
};