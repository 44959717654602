import React from "react";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Rest from "../../../../Providers/Rest";
import Swal from "sweetalert2";
import useAlerts from "../../../../hooks/useAlerts"
import { useReset } from "../../../../hooks";

const FormControl = (props) => {
  const {
    status,
    id,
    disabled,
    isConfirm,
    message,
    record: { attributes },
  } = props;
  const { errorMessage } = useAlerts()
  const { resetList } = useReset();

  const refreshList = () => {
    resetList();
  }

  const requestUpdateStatus = async (data) => {
    const response = await Rest("UPDATE", "coupons", data);

    if (response) {
      if (response.data.attributes.status === 'active') {
        Swal.fire({
          title: 'El cupón ha sido activado, sin embargo la notificación al usuario puede tardar unos minutos.',
          confirmButtonText: 'OK',
          icon: 'success',
        }).then(() => {
          refreshList();
        })
      } else{
        refreshList();
      }      
    }
  }

  let updateStatus = async (data) => {
    try {
      if (isConfirm) {
        Swal.fire({
          title: "Estas seguro?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
        }).then((result) => {
          if (result.isConfirmed) {
            requestUpdateStatus(data);
            Swal.fire(message);
          }
        });
      } else {
        requestUpdateStatus(data);
      }
      
    } catch (e) {
      errorMessage()
    }
  };

  const handleChange = (event) => {
    const data = { id, state: "" };

    if (attributes.status === "active") {
      data.state = "inactive";
      updateStatus(data);
    }
    if (attributes.status === "pending") {
      data.state = "active";
      updateStatus(data);
    }

    if (attributes.status === "inactive") {
      data.state = "active";
      updateStatus(data);
    }
  };
  if (disabled) {
    return (
      <FormControlLabel
        control={
          <Switch
            checked={status}
            onChange={handleChange}
            name={id}
            disabled
            color={"success"}
          />
        }
      />
    );
  } else {
    return (
      <FormControlLabel
        control={
          <Switch
            checked={status}
            onChange={handleChange}
            name={id}
            color={'primary'}
          />
        }
      />
    );
  }
};

export default FormControl;
