import React from 'react';
import moment from 'moment';

import {toMoney} from '../../../../Helpers/MoneyHelper';

moment.locale('es');
const UserRules = (props) => {
    const {record: {
        attributes: {
            default_currency,
            daily_limit_amount,
            daily_transbank_recharge_limit,
            monthly_transbank_recharge_limit,
        },
    }} = props;

    return (
        <div style={{paddingTop: 20, paddingBottom: 20}}>
            <div style={{paddingTop: 20}}>
                Límite diario TRANSBANK
                <div style={{fontSize: 20}}>{daily_transbank_recharge_limit ?
                    `${toMoney(daily_transbank_recharge_limit, default_currency)} ${default_currency.toUpperCase()}` :
                    'sin límite'}</div>
            </div>
            <div style={{paddingTop: 20}}>
                Límite mensual TRANSBANK
                <div style={{fontSize: 20}}>{monthly_transbank_recharge_limit ?
                    `${toMoney(monthly_transbank_recharge_limit, default_currency)} ${default_currency.toUpperCase()}` :
                    'sin límite'}</div>
            </div>
        </div>
    );
};

export default UserRules;