import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import useCreateCustodialAccount from '../../../../hooks/useCreateCustodialAccount';

export const CreateCustodialAccount = (props) => (
	<div style={styles.container}>
		{
			props.isCreateAccount && (
				<Button 
            		onClick={props.onCreate}
            		style={styles.button}
        		>
            		Crear cuenta
        		</Button>
			)
		}
	</div>
);

const styles = {
	button: {
		backgroundColor: '#00aaff',
	},
	distance: {
		marginLeft: 15,
	},
    container: {
        height: 80,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
};

CreateCustodialAccount.propTypes = {
    onCreate: PropTypes.func,
    isCreateAccount: PropTypes.bool,
};

CreateCustodialAccount.defaultProps = {
    onCreate: () => {},
    isCreateAccount: false,
};

const CreateCustodialAccountHoc = (props) => {
    const hook = useCreateCustodialAccount(props);
    return <CreateCustodialAccount {...hook} />;
};

export default CreateCustodialAccountHoc;