import React from 'react';
import { Show, SimpleShowLayout } from 'react-admin';
import {
  Grid,
  TextField,
  Card,
  Typography,
  CardContent,
  Table,
  Button
} from '@material-ui/core';
import styles from './styles';
import HeaderMenu from "../../CouponSegmentList/CouponList/Components/HeaderMenu";

const CouponsShow = (props) => {

  const { history } = props
  const RenderFields = (propsRecord) => {
    const { record: { id, attributes } } = propsRecord;
    const {
      amount,
      bonus_type,
      campaign,
      coupon_design,
      coupon_type,
      currency,
      dest_country,
      end_date,
      identifier,
      min_amount_transaction,
      number_coupons,
      segment,
      start_date,
      status,
      transaction_type,
    } = attributes;

    const listUsers = () => {
      return history.push(`/couponsList?filter=%7B%22id%22%3A%22${id}%22%7D&order=DESC&page=1&perPage=10&sort=id`)
    }

    return (
      <Card style={styles.card}>
        <CardContent>
          <Typography
            variant="h4"
            gutterBottom
            style={styles.titleWhite}
          >
            Cupón
          </Typography>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={styles.divisorTop}
          >
            <Grid
              item
              style={styles.containerField}
            >
              <TextField
                value={id}
                label={'Id'}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid
              item
              style={styles.containerField}
            >
              <TextField
                value={identifier}
                label={'Identificador'}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid
              item
              style={styles.containerField}
            >
              <TextField
                value={campaign}
                label={'Campaña'}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid
              item
              style={styles.containerField}
            >
              <TextField
                value={dest_country.name}
                label={'País de destino'}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid
              item
              style={styles.containerField}
            >
              <TextField
                value={start_date}
                label={'Fecha de inicio'}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid
              item
              style={styles.containerField}
            >
              <TextField
                value={end_date}
                label={'Fecha de fin'}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid
              item
              style={styles.containerField}
            >
              <TextField
                value={coupon_type}
                label={'Típo de cupón'}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid
              item
              style={styles.containerField}
            >
              <TextField
                value={number_coupons}
                label={'Cantidad de cupones'}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid
              item
              style={styles.containerField}
            >
              <TextField
                value={bonus_type}
                label={'Típo de bonificación'}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid
              item
              style={styles.containerField}
            >
              <TextField
                value={currency.toUpperCase()}
                label={'Moneda de origen'}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid
              item
              style={styles.containerField}
            >
              <TextField
                value={amount}
                label={'Valor'}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid
              item
              style={styles.containerField}
            >
              <TextField
                value={min_amount_transaction}
                label={'Monto minimo de transacción'}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid
              item
              style={styles.containerField}
            >
              <TextField
                value={transaction_type}
                label={'Típo de transacción'}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid
              item
              style={styles.containerField}
            >
              <TextField
                value={status}
                label={'Estado'}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        </CardContent>

        <CardContent>
          <Typography
            variant="h4"
            gutterBottom
            style={styles.titleWhite}
          >
            Diseño del cupón
          </Typography>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={styles.divisorTop}
          >
            <Grid
              item
              style={styles.containerField}
            >
              <TextField
                value={coupon_design && coupon_design.data.attributes.design_type}
                label={'Diseño del cupón'}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid
              item
              style={styles.containerField}
              sm={12}
            >
              <TextField
                value={coupon_design && coupon_design.data.attributes.message_body}
                fullWidth
                label={'Cuerpo del mensaje en el modal'}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid
              item
              style={styles.containerField}
              sm={12}
            >
              <TextField
                value={coupon_design && coupon_design.data.attributes.message_transactional}
                fullWidth
                label={'Mensaje en sección transaccional'}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        </CardContent>

        <CardContent>
          {segment &&
            <Typography variant="title" component="div" style={{ color: 'white', marginTop: 20, textAlign: 'center' }}>
              Nombre del segmento: {segment && segment.name}
            </Typography>
          }
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={styles.divisorTop}
          >
            <Grid
              item
              style={styles.containerField}
            >
            </Grid>
            <Table style={{ marginTop: 20 }}>
              {segment ?
                <Grid style={{ display: "flex", justifyContent: "center", }}>
                  <Button
                    onClick={listUsers}
                    style={{ backgroundColor: 'blue' }}
                  >
                    Ver Usuarios
                  </Button>
                </Grid>
                :
                <Typography variant="subtitle1" component="div" style={{ color: 'white', marginTop: 20, textAlign: 'center' }}>
                  No existe un segmento asignado
                </Typography>
              }
            </Table>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  return (
    <Show {...props} actions={false}>
      <SimpleShowLayout>
        <HeaderMenu history={history} />
        <RenderFields />
      </SimpleShowLayout>
    </Show>
  );
}

export default CouponsShow;