import { apiCall } from '../../Middlewares';
import { ENDPOINTS } from '../../Config/env';

const chargeToDlocal = async (amount) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.PAYMENTS.CREATE_CHARGE,
        data: {
            amount,
        }
    });
};

export default {
    chargeToDlocal,
}
