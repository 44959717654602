import React from 'react';
import { Filter, TextInput } from 'react-admin';

import useClearFilters from '../../../hooks/useClearFilters';

const PricesProvidersFilter = (props) => {
  useClearFilters(props.setFilters);

  return (
    <Filter {...props}>
      <TextInput
        label={'Buscar por nombre'}
        source={'name_provider'}
        helperText={"Busca por nombre"}
        alwaysOn
      />
    </Filter>
  );
};

export default PricesProvidersFilter;
