export const styles = {
  container_show_pix: {
    display: "flex",
    justifyContent: "end",
    marginBottom: 20,
    marginTop: 20,
  },
  botton_create: {
    backgroundColor: "#005FEE",
    fontWeight: "bold",
    fontSize: 14,
    color: "white",
    height: 50,
    marginBotton: 20,
  },
  container_return_pix: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 20,
    padding: "5%",
  },
  sub_container_return_pix: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button_send_return_pix: {
    marginTop: 40,
    width: 200,
  },
  container_create_pix: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 400,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 60,
  },
  width_400: {
    width: 400,
  },
};
