import { EuroSymbol } from "@material-ui/icons";
import useAccessRole from "../../hooks/useAccessRole";

import CouponList from "./CouponsList";
import CouponShow from "./CouponsShow";
import CouponCreate from "./CouponCreate";
import CouponEdit from "./CouponCreate";

const { isAccess } = useAccessRole();

export default {
  name: "coupons",
  icon: EuroSymbol,
  list: isAccess("show_coupons") ? CouponList : false,
  show: isAccess("show_coupons") ? CouponShow : false,
  create: isAccess("create_coupons") ? CouponCreate : false,
  edit: isAccess("edit_coupons") ? CouponEdit : false,
};
