import React              from 'react';
import { List, Datagrid } from 'react-admin';
import useExportFiles from '../../../hooks/useExportFiles';
import { FIELDS_EXPORTS_FILES } from '../../utils';

import { BusinessInfo }       from '../components';
import { BusinessFilter } from '../Filters';

const BusinessList = (props) => {
    const { exportFileBusiness } = useExportFiles();

    const exporter = posts => {
        exportFileBusiness(posts, FIELDS_EXPORTS_FILES.BUSINESS)
    }

    const RenderField = (propsRecord) => (
        <BusinessInfo {...propsRecord} />
    );
    
    return (
        <List
            {...props}
            filters={<BusinessFilter />}
            title={'Lista de negocios'}
            bulkActionButtons={false}
            exporter={exporter}
            debounce={1000}
        >
            <Datagrid>
                <RenderField {...props} />
            </Datagrid>
        </List>
    );
};

export default BusinessList;
