import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { ENV } from '../../Config/env';
import Alerts from '../../Resources/Transactions/components/Alerts';

const Photo = (props) => {
  const { record: {
    id,
    attributes: {
      document_image_url,
      back_document_image_url,
      email,
      first_name,
      last_name,
      alert_count,
    },
  } } = props;

  const FRONT = 'MODAL/FRONT';
  const BACK = 'MODAL/BACK';

  const [modal, setModal] = useState({
    type: FRONT,
    visible: false,
  });

  const documentUrl = useMemo(() => {
    if (['local', 'dev'].includes(ENV)) {
      return document_image_url;
    } else {
      return document_image_url ? document_image_url.replace('http:', 'https:') : null;
    }
  }, [document_image_url]);

  const backDocumentUrl = useMemo(() => {
    if (['local', 'dev'].includes(ENV)) {
      return back_document_image_url;
    } else {
      return back_document_image_url ? back_document_image_url.replace('http:', 'https:') : null;
    }
  }, [back_document_image_url]);

  const downloadURI = (uri, name) => {
    var link = document.createElement("a");
    link.download = name;
    link.target = '_blank';
    link.href = uri;
    link.click();
  };

  const isImageFormat = (value) => {
    return /((.png)|(.jpeg)|(.jpg))$/.test(value);
  };

  if (documentUrl || backDocumentUrl) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        {
          modal.visible && (
            <div style={{
              position: 'fixed',
              zIndex: 99999999,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              backgroundColor: '#1a1a1a',
              overflow: 'scroll'
            }}
              onClick={() => setModal(prev => ({ ...prev, visible: !prev.visible }))}
            >
              <img
                style={{ width: '100%', maxWidth: 600 }}
                src={modal.type === FRONT ? documentUrl : backDocumentUrl}
              />
            </div>
          )
        }
        {
          documentUrl && (
            isImageFormat(documentUrl) ? (
              <div
                className={'document_verify_front'}
                onClick={() => setModal({ type: FRONT, visible: true })}
              >
                <img style={{ width: 200 }} src={documentUrl} />
              </div>
            ) : (
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: '#00b7ff', fontSize: 12 }}
                onClick={() => downloadURI(documentUrl, `${first_name} ${last_name} ${email}`)}
              >
                Ver archivo Frontal
              </Button>
            )
          )
        }
        {
          backDocumentUrl && (
            isImageFormat(backDocumentUrl) ? (
              <div
                className={'document_verify_back'}
                onClick={() => setModal({ type: BACK, visible: true })}
              >
                <img style={{ width: 200 }} src={backDocumentUrl} />
              </div>
            ) : (
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: '#00b7ff', fontSize: 12 }}
                onClick={() => downloadURI(backDocumentUrl, `${first_name} ${last_name} ${email}`)}
              >
                Ver archivo Reverso
              </Button>
            )
          )
        }
        <Alerts
            transaction_id={id}
            alert_count={alert_count}
            type={'User'}
        />
      </div>
    );
  }

  return (
    <div></div>
  );
};

Photo.propTypes = {
  record: PropTypes.shape({
    attributes: PropTypes.shape({}),
  }),
}

Photo.defaultProps = {
  record: {
    attributes: {
      document_image_url: '',
    },
  }
}

export default Photo;