const responseHandler = (response, type) => {
    switch (type) {
        default: {
            return {
                data: response.data.wallets,
                total: response.data['total'],
            }
        }
    }
};

export default responseHandler;
