import React from "react";
import { Filter, TextInput, SelectInput } from "react-admin";
import { status } from "./utils";

const CouponFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput
        label={"Buscar"}
        source={"emailSegment"}
        helperText={"Busca por email"}
        alwaysOn
      />
      <SelectInput
        label={"Estado"}
        source={"status"}
        choices={status}
        helperText={"Filtra el usuario por estado de cupón"}
        alwaysOn
      />
    </Filter>
  );
};

export default CouponFilter;
