import React from 'react';
import {
    Col, 
    Container, 
    Row,
} from 'react-grid-system';
import {
    Edit, 
    FormTab, 
    TabbedForm, 
    NumberInput, 
    TextInput, 
    BooleanInput, 
    SaveButton,
    Toolbar,
} from 'react-admin';

import styles from '../../Users/UserEdit/styles';

const VitaPriceToolbar = props => {
    const {hidden} = props;
    return (
        <Toolbar {...props} >
            {!hidden && (<SaveButton />)}
        </Toolbar>
    )
};

const VitaPricesEdit = (props) => (
    <Edit
        title={`Editar vita precio ${props.id}`}
        {...props}
    >
        <TabbedForm toolbar={<VitaPriceToolbar />}>
            <FormTab label="Datos de vita precio" style={styles.titleWhite}>
                <Container style={{ width: '100%' }}>
                    <Row container direction="row" justify="center" alignItems="center" className={{ flexGrow: 1 }}>
                        <Col xs={4} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                            <TextInput
                                label={'Id'}
                                source={'id'}
                                disabled
                            />
                        </Col>
                        <Col xs={4} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                            <TextInput
                                label={'Moneda'}
                                source={'attributes.currency'}
                                disabled
                            />
                        </Col>
                        <Col xs={4} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                            <TextInput
                                label={'Moneda de destino'}
                                source={'attributes.target_currency'}
                                disabled
                            />
                        </Col>
                        <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                            <NumberInput
                                label={'Precio spot'}
                                source={'attributes.spot'}
                            />
                        </Col>
                        <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                            <BooleanInput
                                label={'Estado'}
                                source={'attributes.is_enabled'}
                            />
                        </Col>
                    </Row>
                </Container>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default VitaPricesEdit;