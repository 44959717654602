import { Accessibility } from '@material-ui/icons';

import FortressList   from './FortressList';
import FortressCreate   from './FortressCreate';

import useAccessRole from '../../hooks/useAccessRole';

const {isAccess} = useAccessRole();

export default {
    name: 'fortress',
    icon: Accessibility,
    list: isAccess('get_custodial_accounts_fortress') && isAccess('get_identities_fortress') ? FortressList : false,
    create: isAccess('create_account_fortress') ? FortressCreate : false,
}