import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styles from '../styles';
import { Show, SimpleShowLayout } from 'react-admin';
import { Grid, TextField, Chip, Avatar, Tooltip, Typography } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { PermissionHelper } from '../../../Helpers';

const RoleShow = (props) => {

  const [permissionsList, setPermissionsList] = useState([]);

  useEffect(() => {

    PermissionHelper.getPermissions().then( data => {
      setPermissionsList(data);
    });

  }, []);

  const RenderFields = (propsRecord) => {

    const [activePermission, setActivePermission] = useState([]);

    const { record: {
      attributes: {
        name,
        public_name,
        description,
        created_at,
        updated_at,
        permissions
      }
    } } = propsRecord;

    useEffect(() => {
      setActivePermission(PermissionHelper.Permissions(permissions, permissionsList));
    }, []);

    return (
      <Grid>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          xs={12}
          sm={8}
          item
          style={styles.container}
        >
          <Grid
            xs={12}
            sm={5}
            item
            style={styles.items}
          >
            <TextField
              value={public_name}
              label={'Nombre Publico'}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid
            xs={12}
            sm={5}
            item
            style={styles.items}
          >
            <TextField
              value={name}
              label={'Nombre'}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid
            xs={12}
            sm={5}
            item
            style={styles.items}
          >
            <TextField
              value={moment(created_at).format('LL')}
              label={'Fecha Creado'}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid
            xs={12}
            sm={5}
            item
            style={styles.items}
          >
            <TextField
              value={moment(updated_at).format('LL')}
              label={'Fecha Modificado'}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid
            xs={12}
            sm={12}
            item
            style={styles.items}
          >
            <TextField
              value={description}
              label={'Descripción'}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          item
          xs={12}
          sm={10}
          style={styles.container}
        >
          <Grid xs={12} item>
            <Typography 
              variant="title" 
              gutterBottom
              style={styles.title}
            >
              Permisos del rol <b>{name}</b>
            </Typography>
          </Grid>
          {
            activePermission.map(val => (
              <Grid 
                key={val.id}
                xs={12} 
                sm={3}
                style={styles.divisor}
                item
                className='permission_container'
              >
                <Tooltip title={val.description} {...props}>
                  <Chip
                    label={val.public_name}
                    style={val.active ? styles.checked : styles.noChecked}
                    avatar={
                      <Avatar style={styles.avatar}>
                        {val.active ?
                          <CheckBoxIcon style={{ color: '#fff' }} /> :
                          <CheckBoxOutlineBlankIcon style={{ color: '#fff' }} />
                        }
                      </Avatar>
                    }
                  />
                </Tooltip>
              </Grid>
            ))
          }
        </Grid>
      </Grid>
    )
  };

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <RenderFields />
      </SimpleShowLayout>
    </Show>
  );
};

export default RoleShow;
