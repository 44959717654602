export const INTERNAL_DOCUMENT_TYPES_READING = [
    { id: 'facial_image', name: 'Imagen facial' },
    { id: 'document_front', name: 'Documento frontal' },
    { id: 'document_back', name: 'Documento trasero' },
    { id: 'business_tax_identity_document', name: 'Documento de identidad tributaria' },
    { id: 'business_company_statute', name: 'Estatuto empresarial' },
    { id: 'business_legal_representative_proof_document', name: 'Comprobante de representación legal' },
    { id: 'business_financial_intelligence_unit_document', name: 'Documento UAF' },
    { id: 'business_extra_document', name: 'Documento adicional' },
    { id: 'business_statement_of_funds', name: 'Prueba de fuente de fondos'},
    { id: 'business_ubo_document_front', name: 'Documento frontal beneficiario final'},
    { id: 'business_ubo_document_back', name: 'Documento trasero beneficiario final'},
    { id: 'business_user_profile_document', name: 'Documento de ficha de usuario'}
];

export const INTERNAL_DOCUMENT_TYPES_WRITING = {
    natural: [
        { id: 'facial_image', name: 'Imagen facial' },
        { id: 'document_front', name: 'Documento frontal' },
        { id: 'document_back', name: 'Documento trasero' },
    ],
    business: [
        { id: 'facial_image', name: 'Imagen facial' },
        { id: 'document_front', name: 'Documento frontal' },
        { id: 'document_back', name: 'Documento trasero' },
        { id: 'business_tax_identity_document', name: 'Documento de identidad tributaria' },
        { id: 'business_company_statute', name: 'Estatuto empresarial' },
        { id: 'business_legal_representative_proof_document', name: 'Comprobante de representación legal' },
        { id: 'business_financial_intelligence_unit_document', name: 'Documento UAF' },
        { id: 'business_extra_document', name: 'Documento adicional' },
        { id: 'business_statement_of_funds', name: 'Prueba de fuente de fondos'},
        { id: 'business_ubo_document_front', name: 'Documento frontal beneficiario final'},
        { id: 'business_ubo_document_back', name: 'Documento trasero beneficiario final'},
        { id: 'business_user_profile_document', name: 'Documento de ficha de usuario'}
    ]
};