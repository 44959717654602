import { EuroSymbol } from "@material-ui/icons";

import PricesProvidersList from "./PricesProvidersList";
import useAccessRole from "../../hooks/useAccessRole";

import PricesProviderShow from "./PricesProvidersShow";
import PricesProviderEdit from "./PricesProvidersEdit";

const { isAccess } = useAccessRole();

export default {
  name: "prices_providers",
  icon: EuroSymbol,
  list: PricesProvidersList,
  show: isAccess("show_prices_providers") ? PricesProviderShow : false,
  edit: isAccess("edit_prices_providers") ? PricesProviderEdit : false,
};
