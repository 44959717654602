import React from "react";
import PropTypes from "prop-types";

import styles from "../styles";

const ListActions = (props) => {
  const { editBanner, id, showBanner } = props;
  return (
    <>
      <button style={styles.styleButtonEdit} onClick={() => showBanner(id)}>
        Ver
      </button>
      <button style={{ ...styles.styleButtonEdit,marginLeft: 25 }} onClick={() => editBanner(id)}>
        Editar
      </button>
    </>
  );
};

ListActions.propTypes = {
  editBanner: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};

ListActions.defaultProps = {
  editBanner: () => {},
  id: 1,
};

export default ListActions;
