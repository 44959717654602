import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useAddGeneralLimit from '../../../hooks/useAddGeneralLimit';

export const AddGeneralLimit = (props) => (
    <div style={props.isDisabled ? styles.containerDisabled : styles.container}>
        <FormControl>
            <InputLabel>{props.country ? '' : 'País'}</InputLabel>
            <Select
                style={styles.selectContainer}
                value={props.country}
                onChange={props.changeCountry}
                disabled={props.isDisabled}
            >
                {
                    props.countries.map((country) => (
                        <MenuItem
                            key={country.id}
                            value={country.id}
                            disabled={country.disabled}
                        >
                            {country.name}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
        <FormControl>
            <InputLabel>{props.userType ? '' : 'Tipo de usuario'}</InputLabel>
            <Select
                label="Tipo de usuario"
                style={styles.selectContainer}
                value={props.userType}
                onChange={props.changeUserType}
                disabled={props.isDisabled}
            >
                {
                    props.country && props.usersTypes.map((userType) => (
                        <MenuItem
                            key={userType.id}
                            value={userType.id}
                            disabled={userType.disabled}
                        >
                            {userType.name}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
        <TextField
            style={styles.inputContainer}
            label="Monto límite USD"
            value={props.amount}
            onChange={props.changeAmount}
            disabled={props.isDisabled}
        />
        <Button
            style={props.isValidForm ? styles.button : styles.disabledButton}
            onClick={props.onSave}
            disabled={!props.isValidForm || props.isDisabled}
        >
            GUARDAR
        </Button>
        <Button
            style={styles.deleteButton}
            onClick={props.onDelete}
            disabled={props.isDisabled}
        >
            CANCELAR
        </Button>
    </div>
);

AddGeneralLimit.propTypes = {
    isDisabled: PropTypes.bool,
    country: PropTypes.number,
    userType: PropTypes.string,
    amount: PropTypes.number,
    changeCountry: PropTypes.func,
    changeUserType: PropTypes.func,
    changeAmount: PropTypes.func,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    isValidForm: PropTypes.bool,
    countries: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })
    ),
    usersTypes: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }),
    ),
};

AddGeneralLimit.defaultProps = {
    isDisabled: false,
    country: 0,
    userType: '',
    amount: 0,
    changeAmount: () => { },
    changeCountry: () => { },
    changeUserType: () => { },
    onSave: () => { },
    onDelete: () => { },
    isValidForm: false,
    countries: [],
    usersTypes: [],
};

const AddGeneralLimitHoc = (props) => {
    const hook = useAddGeneralLimit(props);
    return <AddGeneralLimit {...hook} />;
};

const styles = {
    disabledButton: {
        opacity: 0.3,
    },
    button: {
        backgroundColor: '#009bff',
    },
    deleteButton: {
        backgroundColor: 'red',
    },
    selectContainer: {
        width: 170,
    },
    inputContainer: {
        width: 170,
    },
    container: {
        width: 830,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 10,
    },
    containerDisabled: {
        width: 830,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 10,
        opacity: 0.35,
    },
};

export default AddGeneralLimitHoc;