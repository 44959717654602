import React from 'react';
import { List, Datagrid, TextField, ShowButton, EditButton, FunctionField }  from 'react-admin';

import DoneAllIcon from '@material-ui/icons/DoneAll';
import BlockIcon from '@material-ui/icons/Block';
import AdminFilter from '../Filters/AdminFilter';
import { Pagination } from '../../../Components';

const AdminList = (props) => {
  const {hasEdit} = props;

  return (
    <List
      {...props}
      title={'Administradores del sistema'}
      exporter={false}
      filters={<AdminFilter />}
      pagination={<Pagination/>}
      bulkActionButtons={false}
      debounce={1000}
    >
      <Datagrid>
        <TextField
          source={'id'}
          label={'Id'}
        />
        <TextField
          source={'attributes.first_name'}
          label={'Nombre'}
        />
        <TextField
          source={'attributes.last_name'}
          label={'Apellido'}
        />
        <TextField
          source={'attributes.email'}
          label={'Email'}
        />
        <TextField
          source={'attributes.role.public_name'}
          label={'Rol'}
        />
        <FunctionField
          label={'Estado'}
          source={'attributes.status'}
          render={(record) => {
            return record.attributes.status === 'active' ? <DoneAllIcon /> : <BlockIcon />
          }}
        />
        <ShowButton label="" />
        {hasEdit ? <EditButton label="" /> : null}
      </Datagrid>
    </List>
  );
};

export default AdminList;