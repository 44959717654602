const styles = {
  container: {
    marginTop: 20,
  },
  button: {
    marginLeft: -8,
    marginBottom: 10,
  },
  containerPaper: {
    marginTop: 30,
    marginLeft: -8,
  },
  titleProvider: {
    color: "#FFFFFF",
    marginTop: 50,
    marginBottom: 40,
    fontSize: 20,
    marginLeft: -8,
  },
};

export default styles;
