import { GET_LIST } from 'react-admin';
import { replaceSymbols } from '../../../../Helpers/ResourceHelper';
import { API_URL } from '../../../../Config/env';

const requestHandler = (type, params) => {
  switch (type) {
    case GET_LIST: {
      const {
        filter: {
          id,
          alt_id,
          email,
          source,
          status,
          country_iso_code,
          recipient_email,
          transaction_dates,
          is_pending_payment,
          transactions_dates,
          keyEmisor,
          keyRecipient,
          document_number,
          currency,
          bank_id,
          sender_category,
          is_locked,
          is_fast,
        },
        pagination: {
          page,
          perPage,
        },
        sort: {
          field,
          order,
        },
      } = params;

      let exportFile = '';
      if (parseInt(perPage) === 1000) {
        exportFile = `is_export=true&`;
      }


      let url = `${API_URL}/admin/transactions?page=${page}&count=${perPage}&${exportFile}q[s]=${field}+${order}&is_withdrawal=1&`;
      url += `q[category_in][]=3&q[category_in][]=7&${is_pending_payment ? 'q[status_in][]=2&q[status_in][]=4' : 'q[status_eq]=2'}&q[is_pending_payment_eq]=${is_pending_payment ? '1' : '0'}&`;
      url += id ? `q[id_eq]=${id}&` : '';
      url += keyEmisor ? `q[user_emisor_withdrawal]=${replaceSymbols(keyEmisor)}&` : '';
      url += keyRecipient ? `q[user_recipient_withdrawal]=${keyRecipient}&` : '';
      url += alt_id ? `q[alt_id_eq]=${alt_id}&` : '';
      url += currency ? `q[currency_eq]=${currency}&` : '';
      url += bank_id ? `q[bank_id_eq]=${bank_id}&` : '';
      url += is_locked != null ? (is_locked ? 'q[locking_status_not_eq]=unlocked&': 'q[locking_status_eq]=unlocked&') : '';
      url += is_fast ? `q[is_fast_eq]=${is_fast}&` : '';

      // TODO: Validate this 4 code lines 
      url += email ? `q[sender_email_eq]=${replaceSymbols(email)}&` : '';
      url += country_iso_code ? `q[country_iso_code_eq]=${country_iso_code}&` : '';
      url += email ? `q[sender_email_cont]=${replaceSymbols(email)}&` : '';
      url += recipient_email ? `q[recipient_email_cont]=${replaceSymbols(recipient_email)}&` : '';
      url += document_number ? `q[document_number_eq]=${document_number}&` : '';

      url += recipient_email ? `q[recipient_email_withdrawal]=${replaceSymbols(recipient_email)}&` : '';
      url += source ? `q[source_eq]=${source}&` : '';
      url += status ? `q[status_eq]=${status}&` : '';
      url += sender_category ? `q[sender_category_eq]=${sender_category}&` : '';

      url += transactions_dates ?
        transactions_dates['from_date'] ?
          `q[created_at_gteq]=${transactions_dates['from_date']}&` : ''
        : '';

      url += transactions_dates ?
        transactions_dates['to_date'] ?
          `q[created_at_lteq]=${transactions_dates['to_date']}&` : ''
        : '';

      url += transaction_dates ?
        transaction_dates['transaction_date_start'] ?
          `q[transaction_date_start]=${transaction_dates['transaction_date_start']}&` : ''
        : '';
      url += transaction_dates ?
        transaction_dates['transaction_date_end'] ?
          `q[transaction_date_end]=${transaction_dates['transaction_date_end']}&` : ''
        : '';

      return {
        url,
        method: 'get',
      };
    }
    default: {
      return {

      }
    }
  }
};

export default requestHandler;
