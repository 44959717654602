import { UPDATE } from 'react-admin';

import { API_URL } from '../../../../Config/env';

const requestHandler = (type, params) => {
    switch (type) {
        case UPDATE: {
            const account_bank = params.data.attributes.account_bank;
            const status = params.data.attributes.status;
            const description_state_change = params.data.attributes.description_state_change;
            const purpose_comentary = params.data.attributes.purpose_comentary;
            const final_customer = params.data.attributes.final_customer;

            return {
                url: `${API_URL}/admin/transactions/${params.id}/update_transaction`,
                method: 'put',
                data: {
                    account_bank,
                    status,
                    description_state_change,
                    purpose_comentary,
                    final_customer
                },
            };
        }
        default: {
            return {

            }
        }
    }
};

export default requestHandler;
