import React from "react";
import { Paper, Button, TextField } from "@material-ui/core";
import PropTypes from "prop-types";

import { styles } from "../styles";

const ReturnsPixs = ({
  returnPixData,
  handleReturnWithdrawal,
  handleOnChangeReturnPix,
  validateReturnPix,
}) => (
  <Paper style={styles.container_return_pix}>
    <div style={styles.sub_container_return_pix}>
      <TextField
        label={"Identificador end to end"}
        name="comment"
        value={returnPixData.comment}
        onChange={handleOnChangeReturnPix}
        style={styles.width_400}
      />
      <TextField
        label={"Monto"}
        name="value"
        type="number"
        value={returnPixData.value}
        onChange={handleOnChangeReturnPix}
        style={styles.width_400}
      />
      <Button
        variant="contained"
        color="secondary"
        onClick={handleReturnWithdrawal}
        style={styles.button_send_return_pix}
        disabled={!validateReturnPix}
      >
        Enviar
      </Button>
    </div>
  </Paper>
);

ReturnsPixs.propTypes = {
  returnPixData: PropTypes.shape({
    value: PropTypes.string,
    comment: PropTypes.string,
  }),
  handleReturnWithdrawal: PropTypes.func,
  handleOnChangeReturnPix: PropTypes.func,
  validateReturnPix: PropTypes.bool,
};

ReturnsPixs.defaultProps = {
  returnPixData: {
    value: "",
    comment: "",
  },
  handleReturnWithdrawal: () => {},
  handleOnChangeReturnPix: () => {},
  validateReturnPix: false,
};

export default ReturnsPixs;
