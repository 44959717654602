import { apiCall }   from '../../Middlewares';
import { ENDPOINTS } from '../../Config/env';

const createBankAccount = async (data) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.BANK_ACCOUNT.CREATE,
    data,
  });

const getBankAccountList = async (user_id, page, count) => {
  const url = `q[s]=id+DESC&&q[user_id]=${user_id}&page=${page}&count=${count}`;
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.BANK_ACCOUNT.GET_LIST(url)
  });
};

const updateBankAccount = async (id, data) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.BANK_ACCOUNT.UPDATE(id),
    data,
  });

const deleteBankAccount = async (id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.BANK_ACCOUNT.DELETE(id)
  });

const changeBankAccountAccess = async (id, is_active) =>
    await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BANK_ACCOUNT.CHANGE_ACTIVITY_ACCESS(id),
        data: {is_active}
    });

export default {
  createBankAccount,
  getBankAccountList,
  updateBankAccount,
  deleteBankAccount,
  changeBankAccountAccess,
};
