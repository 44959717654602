import { apiCall } from "../../Middlewares";
import { ENDPOINTS } from "../../Config/env";

const getAmounts = async (id, startDate, endDate) => {
  let params = '';

  if (startDate)
    params += `?startDate=${startDate}`

  if (endDate)
    params += `${startDate ? '&' : '?'}endDate=${endDate}`

  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.BENEFICIARIES.GET_AMOUNTS(id, params),
  });
}

const updateCriminalVerificationItemIgnoreStatus = async (id_beneficiary, item_id, nextValue) => {
  return await apiCall(
    {
      authorization: true,
      ...ENDPOINTS.ADMIN.BENEFICIARIES.UPDATE_CRIMINAL_VERIFICATION_ITEM_IGNORE_STATUS(id_beneficiary, item_id),
      data: { nextValue }
    },
  )
}

const requestCriminalVerification = async (id, provider) => {
  return await apiCall(
    {
      authorization: true,
      ...ENDPOINTS.ADMIN.BENEFICIARIES.REQUEST_CRIMINAL_VERIFICATION(id, provider),
    }
  )
}

const update = async (id, data) => {
  return await apiCall(
    {
      authorization: true,
      ...ENDPOINTS.ADMIN.BENEFICIARIES.UPDATE(id),
      data
    }
  )
}

export default {
  getAmounts,
  updateCriminalVerificationItemIgnoreStatus,
  requestCriminalVerification,
  update
};
