import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import {
  Edit,
  FormTab,
  TabbedForm
} from "react-admin";
import useAccessRole from "../../../hooks/useAccessRole";
import { sizeTypeFile } from '../../../Helpers/ValidateHelper';
import TrxLimitsFromUser from "../../../Components/trxLimits/TrxLimitsFromUser";

import {
  AssignRecharges,
  EditBalance,
  PostEditToolbar,
  SetDailyLimit,
  SetTransbankLimit,
  FailedTransactionList,
  FavoriteAccount,
  EditPreferentialPrices,
} from "./CustomComponents";
import AttachmentsPanel from "./CustomComponents/Attachments";
import EditEmail from "./CustomComponents/EditEmail";
import BusinessUserPanel from "./CustomComponents/BusinessUser";
import NaturalUserEditPanel from "./CustomComponents/AccountEditPanel/NaturalUserEditPanel";
import BusinessUserEditPanel from "./CustomComponents/AccountEditPanel/BusinessUserEditPanel";
import BusinessLinkPayment from "./CustomComponents/BusinessLinkPayment";

import styles from "./styles";
import AddCurrencyToUser from "./CustomComponents/AddCurrencyToUser";
import UsdAccountPanel from "./CustomComponents/UsdAccountPanel";

const UserEdit = (props) => {
  const [hidden, setHidden] = useState(false);
  const isFirstRender = useRef();
  const { isAccess } = useAccessRole();

  const editHide = (hidden) => setHidden(hidden);

  return (
    <Edit title={`Editar usuario ${props.id}`} {...props}>
      <TabbedForm toolbar={<PostEditToolbar hidden={hidden} />} validate={sizeTypeFile} className='tabbed-form-custom'>
        {
          props.category === 'natural' && (
            <FormTab label="Datos Personales" style={styles.titleWhite}>
              <NaturalUserEditPanel
                isFirstRender={isFirstRender}
              />
              {isAccess('update_email') && <EditEmail />}
            </FormTab>
          )
        }
        {
          props.category === 'business' && (
            <FormTab label="Datos de la Cuenta" style={styles.titleWhite}>
              <BusinessUserEditPanel
                isFirstRender={isFirstRender}
              />
              {isAccess('update_email') && <EditEmail />}
            </FormTab>
          )
        }
        {
          isAccess('add_user_balance') ?
            <FormTab label="Agregar balance" style={styles.titleWhite} onClick={() => editHide(false)}>
              <EditBalance />
            </FormTab>
            : null
        }
        {
          isAccess('show_general_limits') ?
            <FormTab label="Límites" style={styles.titleWhite} onClick={() => editHide(false)}>
              <TrxLimitsFromUser />
            </FormTab>
            : null
        }
        {
          isAccess('edit_limits') ?
            <FormTab label="Fijar limite transbank" style={styles.titleWhite} onClick={() => editHide(false)}>
              <SetTransbankLimit />
            </FormTab>
            : null
        }
        {
          isAccess('show_failed_transactions') ?
            <FormTab label="Transacciones fallidas" style={styles.titleWhite} onClick={() => editHide(false)}>
              <FailedTransactionList location={props.location.pathname} />
            </FormTab>
            : null
        }
        {
          isAccess('show_favorite_account') ?
            <FormTab label="Favoritos" style={styles.titleWhite} onClick={() => editHide(true)}>
              <FavoriteAccount location={props.location.pathname} />
            </FormTab>
            : null
        }
        {
          isAccess('show_attachment_file') ?
            <FormTab label="Documentación" style={styles.titleWhite} onClick={() => editHide(true)}>
              <AttachmentsPanel location={props.location.pathname} readOnly={false} showTitle={false} />
            </FormTab>
            : null
        }
        {
          isAccess('edit_preferential_prices') ?
            <FormTab label="Precios preferenciales" style={styles.titleWhite} onClick={() => editHide(true)}>
              <EditPreferentialPrices location={props.location.pathname} />
            </FormTab>
            : null
        }
        {
          isAccess('show_business_payment_order_config') && props.category === 'business' &&
          <FormTab label="ENLACES DE PAGO" style={styles.titleWhite} onClick={() => editHide(true)}>
            <BusinessLinkPayment business_user={props.business_user} />
          </FormTab>
        }
        {
          isAccess('edit_users') && props.category === 'business' ?
            <FormTab label="Business" style={styles.titleWhite} onClick={() => editHide(true)}>
              <BusinessUserPanel location={props.location.pathname} business_user={props.business_user} />
            </FormTab>
            : null
        }
        {
          isAccess('add_currency_to_user') ?
            <FormTab label="Activar moneda" style={styles.titleWhite} onClick={() => editHide(true)}>
              <AddCurrencyToUser location={props.location.pathname} />
            </FormTab>
            : null
        }
        {
          isAccess('show_usd_account') ?
            <FormTab label="Cuenta USD" style={styles.titleWhite} onClick={() => editHide(true)}>
              <UsdAccountPanel id={props.id} />
            </FormTab>
            : null
        }
      </TabbedForm>
    </Edit>
  );
};

const mapStateToProps = ({ form }) => {
  return {
    category: form["record-form"]
      ? form["record-form"].initial.attributes.category
      : -1,
    business_user: form["record-form"]
      ? form["record-form"].initial.attributes.business_user
      : -1
  };
};

export default connect(mapStateToProps, null)(UserEdit);
