import React from 'react';
import PropTypes from 'prop-types';

import AccountBankCustom from '../AccountBankCustom';

const AccountBank = (props) => {
    const { record, transaction } = props;
    const { attributes, country_data: { attributes: { name } } } = record;

    return (
        <AccountBankCustom {...attributes} country={name} description={transaction.attributes.description} />
    );
};

AccountBank.propTypes = {
    transaction: PropTypes.shape({
        attributes: PropTypes.shape({
            currency_to_default_currency_price: PropTypes.oneOf([
                PropTypes.string,
                PropTypes.number
            ]),
            amount: PropTypes.number,
        }),
    }),
    record: PropTypes.shape({
        id: PropTypes.string,
        attributes: PropTypes.shape({
            first_name: PropTypes.string,
            last_name: PropTypes.string,
            default_currency: PropTypes.string,
            email: PropTypes.string,
            document_number: PropTypes.string,
            document_type: PropTypes.string,
            account_bank: PropTypes.shape({
                bank: PropTypes.string,
                type_account_bank: PropTypes.string,
                account_bank: PropTypes.string,
            }),
        }),
        country_data: PropTypes.shape({
            attributes: PropTypes.shape({
                name: PropTypes.string,
            }),
        }),
    }),
};

AccountBank.defaultProps = {
    transaction: {
        attributes: {
            currency_to_default_currency_price: null,
            amount: 0,
        },
    },
    record: {
        id: '',
        attributes: {
            first_name: '',
            last_name: '',
            default_currency: '',
            email: '',
            document_number: '',
            document_type: '',
            account_bank: {
                bank: '',
                type_account_bank: '',
                account_bank: '',
            },
        },
        country_data: {
            attributes: {
                name: '',
            },
        },
    },
};

export default AccountBank;
