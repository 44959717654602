import { UPDATE, CREATE } from "react-admin";

import { API_URL } from "../../../../Config/env";

const requestHandler = (type, params) => {
  switch (type) {
    case UPDATE: {
      let {
        id,
        attributes: { title, segment_id: segment, icon, is_only_icon },
      } = params.data;

      let formData = new FormData();

      formData.append("id", id);
      formData.append("segment", segment - 1);
      formData.append("title", title);
      if(icon){
        formData.append("icon", icon.rawFile);
      }
      formData.append("is_only_icon", is_only_icon);


      return {
        url: `${API_URL}/admin/categories/${params.id}`,
        method: "put",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
    }
    case CREATE: {
      const {
        data: {
          attributes: { id, segment_id, title, icon, is_only_icon },
        },
      } = params;

      let formData = new FormData();

      formData.append("id", id);
      formData.append("segment_id", segment_id - 1);
      formData.append("title", title);
      formData.append("icon", icon.rawFile);
      formData.append("is_only_icon", is_only_icon);

      return {
        url: `${API_URL}/admin/categories`,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
    }
  }
};

export default requestHandler;
