import { apiCall }      from '../../Middlewares';
import { ENDPOINTS }    from '../../Config/env';

const reSendCreateCardAccount = async (id) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.CARD_ACCOUNT.CREATE_CARD_ACCOUNT(id),
  });
};

const getCardAccount = async (id) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.CARD_ACCOUNT.GET_CARD_ACCOUNT(id),
  });
};

const updateAccount = async (id, data) =>
  await apiCall({
      authorization: true,
      ...ENDPOINTS.ADMIN.CARD_ACCOUNT.UPDATE(id),
      data,
  });

const getBalance = async (account_id) =>
  await apiCall({
      authorization: true,
      ...ENDPOINTS.ADMIN.CARD_ACCOUNT.GET_BALANCE(account_id)
  });

export default {
  reSendCreateCardAccount,
  getCardAccount,
  updateAccount,
  getBalance
};
