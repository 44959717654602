import React, { useState } from "react";
import { FileField, FileInput } from "react-admin";

const Icon = (prop) => {
  const [isDocument, setIsDocument] = useState(false);

  return (
    <div>
      {isDocument ? (
        <>
          <img style={{width: "100px", height: "100px"}} src={isDocument} title="icon" />
          <br />
          <button
            style={{
              textAlign: "center",
              backgroundColor: "#00F5A5",
              border: "none",
              borderRadius: "4px",
              borderColor: "#fff",
              padding: "7px",
              cursor: "pointer",
              color: "white",
              marginTop: "18px",
            }}
            onClick={() => setIsDocument(!isDocument)}
          >
            Cambiar ícono
          </button>
        </>
      ) : (
        <FileInput
          source="attributes.icon"
          label="Carga un ícono"
          placeholder={<p>Arrastra el ícono o haz click aquí. Tamaño máximo 272x272.</p>}
        >
          <FileField source="icon" title="title" />
        </FileInput>
      )}
    </div>
  );
};

export default Icon;