import { apiCall } from '../../Middlewares';
import { ENDPOINTS } from '../../Config/env';

const automaticBinanceWithdrawal = async (data) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BINANCE.AUTOMATIC_BINANCE_WITHDRAWAL,
        data: { ...data }
    });
};

const completeBinanceWithdrawal = async (data) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BINANCE.COMPLETE_BINANCE_WITHDRAWAL,
        data: { ...data }
    });
};

const rejectBinanceWithdrawal = async (data) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BINANCE.REJECT_BINANCE_WITHDRWAL,
        data: { ...data }
    });
};

const binanceBalance = async () => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BINANCE.BALANCE
    })
};

const masterAdminBalances = async (currency = 'usdt') => {
    const url = ENDPOINTS.ADMIN.BINANCE.MASTER_ADMIN_BALANCES.url;
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BINANCE.MASTER_ADMIN_BALANCES,
        url: `${url}?currency=${currency}`,
    })
};

const getBinanceNetworks = async (currency) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BINANCE.GET_BINANCE_NETWORKS(currency)
    })
};

const binanceWithdrawal = async (data) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BINANCE.BINANCE_WITHDRAWAL,
        data: { ...data }
    });
};

export default {
    automaticBinanceWithdrawal,
    completeBinanceWithdrawal,
    rejectBinanceWithdrawal,
    binanceBalance,
    masterAdminBalances,
    getBinanceNetworks,
    binanceWithdrawal,
}
