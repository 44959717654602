import {LocationCity} from '@material-ui/icons';

import CountryList from './CountryList';
import CountryEdit from './CountryEdit';

export default {
    name: 'países',
    icon: LocationCity,
    list: CountryList,
    edit: CountryEdit,
}
