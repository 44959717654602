import React from 'react';
import moment from "moment";
import Button from "@material-ui/core/Button";
import Swal from "sweetalert2";

import { WINDOW_FILTER_MOUNTH } from '../../../../Config/constants';

import { toMoney } from "../../../../Helpers/MoneyHelper";
import UserProvider from "../../../../Providers/Users";
import { capializeFirstLetter } from "../../../../Utils";

moment.locale("es");
const BalanceInfo = (props) => {
  const {
    history,
    record: {
      attributes: {
        profile_completed,
        email,
      },
      id,
    },
  } = props;

  const goToReceiveTransactions = () => {
    history.push(`/transacciones?filter={"transactions_dates":{"from_date":"${moment().subtract(WINDOW_FILTER_MOUNTH, "months").format("YYYY-MM-DD")}"},"user_key":"${encodeURIComponent(email)}"}&order=DESC&page=1&perPage=10&sort=id`)
  };

  const receiveBinanceBalance = async () => {
    try {
      const balances = await UserProvider.getUserBalances(id);

      let body = '';

      balances.data.forEach(balance => {
        const { provider_account_id = "", provider_name, currency } = balance;

        body = body + `<tr>
          <td>${provider_account_id}</td>
          <td>${capializeFirstLetter(provider_name)}</td>
          <td>${currency.toUpperCase()}</td>
          <td>${toMoney(balance.balance[0], currency)}</td>
          <td>${toMoney(balance.balance[1], currency)}</td>
          <td>${currency === "usdc" ? `<a href="/circle_account_transactions?filter=${encodeURIComponent(JSON.stringify({"id": id}))}&order=DESC&page=1&perPage=25&sort=id" target="_blank" rel="noopener noreferrer">Ver TRXs</a>` : ''}</td>
        </tr>
        `;
      })

      const table = `<table class="table_balance">
        <thead>
          <tr>
            <th>ID proveedor</th>
            <th>Nombre proveedor</th>
            <th>Moneda</th>
            <th>Monto proveedor</th>
            <th>Monto Vita</th>
            <th>Acción</th>
          </tr>
        </thead>
        <tbody>
          ${body}
        </tbody>
      </table>`;

      Swal.fire({
        title: "Balances",
        html: table,
        confirmButtonText: "OK",
        cancelButtonText: "Ver transacciones",
        showCancelButton: false,
        showCloseButton: true,
        customClass: 'swal_balance'
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.data.message,
        customClass: 'swal_balance_error'
      });
    }
  };

  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      {profile_completed && (
        <div style={{ paddingTop: 20, width: 100 }}>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#00b7ff" }}
            onClick={goToReceiveTransactions}
          >
            Ver transacciones
          </Button>
        </div>
      )}
      <div style={{ paddingTop: 20, width: "100%" }}>
        <Button
          variant="contained"
          color="primary"
          style={{ backgroundColor: "#00b7ff" }}
          onClick={receiveBinanceBalance}
        >
          Ver saldos
        </Button>
      </div>
    </div>
  );
};

export default BalanceInfo;
