import React from "react";
import { Divider, Typography } from "@material-ui/core";
import PropTypes from "prop-types";

import { styles } from "../styles";

const TitleSesion = ({ title, width }) => (
  <section
    style={{
      display: "flex",
      marginTop: 20,
    }}
  >
    <Typography style={styles.titleSesionText}>{title}</Typography>
    <Divider style={{ ...styles.titleSesionDivider, width }} />
  </section>
);

TitleSesion.protTypes = {
  title: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

TitleSesion.defaultProps = {
  title: "",
  width: 0,
};

export default TitleSesion;
