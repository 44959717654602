import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import {TextField} from '@material-ui/core';
import {Col, Row} from 'react-grid-system';

import useAddBalanceForAdmin from '../../hooks/useAddBalanceForAdmin';
import useAccessRole from '../../hooks/useAccessRole';
import {toMoney} from '../../Helpers/MoneyHelper';

const Form = (props) => (
    <Col sm={4} style={styles.divisorCoin}>
        <h3 style={styles.titleCoin}>
            Agregar balance al administrador
        </h3>
        <Row container direction="column" justify="center" alignItems="center">
            <Col
                item
                xs={12}
                style={styles.item}
            >
                <Select onChange={props.changeCurrency} value={props.currency}>
                    <MenuItem value="clp">
                        Pesos chileno
                    </MenuItem>
                    <MenuItem value="ars">
                        Pesos argentinos
                    </MenuItem>
                    <MenuItem value="cop">
                        Pesos colombiano
                    </MenuItem>
                    <MenuItem value="usd">
                        Dólar estadounidense
                    </MenuItem>
                    <MenuItem value="mxn">
                        Pesos mexicanos
                    </MenuItem>
                    <MenuItem value="usdt">
                        USDT
                    </MenuItem>
                </Select>
            </Col>
            <Col
                item
                xs={12}
                style={styles.item}
            >
                <TextField
                    label={`Monto ${props.currency.toUpperCase()}`}
                    value={toMoney(props.amount, props.currency.toUpperCase())}
                    onChange={props.changeAmount}
                />
            </Col>
            <Col
                item
                xs={12}
                style={styles.item}
            >
                <Button
                    disabled={!props.isValidForm}
                    onClick={props.onSubmit}
                    style={
                        props.isValidForm ?
                            styles.button :
                            styles.disabled_button
                    }
                >
                    Agregar balance
                </Button>
            </Col>
        </Row>
    </Col>
);

Form.propTypes = {
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    isValidForm: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    changeCurrency: PropTypes.func,
    changeAmount: PropTypes.func,
};

Form.defaultProps = {
    isValidForm: false,
    changeCurrency: () => {},
    changeAmount: () => {},
};

const styles = {
    item: {
        display: 'flex',
        textAlign: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        paddingTop: 10,
        paddingBottom: 10,
    },
    titleCoin: {
        color: '#FFFFFF',
        textAlign: 'center',
    },
    divisorCoin: {
        padding: 10,
    },
    button: {
        backgroundColor: '#00adfd',
    },
    disabled_button: {
        backgroundColor: '#00415b',
    },
};

const AddBalanceForAdmin = (props) => {
    const hook = useAddBalanceForAdmin(props);
    const {isAccess} = useAccessRole();

    if (isAccess('add_balance_for_admin')) {
        return <Form {...hook} />;
    }

    return null;
};

AddBalanceForAdmin.propTypes = {
    history: PropTypes.shape({}).isRequired,
};

export default AddBalanceForAdmin;
