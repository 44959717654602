import {UPDATE} from 'react-admin';

import {API_URL} from '../../../../Config/env';

const requestHandler = (type, params) => {
    switch (type) {
        case UPDATE: {
            const is_modal_route = params.data.attributes.is_modal_route;
            const modal_route = params.data.attributes.modal_route;

            if(is_modal_route) {
                if(modal_route === null || modal_route === '') {
                    throw new Error('El campo Más información no puede estar vacio');
                }
            }

            return {
                url: `${API_URL}/admin/countries/${params.id}`,
                method: 'put',
                data: {...params.data.attributes},
            };
        }
        default: {
            return {

            }
        }
    }
};

export default requestHandler;
