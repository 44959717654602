const style = {
    fontWhite: {
        color: '#FFFFFF',
    },
    divisorTop: {
        paddingTop: 10,
    },
    title: {
        fontSize: 12,
        color: 'rgba(255,255,255,.7)',
        paddingBottom: 10,
    },
    buttonSeccion: {
        width: '100%',
        backgroundColor: "#FFF",
        display: 'flex',
        justifyContent: 'space-between',
    },
};

export default style;
