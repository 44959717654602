import React from 'react';
import PropTypes from 'prop-types';
import {Button, ListItemText} from '@material-ui/core';

import useRequest from '../../../../hooks/useRequest';

export const ShowResponseAndRequest = (props) => (
  <>
    <ListItemText
      primaryTypographyProps={{ style: styles.typography }}
      primary={props.value}
    />
    <br />
    {
      props.isValidValue && (
        <Button 
          onClick={props.onCopy} 
          size="small"
          style={styles.button}
        >
          {`copiar ${props.type}`}
        </Button>
      )
    }
  </>
);

ShowResponseAndRequest.propTypes = {
  value: PropTypes.string,
  type: PropTypes.string,
  isValidValue: PropTypes.bool,
  onCopy: PropTypes.func,
};

ShowResponseAndRequest.deafultProps = {
  value: '',
  type: 'request',
  isValidValue: false,
  onCopy: () => {},
};

const styles = {
  typography: {
    fontSize: '0.875rem',
  },
  button: {
    backgroundColor: '#27B7DE',
  },
};

const ShowResponseAndRequestHOC = (props) => {
  const hook = useRequest(props);
  return <ShowResponseAndRequest {...hook} />;
};

ShowResponseAndRequestHOC.propTypes = {
  type: PropTypes.string,
  record: PropTypes.shape({
    attributes: PropTypes.shape({
      request_and_response: PropTypes.arrayOf(
        PropTypes.shape({}),
      ),
    }),
  }),
};

ShowResponseAndRequestHOC.deafultProps = {
  type: 'request',
  record: {
    attributes: {
      request_and_response: [{}],
    },
  },
};

export default ShowResponseAndRequestHOC;