import { CREATE, UPDATE, DELETE } from 'react-admin';

import { API_URL } from '../../../../../Config/env';

const requestHandler = (type, params) => {
  switch (type) {
    case CREATE: {
      let {
        data: {
          attributes
        }
      } = params;

      return {
        url: `${API_URL}/admin/marketing_message`,
        method: 'post',
        data: attributes,
      };
    }
    case DELETE: {
      let { id } = params;

      return {
        url: `${API_URL}/admin/marketing_message/${id}`,
        method: 'delete',
      };
    }
    case UPDATE: {
      let {
        data: {
          attributes
        }
      } = params;

      return {
        url: `${API_URL}/admin/marketing_message/${params.id}`,
        method: 'put',
        data: attributes,
      };
    }
    default: {
      return {

      }
    }
  }
}

export default requestHandler;