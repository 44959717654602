import React from 'react';
import moment from 'moment';
import styles from '../styles';
import { Show, SimpleShowLayout } from 'react-admin';
import { Grid, TextField, Typography } from '@material-ui/core';

const AdminShow = (props) => {

  const RenderFields = (propsRecord) => {

    const { record: {
      attributes: {
        first_name,
        last_name,
        email,
        role,
        created_at,
        updated_at
      }
    } } = propsRecord;

    const { name, public_name } = role ? role : {};

    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        xs={12}
        sm={8}
        item
        style={styles.container}
      >
        <Grid
          xs={12}
          sm={3}
          item
          style={styles.items}
        >
          <TextField
            value={first_name}
            label={'Nombre'}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid
          xs={12}
          sm={3}
          item
          style={styles.items}
        >
          <TextField
            value={last_name}
            label={'Apellido'}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid
          xs={12}
          sm={3}
          item
          style={styles.items}
        >
          <TextField
            value={email}
            label={'Correo electrónico'}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid
          xs={12}
          sm={5}
          item
          style={styles.items}
        >
          <TextField
            value={moment(created_at).format('LL')}
            label={'Fecha Creado'}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid
          xs={12}
          sm={5}
          item
          style={styles.items}
        >
          <TextField
            value={moment(updated_at).format('LL')}
            label={'Fecha Modificado'}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={styles.container}
        >
          <Grid xs={12} item>
            <Typography 
              variant="title" 
              gutterBottom
              style={styles.title}
            >
              Rol del usuario <b>{first_name} {last_name}</b>
            </Typography>
          </Grid>
          <Grid
            xs={12}
            sm={5}
            item
            style={styles.items}
          >
            <TextField
              value={name}
              label={'Nombre de Rol'}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid
            xs={12}
            sm={5}
            item
            style={styles.items}
          >
            <TextField
              value={public_name}
              label={'Nombre Publico de Rol'}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Show 
      title={`Usuario Administrador #${props.id}`} 
      {...props}
    >
      <SimpleShowLayout>
        <RenderFields />
      </SimpleShowLayout>
    </Show>
  );
};

export default AdminShow;
