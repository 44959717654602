import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-grid-system';
import { Create, FormTab, TabbedForm, SelectInput, FormDataConsumer, FileInput, FileField } from "react-admin";

import Input from '../components/Input';
import styles from '../../Users/UserEdit/styles';
import useCreatePaymentFortress from '../../../hooks/useCreatePaymentFortress';
import { PostEditToolbar } from '../../Users/UserEdit/CustomComponents';
import { FORMULARY_TYPE, WITHDRAWAL_TYPE, STRUCTURE_LEGAL, DOCUMENT_TYPE, DOCUMENT_TYPE_BUSINESS } from '../utils';

const FortressCreate = (props) => (
    <Create {...props}>
        <TabbedForm toolbar={<PostEditToolbar />}>
            <FormTab label="Cuentas" style={styles.titleWhite}>
                <FormDataConsumer>
                    {
                        ({ formData }) => {
                            props.changeFormularyType(formData.formulary_type);
                            return null;
                        }
                    }
                </FormDataConsumer>
                <Container style={{ width: '100%' }}>
                    <Row container direction="row" justify="center" alignItems="center" className={{ flexGrow: 1 }}>
                        <Col xs={3} style={styles.box}>
                            <SelectInput
                                label="Tipo de formulario"
                                source="formulary_type"
                                choices={FORMULARY_TYPE}
                                defaultValue={WITHDRAWAL_TYPE}
                            />
                        </Col>
                        {
                            props.isWithdrawal && (
                                <Col xs={3} style={styles.box}>
                                    <SelectInput
                                        label="Cuenta custodia"
                                        source="custodial"
                                        choices={props.custodialAccounts}
                                        defaultValue={props.accountNumber || null}
                                    />
                                </Col>
                            )
                        }
                        <Input 
                            label={'Descripción'} 
                            source={'comment'}
                            isVisible={props.isWithdrawal}
                        />
                        <Input 
                            label={'Monto'} 
                            source={'amount'}
                            isVisible={props.isWithdrawal}
                        />
                        <Input 
                            label={'Numero de cuenta'} 
                            source={'account_number'}
                            isVisible={props.isWithdrawal}
                        />
                        <Input 
                            label={'Numero de ruta'} 
                            source={'routing_number'}
                            isVisible={props.isWithdrawal}
                        />
                        <Input 
                            label={'nombre del destinatario'} 
                            source={'receiver_name'}
                            isVisible={props.isWithdrawal}
                        />
                        <Input 
                            label={'Calle del destinatario'} 
                            source={'street'}
                            isVisible={props.isWithdrawal}
                        />
                        <Input 
                            label={'Código postal del destinatario'} 
                            source={'postal_code'}
                            isVisible={props.isWithdrawal}
                        />
                        <Input 
                            label={'Ciudad del destinatario'} 
                            source={'city'}
                            isVisible={props.isWithdrawal}
                        />
                        <Input 
                            label={'Estado del destinatario'} 
                            source={'state'}
                            isVisible={props.isWithdrawal}
                        />
                        <Input 
                            label={'País del destinatario'} 
                            source={'country'}
                            isVisible={props.isWithdrawal}
                        />
                        <Input 
                            label={'Nombre del banco receptor'} 
                            source={'receiver_bank_name'}
                            isVisible={props.isWithdrawal}
                        />
                        <Input 
                            label={'Calle del banco receptor'} 
                            source={'street_bank'}
                            isVisible={props.isWithdrawal}
                        />
                        <Input 
                            label={'Código postal del banco receptor'} 
                            source={'postal_code_bank'}
                            isVisible={props.isWithdrawal}
                        />
                        <Input 
                            label={'Ciudad del banco receptor'} 
                            source={'city_bank'}
                            isVisible={props.isWithdrawal}
                        />
                        <Input 
                            label={'Estado del banco receptor'} 
                            source={'state_bank'}
                            isVisible={props.isWithdrawal}
                        />
                        <Input 
                            label={'País del banco receptor'} 
                            source={'country_bank'}
                            isVisible={props.isWithdrawal}
                        />
                        <Input 
                            label={'Nombre'} 
                            source={'first_name'}
                            isVisible={props.isCreateAccount || props.isBusinessCreateAccount}
                        />
                        <Input 
                            label={'Apellidos'} 
                            source={'last_name'}
                            isVisible={props.isCreateAccount || props.isBusinessCreateAccount}
                        />
                        <Input 
                            label={'Telefono'} 
                            source={'phone'}
                            isVisible={props.isCreateAccount || props.isBusinessCreateAccount}
                        />
                        <Input 
                            label={'Correo'} 
                            source={'email'}
                            isVisible={props.isCreateAccount || props.isBusinessCreateAccount}
                        />
                        <Input 
                            label={'Calle'} 
                            source={'street'}
                            isVisible={props.isCreateAccount || props.isBusinessCreateAccount}
                        />
                        <Input 
                            label={'Código postal'} 
                            source={'postal_code'}
                            isVisible={props.isCreateAccount || props.isBusinessCreateAccount}
                        />
                        <Input 
                            label={'Ciudad'} 
                            source={'city'}
                            isVisible={props.isCreateAccount || props.isBusinessCreateAccount}
                        />
                        <Input 
                            label={'Estado'} 
                            source={'state'}
                            isVisible={props.isCreateAccount || props.isBusinessCreateAccount}
                        />
                        <Input 
                            label={'País (XX)'} 
                            source={'country'}
                            isVisible={props.isCreateAccount || props.isBusinessCreateAccount}
                        />
                        <Input 
                            label={'SSN'} 
                            source={'ssn'}
                            isVisible={props.isCreateAccount || props.isBusinessCreateAccount}
                        />
                        <Input 
                            label={'Fecha de nacimiento (YYYY-MM-DD)'} 
                            source={'date_of_birth'}
                            isVisible={props.isCreateAccount || props.isBusinessCreateAccount}
                        />
                        <Input 
                            label={'Nombre de la compañía'} 
                            source={'company_name'}
                            isVisible={props.isBusinessCreateAccount}
                        />
                        <Input 
                            label={'Región de formación'} 
                            source={'region_of_formation'}
                            isVisible={props.isBusinessCreateAccount}
                        />
                        <Input 
                            label={'Descripción'} 
                            source={'description'}
                            isVisible={props.isBusinessCreateAccount}
                        />
                        <Input 
                            label={'Fecha de establecimiento (YYYY-MM-DD)'} 
                            source={'established_on'}
                            isVisible={props.isBusinessCreateAccount}
                        />
                        <Input 
                            label={'NAICS'} 
                            source={'naics'}
                            isVisible={props.isBusinessCreateAccount}
                        />
                        <Input 
                            label={'Descripción NAICS'} 
                            source={'naics_description'}
                            isVisible={props.isBusinessCreateAccount}
                        />
                        <Input 
                            label={'EIN'} 
                            source={'ein'}
                            isVisible={props.isBusinessCreateAccount}
                        />
                        <Input 
                            label={'Sitio web'} 
                            source={'website'}
                            isVisible={props.isBusinessCreateAccount}
                        />
                        <Input 
                            label={'Otra estructura legal'} 
                            source={'other_legal_structure'}
                            isVisible={props.isBusinessCreateAccount}
                        />
                        <Input 
                            label={'URL para eventos fortress (https://)'} 
                            source={'notify_url'}
                            isVisible={props.isAssignWebhookUrl}
                        />
                        { 
                            props.isBusinessCreateAccount && (
                                <SelectInput
                                    label="Estructura legal"
                                    source="legal_structure"
                                    choices={STRUCTURE_LEGAL}
                                    defaultValue={"ccorp"}
                                    style={{marginLeft: 20}}
                                />
                            )
                        }
                        { 
                            !props.isWithdrawal && !props.isAssignWebhookUrl && (
                                <SelectInput 
                                    label={'Tipo de documento'} 
                                    source={'document_type'}
                                    choices={DOCUMENT_TYPE}
                                    defaultValue={"passport"}
                                    style={{marginLeft: 20}}
                                />
                            )
                        }
                        { 
                            props.isBusinessCreateAccount && (
                                <SelectInput 
                                    label={'Tipo de documento del comercio'} 
                                    source={'document_type_business'}
                                    choices={DOCUMENT_TYPE_BUSINESS}
                                    defaultValue={"proofOfAddress"}
                                    style={{marginLeft: 20}}
                                />
                            )
                        }
                        { 
                            !props.isWithdrawal && !props.isAssignWebhookUrl && (
                                <FileInput 
                                    label={'Documento'} 
                                    source={'document_front'}
                                    placeholder={<p>Inserte el documento personal</p>}
                                >
                                    <FileField source="src" title="title" />
                                </FileInput>
                            )
                        }
                        { 
                            props.isBusinessCreateAccount && (
                                <FileInput 
                                    label={'Documento del comercio'} 
                                    source={'document_front_business'}
                                    placeholder={<p>Inserte el documento del negocio</p>}
                                >
                                    <FileField source="src" title="title" />
                                </FileInput>
                            )
                        }
                    </Row>
                </Container>
            </FormTab>
        </TabbedForm>
    </Create>
);

FortressCreate.propTypes = {
    isCreateAccount: PropTypes.bool,
    isWithdrawal: PropTypes.bool,
    isBusinessCreateAccount: PropTypes.bool,
    custodialAccounts: PropTypes.array,
    changeFormularyType: PropTypes.func,
};

FortressCreate.defaultProps = {
    isCreateAccount: false,
    isWithdrawal: false,
    isBusinessCreateAccount: false,
    custodialAccounts: [],
    changeFormularyType: () => {},
};

const FortressCreateHoc = (props) => {
    const hook = useCreatePaymentFortress();
    return <FortressCreate {...props} {...hook} />;
};

export default FortressCreateHoc;