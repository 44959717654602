import React from "react";
import { TabbedForm, FormTab } from "react-admin";

import KeyPixs from "../Components/KeyPixs";
import ReturnsPixs from "../Components/ReturnsPixs";
import ModalCreatePix from "../Components/ModalCreatePix";

import usePix from "../../../hooks/usePix";

const ListPix = () => {
  const {
    createPixData,
    dataKey,
    handleCloseModal,
    handleCreateKeyPix,
    handleDeleteKeyPix,
    handleOnChangeOption,
    handleOnChangeReturnPix,
    handleOpenModal,
    handleReturnWithdrawal,
    openModal,
    returnPixData,
    validateCreatePix,
    validateReturnPix,
  } = usePix();

  return (
    <>
      {openModal && (
        <ModalCreatePix
          open={openModal}
          onClose={handleCloseModal}
          handleCreateKeyPix={handleCreateKeyPix}
          createPixData={createPixData}
          handleOptionChange={handleOnChangeOption}
          validateCreatePix={validateCreatePix}
        />
      )}

      <TabbedForm toolbar={null} style={{ marginTop: 20 }}>
        <FormTab label="Llaves pixs" style={{ color: "white" }}>
          <KeyPixs
            handleDeleteKeyPix={handleDeleteKeyPix}
            handleOpenModal={handleOpenModal}
            data={dataKey}
          />
        </FormTab>
        <FormTab label="Devoluciones pixs" style={{ color: "white" }}>
          <ReturnsPixs
            returnPixData={returnPixData}
            handleReturnWithdrawal={handleReturnWithdrawal}
            handleOnChangeReturnPix={handleOnChangeReturnPix}
            validateReturnPix={validateReturnPix}
          />
        </FormTab>
      </TabbedForm>
    </>
  );
};

export default ListPix;
