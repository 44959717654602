import { apiCall } from '../../Middlewares';
import { ENDPOINTS } from '../../Config/env';

const getOccupations = async () =>
  await apiCall({
    authorization: false,
    ...ENDPOINTS.PUBLIC.GET_OCCUPATIONS
  });

export default {
  getOccupations,
};