import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import {
  Card,
  Typography,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import ModalComponent from "../../../Components/ModalComponent";
import useSnackbarDetails from "./hooks/useSnackbarDetails";

import { styles } from "./styles";

const SnackbarDetails = (props) => {
  const { open, handleCloseModal, location } = props;
  const { listDetailSnackbar, detailsSnackbar } = useSnackbarDetails(location);

  return (
    <ModalComponent open={open} onClose={handleCloseModal}>
      <Card style={styles.container}>
        <Typography
          variant='headline'
          align='center'
          style={{
            fontWeight: "bold",
            paddingTop: 30,
          }}
        >
          Historial
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Estado</TableCell>
              <TableCell align='right'>Horario</TableCell>
              <TableCell align='right'>Mensaje</TableCell>
              <TableCell align='right'>Visibilidad</TableCell>
              <TableCell align='right'>Id operador</TableCell>
              <TableCell align='right'>Email operador</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ whiteSpace: 'nowrap' }}>
            {listDetailSnackbar.length > 0 ? listDetailSnackbar.map(({ created_at, message, status, id, user_email, user_id }) => {
              return (
                <TableRow key={id}>
                  <TableCell
                    component='th'
                    scope='row'
                  >
                    {status ? "Activado" : "Desactivado"}
                  </TableCell>
                  <TableCell align='right'>
                    {moment.utc(created_at).format("LLL")}
                  </TableCell>
                  <TableCell align='right'>{message}</TableCell>
                  <TableCell align='right'>
                    {detailsSnackbar.length
                      ? detailsSnackbar
                          .map(({ iso_code }) => iso_code)
                          .join(" - ")
                      : "Todos los países"}
                  </TableCell>
                  <TableCell align='right'>{user_id ? user_id : 'No aplica'}</TableCell>
                  <TableCell align='right'>{user_email ? user_email : 'No aplica'}</TableCell>
                </TableRow>
              );
            }) : 
            <TableRow>
              <TableCell colSpan={7} style={{ textAlign: 'center', height: 500 }}>
                No hay historial
              </TableCell>
            </TableRow>
          }
          </TableBody>
        </Table>
        <CardContent style={styles.containerButton}>
          <Button style={styles.buttonClose} onClick={handleCloseModal}>
              Cerrar
            </Button>
        </CardContent>
      </Card>
    </ModalComponent>
  );
};

SnackbarDetails.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

SnackbarDetails.defaultProps = {
  handleCloseModal: () => {},
  open: false,
  location: {
    pathname: "",
  },
};

export default SnackbarDetails;
