import React, { useState }                            from 'react';
import PropTypes                                      from 'prop-types';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Card, CardContent, Grid, TextField, Button } from '@material-ui/core';

import { BusinessesProvider } from '../../../Providers';

import styles from './styles';

const BusinessesCreate = (props) => {
    const { history } = props;
    const [email, setEmail] = useState('');

    const showError = (errorMessage) => {
        NotificationManager.error(errorMessage, '', 5000, () => {
        });
    };

    const send = async () => {
        try {
            if (email) {
                await BusinessesProvider.createBusiness(email);
                history.push('/negocios');
            }
        } catch (e) {
            const errorMessage = e?.data?.error || 'Lo sentimos, algo no ha salido bien, intente más tarde';
            showError(errorMessage);
        }

        setEmail('');
    };

    const changeEmail = (event) => {
        setEmail(event.target.value);
    };

    return (
        <Card>
            <NotificationContainer />
            <CardContent>
                <Grid container className={styles.container}>
                    <Grid item xs={12} style={styles.header}>
                        Vita Wallet Business
                    </Grid>
                    <Grid item xs={12} style={styles.description}>
                        Ingrese el correo electrónico del usuario a convertirse en negocio
                    </Grid>
                    <Grid item xs={12} style={styles.boxCenter}>
                        <TextField
                            label="Correo electrónico"
                            value={email}
                            onChange={changeEmail}
                        />
                    </Grid>
                    <Grid item xs={12} style={styles.header}>
                        <Button variant="contained" color="primary" onClick={send}>
                            <div style={{color: '#000000'}}>
                                ASIGNAR NEGOCIO
                            </div>
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

BusinessesCreate.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default BusinessesCreate;
