import React from "react";
import { Datagrid, List, TextField } from "react-admin";
import { CouponFilter } from "../Filters";

import HeaderMenu from "./Components/HeaderMenu";
// import useModal from '../../../hooks/useModal';
// import ModalExport from '../../../Components/ModalExport';

const CouponList = (props) => {
  const { history } = props;
  // const { open, showModal, hiddeModal } = useModal();

  const StatusUser = (propsRecord) => {
    const { record: { attributes: { user: { status } } } } = propsRecord
    return (
      <p >
        {status === null ? 'Inactivo' : status}
      </p>
    )
  }

  const RenderList = () => {
    return (
      <List
        {...props}
        title={"CuponesList"}
        filters={<CouponFilter />}
        bulkActionButtons={false}
        exporter={() => { }}
        debounce={1000}
      >
        <Datagrid>
          <TextField source={"attributes.user.id"} label={"Id user segment"} />
          <TextField source={"attributes.user.email"} label={"Email"} />
          <StatusUser label={"Status"} />

        </Datagrid>
      </List>
    );
  };

  return (
    <>
      <HeaderMenu history={history} />
      <RenderList />
      {/* <ModalExport
				open={open}
				hiddeModal={hiddeModal}
			/> */}
    </>
  );
};

export default CouponList;
