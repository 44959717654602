import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
  Avatar,
  FormControlLabel,
  Switch,
} from "@material-ui/core";

import EditLinkPayment from "../EditLinkPayment";
import useLinkPayment from "../../../../../../../hooks/useLinkPayment";
import { COLUNMS_LINKS_PAYMENT } from "../utils";

const LinksPayment = (props) => {
  const {
    handleCloseModal,
    handleOnchange,
    handleOpenModal,
    handleShowImage,
    handleUpdateProvider,
    hanleOnChangeStatus,
    image_design,
    isOpenModal,
    itemUpdate,
    providersList,
    setImage_design,
  } = useLinkPayment(props.id);

  return (
    <>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              {COLUNMS_LINKS_PAYMENT.map((colum, index) => {
                return <TableCell key={index}>{colum}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {providersList.map((item) => {
              return (
                <TableRow key={item.id}>
                  {item.status === "active" ? (
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={true}
                            onChange={() => hanleOnChangeStatus(item)}
                            name={"id"}
                            color={"primary"}
                          />
                        }
                      />
                    </TableCell>
                  ) : (
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={false}
                            onChange={() => hanleOnChangeStatus(item)}
                            name={"id"}
                            color={"secundary"}
                          />
                        }
                      />
                    </TableCell>
                  )}

                  <TableCell> {item.id} </TableCell>
                  <TableCell> {item.code} </TableCell>
                  <TableCell> {item.public_name} </TableCell>

                  <TableCell style={{height: 150,}}>
                    <img src={item.logotype_image} alt={item.name} style={{width: '100%', height: 80, objectFit: 'contain'}} />
                  </TableCell>

                  <TableCell>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => handleOpenModal(item)}
                      data-testid='edit-button'
                    >
                      Editar
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
      <EditLinkPayment
        handleCloseModal={handleCloseModal}
        handleOnchange={handleOnchange}
        handleUpdateProvider={handleUpdateProvider}
        image_design={image_design}
        isOpenModal={isOpenModal}
        itemUpdate={itemUpdate}
        setImage_design={setImage_design}
        showImage={handleShowImage}
      />
    </>
  );
};

LinksPayment.propTypes = {
  itemUpdate: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    public_name: PropTypes.string,
  }),
  handleCloseModal: PropTypes.func,
  handleOnchange: PropTypes.func,
  handleOpenModal: PropTypes.func,
  handleShowImage: PropTypes.func,
  handleUpdateProvider: PropTypes.func,
  hanleOnChangeStatus: PropTypes.func,
  image_design: PropTypes.array,
  isOpenModal: PropTypes.bool,
  providersList: PropTypes.array,
  setImage_design: PropTypes.func,
};

LinksPayment.defaultProps = {
  itemUpdate: {
    id: 1,
    name: "",
    public_name: "",
  },
  handleCloseModal: () => {},
  handleOnchange: () => {},
  handleOpenModal: () => {},
  handleShowImage: () => {},
  handleUpdateProvider: () => {},
  hanleOnChangeStatus: () => {},
  image_design: [],
  isOpenModal: false,
  providersList: [],
  setImage_design: () => {},
};

export default LinksPayment;
