const styles = {
  containerList: {
    backgroundColor: '#424242',
    minHeight: 880,
    padding: 50
  },
  containerHeader: {
    backgroundColor: '#424242',
    paddingTop: 20,
    paddingBottom: 30,
  },
  styleButtonEdit: {
    backgroundColor: "#025FEA",
    color: "white",
    height: 32,
    border: "none",
    borderRadius: 5,
    fontWeight: "bold",
    width: 60,
    cursor: "pointer",
  },
}


export default styles;