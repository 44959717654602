import { useState } from 'react';
import userTrxLimitsProvider from '../Providers/UserTrxLimits';
import useAlerts from './useAlerts';
import useUserLimitRequestDownload from './useUserLimitRequestDownload';
import { isImageFromUrl } from '../Utils';
import useAccessRole from './useAccessRole';

const useUpdateUserLimitRequest = (props = {}) => {
    const { 
        id = 0,
        status = '',
        requested_limit = 0,
        approval_amount = 0,
        full_name = '',
        address_proof = null,
        income_verification = null,
        hiddeModal = () => {},
        updateRequests = () => {},
        address_proof_at,
        income_verification_at,
    } = props;
    const [ approveAmount, setApproveAmount ] = useState(approval_amount);
    const [ addressProofFile, setAddressProofFile ] = useState(null);
    const [ incomeVerificationFile, setIncomeVerificationFile ] = useState(null);
    const { showModal, showLoading, errorMessage } = useAlerts();
    const downloadHook = useUserLimitRequestDownload(props);
    const { isAccess } = useAccessRole();

    const changeAddressProopFile = (event) => {
        setAddressProofFile(event.target.files[0]);
    };

    const changeIncomeVerificationFile = (event) => {
        setIncomeVerificationFile(event.target.files[0]);
    };

    const onCloseIncomeFile = () => {
        setIncomeVerificationFile(null);
    };

    const onCloseAddressFile = () => {
        setAddressProofFile(null);
    };

    const onUploadFiles = async () => {
        try {
            const { isConfirmed = false } = await showModal({
                title: `¿Estás seguro que deseas actualizar ${
                    incomeVerificationFile && addressProofFile ? 
                        'los documentos de comprobacion de ingresos y residencia?' : 
                        incomeVerificationFile ? 
                            'el documento de comprobación de ingresos?' : 
                            'el documento de comprobación de residencia?'
                }`,
                text: "Esta operación es irreversible",
                icon: "warning",
                confirmButtonColor: '#009bff',
                cancelButtonColor: 'transparent',
                confirmButtonText: 'Aprobar',
            });

            if (isConfirmed) {
                showLoading();

                const data = new FormData();
                if (incomeVerificationFile) {
                    data.append('income_verification', incomeVerificationFile);
                }

                if (addressProofFile) {
                    data.append('address_proof', addressProofFile);
                }
                
                await userTrxLimitsProvider.updateUserTrxLimit(id, data, true);

                hiddeModal();
                updateRequests();

                showModal({
                    title: `¡Documento${
                        incomeVerificationFile && addressProofFile ? 's' : ''
                    } actualizado${
                        incomeVerificationFile && addressProofFile ? 's' : ''
                    }!`,
                    text: "",
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: '#00ff9b',
                    confirmButtonText: 'Entendido',
                });
            }
        } catch (error) {
			errorMessage(error.data.error.message);
		}
    };

    const onApprove = async () => {
        try {
            const { isConfirmed = false } = await showModal({
                title: `¿Estás seguro que deseas aumentar el límite a ${approveAmount} USD${
                    incomeVerificationFile && addressProofFile ? 
                        ', ademas de actualizar los documentos de comprobacion de ingresos y residencia' : 
                        incomeVerificationFile ? 
                            ', ademas de actualizar el documento de comprobación de ingresos' : 
                            addressProofFile ? ', ademas de actualizar el documento de comprobación de residencia' : ''
                }?`,
                text: "Podrás rechazar este limite, cuando lo desees",
                icon: "warning",
                confirmButtonColor: '#009bff',
                cancelButtonColor: 'transparent',
                confirmButtonText: 'Aprobar',
            });

            if (isConfirmed) {
                showLoading();

                const data = new FormData();
                data.append('status', 'approved');
                data.append('approval_amount', approveAmount);

                if (incomeVerificationFile) {
                    data.append('income_verification', incomeVerificationFile);
                }

                if (addressProofFile) {
                    data.append('address_proof', addressProofFile);
                }

                await userTrxLimitsProvider.updateUserTrxLimit(id, data);

                hiddeModal();
                updateRequests();

                showModal({
                    title: '¡Límite aprobado!',
                    text: incomeVerificationFile && addressProofFile ? 
                    'Ademas se actualizaron los documentos de comprobacion de ingresos y residencia' : 
                    incomeVerificationFile ? 
                        'Ademas se actualizó el documento de comprobación de ingresos' : 
                        addressProofFile ? 'Ademas se actualizó el documento de comprobación de residencia' : '',
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: '#00ff9b',
                    confirmButtonText: 'Entendido',
                });
            }
        } catch (error) {
			errorMessage(error.data.error.message);
		}
    };

    const onDecline = async () => {
        try {
            const { isConfirmed = false } = await showModal({
                title: `¿Estás seguro que deseas rechazar el límite a ${approval_amount > 0 ? approval_amount : requested_limit} USD?`,
                text: "Podrás aprobar este limite, cuando lo desees",
                icon: "warning",
                confirmButtonColor: '#ff2e00',
                cancelButtonColor: 'transparent',
                confirmButtonText: 'Rechazar',
            });

            if (isConfirmed) {
                showLoading();

                const data = { status: 'rejected' };
                await userTrxLimitsProvider.updateUserTrxLimit(id, data);

                hiddeModal();
                updateRequests();

                showModal({
                    title: '¡Límite rechazado!',
                    text: "",
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: '#00ff9b',
                    confirmButtonText: 'Entendido',
                });
            }
        } catch (error) {
			errorMessage(error.data.error.message);
		}
    };

    const changeApproveAmount = (event) => {
        setApproveAmount(parseFloat(event.target.value || 0));
    };
    
    return {
        ...props,
        ...downloadHook,
        approveAmount,
        isAmountEdit: !!isAccess('update_general_limits'),
        isApproved: !!isAccess('update_general_limits') && approveAmount > 0 && ( approveAmount !== approval_amount || status !== 'approved'), 
        isDeclined: !!isAccess('update_general_limits') && status !== 'rejected',
        isIncomeVerification: !!income_verification,
        isAddressProof: !!address_proof,
        isImageAddressProof: isImageFromUrl(address_proof),
        isImageIncomeVerification: isImageFromUrl(income_verification),
        full_name,
        address_proof_at,
        income_verification_at,
        isAddressProofFile: !!addressProofFile,
        isIncomeVerificationFile: !!incomeVerificationFile,
        addressProofFile,
        incomeVerificationFile,
        onApprove,
        onDecline,
        changeApproveAmount,
        changeAddressProopFile,
        changeIncomeVerificationFile,
        onCloseIncomeFile,
        onCloseAddressFile,
        onUploadAddressFile: onUploadFiles,
        onUploadIncomeFile: onUploadFiles,
    }
};

export default useUpdateUserLimitRequest;