import React, { useState }          from 'react';
import { Grid }                     from '@material-ui/core';
import { TextInput, BooleanInput }  from 'react-admin';

import { TO_NUMBER, toMoney }  from '../../../../../Helpers/MoneyHelper';

import styles  from './styles';
import TbkLogo from "../../../../../Assets/images/tbk.svg";

const SetTransbankLimit = (props) => {
    const { record } = props;
    const { attributes: { default_currency, daily_transbank_recharge_limit, monthly_transbank_recharge_limit } } = record;
    const [isCheckedDeleteDailyLimitAMount, setCheckedDeleteDailyLimitAMount] = useState(false);
    const [isCheckedDeleteMountlyLimitAMount, setCheckedDeleteMountlyLimitAMount] = useState(false);

    const formatValue = (value) =>
        toMoney(
            value,
            default_currency
        );

    const parseValue = (value) =>
        TO_NUMBER(
            value,
            default_currency
        );

    return(
        <Grid container direction="row" justify="center" alignItems="center">
            <div style={{width: '100%', paddingTop: 50, textAlign: 'center'}}>
                <img style={{width: 200}} src={TbkLogo} />
            </div>
            {
                !isCheckedDeleteDailyLimitAMount ? (
                    <Grid style={styles.divisor}>
                        <TextInput
                            format={formatValue}
                            parse={parseValue}
                            record={record}
                            defaultValue={daily_transbank_recharge_limit}
                            source="daily_transbank_recharge_limit_add"
                            label={daily_transbank_recharge_limit ? `Fijar nuevo límite diario` : `Fijar límite diario`}
                        />
                    </Grid>
                ) : null
            }
            { daily_transbank_recharge_limit ? (
                <Grid style={styles.divisor}>
                    <BooleanInput
                        label="Eliminar limite"
                        source="delete_daily_transbank_recharge_limit_amount"
                        onChange={(event) => {
                            const { target : { checked }} = event;
                            setCheckedDeleteDailyLimitAMount(checked);
                        }}
                    />
                </Grid>
            ) : null}
            {
                !isCheckedDeleteMountlyLimitAMount ? (
                    <Grid style={styles.divisor}>
                        <TextInput
                            format={formatValue}
                            parse={parseValue}
                            record={record}
                            defaultValue={monthly_transbank_recharge_limit}
                            source="monthly_transbank_recharge_limit_add"
                            label={monthly_transbank_recharge_limit ? `Fijar nuevo límite mensual` : `Fijar límite mensual`}
                        />
                    </Grid>
                ) : null
            }
            { monthly_transbank_recharge_limit ? (
                <Grid style={styles.divisor}>
                    <BooleanInput
                        label="Eliminar limite"
                        source="delete_monthly_transbank_recharge_limit_amount"
                        onChange={(event) => {
                            const { target : { checked }} = event;
                            setCheckedDeleteMountlyLimitAMount(checked);
                        }}
                    />
                </Grid>
            ) : null}
        </Grid>
    );
};

export default SetTransbankLimit;
