import React, {useEffect, useState, useCallback} from 'react';
import {BooleanInput, Edit, FormTab, TabbedForm, TextInput, SelectInput, FormDataConsumer, FileInput, FileField} from "react-admin";
import styles from "../../Users/UserEdit/styles";
import {Col, Container, Row} from "react-grid-system";
import useCountries from "../../../hooks/useCountries";
import banksProvider from "../../../Providers/Banks";
import {PostEditToolbar} from "../../Users/UserEdit/CustomComponents";

const BankAccountAccessesEdit = (props) => {
    const {countries} = useCountries();
    const [isoCode, setIsoCode] = useState('');
    const [banks, setBanks] = useState([]);

    const fetchBanks = useCallback(async () => {
        if (isoCode) {
            const response = await banksProvider.getCountryCodeBanks({iso_code: isoCode});
            setBanks(response.data.data.map(item => ({
                id: parseInt(item.id),
                name: item.attributes.name
            })));
        }
    }, [isoCode]);

    const searchCountry = useCallback((countryId) => {
        countries.some((country) => {
            const validation = parseInt(country.id) === parseInt(countryId);
            setIsoCode(country.iso_code);
            return validation;
        })
    }, [countries]);

    useEffect(() => {
        fetchBanks();
    }, [isoCode]);

    return (
        <Edit title={`Editar acceso bancario ${props.id}`} {...props}>
            <TabbedForm toolbar={<PostEditToolbar />}>
                <FormTab label="Acceso a cuenta bancaria" style={styles.titleWhite}>
                    <Container style={{ width: "100%" }}>
                        <FormDataConsumer>
                            {(form) => {
                                if (countries.length > 0 && form && form.formData && form.formData.attributes && form.formData.attributes.country_id) {
                                    searchCountry(form.formData.attributes.country_id);
                                }
                                return null
                            }}
                        </FormDataConsumer>
                        <Row
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            className={{ flexGrow: 1 }}
                        >
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <TextInput label={"Nombre"} source={"attributes.name"} />
                            </Col>
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <SelectInput
                                    label="Fuente"
                                    source="attributes.source"
                                    choices={[
                                        {
                                            label: 'Fintoc',
                                            value: 'fintoc',
                                            name: 'Fintoc',
                                            id: 'fintoc',
                                        },
                                        {
                                            label: 'Floyd',
                                            value: 'floyd',
                                            name: 'Floyd',
                                            id: 'floyd',
                                        },
                                        {
                                            label: 'Bind',
                                            value: 'bind',
                                            name: 'Bind',
                                            id: 'bind',
                                        },
                                    ]}
                                />
                            </Col>
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <SelectInput
                                    label="Tipo de historia"
                                    source="attributes.history_type"
                                    choices={[
                                        {
                                            label: 'Completa',
                                            value: 'full',
                                            name: 'Completa',
                                            id: 'full',
                                        },
                                        {
                                            label: 'Parcial',
                                            value: 'partial',
                                            name: 'Parcial',
                                            id: 'partial',
                                        },
                                    ]}
                                />
                            </Col>
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <SelectInput
                                    label="Banco"
                                    source="attributes.bank_id"
                                    choices={banks}
                                    disabled={banks.length === 0}
                                />
                            </Col>
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <SelectInput
                                    label="Tipo de cuenta"
                                    source="attributes.account_type"
                                    choices={[
                                        {
                                            label: 'Ahorros',
                                            value: 'savings',
                                            name: 'Ahorros',
                                            id: 'savings',
                                        },
                                        {
                                            label: 'Corriente',
                                            value: 'checking',
                                            name: 'Corriente',
                                            id: 'checking',
                                        },
                                    ]}
                                />
                            </Col>
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <TextInput label={"Número de cuenta"} source={"attributes.account_number"} />
                            </Col>
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <TextInput label={"Url"} source={"attributes.endpoint_url"} />
                            </Col>
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <TextInput label={"Url de autenticación"} source={"attributes.auth_endpoint_url"} />
                            </Col>
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <TextInput label={"Proxy (xxx.xxx.xxx.xxx)"} source={"attributes.proxy_url"} />
                            </Col>
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <TextInput label={"Puerto del proxy"} source={"attributes.proxy_port"} />
                            </Col>
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <TextInput label={"Usuario"} source={"attributes.username"} />
                            </Col>
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <TextInput label={"Número de documento"} source={"attributes.document_number"} />
                            </Col>
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <TextInput label={"Contraseña"} source={"attributes.password"} />
                            </Col>
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <TextInput label={"Api key"} source={"attributes.api_key"} />
                            </Col>
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <TextInput label={"Secret key"} source={"attributes.secret_key"} />
                            </Col>
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <TextInput
                                    label={'Cantidad de registros'}
                                    source={'attributes.per_page'}
                                />
                            </Col>
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <TextInput
                                    label={'Expresión regular de posición de documento'}
                                    source={'attributes.search_regex'}
                                />
                            </Col>
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <TextInput
                                    label={'Expresión regular de formato del documento'}
                                    source={'attributes.select_regex'}
                                />
                            </Col>
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <TextInput
                                    label={'Expresión regular para limpiar caracteres del documento'}
                                    source={'attributes.cleanup_regex'}
                                />
                            </Col>
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <BooleanInput label={"Activo?"} source={"attributes.is_active"} />
                            </Col>
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <TextInput
                                    label={'Límite de transacciones a leer'}
                                    source={'attributes.transactions_limit'}
                                />
                            </Col>
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <TextInput
                                    label={'Cantidad de días de aceptación (< today - days)'}
                                    source={'attributes.acceptance_days'}
                                />
                            </Col>
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <TextInput
                                    label={'Clave del certificado'}
                                    source={'attributes.crt_password'}
                                />
                            </Col>
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <FileInput 
                                    source="attributes.crt_file" 
                                    label="Certificado (.crt)"
                                    placeholder={<p>Certificado (.crt)</p>}              
                                >
                                    <FileField 
                                        source="attributes.crt_file" 
                                        title="title"
                                    />
                                </FileInput> 
                            </Col>
                            <Col
                                xs={3}
                                style={{
                                    ...styles.divisor,
                                    display: "flex",
                                    textAlign: "center",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <FileInput 
                                    source="attributes.key_file" 
                                    label="Clave del certificado (.key)"
                                    placeholder={<p>Clave del certificado (.key)</p>}
                                >
                                    <FileField 
                                        source="attributes.key_file" 
                                        title="title" 
                                    />
                                </FileInput> 
                            </Col>
                        </Row>
                    </Container>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default BankAccountAccessesEdit;