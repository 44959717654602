import {
  email,
  required,
  regex
} from 'react-admin';
import { maxSizeIconCategory } from '../Utils';
import { maxSizeDocumentImage, sizeBinaryByte } from '../Utils/index';
export const validateEmail = email('Ingrese un email valido');
export const validateRequired = required('Campo requerido');
export const validateVitaEmail = regex(/^.*(@vitawallet.io)$/, 'No es un vita correo');

export const validateEqualPassword = (values) => {
  const errors = {
    attributes: {}
  };

  if (values.attributes) {
    if (values.attributes.password !== values.attributes.password_confirmation) {
      errors.attributes.password_confirmation = ['Las contraseñas no coinciden.'];
    }
  }

  return errors;
};

export const sizeTypeFile = (values) => {

  const errors = {
    attributes: {}
  };

  const document = values.attributes.document_image;

  if (document) {
    const statement = document.rawFile.type.includes("jpg") || document.rawFile.type.includes("png") ||
      document.rawFile.type.includes("bmp") || document.rawFile.type.includes("gif") ||
      document.rawFile.type.includes("pdf") || document.rawFile.type.includes("jpeg");

    if (document.rawFile.size > maxSizeDocumentImage) {
      errors.attributes.document_image = [`Archivo excede peso máximo de ${maxSizeDocumentImage/sizeBinaryByte}MB`];

      if (!statement) {
        errors.attributes.document_image.push(' y solo se permiten los formatos: jpg, png, bmp, gif, pdf y jpeg.');
      }
    } else {
      if (!statement) {
        errors.attributes.document_image = ['Solo se permiten los formatos: jpg, png, bmp, gif, pdf y jpeg.'];
      }
    }
  }

  return errors;
};


export const validateIconCategory = (value) => {
  const errors = {
    attributes: {}
  };

  const icon = value.hasOwnProperty("attributes") && value.attributes.hasOwnProperty("icon") ? value.attributes.icon : false;

  if (icon) {
    const formatVerification = icon.rawFile.type.includes("jpg") || icon.rawFile.type.includes("png") || icon.rawFile.type.includes("jpeg");

    if (icon.rawFile.size > maxSizeIconCategory) {
      errors.attributes.icon = ['Icono excede peso máximo de 100KB'];

      if (!formatVerification) {
        errors.attributes.icon.push(' y solo se permiten los formatos: jpg, png y jpeg.');
      }
    } else {
      if (!formatVerification) {
        errors.attributes.icon = ['Solo se permiten los formatos: jpg, png y jpeg.']
      }
    }
  }

  return errors;
};
