import { useCallback, useMemo, useState } from 'react';

import useAlerts from './useAlerts';
import useReset from './useReset';
import useAccessRole from './useAccessRole';
import { WithdrawalProvider, TransactionProvider } from '../Providers';
import { toMoney } from '../Helpers/MoneyHelper';
import { createBrowserHistory as createHistory } from 'history';

const usePayouts = (props = {}) => {
    const { transaction = {}, isTransferCountry = () => { return true; }, isTransaction = false } = props;
    const {
        id,
        attributes: {
            payment_processors = {},
            currency = '',
            category = '',
            external_provider = '',
            external_id = '',
            account_bank = {},
            status = '',
            locking_status = 'unlocked',
            beneficiary = {},
        },
    } = transaction;
    const { iso_code_country } = account_bank;
    const [beneficiaryAmount, setBeneficiaryAmount] = useState(null);
    const [isShowModal, setIsShowModal] = useState(false);
    const [isClickOnLock, setIsClickOnLock] = useState(false);
    const history = createHistory({ forceRefresh: true });

    const { reloadPage } = useReset();
    const { isAccess } = useAccessRole();
    const {
        showLoading,
        hiddenLoading,
        showSelect,
        successMessagePayment,
        errorMessage,
        showRequest,
        successMessage,
        showModal,
        close,
        showCustomError,
        errorMessageCustom,
        showHTML,
    } = useAlerts();

    const isMobilePayment = useMemo(() => {
        return account_bank.account_bank && account_bank.account_bank.transfer_type ?
            account_bank.account_bank.transfer_type === 'Pago movil' && !payment_processors.hasOwnProperty('transferswap') : false;
    }, []);

    const isCreatePayout = useMemo(() => {
        const validate_mobile_payment = account_bank.account_bank && account_bank.account_bank.transfer_type ?
            account_bank.account_bank.transfer_type !== 'Pago movil' || payment_processors.hasOwnProperty('transferswap') : true;

        return (
            isAccess('approve_withdrawal') &&
            !external_provider &&
            !external_id &&
            validate_mobile_payment && (
                (iso_code_country || "").toUpperCase() === 'CL' ||
                isTransferCountry(account_bank.iso_code_country)
            )
        );
    }, [isAccess, external_provider, external_id, account_bank, isTransferCountry, iso_code_country]);

    const isRejectPayout = useMemo(() => {
        return isAccess('decline_withdrawal');
    }, [isAccess]);

    const isPayoutsButtons = useMemo(() => {
        return !(external_provider && external_id);
    }, [external_provider, category, currency, external_id]);

    const rejectLabel = useMemo(() => {
        return external_id && external_provider === 'dlocal' ? 'CANCELAR PAGO' : "RECHAZAR PAGO"
    }, [currency, category, external_id, external_provider]);

    const isProcessPayout = useMemo(() => {
        return status === 'pending';
    }, [status]);

    const isCompletedPayout = useMemo(() => {
        return isAccess('approve_withdrawal');
    }, [isAccess]);

    const isEditTransactionLockedStatus = useMemo(() => {
        return isAccess('edit_transaction_locked_status') && !external_provider && status !== 'completed' && status !== 'denied';
    }, [isAccess, status, external_provider]);

    const lockedLabel = useMemo(() => {
        return locking_status !== 'unlocked' ? 'Desbloquear' : 'Bloquear';
    }, [locking_status]);

    const isAddComment = useMemo(() => {
        return isAccess('create_transaction_comments');
    }, [isAccess]);

    const createPayout = useCallback(async () => {
        try {

            let processors = { ...payment_processors };
            if ("alfin" in processors) {
                processors.alfin = "Alfin Banco";
            }

            delete processors["default"];

            if (
                account_bank &&
                account_bank.account_bank &&
                account_bank.account_bank.transfer_type === 'Pago movil' &&
                payment_processors.hasOwnProperty('transferswap')
            ) {
                processors = { manual: 'manual', transferswap: 'transferswap' };
            }

            const params = {
                text: 'Por favor ingrese el procesador de pago',
                inputPlaceholder: 'Seleccione el procesador de pago',
                inputValue: 'manual',
                inputOptions: processors,
            };

            const { isConfirmed = false, value = '' } = await showSelect(params);

            if (isConfirmed && value) {
                if (value.toLowerCase() === 'manual') {
                    manualPayout(true)
                } else {
                    const params = {
                        text: `Vamos a realizar el pago con ${value}`,
                        title: `Pago automático con ${value.toUpperCase()}`
                    };

                    const { isConfirmed = false } = await showModal(params);

                    if (isConfirmed) {
                        showLoading();
                        await TransactionProvider.acceptedWithdawal(id, value);
                        successMessagePayment(value);
                        reloadPage();
                    }
                }
            }
        } catch (error) {
            errorMessage(error && error['data'] && error['data']['message'] ? error['data']['message'] : 'Ha ocurrido un error');
        }

        hiddenLoading();
    }, [
        TransactionProvider,
        payment_processors,
        showLoading,
        showSelect,
        successMessagePayment,
        reloadPage,
        errorMessage,
        hiddenLoading,
    ]);

    const createDefaultPayout = useCallback(async () => {
        if (payment_processors["default"].toLowerCase() === 'manual') {
            manualPayout(true);
        } else {
            try {
                const params = {
                    text: `Vamos a realizar el pago con ${payment_processors["default"]}`,
                    title: `Pago automático con ${payment_processors["default"].toUpperCase()}`,
                };

                const { isConfirmed = false, value = '' } = await showModal(params);

                if (isConfirmed && value) {
                    showLoading();
                    await TransactionProvider.acceptedWithdawal(id, payment_processors["default"]);
                    successMessagePayment(payment_processors["default"]);
                    reloadPage();
                }
            } catch (error) {
                errorMessage(error && error['data'] && error['data']['message'] ? error['data']['message'] : 'Ha ocurrido un error');
            }
        }

        hiddenLoading();
    }, [
        TransactionProvider,
        payment_processors,
        showLoading,
        showSelect,
        successMessagePayment,
        reloadPage,
        errorMessage,
        hiddenLoading,
    ]);

    const deletePayout = useCallback(async () => {
        try {
            const params = {
                text: 'Por favor ingresa la razón del rechazo',
                reasonLabel: 'La razón del rechazo es requerida',
                inputValue: 'Rechazo por Cumplimiento',
            };

            const { isConfirmed = false, value = '' } = await showRequest(params);

            if (isConfirmed) {
                showLoading();
                await TransactionProvider.rejectedWithdrawal(id, value);
                successMessage('Transacción rechazada correctamente');
                reloadPage();
            }
        } catch (error) {
            errorMessage(error && error['data'] && error['data']['message'] ? error['data']['message'] : 'Ha ocurrido un error');
        }

        hiddenLoading();
    }, [
        TransactionProvider,
        showLoading,
        showRequest,
        successMessage,
        reloadPage,
        errorMessage,
        hiddenLoading,
    ]);

    const cancelPayout = useCallback(async () => {
        try {
            showLoading();
            await TransactionProvider.cancelPayment(id);
            reloadPage();
        } catch (error) {
            errorMessage(error && error['data'] && error['data']['message'] ? error['data']['message'] : 'Ha ocurrido un error');
        }

        hiddenLoading();
    }, [
        TransactionProvider,
        showLoading,
        showRequest,
        reloadPage,
        errorMessage,
        hiddenLoading,
    ]);

    const rejectPayout = useCallback(async () => {
        if (external_id && external_provider === 'dlocal') {
            try {
                const { isConfirmed = false } = await showModal({ title: '¿Desea cancelar el pago?' });

                if (isConfirmed) {
                    cancelPayout();
                }
            } catch { }
        } else {
            deletePayout();
        }
    }, [
        external_id,
        showModal,
        deletePayout,
        external_provider
    ]);

    const manualPayout = useCallback(async (isCompleted = false) => {
        try {
            let params = {
                text: external_id ?
                    `Este retiro tiene asociado el id del proveedor ${external_id}, por favor ingresa una descripción`
                    : 'Por favor ingresa una descripción',
                reasonLabel: 'La descripción es requerida',
            };

            const result = await showRequest(params);

            if (result.isConfirmed) {
                if (external_id) {
                    const result2 = await showModal({ title: `¿Desea marcar como ${isCompleted ? 'completada?' : 'procesada?'}` });

                    if (result2.isConfirmed) {
                        showLoading();

                        await WithdrawalProvider.changeStatus(
                            {
                                id,
                                description: result.value,
                                external_id,
                                value: isCompleted ? 0 : 1,
                            }
                        );

                        successMessagePayment("manual");
                        reloadPage();
                    }
                } else {
                    let params = {
                        text: 'Por favor ingrese el id del proveedor',
                        reasonLabel: 'El id es requerido',
                    };

                    const result2 = await showRequest(params);

                    if (result2.isConfirmed) {
                        const result3 = await showModal({ title: `¿Desea marcar como ${isCompleted ? 'completada?' : 'procesada?'}` });

                        if (result3.isConfirmed) {
                            showLoading();

                            await WithdrawalProvider.changeStatus(
                                {
                                    id,
                                    description: result.value,
                                    external_id: result2.value,
                                    value: isCompleted ? 0 : 1,
                                }
                            );

                            successMessagePayment("manual");
                            reloadPage();
                        }
                    }
                }
            }

        } catch (error) {
            errorMessage(error && error['data'] && error['data']['message'] ? error['data']['message'] : 'Ha ocurrido un error');
        }

        hiddenLoading();
    }, [
        external_id,
        showRequest,
        showLoading,
        errorMessage,
        hiddenLoading,
        reloadPage,
        WithdrawalProvider,
        successMessagePayment,
    ]);

    const toProcessPayout = () => {
        manualPayout();
    };

    const toCompletedPayout = () => {
        manualPayout(true);
    };

    const showCommentModal = useCallback(async (is_lock_button = false) => {
        if (is_lock_button) {
            setIsClickOnLock(true);
            setIsShowModal(true);
        } else {
            setIsClickOnLock(false);
            setIsShowModal(true)
        }
    });

    const hiddenCommentModal = useCallback(async (save_comment) => {
        if (isClickOnLock) {
            setIsShowModal(false);

            if (save_comment) {
                await changeLockedStatus();
            }
        } else {
            setIsShowModal(false);
        }
    });

    const changeLockedStatus = useCallback(async () => {
        showLoading();
        const new_locking_status = locking_status === 'unlocked' ? 'locked_by_standard_flow' : 'unlocked'
        try {

            await TransactionProvider.updateLockedStatus(id, { locking_status: new_locking_status });

            reloadPage();
            close();
        } catch (error) {
            close();
            showCustomError(
                'Lo sentimos, algo no fue bien',
                'No fue posible cambiar el estado de bloqueo de este registro.',
                'error'
            );
        }
    }, [
        id,
        locking_status,
        TransactionProvider,
        showLoading,
        reloadPage,
        close,
        showCustomError,
    ]);

    const getTotalAmountRecipient = useCallback(async () => {
        try {
            const response = await TransactionProvider.getTotalAmountRecipient(
                account_bank.document_number,
                account_bank.iso_code_country
            );

            if (response) {
                setBeneficiaryAmount(
                    `${toMoney(response.data.balance.amount, response.data.balance.currency)} ${response.data.balance.currency}`
                )
            }
        } catch (error) {
            errorMessageCustom(
                'Balance',
                error.data.error.message ? error.data.error.message : error
            );
        }
    }, [
        TransactionProvider,
        account_bank,
        setBeneficiaryAmount,
        errorMessageCustom,
    ]);

    const beneficiaryParams = useMemo(() => {
        return {
            beneficiaryAmount,
            getTotalAmountRecipient,
            beneficiary,
            goToFilterTransaction: (document_number) => {
                window.open(`/transacciones?filter={"document_number"%3A"${document_number}"}`, '_blank');
                // history.push(`/transacciones?filter={"document_number"%3A"${document_number}"}`);
            },
            isTransaction,
        };
    }, [
        beneficiary,
        beneficiaryAmount,
        getTotalAmountRecipient,
        history,
        isTransaction,
    ]);

    return {
        id,
        isProcessStatus: status === 'processed' && isAccess('approve_withdrawal'),
        isMobilePayment,
        isCreatePayout,
        isRejectPayout,
        createPayout,
        rejectPayout,
        toProcessPayout,
        toCompletedPayout,
        changeLockedStatus,
        rejectLabel,
        isPayoutsButtons,
        isProcessPayout,
        isCompletedPayout,
        isEditTransactionLockedStatus,
        lockingStatus: locking_status,
        lockedLabel,
        beneficiaryParams,
        defaultPaymentProcessor: payment_processors["default"] || "",
        createDefaultPayout,
        isAddComment,
        isShowModal,
        showCommentModal,
        hiddenCommentModal,
    };
};

export default usePayouts;