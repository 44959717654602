import React from "react";
import { EditButton } from 'react-admin';
import PropTypes from 'prop-types';

const ShowEditButton = (props) => props.hasEdit && <EditButton {...props} label="" />;

ShowEditButton.propTypes = {
  hasEdit: PropTypes.bool.isRequired
};

ShowEditButton.defaultProps = {
  hasEdit: false
};

export default ShowEditButton;