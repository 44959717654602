import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import useFortress from '../../../../hooks/useFortress';

export const PayIn = (props) => (
	<>
		{
			props.isAnchoring && (
				<Button 
            		onClick={props.onAnchoring}
            		style={styles.button}
        		>
            		Instrucciones de fondeo
        		</Button>
			)
		}
		{
			props.isBalances && (
				<Button 
            		onClick={props.onBalances}
            		style={{ ...styles.button, ...styles.distance }}
        		>
            		Balance
        		</Button>
			)
		}
		{ 
		    props.isCreatePayment && (
				<Button 
            		onClick={props.onCreateWithdrawal}
            		style={{ ...styles.button, ...styles.distance }}
        		>
            		Crear retiro
        		</Button>
			)
		}
	</>
);

const styles = {
	button: {
		backgroundColor: '#00aaff',
	},
	distance: {
		marginLeft: 15,
	},
};

PayIn.propTypes = {
    isAnchoring: PropTypes.bool,
	isBalances: PropTypes.bool,
	isCreatePayment: PropTypes.bool,
	onAnchoring: PropTypes.func,
	onBalances: PropTypes.func,
};

PayIn.defaultProps = {
    isAnchoring: false,
	isBalances: false,
	isCreatePayment: false,
	onAnchoring: () => {},
	onBalances: () => {},
};

const PayInHoc = (props) => {
    const hook = useFortress(props);
    return <PayIn {...hook} />;
};

export default PayInHoc;