import React from "react";
import { Grid, Button } from "@material-ui/core";
import PropTypes from "prop-types";

import { styles } from "../../styles";

const UploadImage = ({ title, handleOnChange, showImage, image_design }) => (
  <section style={{ position: "relative" }}>
    <Button
      variant='contained'
      component='label'
      style={styles.uploadContainerButton}
    >
      <Grid container style={{ display: "grid", fontSize: 12 }}>
        <Grid item> Imagen {title}</Grid>
      </Grid>
      <input
        type='file'
        name='files'
        accept='.jpg, .jpeg, .png'
        hidden
        onChange={(e) => handleOnChange(e.target.files)}
      />
    </Button>
    {image_design.length > 0 && (
      <Button onClick={() => showImage(title)} style={styles.uploadShowImage}>
        Ver
      </Button>
    )}
  </section>
);

UploadImage.propTypes = {
  title: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  showImage: PropTypes.func.isRequired,
  image_design: PropTypes.array.isRequired,
};

UploadImage.defaultProps = {
  title: "",
  handleOnChange: () => {},
  showImage: () => {},
  image_design: [],
};

export default UploadImage;
