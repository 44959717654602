import React      from 'react';
import PropTypes  from 'prop-types';
import { Button } from '@material-ui/core';

import styles from './styles';

const InformationDlocal = (props) => {
    const {
        record: {
            is_payin,
            order,
            cashout_id,
            external_id,
            email_payer,
            name_payer,
            transaction_id,
        },
        history,
    } = props;


    const goTransaction = () => {
        history.push(`/transacciones/${transaction_id}/show`);
    };

    const renderEmail = () => {
        return (
            <>
                <div style={styles.title}>Nombre</div>
                <div>{name_payer}</div>
                <div style={styles.separator} />
                <div style={styles.title}>Email</div>
                <div>{email_payer}</div>
                <div style={styles.separator} />
            </>
        );
    };

    if (is_payin)
        return (
            <div style={styles.container}>
                {renderEmail()}
                <div style={styles.title}>Orden de pago</div>
                <div>{order}</div>
            </div>
        );

    return (
        <div style={styles.container}>
            <Button onClick={goTransaction} style={styles.buttonBlue}>
                ver retiro
            </Button>
            <div style={styles.separator} />
            <div style={styles.separator} />
            {renderEmail()}
            <div style={styles.title}>Id cashout</div>
            <div>{cashout_id}</div>
            <div style={styles.separator} />
            <div style={styles.title}>Id externo</div>
            <div>{external_id}</div>
        </div>
    );
};

InformationDlocal.propTypes = {
    record: PropTypes.shape({
        is_payin: PropTypes.bool.isRequired,
        order: PropTypes.string.isRequired,
        cashout_id: PropTypes.string.isRequired,
        external_id: PropTypes.string.isRequired,
        email_payer: PropTypes.string.isRequired,
        name_payer: PropTypes.string.isRequired,
        transaction_id: PropTypes.any.isRequired,
    }).isRequired,
    history: PropTypes.any.isRequired,
};

export default InformationDlocal;
