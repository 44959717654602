import React, { useEffect, useState } from 'react';
import * as IconCryptoCurrency from 'react-cryptocoins';
import ReactCountryFlag from 'react-country-flag';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Card, CardContent, Grid, Button, CircularProgress, Switch, FormControlLabel } from '@material-ui/core';

import { statusPayin } from '../../Config/constants';
import { UserProvider } from '../../Providers';
import { toMoney } from '../../Helpers/MoneyHelper';
import { flagsCountries } from '../../Config/constants';

import styles from './styles';
import useDashboard from './hook';
import useAccessRole from '../../hooks/useAccessRole';
import { Animated } from "react-animated-css";
import Images from "../../Assets/images";
import PendingTransactionsTable from './Components/PendingTransactionsTable';
import OldPendingTransactionsTable from './Components/OldPendingTransactionsTable';
import OldPendingTransactionsFilter from './Components/OldPendingTransactionsFilter';
import StatusPricesProviders from '../StatusPricesProviders'
import GetProvidersBalances from '../providers/GetProvidersBalances';

const Dashboard = (props) => {
  const { history } = props;
  const {
    balance,
    loading,
    addBalanceFromAdmin,
    hiddenLoading,
    addBalanceGeneral,
    addCountries
  } = useDashboard();
  const refreshTime = 3; // Time in minutes.
  const { btc, clp, usd, cop, ars, mxn, usdt, usdc } = balance;
  const [generalBalanceStatus, setGeneralBalanceStatus] = useState('pending');
  const [generalBalance, setGeneralBalance] = useState({});
  const [pendingTransactionsBalanceStatus, setPendingTransactionsBalanceStatus] = useState('pending');
  const [pendingTransactionsBalance, setPendingTransactionsBalance] = useState({});
  const [adminBalanceStatus, setAdminBalanceStatus] = useState('pending');
  const [pendingTransactionListStatus, setPendingTransactionListStatus] = useState('pending');
  const [pendingTransactionsList, setPendingTransactionList] = useState([]);
  const [oldPendingTransactionListStatus, setOldPendingTransactionListStatus] = useState('pending');
  const [oldPendingTransactionListError, setOldPendingTransactionListError] = useState('');
  const [oldPendingTransactionsList, setOldPendingTransactionList] = useState([]);
  const [refresh, triggerRefresh] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [isLoad, setLoad] = useState(false);
  const [isTouch, setTouch] = useState(false);

  const { isAccess } = useAccessRole();

  const getData = async () => {
    setAdminBalanceStatus('loading')
    try {
      const response = await UserProvider.getBalanceAdminEmail();

      addBalanceFromAdmin(response.data);
      setAdminBalanceStatus('ready')
    } catch (error) {
      setAdminBalanceStatus('error');
    }

    try {
      const response = await UserProvider.getBalancesGlobalAdmin();
      addCountries(response.data);
      addBalanceGeneral(response.data);
    } catch (error) { }
  };

  const onChangeAutoRefresh = () => {
    setAutoRefresh(prev => !prev)
  };

  const loadDashboard = async () => {
    getPendingTransactionsBalance();
    getGeneralBalance();
    getData();
    getPendingTransactionsList();
    getOldPendingTransactionsList({ option: 10 });
    setLoad(!isLoad);
    setTouch(true);
  }

  const getPendingTransactionsBalance = async () => {
    setPendingTransactionsBalanceStatus('loading')
    try {
      const response = await UserProvider.getPendingTransactionsBalance();
      setPendingTransactionsBalance(response.data);
      setPendingTransactionsBalanceStatus('ready');
    } catch (error) {
      setPendingTransactionsBalanceStatus('error');
    }
  }

  const getGeneralBalance = async () => {
    setGeneralBalanceStatus('loading')
    try {
      const response = await UserProvider.getGeneralBalance();
      setGeneralBalance(response.data);
      setGeneralBalanceStatus('ready');
    } catch (error) {
      setGeneralBalanceStatus('error');
    }
  }

  const getPendingTransactionsList = async () => {
    setPendingTransactionListStatus('loading');
    try {
      const response = await UserProvider.getPendingTransactionsList();
      setPendingTransactionList(response.data.data);
      setPendingTransactionListStatus('ready');
    } catch (error) {
      setPendingTransactionListStatus('error');
    }
  }

  const getOldPendingTransactionsList = async (filters) => {
    setOldPendingTransactionListStatus('loading');

    try {
      const response = await UserProvider.getOldPendingTransactionsList(filters.option, filters.country, filters.blocked);
      setOldPendingTransactionList(response.data.data);
      setOldPendingTransactionListStatus('ready');
      setOldPendingTransactionListError('');
    } catch (error) {
      setOldPendingTransactionListError(error.data.error);
      setOldPendingTransactionListStatus('error');
    }
  }

  const handleLoad = () => {
    getOldPendingTransactionsList({ option: 10 });
    if (
      oldPendingTransactionListStatus === 'ready' ||
      oldPendingTransactionListStatus === 'error'
    ) {
      triggerRefresh(!refresh);
    };
  };

  useEffect(() => {
    let id = null;

    if (autoRefresh) {
      id = setInterval(getPendingTransactionsList, refreshTime * 60 * 1000);
    } else {
      clearInterval(id)
    }

    return () => clearInterval(id);
  }, [autoRefresh]);

  useEffect(() => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const payin_status = url.searchParams.get("payin_status");
    const payin_id = url.searchParams.get("payin_id");

    if (payin_status) {
      switch (payin_status) {
        case statusPayin.completed:
          NotificationManager.success('¡El pago ha sido completado con éxito!', 'COMPLETADO', 10000, () => {
            history.push(`/dlocal?filter={"id"%3A"${payin_id}"}`);
          });
          break;
        case statusPayin.expired:
          NotificationManager.error('Ha expirado el tiempo permitido para el pago', 'TIEMPO EXPIRADO', 10000, () => {
            history.push(`/dlocal?filter={"id"%3A"${payin_id}"}`);
          });
          break;
        case statusPayin.pending:
          NotificationManager.info('El pago sigue pendiente', 'PENDIENTE', 10000, () => {
            history.push(`/dlocal?filter={"id"%3A"${payin_id}"}`);
          });
          break;
        case statusPayin.failed:
          NotificationManager.error('Ha ocurrido un error con DLocal, por favor intente mas tarde', 'ERROR', 10000, () => {
            history.push(`/dlocal?filter={"id"%3A"${payin_id}"}`);
          });
          break;
        default:
          break;
      }
    }
  }, []);

  if (loading)
    return (
      <Card>
        <NotificationContainer />
        <CardContent>
          <div style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', paddingTop: 20, paddingBottom: 20, }}>
            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
              <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
                <Animated style={{ animationIterationCount: 'infinite' }} animationIn="jello" animationOut="fadeOut" isVisible={true}>
                  <img src={Images.vita} width={60} height={60} alt='Logo Vita Wallet' />
                </Animated>
              </Animated>
            </div>
          </div>
        </CardContent>
      </Card>
    );

  return (
    <Card>
      <NotificationContainer />
      <CardContent>
        <Button onClick={loadDashboard} style={{ border: '1px white solid' }}>
          Cargar dashboard
        </Button>
        <Grid container className={styles.container}>
          {/* <Grid item xs={12} style={styles.header}>
                        Transacciones en USD
                    </Grid>
                    <div style={styles.chartBox}>
                        <Chart data={statistics_transactions} axes={axes} series={series} tooltip dark />
                    </div> */}
          <Grid item xs={12} style={styles.subTitle}>
            <span style={{ marginRight: '2em' }}>Transacciones pendientes</span>
            <Button onClick={getPendingTransactionsBalance} disabled={pendingTransactionsBalanceStatus === 'loading'} style={{ border: '1px white solid' }}>
              {pendingTransactionsBalanceStatus === 'loading' && <CircularProgress size={14} style={{ 'color': 'white' }} />}
              {pendingTransactionsBalanceStatus === 'pending' && 'Cargar'}
              {pendingTransactionsBalanceStatus === 'ready' && 'Refrescar'}
              {pendingTransactionsBalanceStatus === 'error' && 'Error'}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={3} style={styles.itemsTitle}>
                <div>
                  <ReactCountryFlag countryCode={flagsCountries["Estados Unidos"]} style={styles.bigFlag} svg />
                </div><br />
                <div style={{ paddingBottom: 10, fontSize: 40 }}>
                  {'usd' in pendingTransactionsBalance ? pendingTransactionsBalance['usd']['count'] : '-'}
                  <div style={{ fontSize: 12 }}>
                    Cantidad
                  </div>
                </div>
                <div>
                  {'usd' in pendingTransactionsBalance ? toMoney(pendingTransactionsBalance['usd']['total_amount'], 'usd') : '-'} USD
                  <div style={{ fontSize: 12 }}>
                    Monto total
                  </div>
                </div>
              </Grid>
              <Grid item xs={3} style={styles.itemsTitle}>
                <div>
                  <ReactCountryFlag countryCode={flagsCountries["Chile"]} style={styles.bigFlag} svg />
                </div><br />
                <div style={{ paddingBottom: 10, fontSize: 40 }}>
                  {'clp' in pendingTransactionsBalance ? pendingTransactionsBalance['clp']['count'] : '-'}
                  <div style={{ fontSize: 12 }}>
                    Cantidad
                  </div>
                </div>
                <div>
                  {'clp' in pendingTransactionsBalance ? toMoney(pendingTransactionsBalance['clp']['total_amount'], 'usd') : '-'} CLP
                  <div style={{ fontSize: 12 }}>
                    Monto total
                  </div>
                </div>
              </Grid>
              <Grid item xs={3} style={styles.itemsTitle}>
                <div>
                  <ReactCountryFlag countryCode={flagsCountries["Colombia"]} style={styles.bigFlag} svg />
                </div><br />
                <div style={{ paddingBottom: 10, fontSize: 40 }}>
                  {'cop' in pendingTransactionsBalance ? pendingTransactionsBalance['cop']['count'] : '-'}
                  <div style={{ fontSize: 12 }}>
                    Cantidad
                  </div>
                </div>
                <div>
                  {'cop' in pendingTransactionsBalance ? toMoney(pendingTransactionsBalance['cop']['total_amount'], 'usd') : '-'} COP
                  <div style={{ fontSize: 12 }}>
                    Monto total
                  </div>
                </div>
              </Grid>
              <Grid item xs={3} style={styles.itemsTitle}>
                <div>
                  <ReactCountryFlag countryCode={flagsCountries["Argentina"]} style={styles.bigFlag} svg />
                </div><br />
                <div style={{ paddingBottom: 10, fontSize: 40 }}>
                  {'ars' in pendingTransactionsBalance ? pendingTransactionsBalance['ars']['count'] : '-'}
                  <div style={{ fontSize: 12 }}>
                    Cantidad
                  </div>
                </div>
                <div>
                  {'ars' in pendingTransactionsBalance ? toMoney(pendingTransactionsBalance['ars']['total_amount'], 'ars') : '-'} ARS
                  <div style={{ fontSize: 12 }}>
                    Monto total
                  </div>
                </div>
              </Grid>
              <Grid item xs={3} style={styles.itemsTitle}>
                <div>
                  <ReactCountryFlag countryCode={flagsCountries["México"]} style={styles.bigFlag} svg />
                </div><br />
                <div style={{ paddingBottom: 10, fontSize: 40 }}>
                  {'mxn' in pendingTransactionsBalance ? pendingTransactionsBalance['mxn']['count'] : '-'}
                  <div style={{ fontSize: 12 }}>
                    Cantidad
                  </div>
                </div>
                <div>
                  {'mxn' in pendingTransactionsBalance ? toMoney(pendingTransactionsBalance['mxn']['total_amount'], 'mxn') : '-'} MXN
                  <div style={{ fontSize: 12 }}>
                    Monto total
                  </div>
                </div>
              </Grid>
              <Grid item xs={3} style={styles.itemsTitle}>
                <div>
                  <IconCryptoCurrency.Btc color="yellow" size={'3em'} />
                </div><br />
                <div style={{ paddingBottom: 10, fontSize: 40 }}>
                  {'btc' in pendingTransactionsBalance ? pendingTransactionsBalance['btc']['count'] : '-'}
                  <div style={{ fontSize: 12 }}>
                    Cantidad
                  </div>
                </div>
                <div>
                  {'btc' in pendingTransactionsBalance ? toMoney(pendingTransactionsBalance['btc']['total_amount'], 'btc') : '-'} BTC
                  <div style={{ fontSize: 12 }}>
                    Monto total
                  </div>
                </div>
              </Grid>
              <Grid item xs={3} style={styles.itemsTitle}>
                <div>
                <IconCryptoCurrency.Usdt size={'3em'} />
                </div><br />
                <div style={{ paddingBottom: 10, fontSize: 40 }}>
                  {'usdt' in pendingTransactionsBalance ? pendingTransactionsBalance['usdt']['count'] : '-'}
                  <div style={{ fontSize: 12 }}>
                    Cantidad
                  </div>
                </div>
                <div>
                  {'usdt' in pendingTransactionsBalance ? toMoney(pendingTransactionsBalance['usdt']['total_amount'], 'usdt') : '-'} USDT
                  <div style={{ fontSize: 12 }}>
                    Monto total
                  </div>
                </div>
              </Grid>
              <Grid item xs={3} style={styles.itemsTitle}>
                <div>
                  <img src={Images.USDC} alt='' style={{ width: "48px", height: "48px" }} />
                </div><br />
                <div style={{ paddingBottom: 10, fontSize: 40 }}>
                  {'usdc' in pendingTransactionsBalance ? pendingTransactionsBalance['usdc']['count'] : '-'}
                  <div style={{ fontSize: 12 }}>
                    Cantidad
                  </div>
                </div>
                <div>
                  {'usdc' in pendingTransactionsBalance ? toMoney(pendingTransactionsBalance['usdc']['total_amount'], 'usdc') : '-'} USDC
                  <div style={{ fontSize: 12 }}>
                    Monto total
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={styles.subTitle}>
            <span style={{ marginRight: '2em' }}>Posición consolidada</span>
            <Button onClick={getData} disabled={adminBalanceStatus === 'loading'} style={{ border: '1px white solid' }}>
              {adminBalanceStatus === 'loading' && <CircularProgress size={14} style={{ 'color': 'white' }} />}
              {adminBalanceStatus === 'pending' && 'Cargar'}
              {adminBalanceStatus === 'ready' && 'Refrescar'}
              {adminBalanceStatus === 'error' && 'Error'}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={32}>
              <Grid item xs={3} style={styles.itemsTitle}>
                <div>
                  <IconCryptoCurrency.Btc color="yellow" size={30} />
                </div>
                Bitcoin
                <div style={styles.amountCurrency}>
                  {toMoney(btc, 'btc')} BTC
                </div>
              </Grid>
              <Grid item xs={3} style={styles.itemsTitle}>
                <div>
                  <IconCryptoCurrency.Usdt size={30} />
                </div>
                USDT
                <div style={styles.amountCurrency}>
                  {toMoney(usdt, 'usdt')} USDT
                </div>
              </Grid>
              <Grid item xs={3} style={styles.itemsTitle}>
                <div>
                  <img src={Images.USDC} alt='' style={{ width: "30px", height: "30px" }} />
                </div>
                USDC
                <div style={styles.amountCurrency}>
                  {toMoney(usdc, 'usdc')} USDC
                </div>
              </Grid>
              <Grid item xs={3} style={styles.itemsTitle}>
                <div>
                  <ReactCountryFlag countryCode={flagsCountries["Chile"]} style={styles.flag} svg />
                </div>
                Peso chileno
                <div style={styles.amountCurrency}>
                  {toMoney(clp, 'clp')} CLP
                </div>
              </Grid>
              <Grid item xs={3} style={styles.itemsTitle}>
                <div>
                  <ReactCountryFlag countryCode={flagsCountries["Estados Unidos"]} style={styles.flag} svg />
                </div>
                Dólar estadounidense
                <div style={styles.amountCurrency}>
                  {toMoney(usd, 'usd')} USD
                </div>
              </Grid>
              <Grid item xs={3} style={styles.itemsTitle}>
                <div>
                  <ReactCountryFlag countryCode={flagsCountries["Colombia"]} style={styles.flag} svg />
                </div>
                Peso colombiano
                <div style={styles.amountCurrency}>
                  {toMoney(cop, 'clp')} COP
                </div>
              </Grid>
              <Grid item xs={3} style={styles.itemsTitle}>
                <div>
                  <ReactCountryFlag countryCode={flagsCountries["Argentina"]} style={styles.flag} svg />
                </div>
                Peso argentino
                <div style={styles.amountCurrency}>
                  {toMoney(ars, 'ars')} ARS
                </div>
              </Grid>
              <Grid item xs={3} style={styles.itemsTitle}>
                <div>
                  <ReactCountryFlag countryCode={flagsCountries["México"]} style={styles.flag} svg />
                </div>
                Peso mexicano
                <div style={styles.amountCurrency}>
                  {toMoney(mxn, 'mxn')} MXN
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={styles.subTitle}>
            <span style={{ marginRight: '2em' }}>Balance general</span>
            <Button onClick={getGeneralBalance} disabled={generalBalanceStatus === 'loading'} style={{ border: '1px white solid' }}>
              {generalBalanceStatus === 'loading' && <CircularProgress size={14} style={{ 'color': 'white' }} />}
              {generalBalanceStatus === 'pending' && 'Cargar'}
              {generalBalanceStatus === 'ready' && 'Refrescar'}
              {generalBalanceStatus === 'error' && 'Error'}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={32}>
              <Grid item xs={3} style={styles.itemsTitle}>
                <div>
                  <IconCryptoCurrency.Btc color="yellow" size={30} />
                </div>
                Bitcoin
                <div style={styles.amountCurrency}>
                  {'btc' in generalBalance ? toMoney(generalBalance['btc'], 'btc') : '-'} BTC
                </div>
              </Grid>
              <Grid item xs={3} style={styles.itemsTitle}>
                <div>
                  <ReactCountryFlag countryCode={flagsCountries["Chile"]} style={styles.flag} svg />
                </div>
                Peso chileno
                <div style={styles.amountCurrency}>
                  {'clp' in generalBalance ? toMoney(generalBalance['clp'], 'clp') : '-'} CLP
                </div>
              </Grid>
              <Grid item xs={3} style={styles.itemsTitle}>
                <div>
                  <ReactCountryFlag countryCode={flagsCountries["Estados Unidos"]} style={styles.flag} svg />
                </div>
                Dólar estadounidense
                <div style={styles.amountCurrency}>
                  {'usd' in generalBalance ? toMoney(generalBalance['usd'], 'usd') : '-'} USD
                </div>
              </Grid>
              <Grid item xs={3} style={styles.itemsTitle}>
                <div>
                  <IconCryptoCurrency.Usdt size={30} />
                </div>
                USDT
                <div style={styles.amountCurrency}>
                  {'usdt' in generalBalance ? toMoney(generalBalance['usdt'], 'usdt') : '-'} USDT
                </div>
              </Grid>
              <Grid item xs={3} style={styles.itemsTitle}>
                <div>
                  <ReactCountryFlag countryCode={flagsCountries["Colombia"]} style={styles.flag} svg />
                </div>
                COP
                <div style={styles.amountCurrency}>
                  {'cop' in generalBalance ? toMoney(generalBalance['cop'], 'cop') : '-'} COP
                </div>
              </Grid>
              <Grid item xs={3} style={styles.itemsTitle}>
                <div>
                  <ReactCountryFlag countryCode={flagsCountries["Argentina"]} style={styles.flag} svg />
                </div>
                ARS
                <div style={styles.amountCurrency}>
                  {'ars' in generalBalance ? toMoney(generalBalance['ars'], 'ars') : '-'} ARS
                </div>
              </Grid>
              <Grid item xs={3} style={styles.itemsTitle}>
                <div>
                  <ReactCountryFlag countryCode={flagsCountries["México"]} style={styles.flag} svg />
                </div>
                MXN
                <div style={styles.amountCurrency}>
                  {'mxn' in generalBalance ? toMoney(generalBalance['mxn'], 'mxn') : '-'} MXN
                </div>
              </Grid>
              <Grid item xs={3} style={styles.itemsTitle}>
                <div>
                  <img src={Images.USDC} style={{ width: "30px", height: "30px" }} />
                </div>
                USDC
                <div style={styles.amountCurrency}>
                  {'usdc' in generalBalance ? toMoney(generalBalance['usdc'], 'usdc') : '-'} USDC
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} style={styles.subTitle}>
            <span style={{ marginRight: '2em' }}>Lista de Transacciones Pendientes</span>
            <Button onClick={getPendingTransactionsList} disabled={pendingTransactionListStatus === 'loading'} style={{ border: '1px white solid' }}>
              {pendingTransactionListStatus === 'loading' && <CircularProgress size={14} style={{ 'color': 'white' }} />}
              {pendingTransactionListStatus === 'pending' && 'Cargar'}
              {pendingTransactionListStatus === 'ready' && 'Refrescar'}
              {pendingTransactionListStatus === 'error' && 'Error'}
            </Button>
            {
              isAccess('auto_refresh_available') && (
                <FormControlLabel control={<Switch value={autoRefresh} onChange={onChangeAutoRefresh} />} label={`Auto refresh en ${refreshTime} minutos`} style={{ marginLeft: 20 }} />
              )
            }
            <PendingTransactionsTable rows={pendingTransactionsList} history={history} />
          </Grid>
          <Grid item xs={12} style={{ ...styles.subTitle, position: 'relative' }}>
            <span style={{ marginRight: '2em' }}>Lista de Transacciones Pendientes Atrasadas</span>
            <Button onClick={handleLoad} disabled={oldPendingTransactionListStatus === 'loading'} style={{ border: '1px white solid' }}>
              {oldPendingTransactionListStatus === 'loading' && <CircularProgress size={14} style={{ 'color': 'white' }} />}
              {oldPendingTransactionListStatus === 'pending' && 'Cargar'}
              {oldPendingTransactionListStatus === 'ready' && 'Refrescar'}
              {oldPendingTransactionListStatus === 'error' && 'Error'}
            </Button>
            {oldPendingTransactionsList.length > 0 &&
              <OldPendingTransactionsFilter
                getOldPendingTransactionsList={getOldPendingTransactionsList}
                refresh={refresh}
              />
            }
            {
              oldPendingTransactionListError !== '' ? (
                <h4>{oldPendingTransactionListError}</h4>
              ) : (
                <OldPendingTransactionsTable
                  rows={oldPendingTransactionsList}
                  history={history}
                />
              )
            }
          </Grid>
          <Grid item xs={12} style={styles.subTitle}>
            <StatusPricesProviders isDashboardLoad={isLoad} isTouchButton={isTouch} />
          </Grid>
          <Grid item xs={12} style={styles.subTitle}>
            <GetProvidersBalances isDashboardLoad={isLoad} isTouchButton={isTouch} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Dashboard;
