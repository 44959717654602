import { apiCall } from "../../Middlewares";
import { ENDPOINTS } from "../../Config/env";

const deleteCategory = async (id) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.CATEGORY.DELETE(id),
  });
};

const getCategories = async () =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.CATEGORY.GET_CATEGORIES
  });

export default {
  deleteCategory,
  getCategories,
};
