import React from "react";
import { Grid, FormControlLabel, Checkbox } from "@material-ui/core";
import PropTypes from "prop-types";

const RenderCountries = ({
  handleCheckboxChange,
  handleOnChangeAllCountry,
  allCountries,
  optionsCountries,
  isEdit,
}) => (
  <Grid container spacing={8} style={{ marginTop: 15 }}>
    <Grid item xs={3} style={{ marginBottom: -10 }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={allCountries.checked}
            onChange={handleOnChangeAllCountry}
            disabled={isEdit}
          />
        }
        label={allCountries.name}
      />
    </Grid>
    {optionsCountries.map((option) => (
      <Grid item xs={3} key={option.name} style={{ marginBottom: -10 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={option.checked}
              onChange={() => handleCheckboxChange(option.iso_code)}
              disabled={allCountries.checked || isEdit}
            />
          }
          label={option.name}
        />
      </Grid>
    ))}
  </Grid>
);

RenderCountries.propTypes = {
  handleCheckboxChange: PropTypes.func.isRequired,
  handleOnChangeAllCountry: PropTypes.func.isRequired,
  allCountries: PropTypes.object.isRequired,
  optionsCountries: PropTypes.array.isRequired,
  isEdit: PropTypes.bool,
};

RenderCountries.defaultProps = {
  handleCheckboxChange: () => {},
  handleOnChangeAllCountry: () => {},
  allCountries: {
    name: "",
    checked: false,
  },
  optionsCountries: [],
  isEdit: false,
};

export default RenderCountries;
