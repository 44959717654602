import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { withStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { toMoney } from '../../../Helpers/MoneyHelper';
import { Animated } from "react-animated-css";
import Images from "../../../Assets/images";
import { TransactionProvider } from "../../../Providers";
import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

const LotesList = (props) => {
  const { classes } = props;
  const [isLoading, setLoading] = useState(false);
  const [isSuccessful, setSuccessful] = useState(false);
  const [isNext, setNext] = useState(false);
  const [state, setState] = useState({
    hojas: [],
  });

  const isInvalidRow = (row) => {
    return (
      !(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(row.email)) ||
      isNaN(row.monto) ||
      !row.moneda
    );
  };

  const showNext = () => {
    setNext(true);
  };

  const cancelLote = () => {
    setState({ hojas: [] });
  };

  const hiddenNext = () => {
    setNext(false);
  };

  const exit = () => {
    setSuccessful(false);
    hiddenNext();
    setState({
      hojas: []
    });
  };

  const exportExcel = () => {
    const csv = convertToCSV({
      data: state.hojas,
      fields: ['email', 'moneda', 'monto', 'id_transaction', 'id_public', 'error']
    });
    downloadCSV(csv, 'Transacciones');
  };

  const cancelRecarge = () => {
    hiddenNext();
  };

  const renderError = () => {
    let errorCount = 0;
    state.hojas.map((hoja) => {
      if (hoja.error)
        errorCount++;
    });

    if (errorCount) {
      return (
        <div style={{ color: '#FF0000', paddingTop: 20 }}>
          Transacciones fallidas: {errorCount}
        </div>
      );
    }
  };

  const isValidRows = () => {
    let isValid = true;
    state.hojas.map((row) => {
      if (isInvalidRow(row))
        isValid = false;
    });

    return isValid;
  };

  const onRecarge = async () => {
    if (isValidRows()) {
      hiddenNext();
      setLoading(true);
      try {
        const response = await TransactionProvider.rechargeLote(state.hojas);
        console.log(response)
        setState({
          hojas: response.data.activity_report.map((hoja) => {
            return {
              ...hoja,
              error: hoja.error ? hoja.error : '',
            }
          })
        });
        setSuccessful(true);
      } catch (e) {

      }
      setLoading(false);
    }
  };

  const inputChangeHandle = (event) => {
    try {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      setState({
        [name]: value,
        hojas: [],
      });
      let hojas = [];
      if (name === 'file') {
        let reader = new FileReader();
        reader.readAsArrayBuffer(target.files[0]);
        reader.onloadend = (e) => {
          var data = new Uint8Array(e.target.result);
          var workbook = XLSX.read(data, { type: 'array' });

          workbook.SheetNames.forEach(function (sheetName) {
            var XL_row_object = XLSX.utils.sheet_to_json(
              workbook.Sheets[sheetName]
            );

            try {
              XL_row_object = XL_row_object.map(object => {
                return {
                  email: object.email.trim().toLowerCase(),
                  monto: parseFloat(object.monto),
                  moneda: object.moneda.trim().toUpperCase(),
                }
              });

              if (XL_row_object[0] && XL_row_object[0].hasOwnProperty("email")) {
                hojas.push({
                  data: XL_row_object,
                  sheetName,
                })
              }
            } catch (error) {
              Swal.fire({
                icon: 'error',
                title: `No se pudo leer el archivo`,
                text: 'Por favor, asegurate de seguir el formato requerido',
              })
            }

          })

          if (hojas[0] && hojas[0].data) {
            setState({
              ...state,
              selectedFileDocument: target.files[0],
              hojas: hojas[0].data,
            });
          }
        }
      }
    } catch (e) { }
  };

  if (isNext)
    return (
      <div style={{ padding: 25, color: '#FFFFFF', backgroundColor: '#232323', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <Button onClick={onRecarge} style={{ backgroundColor: '#00a6ff', color: '#FFFFFF' }}>
            Recargar
          </Button>
          <div style={{ width: 20 }} />
          <Button onClick={cancelRecarge} style={{ backgroundColor: '#ff0000', color: '#FFFFFF' }}>
            Cancelar
          </Button>
        </div>
        <div style={{ width: '100%', fontSize: 12, maxWidth: 300, padding: 25, textAlign: 'center' }}>
          Si no esta seguro de este lote, por favor cancele y rectifique los datos
        </div>
      </div>
    );

  if (state.hojas.length > 0)
    return (
      <div style={{ color: '#FFFFFF', backgroundColor: '#232323', height: '100%', padding: 25 }}>
        {
          isLoading && (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Animated style={{ animationIterationCount: 'infinite' }} animationIn="bounceIn" animationOut="fadeOut" isVisible={true}>
                <img src={Images.vita} width={40} height={40} />
              </Animated>
            </div>
          )
        }
        <div style={{ opacity: isLoading ? .4 : 1 }}>
          {
            isSuccessful && (
              <Button onClick={exit} style={{ backgroundColor: '#FF0000', color: '#FFFFFF' }}>
                Salir
              </Button>
            )
          }
          {
            isSuccessful && (
              <Button onClick={exportExcel} style={{ marginLeft: 10, backgroundColor: '#00a6ff', color: '#FFFFFF' }}>
                Exportar Excel
              </Button>
            )
          }
          {
            !isLoading && !isSuccessful && isValidRows() && (
              <Button onClick={showNext} style={{ backgroundColor: '#00a6ff', color: '#FFFFFF' }}>
                Recargar lote
              </Button>
            )
          }
          {
            !isLoading && !isSuccessful && (
              <Button onClick={cancelLote} style={{ marginLeft: 10, backgroundColor: '#FF0000', color: '#FFFFFF' }}>
                Cancelar lote
              </Button>
            )
          }
          {renderError()}
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Correo electrónico</TableCell>
                  <TableCell align="right">Moneda</TableCell>
                  <TableCell align="right">Monto</TableCell>
                  <TableCell align="right">Transaccion</TableCell>
                  <TableCell align="right">Error</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.hojas.map(row => (
                  <TableRow key={row.email} style={{ backgroundColor: isInvalidRow(row) ? '#FF0000' : row.error ? '#FF0000' : row.transaccion ? '#00a6ff' : '' }}>
                    <TableCell style={{ fontSize: 15 }} component="th" scope="row">
                      {row.email}
                    </TableCell>
                    <TableCell align="right">{row.moneda}</TableCell>
                    <TableCell align="right">{toMoney(row.monto, row.moneda)}</TableCell>
                    {
                      row.id_transaction ? (
                        <TableCell align="right">{row.id_transaction}</TableCell>
                      ) : (
                        <TableCell align="right"></TableCell>
                      )
                    }
                    {
                      row.error ? (
                        <TableCell align="right">
                          {
                            row.error
                          }
                        </TableCell>
                      ) : (
                        <TableCell align="right"></TableCell>
                      )
                    }
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </div>
      </div>
    );

  return (
    <div style={{ padding: 25, color: '#FFFFFF', backgroundColor: '#232323', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <input
        hidden
        required
        type="file"
        name="file"
        id="file"
        onChange={inputChangeHandle}
      />
      <label htmlFor="file" style={{
        display: 'inline-block',
        backgroundColor: '#00a6ff',
        padding: '15px 40px',
        borderRadius: 5,
        cursor: 'pointer',
        marginTop: '1rem',
      }}>Analizar</label>
      <div style={{ width: '100%', fontSize: 12, maxWidth: 300, padding: 25, textAlign: 'center' }}>
        Por favor seleccion el archivo excel con los datos para realizar la recarga por lote
      </div>
    </div>
  );
}

export default withStyles(styles)(LotesList);
