import { UPDATE, CREATE, DELETE } from 'react-admin';

import { API_URL } from '../../../../Config/env';

const requestHandler = (type, params) => {
  switch (type) {
    case CREATE: {
      let {
        data: {
          attributes
        }
      } = params;
      attributes.country_id = attributes.country.id;

      return {
        url: `${API_URL}/admin/maintenance_charges`,
        method: 'post',
        data: attributes,
      };
    }
    case UPDATE: {
      let {
        data: {
          attributes
        }
      } = params;
      attributes.country_id = attributes.country.id;

      return {
        url: `${API_URL}/admin/maintenance_charges/${params.id}`,
        method: 'put',
        data: attributes,
      };
    }
    default: {
      return {

      }
    }
  }
}

export default requestHandler;