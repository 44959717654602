const styles = {
  constainer: {
    display: "flex",
    justifyContent: "center",
  },
  grid1: { display: "flex", justifyContent: "end" },
  grid3: { display: "flex", justifyContent: "start" },
  label1: {
    color: "white",
    borderRadius: 30,
    textAlign: "center",
    fontSize: 20,
    padding: 6,
    paddingLeft: 10,
    paddingRight: 10,
  },
  label2: {
    color: "white",
    borderRadius: 30,
    textAlign: "center",
    fontSize: 20,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
  label3: {
    color: "white",
    borderRadius: 30,
    textAlign: "center",
    fontSize: 20,
    padding: 6,
    paddingLeft: 10,
    paddingRight: 10,
  },

  container2: {
    marginTop:5,
    display: "grid",
    gridTemplateColumns: "auto auto auto",
  },
  textconfigCup: {
    display: "flex",
    justifyContent: "center",
    marginLeft: 30,
    fontSize: 12,
  },
  textPublic: {
    display: "flex",
    justifyContent: "center",
    marginLeft: -30,
    marginRight: 60,
    fontSize: 12,
  },
  textVisib: {
    display: "flex",
    justifyContent: "start",
    marginLeft: 20,
    fontSize: 12,
  },
};

export default styles;
