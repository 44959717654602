import React from 'react';
import { List, Datagrid, TextField, ShowButton, EditButton, FunctionField }  from 'react-admin';

import DoneAllIcon from '@material-ui/icons/DoneAll';
import BlockIcon from '@material-ui/icons/Block';
import ChargeFilter from '../Filters/ChargeFilter';
import { Pagination } from '../../../Components';

const ChargeList = (props) => {
  const {hasEdit} = props;

  return (
    <List
      {...props}
      title={'Cuotas de mantenimiento'}
      exporter={false}
      // filters={<ChargeFilter />}
      pagination={<Pagination/>}
      bulkActionButtons={false}
      debounce={1000}
    >
      <Datagrid>
        <TextField
          source={'id'}
          label={'Id'}
        />
        <TextField
          source={'attributes.provider'}
          label={'Proveedor'}
        />
        <TextField
          source={'attributes.frequency'}
          label={'Frecuencia'}
        />
        <TextField
          source={'attributes.country.name'}
          label={'País'}
        />
        <TextField
          source={'attributes.amount'}
          label={'Monto (natural)'}
        />
        <TextField
          source={'attributes.amount_to_business'}
          label={'Monto (business)'}
        />
        <TextField
          source={'attributes.type_charge'}
          label={'Tipo'}
        />
        <ShowButton label="" />
        {hasEdit ? <EditButton label="" /> : null}
      </Datagrid>
    </List>
  );
};

export default ChargeList;