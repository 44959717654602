import { EuroSymbol } from "@material-ui/icons";

import SegmentsList from "./SegmentList";
import useAccessRole from "../../hooks/useAccessRole";

import SegmentShow from "./SegmentShow";
import SegmentCreate from "./SegmentsCreate";
import SegmentEdit from "./SegmentEdit";

const { isAccess } = useAccessRole();

export default {
  name: "segments",
  icon: EuroSymbol,
  list: SegmentsList,
  create: isAccess("create_segments") ? SegmentCreate : false,
  show: isAccess("show_segments") ? SegmentShow : false,
  edit: isAccess("edit_segments") ? SegmentEdit : false,
};
