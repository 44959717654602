import { CREATE, UPDATE, GET_ONE } from 'react-admin';

const responseHandler = (response, type) => {
    switch (type) {
        case CREATE: {
          return response.data.binance_networks;
        }
        case UPDATE: {
          return {
              data: response.data.data,
          }
        }
        case GET_ONE: {
          const responseData = response.data.binance_networks.data;

          return {
              data: responseData[0],
          };
        }
        default: {
          const responseData = response.data.binance_networks.data;

          return {
              data: responseData ? responseData : [],
              total: response.data['total'],
          }
      }
    }
};

export default responseHandler;
