import { Settings }     from '@material-ui/icons';

import ConfigurationList  from './SettingsHeadersList';
import ConfigurationEdit  from './SettingsHeadersEdit';
import useAccessRole from "../../hooks/useAccessRole";

const {isAccess} = useAccessRole();

export default {
    name: 'settingsHeaders',
    icon: Settings,
    list: isAccess('show_settings_headers') ? ConfigurationList : false,
    edit: isAccess('edit_settings_headers') ? ConfigurationEdit : false,
}
