import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  CircularProgress,
  Card,
  CardContent,
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { UserProvider, CountriesProvider } from '../../../../Providers';
import ModalComponent from '../../../ModalComponent';
import useModal from '../../../../hooks/useModalComponent';

const INITIAL_FILTER = {
  option: 10,
  country: '',
  blocked: '',
};

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
});

const OldPendingTransactionsFilter = (props) => {
  const { getOldPendingTransactionsList, refresh, classes } = props;
  const [filters, setFilters] = useState(INITIAL_FILTER);
  const [countries, setCountries] = useState([]);

  const [pendingTransactionListExportStatus, setPendingTransactionListExportStatus] = useState('ready');

  const { handleOpenModal, handleCloseModal, openModal } = useModal();

  const exportPendingTransactionsList = async () => {
    handleOpenModal();
  }

  const handleExportConfirmed = async () => {
    handleCloseModal(); // Close the modal
    setPendingTransactionListExportStatus('loading');
    try {
      const response = await UserProvider.exportPendingTransactionsList();
      setPendingTransactionListExportStatus('ready');
    } catch (error) {
      setPendingTransactionListExportStatus('error');
    }
  }

  const handleChange = (e) => {
    setFilters(prev => {
      const filterAux = {
        ...prev,
        [e.target.name]: e.target.value,
      };

      getOldPendingTransactionsList(filterAux);

      return (filterAux)
    });
  };

  useEffect(() => {
    setFilters(INITIAL_FILTER);
  }, [refresh]);

  useEffect(() => {
    (async () => {
      try {
        const response = await CountriesProvider.fetchFilterCountriesAdmin('q[is_transfer_eq]=true&page=0&count=100');
        setCountries([
          {
            attributes: {
              iso_code: "btc",
              name: 'BTC'
            }
          },
          {
            attributes: {
              iso_code: "usdc",
              name: 'USDC'
            }
          },
          {
            attributes: {
              iso_code: "usdt",
              name: 'USDT'
            }
          },
          ...response.data.countries.data,
        ]);
      } catch { }
    })();
  }, []);

  return (
    <form style={{ position: 'absolute', right: 0, top: 10 }}>
      <Button onClick={exportPendingTransactionsList} disabled={pendingTransactionListExportStatus === 'loading'} style={{ border: '1px white solid', marginRight: '14px' }}>
        {pendingTransactionListExportStatus === 'loading' && <CircularProgress size={14} style={{ 'color': 'white' }} />}
        {pendingTransactionListExportStatus === 'ready' && 'Exportar'}
        {pendingTransactionListExportStatus === 'error' && 'Error'}
      </Button>
      <FormControl className={classes.formControl}>
        <InputLabel id='blocked-label'>Bloqueadas</InputLabel>
        <Select
          labelid='blocked-label'
          id='blocked'
          name='blocked'
          value={filters.blocked}
          onChange={handleChange}
        >
          <MenuItem value=''>Todas</MenuItem>
          <MenuItem value='true'>Si</MenuItem>
          <MenuItem value='false'>No</MenuItem>
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id='country-label'>País</InputLabel>
        <Select
          labelid='country-label'
          id='country'
          name='country'
          value={filters.country}
          onChange={handleChange}
        >
          <MenuItem value=''>Todos</MenuItem>
          {countries.map((country, index) => (
            <MenuItem value={country.attributes.iso_code} key={index}>
              {country.attributes.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <RadioGroup
          name='option'
          value={filters.option}
          onChange={handleChange}
          row
        >
          <FormControlLabel value='10' control={<Radio />} label='10' />
          <FormControlLabel value='30' control={<Radio />} label='30' />
          <FormControlLabel value='50' control={<Radio />} label='50' />
        </RadioGroup>
      </FormControl>

      <ModalComponent
        open={openModal}
        onClose={handleCloseModal}
        marginTop={100}
        backdropStyle={{
            backgroundColor: 'rgba(0,0,0,0.5)',
        }}
      >
        <Card>
          <Typography
            variant='headline'
            align='center'
            style={{
                fontWeight: "bold",
                paddingTop: 30,
            }}
                          >
            Va a generar un archivo CSV con la información completa mostrada previamente. ¿Desea continuar?
          </Typography>
          <CardContent style={{ display: 'flex', justifyContent: 'center' }}>
              <Button style={{fontSize: 16, fontWeight: 'bold', textTransform: 'none',
                              marginRight: 10,  backgroundColor: 'rgb(0, 166, 255)'}} onClick={handleExportConfirmed}>Sí</Button>
              <Button style={{fontSize: 16, fontWeight: 'bold', textTransform: 'none',
                              marginRight: 10,  backgroundColor: '#6e7d88'}} onClick={handleCloseModal}>No</Button>
          </CardContent>
        </Card>
      </ModalComponent>
    </form>
  );
};

export default withStyles(styles)(OldPendingTransactionsFilter);
