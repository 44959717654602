import colors from "../../../Assets/Colors";

const styles = {
  header: {
    color: colors.white,
    textAlign: "center",
    fontSize: 20,
    padding: 20,
  },
  subTitle: {
    color: colors.white,
    fontSize: 24,
    padding: 20,
  },
  description: {
    color: colors.white,
    textAlign: "center",
    fontSize: 14,
  },
  container: {
    flexGrow: 1,
  },
  dlocalImage: {
    width: 200,
  },
  content: {
    color: colors.white,
  },
  boxCenter: {
    paddingTop: 20,
    paddingBottom: 20,
    textAlign: "center",
  },
  inputFile: {
    padding: 50,
    backgroundColor: "transparent",
    borderStyle: "dotted",
    borderColor: "white",
    borderWidth: 1,
  },
  notFound: {
    color: colors.white,
    fontSize: 16,
    padding: 20,
  },
};

export default styles;
