import { useState, useEffect } from 'react';
import useAlerts from './useAlerts';

import useAccessRole from './useAccessRole';
import Navigation from '../Navigation';
import FortressProvider from '../Providers/fortress';
import { WITHDRAWAL_TYPE, CREATE_ACCOUNT_TYPE, CREATE_BUSINESS_ACCOUNT_TYPE, ASSIGN_WEBHOOK_URL } from '../Resources/Fortress/utils';

const useCreatePaymentFortress = () => {
    const {accountNumber = '', id = ''} = Navigation.nav().history.location.state || {};
    const [formularyType, setFormularyType] = useState(WITHDRAWAL_TYPE);
    const [custodialAccounts, setCustodialAcounts] = useState(accountNumber && id ? [ {id: id, name: accountNumber} ] : []);
    const {errorMessage} = useAlerts();
    const {isAccess} = useAccessRole();

    useEffect(() => {
        if (!accountNumber && !id && formularyType === WITHDRAWAL_TYPE) {
            (async () => {
                try {
                    const response = await FortressProvider.getCustodialAccounts();
                    setCustodialAcounts(
                        response.data.data.map(
                            (item) => ({ 
                                ...item, 
                                id: `${item.id}****${item.ownerIdentityId}`, 
                                name: item.accountNumber,
                            })
                        )
                    );
                } catch(error){
                    errorMessage(error.data.error.message);
                }
            })();
        }
    }, [formularyType, accountNumber]);

    const changeFormularyType = (value) => {
        setFormularyType(value);
    };

    return {
        isWithdrawal: formularyType === WITHDRAWAL_TYPE,
        isCreateAccount: formularyType === CREATE_ACCOUNT_TYPE,
        isBusinessCreateAccount: formularyType === CREATE_BUSINESS_ACCOUNT_TYPE,
        isAssignWebhookUrl: isAccess('assign_webhook_url_fortress') && formularyType === ASSIGN_WEBHOOK_URL,
        formularyType,
        custodialAccounts,
        changeFormularyType,
        accountNumber: id,
    }
};

export default useCreatePaymentFortress;