const styles = {
  items: {
    marginBottom: 16
  },
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20,
    marginBottom: 20
  }
};

export default styles;