import { /* UPDATE, CREATE, */ GET_LIST } from 'react-admin';

import { API_URL } from '../../../../Config/env';

const requestHandler = (type, params) => {
    switch (type) {
        case GET_LIST: {
            const {
                filter: {
                    business_id = null
                },
                pagination: {
                    page,
                    perPage,
                },
                sort: {
                    field,
                    order,
                },
            } = params;

            let exportFile = '';
            if (parseInt(perPage) === 1000){
                exportFile = `is_export=true&`;
            }

            return {
                url: `${API_URL}/admin/businesses/wallets?page=${page}&count=${perPage}&${exportFile}q[s]=${field}+${order}&${business_id ? `&business_id=${business_id}` : ''}`,
                method: 'get',
            };
        }
        default: {
            return {

            }
        }
    }
}

export default requestHandler;
