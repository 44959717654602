import { apiCall }    from '../../Middlewares';
import { ENDPOINTS }  from '../../Config/env';

const getUserTrxLimits = async (query = '') => {
    const url = `${ENDPOINTS.ADMIN.USER_TRX_LIMITS.GET.url}${query}`;
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.USER_TRX_LIMITS.GET,
        url,
    });
};

const createActiveUserTrxLimits = async (data) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.USER_TRX_LIMITS.CREATE,
        data,
    });
};

const updateUserTrxLimit = async (id, data = {}, isFormData = false) => {
    const url = ENDPOINTS.ADMIN.USER_TRX_LIMITS.UPDATE.url.replace(':id', id);

    const params = {
        authorization: true,
        ...ENDPOINTS.ADMIN.USER_TRX_LIMITS.UPDATE,
        url,
        data,
    };

    if (isFormData) {
        params["headers"] = {
            'Content-Type': 'multipart/form-data',
        }
    }

    return await apiCall(params);
};

export default {
    getUserTrxLimits,
    updateUserTrxLimit,
    createActiveUserTrxLimits,
};