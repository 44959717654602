const styles = {
  container: {
    width: "100%",
  },
  table: {
    backgroundColor: "#323232",
  },
  paper: {
    width: "100%",
    marginTop: 3,
    overflowX: "auto",
  },
  containerTab: {
    paddingBottom: 20,
  },
  title: {
    color: "white",
    backgroundColor: "#323232",
    height: 50,
    paddingTop: 10,
    fontSize: 30,
    fontWeight: "bold",
  },
  textTitle: {
    fontSize: 20,
  },
  textBody: {
    fontSize: 14,
  },
};

export default styles;
