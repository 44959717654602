import React from 'react';
import {BooleanInput, Create, FormTab, TabbedForm, TextInput, SelectInput} from "react-admin";
import {PostEditToolbar} from "../../Users/UserEdit/CustomComponents";
import styles from "../../Users/UserEdit/styles";
import {Col, Container, Row} from "react-grid-system";
import {TO_NUMBER, toMoney} from "../../../Helpers/MoneyHelper";
import {currencies} from '../../../hooks/useLimitCreate';
import useCountries from '../../../hooks/useCountries';

const PricesCreate = (props) => {
    const {pricesCountriesWhitOutConfig} = useCountries();

    return (
        <Create {...props}>
            <TabbedForm toolbar={<PostEditToolbar />}>
                <FormTab label="Datos Personales" style={styles.titleWhite}>
                    <Container style={{ width: '100%' }}>
                        <Row container direction="row" justify="center" alignItems="center" className={{ flexGrow: 1 }}>
                            <Col xs={6} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <SelectInput label="Moneda" source="attributes.currency" choices={currencies} />
                            </Col>
                            <Col xs={6} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <SelectInput label="País" source="attributes.country_id" choices={pricesCountriesWhitOutConfig} />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <TextInput
                                    label={'Spread de venta'}
                                    source={'attributes.sell_fee'}
                                    format={v => toMoney(v, 'USD')}
                                    parse={v => TO_NUMBER(v, 'USD')}
                                />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <TextInput
                                    label={'Spread de venta para envíos internos'}
                                    source={'attributes.sell_fee_send'}
                                    format={v => toMoney(v, 'USD')}
                                    parse={v => TO_NUMBER(v, 'USD')}
                                />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <TextInput
                                    label={'Spread de venta para tarjetas'}
                                    source={'attributes.sell_fee_card'}
                                    format={v => toMoney(v, 'USD')}
                                    parse={v => TO_NUMBER(v, 'USD')}
                                />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <TextInput
                                    label={'Costo fijo para retiros a cuenta propia en moneda origen'}
                                    source={'attributes.fixed_cost'}
                                    format={v => toMoney(v, 'USD')}
                                    parse={v => TO_NUMBER(v, 'USD')}
                                />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <TextInput
                                    label={'Costo fijo para envíos internacionales'}
                                    source={'attributes.fixed_cost_transfer'}
                                    format={v => toMoney(v, 'USD')}
                                    parse={v => TO_NUMBER(v, 'USD')}
                                />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <TextInput
                                    label={'Costo fijo para envíos internos'}
                                    source={'attributes.fixed_cost_send'}
                                    format={v => toMoney(v, 'USD')}
                                    parse={v => TO_NUMBER(v, 'USD')}
                                />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <TextInput
                                    label={'Costo fijo para vita card'}
                                    source={'attributes.fixed_cost_vita_card'}
                                    format={v => toMoney(v, 'USD')}
                                    parse={v => TO_NUMBER(v, 'USD')}
                                />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <BooleanInput
                                    label={'Costo fijo en retiros'}
                                    source={'attributes.is_apply_fixed_cost_in_withdrawal'}
                                />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <BooleanInput
                                    label={'Costo fijo en envíos'}
                                    source={'attributes.is_apply_fixed_cost_in_sents'}
                                />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <BooleanInput
                                    label={'Costo fijo en transferencias'}
                                    source={'attributes.is_apply_fixed_cost_in_transfers'}
                                />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <BooleanInput
                                    label={'Costo fijo en vita card'}
                                    source={'attributes.is_apply_fixed_cost_in_vita_card'}
                                />
                            </Col>
                        </Row>
                    </Container>
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default PricesCreate;
