import React from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';

const ModalExport = (props) => {
  const {
    open,
    hiddeModal,
  } = props;
  
  return (
    <Modal
      open={open}
      onClose={hiddeModal}
      className="export_modal"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disablePortal
      disableEnforceFocus
      disableAutoFocus
    >
      <div className="export_paper">
        <h2 id="server-modal-title" className="text-center">¡Solicitud realizada!</h2>
        <p id="server-modal-description">Te enviaremos un correo electrónico una vez este preparado el archivo.</p>
        <Button
          className="mx-auto d-block"
          variant="outlined"
          color="secondary"
          onClick={hiddeModal}
        >OK</Button>
      </div>
    </Modal>
  );
};

export default ModalExport;
