export const styles = {
    description: {
        color: '#FFFFFF',
        textAlign: 'center',
        padding: 20,
    },
    divisorTop: {
        paddingTop: 20,
    },
    titleCoin: {
        color: '#FFFFFF',
        textAlign: 'center',
    },
    divisorCoin: {
        padding: 10,
    },
};

export const stylesHoc = {
    box: {
        height: "100%",
        width: "100%"
    },
    container: {
        height: "100%"
    },
    innerContainer: {
        height: "100%"
    },
    item: {
        flex: 1
    }
};
