import {useState, useMemo, useCallback, useEffect} from 'react';

import countriesService from '../Providers/Countries';
import userService from '../Providers/Users';
import {TO_NUMBER} from '../Helpers/MoneyHelper';

import useLimitList from './useLimitList';

const fieldsTotal = [
    {
        label: 'Tipo de limite',
        value: 'limit_type',
    },
    {
        label: 'Tipo de transaccion',
        value: 'transaction_type',
    },
    {
        label: 'Categoria',
        value: 'category',
    },
];

const limitTypes = [
    {
        label: 'Limite diario',
        value: 'daily',
    },
    {
        label: 'Limite mensual',
        value: 'monthly',
    }
];

const receivedTransactionType = [
    {
        label: 'Recargas',
        value: 'deposit',
    },
    {
        label: 'Envios internos',
        value: 'sent',
    },
];

const sentTransactionType = [
    {
        label: 'Envios internos',
        value: 'sent',
    },
    {
        label: 'Retiros',
        value: 'withdrawal',
    },
    {
        label: 'Remesas',
        value: 'transfer',
    },
    {
        label: 'Envios vita card',
        value: 'vita_card',
    },
];

const categories = [
    {
        label: 'Recibir',
        value: 'receive_type',
    },
    {
        label: 'Enviar',
        value: 'send_type',
    },
];

export const currencies = [
    {
        label: 'Peso chileno',
        value: 'clp',
        name: 'Peso chileno',
        id: 'clp',
    },
    {
        label: 'Dólar estadounidense',
        value: 'usd',
        name: 'Dólar estadounidense',
        id: 'usd',
    },
    {
        label: 'Peso colombiano',
        value: 'cop',
        name: 'Peso colombiano',
        id: 'cop',
    },
    {
        label: 'Peso argentino',
        value: 'ars',
        name: 'Peso argentino',
        id: 'ars',
    },
    {
        label: 'Peso mexicano',
        value: 'mxn',
        name: 'Peso mexicano',
        id: 'mxn',
    },
];

const parseValue = (value) => {
    return TO_NUMBER(value, 'clp');
};

const searchValueLabel = (values, value) => {
    let label = '';
    values.map((item) => {
        if (item.value === value) {
            label = item.label;
        }
        return null;
    });
    return label;
};

const searchFieldLabel = (field) => {
    let label = '';

    if (field === 'country_id') {
        label = 'País';
    } else {
        if (field === 'currency') {
            label = 'Moneda';
        } else {
            fieldsTotal.map((item) => {
                if (item.value === field) {
                    label = item.label;
                }
                return null;
            });
        }
    }

    return label;
};

const useLimitCreate = (user_id, onCreate = () => {}, isAdmin = false, defaultCurrency = 'clp') => {
    const [isSentCategory, setSentCategory] = useState(true);
    const [field, setField] = useState('');
    const [amount, setAmount] = useState(0);
    const [rule, setRule] = useState([]);
    const [countries, setCountries] = useState([]);
    const {limits} = useLimitList(user_id);

    const changeField = (event) => {
        setField(event.target.value);
    };

    const changeAmount = (event) => {
        setAmount(parseValue(event.target.value));
    };

    const prepareRequest = useCallback(() => {
        const request = {};
        rule.map((itemRule) => {
            request[itemRule.field] = itemRule.value;
            return null;
        });

        if (request.limit_type === 'daily') {
            request.daily_limit = amount;
            request.monthly_limit = null;
        } else {
            request.daily_limit = null;
            request.monthly_limit = amount;
        }

        if (user_id) {
            request.currency = defaultCurrency.toLowerCase();
        }

        return request;
    }, [rule, amount, user_id, defaultCurrency]);

    const isCreatedLimit = useCallback(() => {
        let isCreated = false;
        const request = prepareRequest();

        limits.map((limit) => {
            const {
                attributes: {
                    category,
                    currency,
                    transaction_type,
                    daily_limit,
                    monthly_limit,
                    country_id,
                },
            } = limit;

            if (
                category === request.category &&
                currency === request.currency &&
                transaction_type === request.transaction_type &&
                (
                    (daily_limit !== null && request.daily_limit !== null) ||
                    (monthly_limit !== null && request.monthly_limit !== null)
                )
            ) {
                if (request.hasOwnProperty('country_id')) {
                    if (country_id) {
                        if (parseInt(country_id) === parseInt(request.country_id)) {
                            isCreated = true;
                        }
                    }
                } else {
                    if (country_id === null) {
                        isCreated = true;
                    }
                }
            }

            return null;
        });

        return isCreated;
    }, [limits, prepareRequest]);

    useEffect(() => {
        let isFetch = true;

        (async () => {
            try {
                if (isFetch) {
                    const response = await countriesService.fetchCountries();
                    const data = [];
                    response.data.data.map((itemResponse) => {
                        if (itemResponse.attributes.is_cashout || itemResponse.attributes.is_transfer) {
                            data.push({
                                ...itemResponse,
                                label: itemResponse.attributes.name,
                                value: itemResponse.id,
                            });
                        }
                        return null;
                    });
                    isFetch = false;
                    setCountries(data);
                }
            } catch {}
        })();

        return () => {
            isFetch = false;
        };
    }, []);

    const isExitInRule = useCallback((value) => {
        let isInvalid = false;
        let isCategoryExist = false;

        rule.map((itemRule) => {
            if (itemRule.field === value) {
                isInvalid = true;
            }

            if (itemRule.field === 'category') {
                isCategoryExist = true;
            }
            return null;
        });

        if (!isInvalid && value === 'transaction_type' && !isCategoryExist) {
            isInvalid = true;
        }

        return isInvalid;
    }, [rule]);

    const values = useMemo(() => {
        switch (field) {
            case 'limit_type': {
                return limitTypes;
            }
            case 'transaction_type': {
                return isSentCategory ?
                    sentTransactionType :
                    receivedTransactionType;
            }
            case 'category': {
                return categories;
            }
            case 'country_id': {
                return countries;
            }
            case 'currency': {
                return currencies;
            }
            default: {
                return null;
            }
        }
    }, [field, isSentCategory]);

    const changeValue = useCallback((event) => {
        const value = event.target.value;

        setRule([
            {
                field,
                value,
                fieldLabel: searchFieldLabel(field),
                valueLabel: searchValueLabel(values, value),
            },
            ...rule,
        ]);

        setField('');

        if (value === 'send_type' || value === 'receive_type') {
            setSentCategory(value === 'send_type')
        }
    }, [field, setRule, rule, values]);

    const removeRule = useCallback((item) => {
        let newRules = [];
        rule.map((itemRule) => {
            if (
                itemRule.field !== item.field &&
                !(
                    item.field === 'category' &&
                    itemRule.field === 'transaction_type'
                ) &&
                !(
                    item.field === 'category' &&
                    itemRule.field === 'country_id'
                ) &&
                !(
                    item.field === 'transaction_type' &&
                    itemRule.field === 'country_id'
                )
            ) {
                newRules.push(itemRule);
            }

            return null;
        });

        setRule(newRules);
    }, [rule, setRule]);

    const isTransferOrCashout = useCallback(() => {
        let isTransferCashout = false;
        let isCountryexist = false;

        rule.map((itemRule) => {
            if (itemRule.value === 'transfer') {
                isTransferCashout = true;
            }

            if (itemRule.field === 'country_id') {
                isCountryexist = true;
            }
            return null;
        });

        return isCountryexist ? false : isTransferCashout;
    }, [rule]);

    const fields = useMemo(() => {
        let fieldsToShow = [];
        fieldsTotal.map((fieldToShow) => {
            if (!isExitInRule(fieldToShow.value)) {
                fieldsToShow.push(fieldToShow);
            }

            return null;
        });

        if (isTransferOrCashout()) {
            fieldsToShow.push({
                label: 'País',
                value: 'country_id'
            });
        }

        if (isAdmin && !isExitInRule('currency')) {
            fieldsToShow.push({
                label: 'Moneda',
                value: 'currency'
            });
        }

        return fieldsToShow;
    }, [isTransferOrCashout, isAdmin, isExitInRule]);

    const isFormValid = useMemo(() => {
        const adminValidation = isAdmin ? isExitInRule('currency') : true;
        return (
            !isCreatedLimit() &&
            amount > 0 &&
            isExitInRule('limit_type') &&
            isExitInRule('category') &&
            isExitInRule('transaction_type') &&
            adminValidation
        );
    }, [amount, isExitInRule, isAdmin, isCreatedLimit]);

    const createUserLimit = useCallback(async () => {
        try {
            const request = prepareRequest();
            await userService.addUserLimit({
                ...request,
                user_id,
            });

            onCreate();
        } catch {}
    }, [rule, user_id, onCreate, amount]);

    return {
        field,
        fields,
        values,
        rule,
        amount,
        isFormValid,
        changeField,
        changeValue,
        changeAmount,
        removeRule,
        createUserLimit,
        isCreatedLimit,
    };
};

export default useLimitCreate;
