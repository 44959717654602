import React from 'react';
import PropTypes from 'prop-types';

import { alertTypeToEmoji } from '../../../../Utils';
import styles from './styles';

const Alerts = (props) => {
  const { transaction_id, active_alerts, alert_count, type } = props;

  const goToAlerts = () => {
    window.open(`/general_alerts?filter=%7B"alertable_id"%3A"${transaction_id}"%2C"alertable_type"%3A"${type}"%7D&order=DESC&page=1&perPage=10&sort=id`, '_blank');
  };

  return (
    <div style={styles.emojiContainer}>
      { active_alerts.map((alert_type, key) => (
        <span
          key={key}
          style={styles.emoji}
        >
          {alertTypeToEmoji(alert_type)}
        </span>
      ))}
      { alert_count > 0 && (
        <span
          onClick={() => goToAlerts()}
          style={{ ...styles.emoji, cursor: 'pointer' }}
        >
          { alertTypeToEmoji('warning') }
        </span>
      )}
    </div>
  );
};

Alerts.propTypes = {
  transaction_id: PropTypes.string,
  active_alerts: PropTypes.array,
  alert_count: PropTypes.number,
  type: PropTypes.string,
};

Alerts.defaultProps = {
  transaction_id: 0,
  active_alerts: [],
  alert_count: 0,
  type: 'Transaction',
};

export default Alerts;