const styles = {
    title: {
      color: "#FFFFFF",
    },
    container: {
      width: "80%",
      margin: "auto",
      marginTop: "20px",
    },
    container_header:{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }
};

export default styles
