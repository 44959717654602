import React from 'react';
import PropTypes from 'prop-types';

const TabPanel = (props) => {
  const { children, value, index } = props;

  return (
    <div hidden={value !== index} >
      {value === index && ( children )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired
};

export default TabPanel;