import { UPDATE } from 'react-admin';

import { API_URL } from '../../../../Config/env'; 

const requestHandler = (type, params) => {
  switch (type){
    case UPDATE: {
      let {
        id,
        attributes: {
          vita_enable,
          company,
          utility_number,
          utility_type,
          id: service_category_id,
        },
      } = params.data;

      return {
        url: `${API_URL}/admin/services/${params.id}`,
        method: 'put',
        data: {
          id,
          vita_enable,
          company,
          utility_number,
          utility_type,
          service_category_id,
        }
      }
    }
  }
};

export default requestHandler;