import { UPDATE, CREATE, GET_ONE } from "react-admin";

import { API_URL } from "../../../../Config/env";

const requestHandler = (type, params) => {
  const formData = new FormData();
  switch (type) {
    case CREATE: {
      formData.append("identifier", params.identifier.toUpperCase());
      formData.append("description", params.description);
      formData.append("text_button", params.textButton);
      formData.append("segment", params.segment);
      formData.append("scene", params.scene);
      formData.append("is_global", params.isGlobal);
      formData.append("is_active", params.isActive);
      formData.append(
        "list_countries",
        JSON.stringify(params.snackbarsCountries)
      );

      return {
        url: `${API_URL}/admin/snackbars`,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
    }

    case UPDATE: {
      if (params.is_change_status) {
        formData.append("is_active", params.is_active);
        formData.append("is_change_status", true);
        formData.append("is_global", params.is_global);

        return {
          url: `${API_URL}/admin/snackbars/${params.id}`,
          method: "put",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
      } else {
        formData.append("identifier", params.identifier);
        formData.append("description", params.description);
        formData.append("text_button", params.textButton);
        formData.append("segment", params.segment);
        formData.append("scene", params.scene);
        formData.append("is_global", params.isGlobal);
        formData.append("is_active", params.status);

        return {
          url: `${API_URL}/admin/snackbars/${params.id}`,
          method: "put",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
      }
    }

    case GET_ONE: {
      if (params.isShowDetails){
        return {
          url: `${API_URL}/admin/snackbars/show_details/${params.id}`,
          method: "get",
        };
      }
      
      return {
        url: `${API_URL}/admin/snackbars/?q[id_eq]=${params.id}`,
        method: "get",
      };
    }
    
    default: {
      return {};
    }
  }
};

export default requestHandler;
