const styles = {
  divisorTop: {
    paddingTop: 30,
  },
  titleWhite: {
    color: "#FFFFFF",
    textAlign: "center",
  },
  containerField: {
    padding: 5,
    width: "50%",
  },
  modalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardContainer: {
    width: "100%",
    backgroundColor: "#303030",
    padding: "1rem",
    maxWidth: 464,
  },
  buttonClose: {
    backgroundColor: "red",
    color: "black",
  },
};

export default styles;
