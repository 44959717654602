import React from 'react';
import PropTypes from 'prop-types';

export const Selector = (props) => (
    <div style={styles.container}>
        <div 
            style={
                props.isGeneralLimits ? 
                    styles.buttonActive : 
                    styles.button
            }
            onClick={props.onGeneralLimits}
        >
            LÍMITES GENERALES
        </div>
        <div 
            style={
                props.isUserTrxLimits ? 
                    styles.buttonActive : 
                    styles.button
            }
            onClick={props.onUserTrxLimits}
        >
            LÍMITES DE USUARIOS
        </div>
    </div>
);

PropTypes.propTypes = {
    isGeneralLimits: PropTypes.bool,
    isUserTrxLimits: PropTypes.bool,
    onGeneralLimits: PropTypes.func,
    onUserTrxLimits: PropTypes.func,
};

PropTypes.defaultProps = {
    isGeneralLimits: false,
    isUserTrxLimits: false,
    onGeneralLimits: () => {},
    onUserTrxLimits: () => {},
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        color: '#FFFFFF',
        fontWeight: 'bold',
        width: 380,
        justifyContent: 'space-between',
    },
    buttonActive: {
        cursor: 'pointer',
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '2px solid #009bff',
    },
    button: {
        cursor: 'pointer',
        paddingBottom: 5,
        paddingTop: 5,
    },
};

export default Selector;