import { useState } from 'react';

import { currencyCountries } from '../../Config/constants';

const useDashboard = () => {
    const [balance, setState] = useState({
        btc: 0,
        eth: 0,
        clp: 0,
        usd: 0,
        cop: 0,
    });

    const [balanceGeneral, setBalanceGeneral] = useState({
        statistics_transactions: [],
        btc_general: 0,
        eth_general: 0,
        clp_general: 0,
        cop_general: 0,
        usd_general: 0,
        usdt_general: 0,
        btc_buy_clp: 0,
        btc_sell_clp: 0,
        btc_buy_usd: 0,
        btc_sell_usd: 0,
    });

    const [loading, setLoading] = useState(false);

    const [countries, setCountries] = useState([]);

    return {
        balance,
        balanceGeneral,
        loading,
        countries,
        addCountries: (payload) => {
            let { countries, prices } = payload;
            const c = [];
            countries = countries.map((country) => {
                if (country.name !== null && country.name !== undefined && country.name !== '' && currencyCountries[country.name]) {
                    const column = `usd_${currencyCountries[country.name].toLowerCase()}`;
                    if (/*country.is_cashout && */prices[column]) country.price_usd = prices[column];
                    c.push(country);
                }
                return country;
            });
            setCountries(c);
        },
        addBalanceFromAdmin: (balances) => {
            setState(balances);
        },
        addBalanceGeneral: (payload) => {
            const { prices_btc, balance_general } = payload;
            const { btc, clp, usd, usdt, cop } = balance_general;
            const { sell_price_usd, buy_price_usd, sell_price_clp, buy_price_clp } = prices_btc;

            setBalanceGeneral({
                ...balanceGeneral,
                btc_general: btc,
                eth_general: 0,
                clp_general: clp,
                cop_general: cop,
                usd_general: usd,
                usdt_general: usdt,
                btc_buy_clp: buy_price_clp,
                btc_sell_clp: sell_price_clp,
                btc_buy_usd: buy_price_usd,
                btc_sell_usd: sell_price_usd,
            });
        },
        hiddenLoading: () => {
            setLoading(false);
        },
    };
};

export default useDashboard;
