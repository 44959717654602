import React from 'react';

import PreferentialPricesList from './PreferentialPricesList';
import useAccessRole from '../../hooks/useAccessRole';

const {isAccess} = useAccessRole();

export default {
  name: 'preferential_prices',
  list: isAccess('show_preferential_prices') ? PreferentialPricesList : false,
}