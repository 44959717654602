import React from "react";
import {
  Edit,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  BooleanInput,
  SaveButton,
  Toolbar,
  ArrayInput,
  SelectInput
} from "react-admin";
import { Card } from "@material-ui/core";
import { Col, Container, Row } from "react-grid-system";
import { validateRequired } from "../../../Helpers/ValidateHelper";

const BinanceNetworksEdit = (props) => {
  const BinanceNetworkEditToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );

  const Form = () => {
    return (
      <>
        <Card style={{ width: "100%" }}>
          <Container style={{ width: "100%", maxWidth: "100%" }}>
            <Row>
              <Col xs={4}>
                <TextInput disabled label="Id" source="id" />
              </Col>
              <Col xs={4}>
                <TextInput
                  source="attributes.key_name"
                  label="Identificador de red"
                  validate={validateRequired}
                />
              </Col>
              <Col xs={4}>
                <TextInput source="attributes.name" label="Nombre" />
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <TextInput
                  disabled
                  source="attributes.address_regex"
                  label="Expresión regular"
                  validate={validateRequired}
                />
              </Col>
              <Col xs={4}>
                <TextInput
                  source="attributes.estimated_arrival_time"
                  label="Tiempo promedio"
                  validate={validateRequired}
                />
              </Col>
              <Col xs={4}>
                <TextInput
                  source="attributes.withdraw_message"
                  label="Mensaje de retiro"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <BooleanInput label={"Habilitado"} source={"attributes.status"} />
              </Col>
            </Row>
          </Container>
        </Card>
        <br />
        <Card>
              <ArrayInput label={"Configuración por asset"} source="attributes.network_asset_configurations">
                <SimpleFormIterator inline fullWidth disableAdd disableRemove>
                  <TextInput disabled source="id" label="Id" validate={validateRequired} />
                  <TextInput disabled source="asset_iso_code" label="Asset" validate={validateRequired} />
                  <TextInput disabled source="external_provider" label="Proveedor externo" validate={validateRequired} />
                  <TextInput source="withdraw_fee" label="Tarifa de retiro" validate={validateRequired} />
                  <TextInput source="withdraw_min" label="Envío mínimo" validate={validateRequired} />
                  <TextInput source="withdraw_max" label="Envío máximo" validate={validateRequired} />
                  <TextInput source="decimal_withdrawal" label="Decimales" validate={validateRequired} />
                  <TextInput source="min_fee_required" label="Monto requerido para transferir a custodia" validate={validateRequired} />
                  <SelectInput source="status" label="Estado" validate={validateRequired}
                    choices = {[
                      {id: 'active', name: 'Activa'},
                      {id: 'inactive', name: 'Inactiva'}
                    ]}
                  />
                  <BooleanInput source="is_calculate_risk" label="Calcular riesgo" validate={validateRequired} />
                  <BooleanInput source="is_address_available" label="Generar dirección" validate={validateRequired} />
                </SimpleFormIterator>
              </ArrayInput>
        </Card>
      </>
    );
  };

  return (
    <>
      <Edit {...props} title={`Editar red ${props.id}`}>
        <SimpleForm toolbar={<BinanceNetworkEditToolbar />}>
          <Form />
        </SimpleForm>
      </Edit>
    </>
  );
};

export default BinanceNetworksEdit;
