import { GET_LIST } from 'react-admin';

const responseHandler = (response, type) => {
  switch (type) {
    case GET_LIST: {
      return response.data
    }
  }
};

export default responseHandler;
