import React from "react";
import { List, TextField, Datagrid, FunctionField } from "react-admin";

import { ListActions, StatusSwich } from "..";
import {
  Filters,
  ListCountries,
} from "../../../../../Components/Comunications";

const ListSnackbars = (props) => {
  return (
    <List
      {...props}
      title={"Snackbars"}
      filters={<Filters />}
      bulkActionButtons={false}
      exporter={false}
      debounce={1000}
    >
      <Datagrid>
        <TextField source={"attributes.segment"} label={"Segmentación"} />
        <TextField source={"attributes.identifier"} label={"Nombre snackbar"} />
        <TextField source={"attributes.description"} label={"Texto snackbar"} />
        <FunctionField
          label={"Visibilidad"}
          render={(record) => (
            <ListCountries
              data={JSON.parse(record.attributes.list_countries)}
            />
          )}
        />
        <FunctionField
          label={"Estado"}
          render={(record) => <StatusSwich {...record} />}
        />
        <FunctionField
          label={"Acciones"}
          render={(record) => (
            <ListActions
              {...record}
              editSnackbar={props.editSnackbar}
              showSnackbar={props.showSnackbar}
            />
          )}
        />
      </Datagrid>
    </List>
  );
};

export default ListSnackbars;
