import React, { useState } from 'react';

import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  Typography,
  Divider
} from '@material-ui/core';
import { toMoney } from '../../../../../../Helpers/MoneyHelper';
import { replaceSymbols } from '../../../../../../Helpers/ResourceHelper';
import { TransactionProvider, UserProvider } from '../../../../../../Providers';
import Swal from 'sweetalert2';
import useAccessRole from '../../../../../../hooks/useAccessRole';

const crypto_coins = ['btc', 'usdc', 'usdt']

export default function TransferCurrenciesModal(props) {

  const stepsPerCurrency = {
    usd: 0.01,
    vita_card: 0.01,
    usdt: 0.00000001,
    usdc: 0.00000001,
    btc: 0.00000001
  }

  const { disabled, balances, senderEmail, senderId } = props;

  const [emailFieldHelperText, setEmailFieldHelperText] = useState('');
  const [recipientData, setRecipientData] = useState({});
  const [open, setOpen] = useState(false);
  const [emailLocked, setEmailLocked] = useState(false);
  const { isAccess } = useAccessRole();

  const [errorCurrency, setErrorCurrency] = useState(null);
  const [formValues, setFormValues] = useState({
    sender_email: senderEmail,
    recipient_email: '',
    total: '',
    currency: Object.keys(balances || {}).filter((item) => !crypto_coins.includes(item.toLowerCase()))[0],
  });

  const handleFormChange = (event) => {
    let { name, value } = event.target;

    const data = { ...formValues, [name]: value };

    if (recipientData["default_fiat_currency"]) {
      if (name === 'currency' && crypto_coins.includes(value.toLowerCase())) {
        data['currency_received'] = value.toLowerCase()
      } else {
        data['currency_received'] = recipientData['default_fiat_currency'].toLowerCase()
      }
    }

    setFormValues(data);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setErrorCurrency(null);
  };

  const handleClose = () => {
    setEmailFieldHelperText('');
    setOpen(false);
    setEmailLocked(false);
    setFormValues({
      sender_email: '',
      recipient_email: '',
      total: '',
      currency: 'usd',
      currency_received: 'usd',
    });
  };

  const validateReceiverUserData = (responseData) => {
    let user = responseData.users.data[0];
    const userStatus = user?.attributes?.status;
    const userIsSentReceive = user?.attributes?.is_sent_receive || false;
    const userProfileVerification = user?.attributes?.profile_verification;
    const allowedProfileVerificationStatuses = ['rejected', 'accepted'];
    return userIsSentReceive && userStatus === 'active' && allowedProfileVerificationStatuses.includes(userProfileVerification);
  };

  const handleEmailValidation = async () => {
    if (formValues.recipient_email.toLowerCase() === senderEmail) {
      setEmailFieldHelperText('La cuenta de origen no puede ser igual a la cuenta de destino');
      return;
    }

    try {
      const response = await UserProvider.getVitaUser(`?q[email_eq]=${encodeURIComponent(formValues.recipient_email.toLowerCase())}`);
      let is_valid = validateReceiverUserData(response.data);
      if (is_valid) {
        const attributes = response.data.users.data[0]['attributes'];
        setEmailLocked(true);
        setRecipientData(attributes);
        setEmailFieldHelperText(
          `Usuario 
          ${attributes['first_name']} 
          ${attributes['last_name']} 
          validado correctamente`
        );
        handleFormChange({ target: { name: 'currency_received', value: attributes['default_fiat_currency'].toLowerCase() } })
      } else {
        setEmailFieldHelperText('Este usuario no existe o no puede recibir dinero en este momento');
      }
    } catch (error) {
      setEmailFieldHelperText('No se pudo verificar este usuario');
    }
  };

  const getUserQuotationPrices = async (user_id) => {
    return await UserProvider.getUserQuotationPrices(user_id);
  }

  const getUserCryptoQuotationPrices = async (user_id) => {
    return await UserProvider.getUserCryptoQuotationPrices(user_id);
  }

  const createTransaction = async (currency, total, senderId, senderEmail, recipientData, destination_currency) => {
    let params = {
      category: 'sent',
      currency: currency,
      currency_received: destination_currency,
      description: 'Transferencia de fondos solicitada por el titular de la cuenta',
      source: 'admin',
      total: total,
      sender_email: senderEmail,
      recipient_email: recipientData.email,
    }
    await getUserCryptoQuotationPrices(senderId);
    await getUserQuotationPrices(senderId);
    return await TransactionProvider.createTransaction(params);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (emailLocked) {
      Swal.fire({
        title: '¿Está seguro de continuar?',
        text: 'Asegúrese de haber ingresado la información correctamente antes de continuar.',
        icon: 'warning',
        showCancelButton: true,
      }).then(async (confirmation) => {
        if (confirmation.isConfirmed) {
          try {
            Swal.fire({
              title: 'Loading ...',
              html: 'Please wait...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              },
            });
            await createTransaction(formValues.currency, Number(formValues.total), senderId, senderEmail, recipientData, formValues.currency_received);
            Swal.fire({
              title: 'Transferencia completada',
              text: 'La transferencia se ha realizado con éxito',
              icon: 'success',
            });
            handleClose();
          } catch (error) {
            Swal.fire({
              title: 'Lo sentimos, tuvimos un error en el envío',
              text: `${error.data['message']}`,
              icon: 'error',
            });
          }
        }
      });
    }
  };

  const changeCurrency = async (event) => {
    setErrorCurrency(null);

    const email = formValues.recipient_email,
      currency = event.target.value;

    if (crypto_coins.includes(currency)) {
      try {
        const response = await UserProvider.validateCurrency(replaceSymbols(email), currency);

        if (response.data.hasOwnProperty('data') && response.data.data.hasOwnProperty('attributes')) {
          if (!response.data.data.attributes.active_cryptos.hasOwnProperty(currency)) {
            setErrorCurrency(`El destino no tiene activado ${currency.toUpperCase()}`);
          }
        } else {
          Swal.fire({
            title: 'Lo sentimos, tuvimos un error al validar la moneda.',
            icon: 'error',
          });
        }
      } catch (error) {
        Swal.fire({
          title: 'Lo sentimos, tuvimos un error al validar la moneda.',
          text: error.data && error.data.user ? error.data?.user : error.data?.message,
          icon: 'error',
        });
      }
    }


  }

  return (
    <>
      {
        isAccess('create_admin_transfers') && (
          <Button disabled={disabled} variant='outlined' onClick={handleClickOpen}>
            Transferir a otro usuario
          </Button>
        )
      }
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Transferencia a Usuario Vita</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              margin='dense'
              name='recipient_email'
              label='Correo del destinatario'
              type='email'
              disabled={emailLocked}
              fullWidth
              value={formValues.recipient_email}
              onChange={handleFormChange}
              variant='standard'
            />
            <FormHelperText>{emailFieldHelperText}</FormHelperText>
            <Divider style={{ marginTop: '10px' }} variant='middle' />
            {emailLocked && (
              <div>
                <Typography style={{ marginTop: '30px', marginBottom: '10px' }} variant='subheading'>
                  Cuenta de origen
                </Typography>
                <Select
                  fullWidth
                  value={formValues.currency}
                  name='currency'
                  label='Moneda'
                  onChange={(event) => {
                    handleFormChange(event);
                    changeCurrency(event);
                  }}
                >
                  {Object.entries(balances).map(([key, value]) => {
                    let excludeBalances = ['vita_card', ...crypto_coins];
                    if (!excludeBalances.includes(key)) {
                      return <MenuItem key={key} value={key}>{key.toUpperCase().replaceAll('_', ' ')}</MenuItem>;
                    }
                    return null;
                  })}
                </Select>
                <FormControl fullWidth>
                  <TextField
                    required
                    autoFocus
                    margin='dense'
                    name='total'
                    label='Monto'
                    type='number'
                    InputProps={{
                      inputProps: {
                        min: stepsPerCurrency[formValues.currency],
                        max: balances[formValues.currency],
                        step: stepsPerCurrency[formValues.currency],
                      },
                    }}
                    fullWidth
                    value={formValues.total}
                    onChange={handleFormChange}
                    variant='standard'
                  />
                </FormControl>

                <FormHelperText>
                  Saldo disponible: {toMoney(balances[formValues.currency], formValues.currency.toUpperCase())}{' '}
                  {formValues.currency.toUpperCase().replaceAll('_', ' ')}
                </FormHelperText>

                <Divider variant='middle' style={{ marginTop: '20px' }} />
                <Typography style={{ marginTop: '10px', marginBottom: '10px' }} variant='subheading'>
                  Cuenta de destino
                </Typography>
                <Select
                  readOnly={true}
                  fullWidth
                  value={formValues.currency_received}
                  name='currency_received'
                  label='Moneda'
                  onChange={handleFormChange}
                >
                  {
                    Object.keys(recipientData['balances'] || []).map((currency_received) => (
                      <MenuItem key={currency_received} value={currency_received}>{currency_received.toUpperCase()}</MenuItem>
                    ))
                  }
                </Select>
                <FormHelperText>
                  Saldo disponible:{' '}
                  {formValues.currency_received && recipientData['balances'] && recipientData['balances'][formValues.currency_received]
                    ? toMoney(recipientData['balances'][formValues.currency_received], formValues.currency_received.toUpperCase())
                    : recipientData['balances'] ? 'El usuario no soporta la moneda destino' : 'Consultando...'}{' '}
                  {formValues && formValues.currency_received ? formValues.currency_received.toUpperCase().replaceAll('_', ' ') : ''}
                </FormHelperText>

                <Divider style={{ marginTop: '20px' }} variant='middle' />

                <DialogContentText style={{ marginTop: '10px' }}>
                  {emailLocked && Object.keys(recipientData).length !== 0 && (
                    <Typography variant='subtitle'>
                      Va a transferir{' '}
                      <strong>
                        {' '}
                        {toMoney(formValues.total, formValues.currency.toLocaleUpperCase())}{' '}
                        {formValues.currency.toUpperCase().replaceAll('_', ' ')}
                      </strong>{' '}
                      desde la cuenta <strong>{senderEmail}</strong> a la cuenta <strong>{recipientData.email}</strong>.
                      Asegúrese de que esta información es correcta antes de proceder con el envío.
                    </Typography>
                  )}
                </DialogContentText>
              </div>
            )}
            {errorCurrency && <FormHelperText error={true}>{errorCurrency}</FormHelperText>}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            {!emailLocked && <Button onClick={handleEmailValidation} autoFocus={emailLocked}>Validar</Button>}
            {emailLocked && formValues.currency_received && recipientData['balances'] && recipientData['balances'].hasOwnProperty(formValues.currency_received) && <Button type='submit' autoFocus={false} disabled={errorCurrency}>Transferir</Button>}
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
