import React, { memo } from "react";
import PropTypes from "prop-types";
import { BooleanInput } from "react-admin";
import {
  Button,
  Modal,
  Card,
  TextField,
  CardContent,
  Typography,
  Grid,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import styles from "./styles";

const EditProvider = memo((props) => (
  <Modal
    open={props.isOpenModal}
    onClose={props.handleCloseModal}
    style={styles.modalContainer}
  >
    <Card style={styles.cardContainer}>
      <CardContent>
        <Typography
          variant='h4'
          gutterBottom
          style={styles.titleWhite}
          data-testid='Title-proveedor'>
          Detalles del proveedor
        </Typography>
        <Grid
          container
          direction='row'
          justify='center'
          alignItems='center'
          style={styles.divisorTop}
        >
          <Grid item style={styles.containerField}>
            <TextField
              type="number"
              value={props.itemUpdate.id}
              onChange={props.handleOnchange}
              name='id'
              label={"Id"}
              InputProps={{
                readOnly: true,
              }}
              data-testid='text-id'
            />
          </Grid>

          <Grid item style={styles.containerField}>
            <TextField
              value={props.itemUpdate.name}
              label={"Nombre"}
              onChange={props.handleOnchange}
              name='name'
              InputProps={{
                readOnly: true,
              }}
              data-testid='text-name'
            />
          </Grid>

          <Grid item style={styles.containerField}>
            <TextField
              type="string"
              value={props.itemUpdate.public_name}
              label={"Nombre Público *"}
              onChange={props.handleOnchange}
              name='public_name'
              InputProps={{
                readOnly: false,
              }}
              data-testid='text-public_name'
            />
          </Grid>

          <Grid item style={styles.containerField}>
            <TextField
              type="string"
              value={props.itemUpdate.arrive_message}
              label={"Tiempo de arribo *"}
              onChange={props.handleOnchange}
              name='arrive_message'
              InputProps={{
                readOnly: false,
              }}
              data-testid='text-arrive_message'
            />
          </Grid>

          <Grid item style={styles.containerField}>
            <TextField
              type="string"
              value={props.itemUpdate.order}
              label={"Orden *"}
              onChange={props.handleOnchange}
              name='order'
              InputProps={{
                readOnly: false,
              }}
              data-testid='text-order'
            />
          </Grid>

          <Grid item style={styles.containerField}>
            <BooleanInput
              label={"Posee formulario *"}
              source="is_form"
              value={props.itemUpdate.is_form}
              onChange={(e) => props.handleOnchange(e, 'is_form')}
              defaultValue={props.itemUpdate.is_form}
            />
          </Grid>

          <Grid item style={styles.containerField}>
            <BooleanInput
              label={"Es confiable *"}
              source="is_trusted"
              value={props.itemUpdate.is_trusted}
              onChange={(e) => props.handleOnchange(e, 'is_trusted')}
              defaultValue={props.itemUpdate.is_trusted}
            />
          </Grid>

          <Grid item style={styles.containerField}>
            <BooleanInput
              label={"Siempre visible, incluso sin moneda *"}
              source="is_auto_recharge"
              value={props.itemUpdate.is_auto_recharge}
              onChange={(e) => props.handleOnchange(e, 'is_auto_recharge')}
              defaultValue={props.itemUpdate.is_auto_recharge}
            />
          </Grid>

          <Grid item>
            <Grid container style={styles.gridButton}>
              <Button
                variant='contained'
                component='label'
                style={styles.styleButtonImage}
              >
                <Grid container style={styles.girdTextImage}>
                  <Grid item style={styles.gridImage}>
                    <InputLabel style={{ fontSize: 30 }}>🖼</InputLabel>
                  </Grid>
                  <Grid item>Subir imagen *</Grid>
                </Grid>
                <input
                  type='file'
                  name='files'
                  accept='.jpg, .jpeg, .png'
                  hidden
                  onChange={(e) => props.setImage_design(e.target.files)}
                />
              </Button>
              {props.image_design.length > 0 && (
                <Button onClick={props.showImage}>
                  Ver Imagen
                </Button>
              )}
            </Grid>
          </Grid>

          <Grid
            style={styles.gridComisionContainer}
          >
            <Grid
              container
              direction='row'
              justify='center'
              alignItems='center'
            >
              <Typography variant='h4' gutterBottom style={styles.titleWhite}>
                Comisiones locales
              </Typography>

              <Grid
                container
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Grid item style={styles.gridSelect}>
                  <Select
                    label='Tipo de Comisión'
                    value={props.itemUpdate.commission_type_local}
                    name='commission_type_local'
                    onChange={props.handleOnchange}
                    style={styles.selectItem}
                    disabled={props.isGlobal}
                    data-testid='select-commission_type_local'
                  >
                    <MenuItem value={"value"}>Valor</MenuItem>
                    <MenuItem value={"percentage"}>Porcentaje</MenuItem>
                  </Select>
                </Grid>

                <Grid item style={styles.containerField}>
                  <TextField
                    value={props.itemUpdate.commission_local}
                    label={"Valor de comisión *"}
                    onChange={props.handleOnchange}
                    type='number'
                    name='commission_local'
                    min='0'
                    disabled={props.isGlobal}
                    InputProps={{
                      readOnly: false,
                      inputProps: {
                        min: 0,
                      },
                    }}
                    data-testid='text-commission_local'
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction='row'
              justify='center'
              alignItems='center'
            >
              <Typography variant='h4' gutterBottom style={styles.titleWhite}>
                Comisiones Globales
              </Typography>
              <Grid
                container
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Grid item style={styles.gridSelect}>
                  <Select
                    label='Tipo de Comisión'
                    value={props.itemUpdate.commission_type_global}
                    name='commission_type_global'
                    onChange={props.handleOnchange}
                    style={styles.selectItem}
                    disabled={!props.isGlobal}
                    data-testid='select-commission_type_global'
                  >
                    <MenuItem value={"value"}>Valor</MenuItem>
                    <MenuItem value={"percentage"}>Porcentaje</MenuItem>
                  </Select>
                </Grid>

                <Grid item style={styles.containerField}>
                  <TextField
                    disabled={!props.isGlobal}
                    value={props.itemUpdate.commission_global}
                    label={"Valor de comisión *"}
                    onChange={props.handleOnchange}
                    type='number'
                    name='commission_global'
                    min='0'
                    InputProps={{
                      readOnly: false,
                      inputProps: {
                        min: 0,
                      },
                    }}
                    data-testid='text-commission_global'
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction='row'
              justify='center'
              alignItems='center'
            >
              <Typography variant='h4' gutterBottom style={styles.titleWhite}>
                Status del provedor
              </Typography>
              <Grid
                container
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Grid item style={styles.gridSelect}>
                  <Select
                    label='Status del provedor'
                    value={props.itemUpdate.is_active}
                    name='is_active'
                    onChange={props.handleOnchange}
                    style={styles.selectItem}
                    data-testid='select-is_active'
                  >
                    <MenuItem value={true}>Activo</MenuItem>
                    <MenuItem value={false}>Inactivo</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Grid
        container
        direction='row'
        justify='center'
        alignItems='center'
        style={styles.divisorTop}
      >
        <Grid item style={styles.containerField}>
          <Typography
            variant='h4'
            gutterBottom
            style={styles.titleWhite}
            data-testid='Tipo-comision'
          >
            Tipo de comisión a escoger *
          </Typography>
          <Select
            label='Tipo de Comisión a usar'
            value={props.itemUpdate.global_commission_status}
            name='global_commission_status'
            onChange={props.handleOnchange}
            style={styles.selectItem}
            data-testid='select-global_commission_status'
          >
            <MenuItem value={"local"}>Local</MenuItem>
            <MenuItem value={"global"}>Global</MenuItem>
          </Select>
        </Grid>
      </Grid>

      <Grid
        container
        direction='row'
        justify='center'
        alignItems='center'
        style={styles.divisorTop}
      >
        <Grid item style={styles.containerField}>
          <Button
            onClick={props.handleCloseModal}
            data-testid='button-Cerrar'
            style={styles.buttonClose}
          >
            Cerrar
          </Button>
        </Grid>
        <Grid item style={styles.containerField}>
          <Button
            onClick={props.handleUpdateProvider}
            disabled={!props.isUpdate}
            style={{ backgroundColor: !props.isUpdate ? "gray" : '#33F5A5' }}
            data-testid='button-Actualizar'
            color="primary"
          >
            Actualizar
          </Button>
        </Grid>
      </Grid>
    </Card>
  </Modal>
));

EditProvider.propTypes = {
  handleCloseModal: PropTypes.func,
  handleOnchange: PropTypes.func,
  handleUpdateProvider: PropTypes.func,
  setImage_design: PropTypes.func,
  isOpenModal: PropTypes.bool,
  isUpdate: PropTypes.bool,
  image_design: PropTypes.array,
  itemUpdate: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    public_name: PropTypes.string,
    is_form: PropTypes.bool,
    bank_information: PropTypes.object,
    arrive_message: PropTypes.string,
    commission_country: PropTypes.shape({
      commission: PropTypes.string,
      commission_type: PropTypes.string,
    }),
    commission_global: PropTypes.shape({
      commission: PropTypes.string,
      commission_type: PropTypes.string,
    }),
    status_global_commission: PropTypes.bool,
    is_active: PropTypes.bool,
    recharge_provider_image: PropTypes.string,
  }),
};

EditProvider.defaultProps = {
  handleCloseModal: () => { },
  handleOnchange: () => { },
  handleUpdateProvider: () => { },
  setImage_design: () => { },
  isOpenModal: false,
  isUpdate: false,
  image_design: [],
  itemUpdate: {
    id: 1,
    name: '',
    public_name: '',
    is_form: false,
    bank_information: {},
    arrive_message: '',
    commission_country: {
      commission: '1',
      commission_type: 'value',
    },
    commission_global: {
      commission: '1',
      commission_type: 'value',
    },
    status_global_commission: true,
    is_active: true,
    recharge_provider_image: '',
  },
};

export default EditProvider;
