import { apiCall } from '../../Middlewares';
import { ENDPOINTS } from '../../Config/env';

const fetchFilterFormType = (filter) => {
	let { url } = ENDPOINTS.ADMIN.FORM_TYPES.GET_FORM;

	if (filter) {
		url = `${url}${filter}`;
	}

	return apiCall({
		authorization: true,
		...ENDPOINTS.ADMIN.FORM_TYPES.GET_FORM,
		url,
	});
};

const fetchFilterOptions = (filter) => {
	let { url } = ENDPOINTS.ADMIN.FORM_TYPES.GET_OPTIONS;

	if (filter) {
		url = `${url}${filter}`;
	}

	return apiCall({
		authorization: true,
		...ENDPOINTS.ADMIN.FORM_TYPES.GET_OPTIONS,
		url,
	});
};

export default {
	fetchFilterFormType,
	fetchFilterOptions
};
