import React from 'react';
import PropTypes from 'prop-types';
import UserLimitRequests from '../UserLimitRequests';

const TrxLimitsFromUser = (props) => (
    <>
        <UserLimitRequests 
            isFilters={false}
            user={props.record}
        />
    </>
);

TrxLimitsFromUser.propTypes = {
    record: PropTypes.shape({
        id: PropTypes.number,
    }),
};

TrxLimitsFromUser.defaultProps = {
    record: null,
};

export default TrxLimitsFromUser;