import React from "react";
import { List, Datagrid, TextField } from "react-admin";
import { ReferrerFilter } from '../Filters';
import { Pagination }  from 'react-admin';
import useExportFiles from "../../../hooks/useExportFiles";
import { FIELDS_EXPORTS_FILES } from "../../utils";

const ReferrerList = (props) => {
  const { exportFilesAttributes } = useExportFiles();

  const exporter = posts => {
    exportFilesAttributes(posts, FIELDS_EXPORTS_FILES.REFERRER)
  }

  return (
    <>
      <List 
        {...props} 
        title={"Lista de usuarios"}
        filters={<ReferrerFilter />}
        pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 500]}  />}
        bulkActionButtons={false}
        exporter={exporter}
        debounce={1000}
      >
        <Datagrid>
          <TextField 
            source={"id"}
            label={"Id"}
          />
          <TextField
            source={"attributes.email"}
            label={"Correo"}
          />
          <TextField
            source={"attributes.quantity"}
            label={"Cantidad de referidos"}
          />
        </Datagrid>
      </List>
    </>
  );
};

export default ReferrerList;