import ServiceList from './ServiceList';
import ServiceEdit from './ServiceEdit';
import useAccessRole from '../../hooks/useAccessRole';

const {isAccess} = useAccessRole();

export default {
  name: 'services',
  list: ServiceList,
  edit: isAccess('edit_services') ? ServiceEdit : false,
}