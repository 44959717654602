import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { Animated } from 'react-animated-css';
import Button from '@material-ui/core/Button';
import PinInput from 'react-pin-input';
import ReactLoading from 'react-loading';
import ReCAPTCHA from "react-google-recaptcha";

import useEncrypt from '../../hooks/useEncrypt';
import Images from '../../Assets/images';
import { useCurrentHeight, useAlerts } from '../../hooks';
import { apiCall } from "../../Middlewares";
import { ENDPOINTS, GOOGLE_RECAPTCHA } from "../../Config/env";

const Login = (props) => {
  const [errorPin, setErrorPin] = useState(false);
  const [pin, setPin] = useState('');
  const [isForm, setForm] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPinCode, setPinCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recaptcha, setRecaptcha] = useState(false);
  const [gToken, setGToken] = useState(null);
  const { encrypt } = useEncrypt();
  let height = useCurrentHeight();
  const { errorMessage } = useAlerts()

  useEffect(() => {
    setTimeout(() => setForm(true), 500);
  }, []);

  useEffect(() => {
    if (pin && pin.length === 6) {
      setLoading(true);
      setTimeout(sendSecondFactor, 2000)
    }
  }, [pin]);

  const changeEmail = (e) => {
    setEmail(e.target.value);
  };

  const changePassword = (e) => {
    setPassword(e.target.value);
  };

  const sendSecondFactor = async () => {
    try {
      const response = await apiCall({
        ...ENDPOINTS.ADMIN.AUTH.AUTH_CONFIRM_SECOND_FACTOR,
        data: {
          email,
          code: pin
        }
      });

      const { data } = response;

      const user = data.data;
      if (response.status === 200) {
        localStorage.setItem('headers', JSON.stringify(response.headers));
        localStorage.setItem('role_access', JSON.stringify(user.attributes.role));
        window.location = "/";
      } else {
        setErrorPin(true);
        setLoading(false);
      }
    } catch (e) {
      setErrorPin(true);
      setLoading(false);
    }
    setPin('');
  };

  const reSendCode = async () => {
    try {
      await apiCall({
        ...ENDPOINTS.ADMIN.AUTH.AUTH_RESEND_CODE_DOUBLE_AUTHENTICATION,
        data: {
          email,
          code: pin
        }
      });
    } catch (e) {

    }
  };

  const handlePaste = async () => {
    try {
      const pasteCode = await window.navigator.clipboard.readText()
      if (pasteCode) {
        if (pasteCode.length === 6) {
          setPin(pasteCode)
        }
        else {
          errorMessage('código incorrecto')
        }
      }
    } catch (error) {
      errorMessage('Necesitas permisos de tu navegador para pegar tu código de verificación')
    }
  };

  const validToken = (value) => {
    setRecaptcha(true);
    setGToken(value);
  }

  const send = async () => {
    if (email && password && recaptcha) {
      setLoading(true);
      try {
        const response = await apiCall({
          ...ENDPOINTS.ADMIN.AUTH.SIGN_IN,
          data: {
            email: encrypt(email),
            password: encrypt(password),
            grecaptcha: gToken,
          },
          authorization: true
        });

        const user = response.data.hasOwnProperty('data') ? response.data.data : null;
        const init_second_factor = response.data.hasOwnProperty('init_second_factor') ? response.data.init_second_factor : false;
        if (user !== null && user.attributes.admin) {
          if (user.attributes.second_factor) {
            setLoading(false);
            setPinCode(true);
          } else {
            localStorage.setItem('headers', JSON.stringify(response.headers));
            localStorage.setItem('role_access', JSON.stringify(user.attributes.role));
            window.location = "/";
          }
        } else if (init_second_factor) {
          setPinCode(true);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    }
  };

  const renderInputs = () => {
    //type LoadingType = "blank" | "balls" | "bars" | "bubbles" | "cubes" | "cylon" | "spin" | "spinningBubbles" | "spokes";
    if (loading)
      return (
        <div style={{ justifyContent: 'center', alignItems: 'center', width: '100%', display: 'flex' }}>
          <ReactLoading type={'spin'} color={'#00c9e3'} height={25} width={25} />
        </div>
      );

    if (isPinCode)
      return (
        <div>
          <PinInput
            length={6}
            initialValue=""
            secret
            onChange={(value, index) => {

              setErrorPin(false);
              setPin(value);
            }}
            type="numeric"
            style={{ padding: '10px', color: '#FFFFFF' }}
            inputStyle={{ borderColor: errorPin ? '#FF0000' : '#b1b1b1', color: '#FFFFFF' }}
            inputFocusStyle={{ borderColor: '#00c9e3', color: '#FFFFFF' }}
            onComplete={(value, index) => { }}
          />
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: '#131313' }}
            onClick={handlePaste}
          >
            Pegar Código
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: '#131313' }}
            onClick={reSendCode}
          >
            Reenviar Código
          </Button>
        </div>
      );

    return (
      <div>
        <TextField
          id="standard-basic"
          label="User"
          variant="filled"
          floatingLabelStyle={{ color: "white" }}
          InputLabelProps={{
            style: {
              color: "white",
            },
          }}
          InputProps={{
            style: {
              color: "white",
              backgroundColor: '#080808',
            },
          }}
          onChange={changeEmail}
        />
        <div style={{ height: 10 }} />
        <TextField
          id="standard-password-input"
          label="Password"
          type="password"
          autoComplete="current-password"
          InputLabelProps={{
            style: {
              color: "white",
            },
          }}
          InputProps={{
            style: {
              color: "white",
              backgroundColor: '#080808',
            }
          }}
          onChange={changePassword}
        />
        <div style={{ paddingLeft: 40, paddingTop: 10 }}>
          <ReCAPTCHA
            onChange={validToken}
            size="normal"
            sitekey={GOOGLE_RECAPTCHA}
            theme="dark"
          />
        </div>
        <div style={{ padding: 20 }}>
          <Button variant="contained" color="primary" style={{ backgroundColor: '#131313' }}
            onClick={send}>
            GO!
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div style={{ backgroundColor: '#131313', justifyContent: 'center', alignItems: 'center', width: '100%', height: height, display: 'flex' }}>
      {isForm ? (
        <Animated animationIn="bounceIn" animationOut="bounceOut" isVisible={true}>
          <div style={{ width: 400, height: 255, display: 'flex', flexDirection: 'column', paddingTop: 20, paddingBottom: 20, }}>
            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
              <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={!loading}>
                <Animated style={{ animationIterationCount: 'infinite' }} animationIn="jello" animationOut="fadeOut" isVisible={true}>
                  <img src={Images.vita} width={60} height={60} />
                </Animated>
              </Animated>
            </div>
            <div style={{ width: 400, textAlign: 'center', paddingTop: 20 }}>
              {renderInputs()}
            </div>
          </div>
        </Animated>
      ) : null}
    </div>
  );
};

export default Login;
