const styles = {
    item: {
        color: '#FFFFFF',
    },
    flag: {
        fontSize: '4em',
    },
    buttonBlue: {
        backgroundColor: '#4887de',
        color: '#000000',
        textAlign: 'center',
        width: '100%',
    },
    textTitle:{
        backgroundColor: '#4887de',
        width: 300,
        height: 50,
        textAlign: 'center',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        color:'white',
        fontWeight: '900',
        fontSize:'18px'
    },
    textEmail:{
        all: 'unset',
        textDecoration:'underline',
        cursor:'pointer',
        fontWeight: '900',
        fontSize:'12px',
        textTransform: 'capitalize',
        marginTop: 8,
    },
    styleLink:{
        display: "flex",
        marginTop: 10,
        color: '#54CDFD',
        fontWeight: 'bold',
        marginBottom: 5,
        justifyContent:'center',
    },
    emojiContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    emoji: {
        margin: 7,
        fontSize: 22,
    },
    remitent_container: {
        display: 'flex',
        flew: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    remitent_label: {
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: 'bold'
    },
    remitent_flag: {
        fontSize: 24,
        marginRight: 8
    }
};

export default styles;
