import { useEffect, useState } from "react";
import Swal from "sweetalert2";

import { LinkPayment } from "../Providers";
import useAlerts from "./useAlerts";
import useAccessRole from "./useAccessRole";
import useReset from "./useReset";

const useLinkPayment = (idCountry) => {
  const { successMessage, errorMessage } = useAlerts();
  const { isAccess } = useAccessRole();
  const { resetList } = useReset();

  const [providersList, setprovidersList] = useState([]);
  const [image_design, setImage_design] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [itemUpdate, setItemUpdate] = useState({
    id: 0,
    name: "",
    public_name: "",
  });

  const getProviders = async () => {
    try {
      const {
        data: { data },
      } = await LinkPayment.fetchProviderPayment(idCountry);
      if (data.length) {
        setprovidersList(data.map((item) => item.attributes));
      }
    } catch (error) {}
  };

  const hanleOnChangeStatus = async (item) => {
    if (isAccess("edit_payment_order_providers")) {
      let data = { status: item.status };

      if (data.status === "active") {
        data.status = "inactive";
      } else {
        data.status = "active";
      }

      try {
        await LinkPayment.updateProviderPayment(idCountry, item.id, data);
        resetList();
      } catch (error) {}
    } else {
      return errorMessage("No posee permisos para esta operación");
    }
  };

  const handleUpdateProvider = async () => {
    if (isAccess("edit_payment_order_providers")) {
      try {
        const formData = new FormData();

        formData.append("name", itemUpdate.name);
        formData.append("public_name", itemUpdate.public_name);
        if (image_design.length) {
          formData.append("logotype_image", image_design[0]);
        }

        const response = await LinkPayment.updateProviderPayment(
          idCountry,
          itemUpdate.id,
          formData
        );

        if (response.status === 200) {
          handleCloseModal();
          getProviders(idCountry);
          successMessage();
        }
      } catch (error) {
      }
    } else {
      return errorMessage("No posee permisos para esta operación");
    }
  };

  const handleOpenModal = (item) => {
    setItemUpdate({
      id: item.id,
      name: item.code,
      public_name: item.public_name,
    });

    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setImage_design([]);
    setItemUpdate({
      id: 0,
      name: "",
      public_name: "",
    });
    setIsOpenModal(false);
  };

  const handleOnchange = (e) =>
    setItemUpdate({
      ...itemUpdate,
      [e.target.name]: e.target.value,
    });

  const handleShowImage = () => {
    Swal.fire({
      position: "center",
      heightAuto: true,
      imageUrl: URL.createObjectURL(image_design[0]),
      text: "Imagen Cargada Correctamente.",
      width: 350,
      imageWidth: 200,
      imageHeight: 200,
      imageAlt: "Error",
    });
  };

  useEffect(() => {
    if (idCountry && isAccess("show_payment_order_providers")) {
      getProviders();
    }
    // eslint-disable-next-line
  }, []);

  return {
    handleCloseModal,
    handleOnchange,
    handleOpenModal,
    handleShowImage,
    handleUpdateProvider,
    hanleOnChangeStatus,
    image_design,
    isOpenModal,
    itemUpdate,
    providersList,
    setImage_design,
  };
};

export default useLinkPayment;
