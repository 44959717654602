import Swal from "sweetalert2";

const useAlerts = () => {
  const errorMessageCustom = (title, message, type = 'info') => {
    Swal.fire(title, message, type);
  };

  const confirmationModal = ({ title = "Muy bien!", text, icon = "success", background = "rgb(19,19,19)" }) => {
    return Swal.fire({
      title: title,
      text: text,
      icon: icon,
      background: background,
    });
  }

  const errorMessage = (value = 'Error') => {
    Swal.fire({
      icon: "error",
      title: "¡ERROR!",
      text: value,
    });
  };

  const successMessage = (value = '') => {
    Swal.fire({
      icon: "success",
      title: "¡ÉXITO!",
      text: value,
    });
  };

  const successNotification = (value = '', status = 'success') => {
    const Notification = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
    });

    Notification.fire({
      icon: status,
      title: value
    });
  }

  const showComponent = (div) => {
    Swal.fire({
      title: "Agregar comentario",
      html: div,
    });
  };

  const successMessagePayment = (value = '') => {
    Swal.fire({
      position: "top-center",
      icon: "success",
      title: `Pago ${value.toLowerCase() === 'manual' ? 'manual' : `automático${value ? ` con ${value},` : ''}`} realizado con éxito`,
      showConfirmButton: true,
      timer: 2000,
      background: "rgb(19,19,19)",
    });
  };

  const showLoading = () => {
    Swal.fire({
        title: 'Loading ...',
        html: 'Please wait...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
    });
  };

  const hiddenLoading = () => {
    Swal.hideLoading();
  };

  const close = () => {
    Swal.close();
  };

  const showCustomError = (title, message, type) => {
    Swal.fire(title, message, type);
  };

  const showSelect = async (payload = {}) => {
    const params = {
      text: 'Por favor ingrese la opción deseada',
      input: 'select',
      inputPlaceholder: 'Seleccione la opción deseada',
      showCancelButton: true,
      confirmButtonText: "Siguiente",
      cancelButtonText: "Cancelar",
      showLoaderOnConfirm: true,
      ...payload,
    };

    try {
      const response = await Swal.fire(params);
      return response;
    } catch {
      return {}
    }
  };

  const showRequest = async (payload = {}) => {
    const params = {
      text: "Ingrese el motivo/descripción",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      preConfirm: (value) => {
        if (!value.trim()) {
          Swal.showValidationMessage(
            payload['reasonLabel'] || 'El motivo/descripción es requerido'
          );
        }
      },
      confirmButtonText: "Siguiente",
      cancelButtonText: "Cancelar",
      showLoaderOnConfirm: true,
      ...payload,
    };

    try {
      const response = await Swal.fire(params);
      return response;
    } catch {
      return {}
    }
  };

  const showModal = (payload = {}) => {
    const params = {
      title: '¿Estás seguro?',
      text: "Esta operación no se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      background: 'rgb(19,19,19)',
      confirmButtonColor: '#9BA40E',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continuar',
      showLoaderOnConfirm: false,
      ...payload,
    };

    return Swal.fire(params);
  };

  const successfulCopy = () => {
    Swal.fire('¡Copiado!');
  };

  const showHTML = (params = {}) => {
    Swal.fire({
      html: '<div>Contenido</div>',
      ...params,
    });
  };

  return {
    errorMessage,
    successMessage,
    successMessagePayment,
    showLoading,
    hiddenLoading,
    showSelect,
    showRequest,
    showModal,
    close,
    showCustomError,
    confirmationModal,
    successfulCopy,
    errorMessageCustom,
    showHTML,
    showComponent,
    successNotification,
  };
};

export default useAlerts;