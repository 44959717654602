import React from "react";
import { List, Datagrid, TextField, EditButton } from "react-admin";
import { Pagination } from "../../../Components";

const BinanceNetworksList = (props) => {
  return (
    <List
      {...props}
      title={"Lista de blockchains"}
      pagination={<Pagination />}
      exporter={false}
      bulkActionButtons={false}
      debounce={1000}
    >
      <Datagrid>
        <TextField source="id" label="Id" />
        <TextField source="attributes.key_name" label="Identificador de red" />
        <TextField source="attributes.name" label="Nombre" />
        <TextField source="attributes.status_name" label="Estado" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default BinanceNetworksList;
