import { UPDATE, CREATE } from "react-admin";

import { API_URL } from "../../../../Config/env";

const requestHandler = (type, params) => {
  switch (type) {
    case UPDATE: {
      let {
        attributes: {
          key_name,
          name,
          withdraw_message,
          estimated_arrival_time,
          status,
          network_asset_configurations
        },
      } = params.data;

      status = status ? "enabled" : "disabled";

      return {
        url: `${API_URL}/admin/binance_networks/${params.id}`,
        method: "put",
        data: {
          key_name,
          name,
          withdraw_message,
          estimated_arrival_time,
          status,
          network_asset_configurations: network_asset_configurations.map((item) => {
            return {
              id: item.id,
              withdraw_min: item.withdraw_min,
              withdraw_max: item.withdraw_max,
              withdraw_fee: item.withdraw_fee,
              status: item.status,
              is_address_available: item.is_address_available,
              min_fee_required: item.min_fee_required,
              is_calculate_risk: item.is_calculate_risk,
              decimal_withdrawal: item.decimal_withdrawal
            }
          })
        },
      };
    }
    default:
      return null;
  }
};

export default requestHandler;
