import React, { useEffect, useState, useCallback } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import {EmailSegmentsProvider} from '../../../../../Providers';
import useAlerts from '../../../../../hooks/useAlerts';

import {
  CardContent,
  Grid,
  TextField,
  Button,
  InputLabel,
} from "@material-ui/core";

import {
  COUNTRIES_CURRENCY_COUPONS,
  BONIFICATION_TYPE,
  CATEGORIES,
  COUPON_TYPE,
} from "../utils";

import Swal from "sweetalert2";

import { stylesCuponInitial } from "./styles";

const CuponInitial = (props) => {
  const {
    sendNewCoupon,
    CountrySelectHoc,
    history,
    couponEditData,
    savedData,
  } = props;
  const [couponDetails, setCouponDetails] = useState({
    identifier: null,
    campaign: null,
    dest_country_id: null,
    start_date: "",
    end_date: "",
    coupon_type: null,
    number_coupons: null,
    bonus_type: null,
    currency: null,
    amount: null,
    min_amount_transaction: null,
    transaction_type: null,
    email_id: null,
  });

  const [validateFields, setValidateFields] = useState(false);
  const {errorMessage, successMessage} = useAlerts();

  const validate = () => {
    const {
      identifier,
      campaign,
      dest_country_id,
      start_date,
      end_date,
      bonus_type,
      currency,
      amount,
      min_amount_transaction,
      transaction_type,
      number_coupons,
    } = couponDetails;

    identifier &&
      campaign &&
      dest_country_id &&
      start_date &&
      end_date &&
      bonus_type &&
      currency &&
      amount &&
      min_amount_transaction &&
      transaction_type &&
      !amount.includes('-') &&
      !min_amount_transaction.includes('-')  &&
      amount !== 0 &&
      min_amount_transaction !== 0 &&
      number_coupons &&
      setValidateFields(true);
  };

  const testingAmount = () => {
    if (couponDetails.bonus_type === "percentage") {
      if (couponDetails.amount > 100) {
        Swal.fire({
          icon: "error",
          title: "Porcentaje máximo permitido 100%",
        });
        setCouponDetails({
          ...couponDetails,
          amount: 100,
        });
      }
    }
  };

  useEffect(() => {
    testingAmount();
  }, [couponDetails]);

  useEffect(() => {
    validate();
  }, [couponDetails]);

  const loadindData = () => {
    if (couponEditData !== null) {
      const {
        data: {
          amount,
          bonus_type,
          campaign,
          currency,
          dest_country,
          end_date,
          identifier,
          min_amount_transaction,
          number_coupons,
          start_date,
          transaction_type,
          coupon_type,
          email_id,
        },
      } = couponEditData;

      setCouponDetails({
        identifier,
        campaign,
        dest_country_id:
          dest_country !== null ? dest_country.id.toString() : null,
        start_date,
        end_date,
        number_coupons,
        bonus_type,
        currency,
        amount,
        min_amount_transaction,
        transaction_type,
        coupon_type,
        email_id,
      });
    }
    if (savedData !== null) {
      const {
        amount,
        bonus_type,
        campaign,
        currency,
        dest_country_id,
        end_date,
        identifier,
        min_amount_transaction,
        number_coupons,
        start_date,
        transaction_type,
        coupon_type,
        email_id,
      } = savedData;

      setCouponDetails({
        identifier,
        campaign,
        dest_country_id: dest_country_id,
        start_date,
        end_date,
        number_coupons,
        bonus_type,
        currency,
        amount,
        min_amount_transaction,
        transaction_type,
        coupon_type,
        email_id,
      });
    }
  };

  useEffect(() => {
    loadindData();
  }, [couponEditData, savedData]);

  const clearCouponDetails = () => {
    setCouponDetails({
      identifier: "",
      campaign: "",
      dest_country: null,
      start_date: "",
      end_date: "",
      number_coupons: "",
      bonus_type: null,
      currency: null,
      amount: "",
      min_amount_transaction: "",
      transaction_type: null,
      coupon_type: null,
      email_id: null,
    });
  };

  const handleOnchange = (e) => {
    setCouponDetails({
      ...couponDetails,
      [e.target.name]: e.target.value,
    });
    if (e.target.value === "") {
      setValidateFields(false);
    }
    if (e.target.name === 'amount' || e.target.name === 'number_coupons' || e.target.name === 'min_amount_transaction') {
      if (couponDetails[e.target.name] < 0 ) {
      Swal.fire({
        icon: "error",
        title: "No se permiten valores negativos",
      });
      setCouponDetails({
        ...couponDetails,
        [e.target.name]: 0,
      });
    }
    }
    if(e.target.name === 'identifier'){
      if (e.target.value.includes('/')){
        errorMessage('Simbolo no permitido')
        setCouponDetails({
          ...couponDetails,
          identifier: '',
        });
      } 
    }
  };

  const sendInformation = () => {
    if (couponDetails.number_coupons < 0 || couponDetails.number_coupons === 0 ) {
     return Swal.fire({
        icon: "error",
        title: "No se permiten valores negativos o en 0",
      });
    }
    sendNewCoupon(couponDetails);
  };

  const checkTransactionalSegment = useCallback(async () => {
    try {
      const response = await EmailSegmentsProvider.checkTransactionalSegment(couponDetails.email_id);
      successMessage(response.data.message);
    } catch (error) {
      errorMessage(error.data.message);
    }
  }, [couponDetails]);

  return (
    <CardContent style={stylesCuponInitial.cardContainer}>
      <Grid container>
        <Grid container xs={12} style={stylesCuponInitial.boxCenter}>
          <Grid item xs={3}>
            <TextField
              label='Identificador *'
              name={"identifier"}
              variant='outlined'
              value={couponDetails.identifier}
              onChange={handleOnchange}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ marginTop: 5 }}
              fullWidth
            />
          </Grid>
          <Grid item xs={3} style={stylesCuponInitial.gridItem}>
            <TextField
              label='Campaña *'
              name={"campaign"}
              value={couponDetails.campaign}
              onChange={handleOnchange}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ marginTop: 5 }}
              fullWidth
            />
          </Grid>

          <Grid item xs={5}>
            <InputLabel style={stylesCuponInitial.inputCountry}>
              País destino *
            </InputLabel>
            <CountrySelectHoc
              value={couponDetails.dest_country_id}
              onChange={handleOnchange}
              name={"dest_country_id"}
            />
          </Grid>
        </Grid>

        <Grid container xs={12}>
          <Grid item xs={3} style={{ marginRight: 15 }}>
            <TextField
              value={couponDetails.start_date}
              label='Fecha de inicio *'
              name={"start_date"}
              type='date'
              onChange={handleOnchange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={3} style={stylesCuponInitial.gridItem2}>
            <TextField
              value={couponDetails.end_date}
              name={"end_date"}
              label='Fecha de fin *'
              type='date'
              onChange={handleOnchange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={4} style={stylesCuponInitial.gridItem}>
            <InputLabel style={stylesCuponInitial.inputBonus}>
              Tipo de cupon *
            </InputLabel>

            <Select
              value={couponDetails.coupon_type}
              onChange={handleOnchange}
              name={"coupon_type"}
              fullWidth
            >
              {COUPON_TYPE.map((itemField, index) => (
                <MenuItem key={index} value={itemField.id}>
                  {`${itemField.name}`}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        <Grid container xs={12} style={stylesCuponInitial.boxCenter}>
          <Grid item xs={3}>
            <TextField
              label='Cantidad de cupones *'
              name={"number_coupons"}
              value={couponDetails.number_coupons}
              onChange={handleOnchange}
              type='number'
              InputProps={{ inputProps: { min: 1 } }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              // helperText='Campo opcional'
            />
          </Grid>
          <Grid item xs={3} style={stylesCuponInitial.gridItem}>
            <InputLabel style={stylesCuponInitial.inputBonus}>
              Tipo de bonificación *
            </InputLabel>
            <Select
              value={couponDetails.bonus_type}
              onChange={handleOnchange}
              name={"bonus_type"}
              fullWidth
            >
              {BONIFICATION_TYPE.map((itemField, index) => (
                <MenuItem key={index} value={itemField.id}>
                  {`${itemField.name}`}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={5}>
            <InputLabel style={stylesCuponInitial.inputCoin}>
              Moneda Origen *
            </InputLabel>
            <Select
              value={couponDetails.currency}
              onChange={handleOnchange}
              name={"currency"}
              style={{ width: 200 }}
            >
              {COUNTRIES_CURRENCY_COUPONS.map((itemField) => (
                <MenuItem key={`field${itemField.id}`} value={itemField.id}>
                  {`${itemField.id} - ${itemField.name}`}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={3}>
            <TextField
              label='valor *'
              name={"amount"}
              value={couponDetails.amount}
              onChange={handleOnchange}
              InputProps={{ inputProps: { min: 1 } }}
              InputLabelProps={{
                shrink: true,
              }}
              type='number'
              fullWidth
            />
          </Grid>

          <Grid item xs={3} style={stylesCuponInitial.gridItem}>
            <TextField
              label='Monto Mín. de transacción *'
              name={"min_amount_transaction"}
              value={couponDetails.min_amount_transaction}
              onChange={handleOnchange}
              InputProps={{ inputProps: { min: 1 } }}
              InputLabelProps={{
                shrink: true,
              }}
              type='number'
              fullWidth
            />
          </Grid>

          <Grid item xs={5}>
            <InputLabel style={stylesCuponInitial.inputTypeTransaction}>
              Tipo de transacción *
            </InputLabel>
            <Select
              value={couponDetails.transaction_type}
              onChange={handleOnchange}
              name={"transaction_type"}
              style={{ width: 200 }}
            >
              {CATEGORIES.map((itemField) => (
                <MenuItem key={`field${itemField.id}`} value={itemField.id}>
                  {`${itemField.name}`}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        <Grid container xs={12} style={{paddingTop: 20, justifyContent: 'center'}}>
          <Grid item xs={5}>
            <TextField
              label='transactional_message_id'
              name={"email_id"}
              value={couponDetails.email_id}
              onChange={handleOnchange}
              InputProps={{ inputProps: { min: 1 } }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item container xs={3} alignItems="center" style={{display: 'flex', paddingLeft: 10}}>
            <Button 
              variant="outlined" 
              color="secondary"
              onClick={checkTransactionalSegment}
            >
              Probar
            </Button>
          </Grid>
        </Grid>

        <Grid container xs={12} style={{ marginTop: 35, marginBottom: 5 }}>
          <Grid item xs={3} style={{}}>
            <Button
              onClick={clearCouponDetails}
              style={stylesCuponInitial.noStylesButton}
            >
              limpiar
            </Button>
          </Grid>

          <Grid xs={3}></Grid>
          <Grid xs={3}>
            <Button
              onClick={() => history.push("/coupons")}
              style={stylesCuponInitial.noStylesButton}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid xs={3}>
            <Button
              variant='contained'
              color='primary'
              onClick={sendInformation}
              disabled={validateFields === false}
            >
              <div style={stylesCuponInitial.textStyle}>Continuar</div>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default CuponInitial;
