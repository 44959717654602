import { useState, useCallback, useMemo, useEffect } from 'react';

import TransactionsProvider from '../Providers/Transactions';

const useCrateFieldRule = (props) => {
  const { section = null, onRulesFetch = () => { }, isSectionCreate = false, countryIsoCode = '', onAdd = () => { } } = props;
  const [field, setField] = useState({
    label: '',
    name: '',
    min: '',
    max: '',
    position: '',
    type: 'text',
    col: '12',
  });
  const [keyVisible, setKeyVisible] = useState('');
  const [labelVisible, setLabelVisible] = useState('');
  const [options, setOptions] = useState(null);
  const [isAddField, setAddField] = useState(isSectionCreate);
  const [sectionName, setSectionName] = useState('');

  const changeSectionName = (event) => {
    setSectionName(event.target.value);
  };

  const changeLabel = useCallback((event) => {
    setField({
      ...field,
      label: event.target.value,
    });
  }, [field, setField]);

  const changeName = useCallback((event) => {
    setField({
      ...field,
      name: event.target.value,
    });
  }, [field, setField]);

  const changeMin = useCallback((event) => {
    setField({
      ...field,
      min: event.target.value.replace(/[^0-9.]/g, ''),
    });
  }, [field, setField]);

  const changeMax = useCallback((event) => {
    setField({
      ...field,
      max: event.target.value.replace(/[^0-9.]/g, ''),
    });
  }, [field, setField]);

  const changePosition = useCallback((event) => {
    setField({
      ...field,
      position: event.target.value,
    });
  }, [field, setField]);

  const changeType = useCallback((event) => {
    const type = event.target.value;

    if (type === 'select') {
      setOptions([{
        label: '',
        value: '',
      }]);
    } else {
      setOptions(null);
    }

    setField({
      ...field,
      type,
    });
  }, [field, setField, setOptions]);

  const addOption = useCallback((event, type, index) => {
    const data = [...options];

    if (type === 'label') {
      data[index].label = event.target.value;
    } else {
      data[index].value = event.target.value;
    }

    setOptions(data);
  }, [options, setOptions]);

  const changeCol = useCallback((event) => {
    const col = event.target.value.replace(/[^0-9.]/g, '');
    const position = col === '12' ? '' : field.position;
    setField({
      ...field,
      col,
      position,
    });
  }, [field, setField]);

  const changeKeyVisible = useCallback((event) => {
    setKeyVisible(event.target.value);
  }, [setKeyVisible]);

  const changeLabelVisible = useCallback((event) => {
    setLabelVisible(event.target.value);
  }, [setLabelVisible]);

  const changeAddField = useCallback(() => {
    setAddField(!isAddField);
  }, [isAddField, setAddField]);

  const addNewOption = useCallback(() => {
    const data = [...options];

    data.push({
      label: '',
      value: '',
    });

    setOptions(data);
  }, [options, setOptions]);

  const deleteOption = useCallback((index) => {
    const data = [];

    options.map((option, i) => {
      if (i !== index) {
        data.push(option);
      }

      return null;
    });

    setOptions(data);
  }, [options, setOptions]);

  const isDisabledPositionSelect = useMemo(() => {
    return field.col === '12';
  }, [field]);

  const isValidForm = useMemo(() => {
    const validationCol = field.col !== '12' ? field.position !== '' : true;
    let validationOptions = true;
    let validationSectionCreate = isSectionCreate ? sectionName !== '' : section !== null;

    if (field.type === 'select' && options) {
      options.map((option) => {
        if (!option.value || !option.label) {
          validationOptions = false;
        }

        return null;
      });
    }

    return (
      field.col &&
      field.label &&
      field.name &&
      field.type &&
      validationCol &&
      validationOptions &&
      validationSectionCreate
    );
  }, [field, options, section, isSectionCreate, sectionName]);

  const reset = () => {
    setAddField(false);
    setOptions(null);
    setKeyVisible('');
    setSectionName('');
    setLabelVisible('');
    setField({
      label: '',
      name: '',
      min: '',
      max: '',
      position: '',
      type: 'text',
      col: '12',
    });
  };

  useEffect(() => {
    if (!isAddField) {
      reset();
    }
  }, [isAddField]);

  const createField = useCallback(async () => {
    try {
      if (section !== null || (isSectionCreate && sectionName)) {
        const data = { ...field };
        data["options"] = options;

        if (keyVisible && labelVisible) {
          data["visibility"] = {
            key: labelVisible,
            value: keyVisible,
          }
        }

        const response = await TransactionsProvider.createTransferRule({
          field: data,
          section,
          section_name: sectionName,
          country_iso_code: countryIsoCode
        });

        onRulesFetch(response);
        onAdd();
        reset();
      }
    } catch { }
  }, [
    field,
    options,
    section,
    keyVisible,
    labelVisible,
    isSectionCreate,
    sectionName,
    countryIsoCode,
    onRulesFetch,
    onAdd,
  ]);

  return {
    field,
    sectionName,
    keyVisible,
    labelVisible,
    isAddField,
    options,
    isDisabledPositionSelect,
    isValidForm,
    changeAddField,
    changeLabel,
    changeName,
    changeMin,
    changeMax,
    changePosition,
    changeType,
    changeCol,
    changeKeyVisible,
    changeLabelVisible,
    createField,
    addOption,
    addNewOption,
    deleteOption,
    changeSectionName,
  };
};

export default useCrateFieldRule;
