import {GET_LIST} from 'react-admin';

import {API_URL} from '../../../../Config/env';

const requestHandler = (type, params) => {
    switch (type) {
        case GET_LIST: {
            const {
                filter: {
                    id,
                    alt_id,
                    status,
                },
                pagination: {
                    page,
                    perPage,
                },
                sort: {
                    field,
                    order,
                },
            } = params;

            let url = `${API_URL}/admin/transactions_status?page=${page}&count=${perPage}&q[s]=${field}+${order}&`;
            url += id ? `q[transaction_id_eq]=${id}&` : '';
            url += alt_id ? `q[alt_id_eq]=${alt_id}&` : '';
            url += status ? `q[status_eq]=${status}&` : '';

            return {
                url,
                method: 'get',
            };
        }
        default: {
            return {

            }
        }
    }
};

export default requestHandler;
