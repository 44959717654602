import React, { useState, useEffect } from 'react';
import { Filter, TextInput, SelectInput, DateInput } from 'react-admin';

import { PermissionHelper, ObjectivesHelper } from '../../../../Helpers'; 
import { Grid, withStyles } from '@material-ui/core';

const styles = {
  helperText: {
    color: ' rgba(255, 255, 255, 0.7)',
    margin: '8px 0',
    fontSize: '0.75rem',
    textAlign: 'left',
    lineHeight: '1em'
  },
  dateInput: {
    margin: '8px 0 0 0',
    '&:nth-child(1)': {
      paddingRight: '5px'
    },
    '&:nth-child(2)': {
      paddingLeft: '5px'
    },
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      backgroundColor: ' rgb(140, 140, 140)',
      borderRadius: '5px',
      display: 'block'
    }
  }
};

const HistoryFilter = (props) => {
  const { classes } = props;

  const [permissionsList, setPermissionsList] = useState([]);
  const [objectivesList, setObjectivesList] = useState([]);

  useEffect(() => {
    PermissionHelper.getPermissions().then( data => {
      setPermissionsList(data);
    });
    ObjectivesHelper.getObjectives().then( data => {
      setObjectivesList(data);
    });
  }, []);

  useEffect(() => {
    props.setFilters && props.setFilters({
      history_dates: props.filterValues.history_dates
    });
  }, [])

  return (
    <Filter {...props}>
      <TextInput
        label={'Buscar'}
        source={'search_key'}
        helperText={"Busca un usuario por id, nombre, apellido, email o DNI"}
        alwaysOn
      />
      <TextInput
        label={'Id objetivo'}
        source={'id_goal'}
        helperText={"Filtra por id objetivo"}
      />
      <SelectInput
        label={'Tipo objetivo'}
        source={'type_goal'}
        choices={objectivesList}
        optionText="target_name"
        optionValue="target_public_name"
        helperText={"Filtra por tipo de objetivo"}
      />
      <SelectInput
        label={'Acción'}
        source={'action'}
        choices={permissionsList}
        optionText="public_name"
        optionValue="name"
        helperText={"Filtra por acción ejecutada"}
      />
      <Grid
        label="Fecha ejecutada la acción"
        source={'history_dates'}
      >
        <Grid
          container
        >
          <DateInput
            label={'Desde'}
            source="history_dates.history_date_start"
            className={classes.dateInput}
          />
          <DateInput
            label={'Hasta'}
            source="history_dates.history_date_end"
            className={classes.dateInput}
          />
        </Grid>
        <p className={classes.helperText}>Fecha ejecutada la acción</p>
      </Grid>
    </Filter>
  );
};

export default withStyles(styles)(HistoryFilter);
