import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import useGeneralLimits from '../../../hooks/useGeneralLimits';
import AddGeneralLimit from '../AddGeneralLimit';
import InputLabel from '@material-ui/core/InputLabel';
import GeneralLimit from '../GeneralLimit';
import TablePagination from '@material-ui/core/TablePagination';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { USERS_TYPES } from '../utils';

const Pagination = (props) => (
    <div style={{ width: '100%', maxWidth: 845 }}>
        <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 250, 500]}
            component="div"
            count={props.total}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            backIconButtonProps={{ 'aria-label': 'Previous Page' }}
            nextIconButtonProps={{ 'aria-label': 'Next Page' }}
            onChangePage={props.changePage}
            onChangeRowsPerPage={props.changeRowsPerPage}
        />
    </div>
);

export const GeneralLimits = (props) => (
    <div style={styles.container}>
        <div style={styles.formContainer}>
            {
                props.isAddLimit && (
                    <>
                        <Button
                            style={props.isEdit ? styles.buttonEdit : styles.button}
                            onClick={props.addNewLimit}
                            disabled={!props.isCreate || props.isEdit}
                        >
                            NUEVO LÍMITE
                        </Button>
                        <div style={{ width: 20 }} />
                    </>
                )
            }
            <FormControl>
                <InputLabel shrink id='user-type'>{'Tipo de usuario'}</InputLabel>
                <Select
                    labelId='user-type'
                    label="Tipo de usuario"
                    style={styles.selectContainer}
                    value={props.userType}
                    onChange={props.changeUserType}
                    disabled={!props.isCreate || props.isEdit}
                >
                    <MenuItem value={null}>
                    </MenuItem>
                    <MenuItem value={'personal'}>
                        Natural
                    </MenuItem>
                    <MenuItem value={'business'}>
                        Empresa
                    </MenuItem>
                </Select>
            </FormControl>
            <div style={{ width: 20 }} />
            <FormControl>
                <InputLabel shrink id='country-label'>{'País'}</InputLabel>
                <Select
                    labelId='country-label'
                    label="País"
                    style={styles.selectContainer}
                    value={props.countryId}
                    onChange={props.changeCountryId}
                    disabled={!props.isCreate || props.isEdit}
                >
                    {
                        props.registerCountries.map((item) => (
                            <MenuItem
                                key={item.id}
                                value={item.id}
                            >
                                {item.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </div>
        {
            props.newLimits.map((newLimit) => (
                <AddGeneralLimit
                    countries={props.countries}
                    key={`AddGeneralLimit${newLimit.id}`}
                    onDelete={() => props.onDelete(newLimit.id)}
                    onCountrySelect={(country) => props.onCountrySelect(newLimit.id, country)}
                    onUserTypeSelect={(userType) => props.onUserTypeSelect(newLimit.id, userType)}
                    newLimits={props.newLimits}
                    loadGeneralLimits={props.loadGeneralLimits}
                    isDisabled={props.isEdit}
                />
            ))
        }
        {
            props.limits.map((limit) => (
                <GeneralLimit
                    key={`GeneralLimit${limit.id}`}
                    {...limit}
                    onDelete={() => props.onDeleteGeneralLimit(limit.id)}
                    onSave={(amount) => props.onSave(limit, amount)}
                    onEdit={() => props.onEdit(limit)}
                    isEdit={props.isEdit && props.limitEdit.id === limit.id}
                    isDisabled={props.isEdit && props.limitEdit.id !== limit.id}
                    onCancelEdit={props.onCancelEdit}
                />
            ))
        }
        {
            props.limits.length > 0 && <Pagination
                page={props.page}
                rowsPerPage={props.count}
                changePage={props.changePage}
                changeRowsPerPage={props.changeRowsPerPage}
                total={props.total}
            />
        }
    </div>
);

GeneralLimits.propTypes = {
    page: PropTypes.number,
    count: PropTypes.number,
    changePage: PropTypes.func,
    changeRowsPerPage: PropTypes.func,
    total: PropTypes.number,
    isEdit: PropTypes.bool,
    countries: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })
    ),
    limits: PropTypes.arrayOf(
        PropTypes.shape({
            country_name: PropTypes.string.isRequired,
            country_id: PropTypes.number.isRequired,
            user_type: PropTypes.oneOf(USERS_TYPES).isRequired,
            limit_amount: PropTypes.number.isRequired,
        })
    ),
    newLimits: PropTypes.arrayOf(
        PropTypes.shape({
            onDelete: PropTypes.func.isRequired,
        })
    ),
    addNewLimit: PropTypes.func,
    onDelete: PropTypes.func,
    onCountrySelect: PropTypes.func,
    onUserTypeSelect: PropTypes.func,
    onDeleteGeneralLimit: PropTypes.func,
    loadGeneralLimits: PropTypes.func,
    onCancelEdit: PropTypes.func,
    onSave: PropTypes.func,
    onEdit: PropTypes.func,
    limitEdit: PropTypes.shape({
        country_name: PropTypes.string.isRequired,
        country_id: PropTypes.number.isRequired,
        user_type: PropTypes.oneOf(USERS_TYPES).isRequired,
        limit_amount: PropTypes.number.isRequired,
    }),
    isCreate: PropTypes.bool,
    isAddLimit: PropTypes.bool,
    registerCountries: PropTypes.arrayOf({
        id: PropTypes.number,
        name: PropTypes.string,
    }),
    changeUserType: PropTypes.func,
    userType: PropTypes.string,
    isLimits: PropTypes.bool,
};

GeneralLimits.defaultProps = {
    page: 1,
    count: 1,
    changePage: () => { },
    changeRowsPerPage: () => { },
    total: 1,
    isEdit: false,
    countries: [],
    limits: [],
    newLimits: [],
    addNewLimit: () => { },
    onDelete: () => { },
    onCountrySelect: () => { },
    onUserTypeSelect: () => { },
    onDeleteGeneralLimit: () => { },
    loadGeneralLimits: () => { },
    onCancelEdit: () => { },
    onSave: () => { },
    onEdit: () => { },
    limitEdit: null,
    isCreate: false,
    isAddLimit: false,
    registerCountries: [],
    userType: 0,
    changeUserType: () => { },
    isLimits: false,
};

const GeneralLimitsHoc = (props) => {
    const hook = useGeneralLimits(props);
    return <GeneralLimits {...hook} />;
};

const styles = {
    formContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: 40,
    },
    button: {
        backgroundColor: '#00CA65',
        width: 120,
        fontSize: 12,
    },
    container: {
        paddingLeft: 30,
        paddingTop: 20,
    },
    buttonEdit: {
        backgroundColor: '#00CA65',
        width: 120,
        fontSize: 12,
        opacity: 0.35,
    },
    selectContainer: {
        width: 170,
    },
};

export default GeneralLimitsHoc;