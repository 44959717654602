import React from 'react';
import { List, Datagrid }  from 'react-admin';

import MarketingInfo from '../MarketingInfo';
import MarketingFilter from '../MarketingFilter';
import { Pagination } from '../../../../Components';

const MarketingList = (props) => {

  const RenderField = (propsRecord) => (
    <MarketingInfo {...propsRecord} />
  )

  return (
    <List
      {...props}
      title={'Lista de mensajes Marketing'}
      exporter={false}
      filters={<MarketingFilter />}
      pagination={<Pagination/>}
      bulkActionButtons={false}
      debounce={1000}
    >
      <Datagrid>
        <RenderField {...props}/>
      </Datagrid>
    </List>
  );
};

export default MarketingList;