import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
} from "react-admin";
import { Link } from "react-router-dom";
import { Pagination } from "../../../Components";
import ServiceProvider from '../../../Providers/Services';
import useAccessRole from "../../../hooks/useAccessRole";
import {ServiceFilter} from "../Filters";
import { useReset } from '../../../hooks';

const ServiceList = (props) => {
  const { isAccess } = useAccessRole();
  const { resetList } = useReset();

  const ButtonAdministrator = () => {
    return (
      isAccess('edit_category') ? <Link
        to="categories"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: 120,
        }}
      >
        <button
          style={{
            position: "absolute",
            zIndex: "1",
            textAlign: "center",
            backgroundColor: "#00F5A5",
            border: "none",
            borderRadius: "4px",
            borderColor: "#fff",
            padding: "7px",
            cursor: "pointer",
            color: "white",
            marginTop: "25px",
            marginRight: "30px",
          }}
        >
          Administrar categorías
        </button>
      </Link> : <span></span>
    );
  };

  const getStatus = async(utility_number) => {
    await ServiceProvider.getStatus(utility_number);
    resetList();
  }

  const ActivateButton = (prop) => {
    const { record: { attributes } } = prop;

    return (
      isAccess('edit_category') ? <button
        style={{
          backgroundColor: "#00F5A5",
          color: "white",
          border: "none",
          borderRadius: "4px",
          borderColor: "#fff",
          padding: "7px",
          cursor: "pointer",
        }}
        onClick={() => getStatus(attributes.utility_number)}
      >
        {attributes ? (attributes.vita_enable ? "Desactivar" : "Activar") : null}
      </button> : <span></span>
    )
  };

  return (
    <>
      <ButtonAdministrator />
      <List
        {...props}
        title={"Lista de servicios"}
        filters={<ServiceFilter />}
        pagination={<Pagination />}
        exporter={false}
        bulkActionButtons={false}
        debounce={1000}
      >
        <Datagrid>
          <TextField source="id" label="Id" />
          <TextField source="attributes.utility_name" label="Convenio" />
          <TextField
            source="attributes.utility_number"
            label="Número de convenio"
          />
          <TextField
            source="attributes.utility_type"
            label="Tipo de convenio"
          />
          <TextField source="attributes.title" label="Categoría" />
          <ActivateButton />
          <EditButton />
        </Datagrid>
      </List>
    </>
  );
};

export default ServiceList;
