import { apiCall }      from '../../Middlewares';
import { ENDPOINTS }    from '../../Config/env';

const fetchBanks = async () =>
    await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BANKS.LIST
    });

const getCountryCodeBanks = async (data) =>
    await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BANKS.COUNTRY_CODE_BANK,
        data,
    });

export default {
    fetchBanks,
    getCountryCodeBanks
};
