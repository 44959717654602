import React from 'react';
import { List, Datagrid, TextField, EditButton, BooleanField, DeleteButton, FunctionField } from 'react-admin';
import moment from "moment";

import useAccessRole from '../../../hooks/useAccessRole';

moment.locale('es');
const BankAccountAccessesList = (props) => {
  const { isAccess } = useAccessRole();

  return (
    <List
      {...props}
      title={'Accesos a cuentas bancarias'}
      bulkActionButtons={false}
      exporter={false}
      sort={{ field: 'id', order: 'ASC' }}
      debounce={1000}
    >
      <Datagrid>
        <TextField
          source={'id'}
          label={'Id'}
        />
        <TextField
          source={'attributes.name'}
          label={'Nombre'}
        />
        <BooleanField
          source={'attributes.is_active'}
          label={'Activo?'}
        />
        <TextField
          source={'attributes.source'}
          label={'Fuente'}
        />
        <FunctionField
          label={'Fecha inicial para obtención de recargas'}
          render={(record) => {
            return moment.utc(record.attributes.start_date).format('LLL');
          }}
        />
        <FunctionField
          label={'Tipo de historia'}
          render={(record) => {
            return `${record.attributes.history_type ? record.attributes.history_type === 'partial' ? 'Parcial' : 'Completa' : ''}`;
          }}
        />
        <FunctionField
          label={'Tipo de cuenta'}
          render={(record) => {
            return `${record.attributes.account_type ? record.attributes.account_type === 'savings' ? 'Ahorros' : 'Corriente' : ''}`;
          }}
        />
        <TextField
          source={'attributes.endpoint_url'}
          label={'Url de acceso'}
        />
        <TextField
          source={'attributes.auth_endpoint_url'}
          label={'Url de autenticación'}
        />
        <TextField
          source={'attributes.proxy_url'}
          label={'Proxy (xxx.xxx.xxx.xxx)'}
        />
        <TextField
          source={'attributes.proxy_port'}
          label={'Puerto del proxy'}
        />
        <TextField
          source={'attributes.document_number'}
          label={'N. de documento'}
        />
        <TextField
          source={'attributes.country_name'}
          label={'País'}
        />
        <TextField
          source={'attributes.bank_name'}
          label={'Banco'}
        />
        <TextField
          source={'attributes.account_number'}
          label={'N. de cuenta'}
        />
        <TextField
          source={'attributes.username'}
          label={'Usuario'}
        />
        <TextField
          source={'attributes.password'}
          label={'Contraseña'}
        />
        <TextField
          source={'attributes.api_key'}
          label={'Api Key'}
        />
        <TextField
          source={'attributes.secret_key'}
          label={'Secret Key'}
        />
        <TextField
          source={'attributes.per_page'}
          label={'Cantidad de registros'}
        />
        <TextField
          source={'attributes.transactions_limit'}
          label={'Límite de transacciones a leer'}
        />
        <TextField
          source={'attributes.acceptance_days'}
          label={'Cantidad de días de aceptación (< today - days)'}
        />
        <TextField
          source={'attributes.search_regex'}
          label={'Expresión regular de posición de documento'}
        />
        <TextField
          source={'attributes.select_regex'}
          label={'Expresión regular de formato del documento'}
        />
        <TextField
          source={'attributes.cleanup_regex'}
          label={'Expresión regular para limpiar caracteres del documento'}
        />
        <TextField
          source={'attributes.crt_file'}
          label={'Certificado'}
        />
        <TextField
          source={'attributes.key_file'}
          label={'Clave de certificado'}
        />
        <TextField
          source={'attributes.crt_password'}
          label={'Contraseña de certificado'}
        />
        {isAccess('update_bank_account_access') && <EditButton />}
        {isAccess('destroy_bank_account_access') && <DeleteButton />}
      </Datagrid>
    </List>
  );
}

export default BankAccountAccessesList;