import PropTypes from "prop-types";

const ListCountries = ({ data }) =>
  data.length
    ? data.map((item) => item.iso_code).join(" - ")
    : "Todos los países";

ListCountries.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      iso_code: PropTypes.string,
    })
  ),
};

ListCountries.defaultProps = {
  data: [],
};

export default ListCountries;
