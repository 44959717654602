import React from 'react';
import {Create, FormTab, TabbedForm, TextInput, SelectInput, BooleanInput} from "react-admin";
import styles from "../../Users/UserEdit/styles";
import {Col, Container, Row} from "react-grid-system";
import {TO_NUMBER, toMoney} from "../../../Helpers/MoneyHelper";
import useGetCurrenciesVitaPrices from '../../../hooks/useGetCurrenciesVitaPrices';

const VitaPricesCreate = (props) => {
    const { currencies, targetCurrencies } = useGetCurrenciesVitaPrices();
    return (
        <Create {...props}>
            <TabbedForm>
                <FormTab label="Vita precios" style={styles.titleWhite}>
                    <Container style={{ width: '100%' }}>
                        <Row container direction="row" justify="center" alignItems="center" className={{ flexGrow: 1 }}>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <SelectInput label="Moneda" source="attributes.currency" choices={currencies} />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <SelectInput label="Moneda destino" source="attributes.target_currency" choices={targetCurrencies} />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <TextInput
                                    label={'Precio Spot'}
                                    source={'attributes.spot'}
                                    format={v => toMoney(v, 'USD')}
                                    parse={v => TO_NUMBER(v, 'USD')}
                                />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <BooleanInput source="attributes.is_enabled" label="Estado" />
                            </Col>
                        </Row>
                    </Container>
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default VitaPricesCreate;