export const COLUMS = [
    'ID',
    'ORDEN',
    'NOMBRE',
    'NOMBRE PÚBLICO',
    'TIENE FORMULARIO',
    'ES CONFIABLE',
    'DETALLES BANCARIOS',
    'MENSAJE ARRIVO',
    'COMISIÓN LOCAL',
    'TIPO COMISIÓN LOCAL',
    'COMISIÓN GLOBAL',
    'TIPO COMISIÓN GLOBAL',
    'COMISIÓN ACTIVA',
    'STATUS',
    'IMAGEN',
    'SIEMPRE VISIBLE, INCLUSO SIN MONEDA',
    'EDITAR',
];

export const COLUNMS_LINKS_PAYMENT = [
    'ESTADO',
    'ID',
    'NOMBRE',
    'NOMBRE PÚBLICO',
    'IMAGEN',
    'EDITAR',
];