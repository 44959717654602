import { Accessibility } from '@material-ui/icons';

import BankMovementsList from './BankMovementsList';
import BankMovementsEdit from './BankMovementsEdit';
import useAccessRole from '../../hooks/useAccessRole';

const {isAccess} = useAccessRole();

export default {
    name: 'bank_movements',
    icon: Accessibility,
    list: isAccess('show_bank_movements') ? BankMovementsList : false,
    edit: isAccess('assign_bank_movements') ? BankMovementsEdit : false,
}