export const style = ({ 
  sceneShowBanner,
  fontColor1,
  fontColor2,
  btColorBg,
  btColorText,
}) => ({
  container: {
    marginTop: 50,
    height: sceneShowBanner === "desktop" ? 200 : 80,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  background_container: {
    paddingLeft: 24,
    width: 320,
    height: sceneShowBanner === "desktop" ? 180 : 80,
    backgroundColor: "gray",
    display: "flex",
    borderRadius: 4,
    background: `linear-gradient(to right, ${fontColor1}, ${fontColor2})`,
  },
  banner_title_angie: {
    color: "#FFFFFF",
    maxWidth: "80%",
    marginTop: 30,
    fontSize: 18,
    marginBottom: 18,
  },
  container_button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    width: 180,
    height: 60,
    backgroundColor: btColorBg,
  },
  button_title: {
    color: btColorText,
  },
  banner_image: {
    width: 152,
    height: 80,
    position: "absolute",
    left: -15,
    Zindex: 1,
  },
  image_background: {
    width: 160,
    height: 180,
    left: "auto",
    resizeMode: "contain",
  },
  container_image: {
    position: "relative",
    marginTop: 40,
    zIndex: 1,
  },
  banner_card_image_alejandra: {
    width: 143,
    height: 80,
    resizeMode: "contain",
  },
  banner_mobile: {
    paddingLeft: 24,
    width: 320,
    height: sceneShowBanner === "desktop" ? 180 : 80,
    backgroundColor: "gray",
    borderRadius: 4,
    background: `linear-gradient(to right, ${fontColor1}, ${fontColor2})`,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    paddingRigth: 15,
  },
  banner_title_alejandra: {
    color: "#FFFFFF",
    marginTop: 10,
    fontSize: 16,
    marginLeft: -30,
  },
  container_button_close: {
    display: "flex",
    width: "100%",
    alignContent: "center",
    justifyContent: "center",
    marginTop: 20,
  },
  text_information: {
    width: "100%",
    marginTop: 50,
    color: "#ffffff",
    textAlign: "center",
  },
}); 