import React  from 'react';
import moment from 'moment';

import { Grid } from "@material-ui/core";

import { statusCard, cardType } from '../../../../Config/constants';

moment.locale('es');
const CardRequestInfo = (props) => {
  const { record: { attributes } } = props;

  if (!attributes.card_requests) return null;

  const {
    card_requests: {
      status,
      id,
      card_type
    }
  } = attributes;

  return (
    <Grid style={{
      padding: '7%',
      display: 'flex',
      flexDirection: 'flexRow'
    }} >
      <div
        style={{ paddingRight: '10%' }}
      >
        {cardType[card_type]}
        <div>
          ID {id}
        </div>
      </div>
      <div
        style={{
          fontWeight: "bold",
          color: `${status == 'active' ? '#07FEC1' : '#FF8064'}`,
          background: `${status == 'active' ? 'rgba(7, 254, 193, 0.1)' : 'rgba(255, 128, 100, 0.1)'}`,
          border: `2px solid ${status == 'active' ? '#07FEC1' : '#FF8064'}`,
          padding: '5px 15px',
          borderRadius: '50px'
        }}
      >
        <div>{statusCard[status]}</div>
      </div>
    </Grid>
  );
};

export default CardRequestInfo;