import React from "react";
import { Grid } from "@material-ui/core";
import { SelectInput } from 'react-admin';
import { PAYMENT_PROCESSORS } from "../../../../../../../Utils";

const Payment = () => {
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <SelectInput
        native
        label="Seleccione el proveedor de pago"
        source="attributes.payment_processor"
        choices={PAYMENT_PROCESSORS}
        style={{ width: 350 }}
      />
    </Grid>
  )
};

export default Payment;
