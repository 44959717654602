import React from "react";

import { Grid, InputLabel } from "@material-ui/core";

import styles from "./styles";

const HeaderStatus = (props) => {
  const {
    statusCoupon: { firstScreen, secondScreen, thirdScreen },
  } = props;

  return (
    <Grid container style={{ marginTop: 15 }}>
      <Grid container style={styles.constainer} xs={12}>
        <Grid item xs={4}>
          <InputLabel
            style={{
              ...styles.label1,
              backgroundColor: firstScreen ? "green" : "gray",
            }}
          >
            1
          </InputLabel>
        </Grid>
        <Grid item xs={4}>
          <InputLabel
            style={{
              ...styles.label2,
              backgroundColor: secondScreen ? "green" : "gray",
            }}
          >
            {"2"}
          </InputLabel>
        </Grid>
        <Grid item xs={4}>
          <InputLabel
            style={{
              ...styles.label1,
              backgroundColor: thirdScreen ? "green" : "gray",
            }}
          >
            3
          </InputLabel>
        </Grid>
      </Grid>
      <Grid container style={styles.constainer} xs={12}>
        <Grid item xs={4}>
          <div style={{ textAlign: 'center', fontSize: 12, paddingTop: 15, color: '#FFFFFF' }}>
            Configuración del cupón
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ textAlign: 'center', fontSize: 12, paddingTop: 15, color: '#FFFFFF' }}>Público</div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ textAlign: 'center', fontSize: 12, paddingTop: 15, color: '#FFFFFF' }}>Visibilidad</div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HeaderStatus;