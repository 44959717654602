import React from 'react';
import { List, Datagrid, TextField, ShowButton, EditButton, } from 'react-admin';
import ReactCountryFlag from 'react-country-flag';

import { useAccessRole } from '../../../hooks';
import { useAlerts } from '../../../hooks';
import { CardAccountFilter } from '../Filters'
import { CardRequestInfo } from '../CustomComponents';
import { Pagination } from '../../../Components';
import useExportFiles from '../../../hooks/useExportFiles';
import { FIELDS_EXPORTS_FILES } from '../../utils';
import { flagsCountries } from '../../../Config/constants';

const CardAccountList = (props) => {
  const { isAccess } = useAccessRole();
  const { successNotification } = useAlerts();
  const { exportFileCardAccount } = useExportFiles();

  const exporter = posts => exportFileCardAccount(posts, FIELDS_EXPORTS_FILES.CARD_ACCOUNT);

  const Actions = props => (
    <>
      {isAccess('show_card_accounts') && <ShowButton {...props} label='Ver' />}
      {isAccess('update_card_accounts') && <EditButton {...props} label='Editar' />}
    </>
  );

  const IdColumn = (props) => {
		return (
			<TextField
				{...props}
				onClick={() => copyValues(props.record.attributes.user.id)}
			/>
		)
	};

  const ShowCountry = props => (
    <>
      <ReactCountryFlag
        countryCode={flagsCountries[props.record.attributes.country_select]}
        style={{ fontSize: 24, marginRight: 8 }}
        svg
      />
      {props.record.attributes.country_select}
    </>
  );

  const copyValues = async (value) => {
		await navigator.clipboard.writeText(value);
		successNotification(`Valor copiado ${value}`, 'success')
	};

  return (
    <List
      {...props}
      title={'Vita Card'}
      filters={<CardAccountFilter />}
      pagination={<Pagination />}
      bulkActionButtons={false}
      exporter={exporter}
      debounce={1000}
    >
      <Datagrid>
        <IdColumn label={'ID usuario'} source={'attributes.user.id'} />
        <ShowCountry label={'País'} {...props} />
        <TextField
          source={'attributes.fullname'}
          label={'Nombre'}
        />
        <TextField
          source={'attributes.email'}
          label={'Correo'}
        />
        <CardRequestInfo label={'Tarjeta'} />
        <TextField
          source={'attributes.provider'}
          label={'Proveedor'}
        />
        <Actions label={'Acción'} {...props} />
      </Datagrid>
    </List>
  );
}

export default CardAccountList;