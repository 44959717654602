import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Swal from "sweetalert2";

import styles from "./styles";
import AddRoute from "./AddRoute";
import { UserProvider } from "../../../../../Providers";
import { useReset } from "../../../../../hooks";
import useAccessRole from "../../../../../hooks/useAccessRole";
import { MAP_PREFERENTIAL_TYPES_TO_NAMES } from "../utils";

const EditPreferentialPrices = (props) => {
  const { isAccess } = useAccessRole();
  const [discount, setDiscount] = useState({});
  const [oldDiscount, setOldDiscount] = useState(discount);
  const [currentRoutes, setCurrentRoutes] = useState();
  const [pricesConfiguration, setPricesConfiguration] = useState();
  const [origins, setOrigins] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [isAddRoute, setIsAddRoute] = useState(false);
  const { resetList } = useReset();

  useEffect(() => {
    (async () => {
      let currentUserRoutes = await UserProvider.getCurrentSendUserRoutes(
        props.record.id
      );
      setCurrentRoutes(currentUserRoutes.data);

      currentUserRoutes.data.forEach((route) => {
        discount[`${route.preferential_type}-${route.price_configuration_id}`] = parseFloat(route.preferential_price)
      });
      setDiscount((prev) => ({ ...prev, ...discount }));
      setOldDiscount((prev) => ({ ...prev, ...discount }));

      let availableSendRoutes = await UserProvider.getAvailableSendRoutes(
        props.record.id
      );

      setPricesConfiguration(availableSendRoutes.data.prices_configuration);
      setOrigins(availableSendRoutes.data.origins)
      setDestinations(availableSendRoutes.data.destinations)
    })();
  }, []);

  const selectHandlerOrigin = (e, value) => {
    let newStates = {
      ...discount,
    };

    newStates[value] = e.target.value;
    setDiscount(newStates);
  };

  const preferentialPriceHandler = (e, route) => {
    discount[`${route.preferential_type}-${route.price_configuration_id}`] = e.target.value;
    setDiscount({ ...discount });
  };

  const cancelEditRoute = (route) => {
    setDiscount((prev) => ({ ...prev, [route.price_configuration_id]: oldDiscount[`${route.preferential_type}-${route.price_configuration_id}`]}));
  };

  const saveEditUserRoute = async (route) => {
    let data = {
      id: props.record.id,
      price_configuration_id: route.price_configuration_id,
      preferential_price: discount[`${route.preferential_type}-${route.price_configuration_id}`],
      preferential_type: route.preferential_type
    };

    Swal.fire({
      title: "Estás seguro?",
      text: `Seguro que quieres guardar los cambios de la ruta?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, guardar!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await UserProvider.saveEditUserRoute(data);
        resetList();

        Swal.fire(
          'Buen trabajo!',
          `Los cambios de la ruta fueron guardados correctamente!`,
          'success'
        )
      }
    });
  };

  const deleteUserRoutes = async (routes) => {
    Swal.fire({
      title: "Estás seguro?",
      text: `Quieres elminar ${routes.length > 1 ? "todas las rutas" : `la ruta ${routes[0].origin_currency.toUpperCase()} - ${routes[0].country_name || routes[0].destination_currency.toUpperCase()}`}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = routes.map(item => {
          return {
            price_configuration_id: item.price_configuration_id,
            preferential_type: item.preferential_type}
        });

        await UserProvider.deleteUserRoutes({id: props.record.id, data});
        resetList();

        Swal.fire(
          'Buen trabajo!',
          `${routes.length > 1 ? "Se eliminaron las rutas satisfactoriamente." : "Se eliminó la ruta satisfactoriamente."}`,
          'success'
        )
      }
    });
  };

  const getInitialValue = () => {
    const originRoute = origins[0].toUpperCase();
    const destinationCurrencyRoute = destinations[0].currency.toUpperCase();
    const preferentialType = (currentRoutes.length > 0 && currentRoutes[0].preferential_type) || "price" ;
    const configuration = pricesConfiguration.find(config => config.code === `${originRoute}${destinationCurrencyRoute}` && config.country_id === destinations[0].id);

    return configuration && {
      value: (preferentialType === "exchange" || preferentialType === "price" ? configuration.sell_fee : configuration[preferentialType]),
      preferentialType: MAP_PREFERENTIAL_TYPES_TO_NAMES[preferentialType]
    };
  }

  return (
    <Paper style={styles.descriptionInput}>
      {currentRoutes && currentRoutes.length !== 0 &&
        <Button
          style={{
            backgroundColor: "#E9320A",
            color: "white",
            border: "none",
            borderRadius: "4px",
            borderColor: "#fff",
            padding: "7px",
            cursor: "pointer",
            marginLeft: 40,
            marginTop: "50px",
          }}
          onClick={() => deleteUserRoutes(currentRoutes)}
        >
          ELIMINAR RUTAS
        </Button>
      }
      {isAccess('create_send_user_route') && pricesConfiguration && pricesConfiguration.length !== 0 &&
        <Button
          style={{
            backgroundColor: "#005FEE",
            color: "white",
            border: "none",
            borderRadius: "4px",
            borderColor: "#fff",
            padding: "7px",
            cursor: "pointer",
            marginLeft: 40,
            marginTop: "50px",
          }}
          onClick={() => setIsAddRoute(!isAddRoute)}
        >
          NUEVA RUTA
        </Button>
      }
      {isAddRoute && (
        <AddRoute
          pricesConfiguration={pricesConfiguration}
          origins={origins}
          initialValue={getInitialValue()}
          destinations={destinations}
          setIsAddRoute={setIsAddRoute}
          props={props}
        />
      )}
      <Table style={styles.descriptionInput}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell numeric></TableCell>
            <TableCell numeric></TableCell>
            <TableCell numeric></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentRoutes && currentRoutes.length > 0
            ? currentRoutes.map((currentRoute) => {
                return (
                  <div>
                    <div style={{marginTop: '60px'}}>
                      <TableRow key={currentRoute.price_configuration_id}>
                        <Select
                          onChange={selectHandlerOrigin}
                          value={currentRoute.origin_currency}
                          style={styles.descriptionInputRight}
                          disabled
                        >
                          <MenuItem value={currentRoute.origin_currency}>
                            {currentRoute.origin_currency}
                          </MenuItem>
                        </Select>
                        <Select
                          value={currentRoute.country_name || currentRoute.destination_currency}
                          style={styles.descriptionInputRight}
                          disabled
                        >
                          <MenuItem value={currentRoute.country_name || currentRoute.destination_currency}>
                            {currentRoute.country_name || currentRoute.destination_currency}
                          </MenuItem>
                        </Select>
                        <TextField
                          label="Código"
                          value={`${currentRoute.origin_currency}${currentRoute.destination_currency}`.toUpperCase()}
                          style={styles.descriptionInputRight}
                          disabled
                        />
                        <TextField
                          label="Tipo Preferencial"
                          value={MAP_PREFERENTIAL_TYPES_TO_NAMES[currentRoute.preferential_type]}
                          style={styles.descriptionInputRight}
                          disabled
                        />
                        <TextField
                          label={MAP_PREFERENTIAL_TYPES_TO_NAMES[currentRoute.preferential_type]}
                          value={currentRoute.preferential_type === "exchange" || currentRoute.preferential_type === "price" ? currentRoute.sell_fee : currentRoute[currentRoute.preferential_type]}
                          style={styles.descriptionInputRight}
                          disabled
                        />
                        <TextField
                          id="standard-basic"
                          label="Monto Preferencial"
                          value={`${discount[`${currentRoute.preferential_type}-${currentRoute.price_configuration_id}`]}`}
                          onChange={(e) => preferentialPriceHandler(e, currentRoute)}
                          style={styles.descriptionInputRight}
                        />
                      </TableRow>
                      </div>
                      <div style={{marginTop: '20px'}}>
                      <TableRow>
                        {isAccess('save_edit_user_route') &&
                          <Button
                            style={{
                              backgroundColor:
                                discount[`${currentRoute.preferential_type}-${currentRoute.price_configuration_id}`] !==
                                  oldDiscount[`${currentRoute.preferential_type}-${currentRoute.price_configuration_id}`] &&
                                discount[`${currentRoute.preferential_type}-${currentRoute.price_configuration_id}`] >= 0
                                  ? "#00BB8D"
                                  : "#B0B0B0",
                              color: "white",
                              border: "true",
                              borderRadius: "4px",
                              padding: "7px",
                              cursor: discount[`${currentRoute.preferential_type}-${currentRoute.price_configuration_id}`] !== oldDiscount[`${currentRoute.preferential_type}-${currentRoute.price_configuration_id}`] &&
                                discount[`${currentRoute.preferential_type}-${currentRoute.price_configuration_id}`] >= 0 ? "pointer" : "default",
                              marginRight: 10,
                            }}
                            onClick={discount[`${currentRoute.preferential_type}-${currentRoute.price_configuration_id}`] !==
                              oldDiscount[`${currentRoute.preferential_type}-${currentRoute.price_configuration_id}`] &&
                              discount[`${currentRoute.preferential_type}-${currentRoute.price_configuration_id}`] >= 0 ? () => saveEditUserRoute(currentRoute): null}
                          >
                            GUARDAR CAMBIOS
                          </Button>
                        }
                        <Button
                          style={{
                            backgroundColor: "#E9320A",
                            color: "white",
                            border: "true",
                            borderRadius: "4px",
                            padding: "7px",
                            cursor: "pointer",
                            marginRight: 10,
                          }}
                          onClick={() => cancelEditRoute(currentRoute)}
                        >
                          CANCELAR
                        </Button>
                        {isAccess('delete_user_route') &&
                          <Button
                            variant="outlined"
                            style={{
                              backgroundColor: "#424242",
                              borderColor: "#E9320A",
                              color: "white",
                              border: "true",
                              borderRadius: "10px",
                              padding: "7px",
                              cursor: "pointer",
                            }}
                            onClick={() => deleteUserRoutes([currentRoute])}
                          >
                            Eliminar ruta
                          </Button>
                        }
                      </TableRow>
                    </div>
                  </div>
                );
              })
            : null}
        </TableBody>
      </Table>
    </Paper>
  );
};

EditPreferentialPrices.propTypes = {
  record: PropTypes.object,
};

export default EditPreferentialPrices;
