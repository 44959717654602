import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  BooleanInput,
  SaveButton,
  Toolbar,
} from "react-admin";

const ServiceEdit = (props) => {
  const ServiceEditToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );

  const toServices = () => {
    window.location.href = "/services";
  };

  const GoServices = () => {
    return (
      <button
        onClick={toServices}
        style={{
          position: "absolute",
          zIndex: "1",
          textAlign: "center",
          backgroundColor: "#00F5A5",
          border: "none",
          borderRadius: "4px",
          borderColor: "#fff",
          padding: "7px",
          cursor: "pointer",
          color: "white",
          marginTop: "19px",
          right: "50px",
        }}
      >
        Ir a lista de convenios
      </button>
    );
  };

  return (
    <>
      <GoServices />
      <Edit {...props} title={`Editar convenio ${props.id}`}>
        <SimpleForm toolbar={<ServiceEditToolbar />}>
          <TextInput disabled label="Id" source="id" />
          <TextInput disabled source="attributes.utility_name" label="Empresa" />
          <TextInput
            disabled
            source="attributes.utility_number"
            label="Número de convenio"
          />
          <TextInput
            disabled
            source="attributes.utility_type"
            label="Tipo de convenio"
          />
          <ReferenceInput
            source="attributes.id"
            reference="categories"
            label="Categoría"
          >
            <SelectInput
              optionText="attributes.title"
              optionValue="attributes.id"
            />
          </ReferenceInput>
          <BooleanInput
            label={"Habilitado"}
            source={"attributes.vita_enable"}
          />
        </SimpleForm>
      </Edit>
    </>
  );
};

export default ServiceEdit;
