import { useState, useEffect, useCallback, useMemo } from 'react';
import ProvidersProvider from '../Providers/Providers';
import useAccessRole from './useAccessRole';
import { toMoney } from '../Helpers/MoneyHelper';

const useGetProvidersBalances = (props = {}) => {
    const { isDashboardLoad = false, isTouchButton = false } = props;
    const [unsupportedProviders, setUnsupportedProviders] = useState([]);
    const [providers, setProviders] = useState([]);
    const [balances, setBalances] = useState([]);
    const [errors, setErrors] = useState([]);
    const [isStart, setStart] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [isFirstReload, setFirstReload] = useState(false);
    const { isAccess } = useAccessRole();

    const getProviders = async () => {
        try {
            if (isAccess('show_providers_balances')) {
                const response = await ProvidersProvider.getProviders();
                setProviders(response.data.providers);
                setUnsupportedProviders(response.data.unsupported_providers);
                setFirstReload(true);
            }
        } catch { }
    };

    const getProviderBalance = useCallback(async (provider) => {
        try {
            if (isAccess('show_providers_balances')) {
                const response = await ProvidersProvider.getProviderBalance(provider);
                setBalances(prevItems => [...prevItems, response.data]);
            }
        } catch (error) {
            const e = error.data ? error.data.error ? error.data.error.message : error.data.message : "la api no pudo devolver error";

            setBalances(prevItems => [...prevItems, {
                provider: provider,
                balances: [
                    {
                        name: `Proveedor ${provider.toUpperCase()}`,
                        currency: "---",
                        balance: 0,
                        error: e
                    },
                ],
                is_grouped_accounts: false,
            }]);

            setErrors(prevItems => [
                ...prevItems,
                { provider, error: e, is_unsupported: error.data && error.data.error ? error.data.error.is_unsupported : false }
            ]);
        }
    }, [balances, setBalances, errors, setErrors]);

    const getProvidersBalances = useCallback(async () => {
        if (providers.length > 0) {
            setStart(false);
            setBalances([]);
            setErrors([]);

            providers.forEach((provider) => (
                getProviderBalance(provider.name)
            ));
        } else {
            getProviders();
        }
    }, [providers]);

    const reload = useCallback((provider) => {
        setBalances([...balances.filter((balance) => (balance.provider.toLowerCase() !== provider.toLowerCase()))])
        getProviderBalance(provider);
    }, [setBalances, errors, getProviderBalance, balances])

    useEffect(() => {
        let isFetch = true;

        if (isFetch && isFirstReload && providers.length > 0) {
            setFirstReload(false);
            isFetch = false;
            getProvidersBalances();

        }

        return () => {
            isFetch = false;
        }
    }, [providers, isFirstReload]);

    useEffect(() => {
        let isFetch = true;

        (async () => {
            try {
                if (isFetch && isTouchButton) {
                    await getProviders();
                }
            } catch { }
        })();

        return () => {
            isFetch = false;
        }
    }, [isDashboardLoad, isTouchButton]);

    const providersMemo = useMemo(() => {
        let isProviderLoading = false;

        let newProviders = providers
            .filter((provider) =>
                (balances.find((balance) => balance.provider.toLowerCase() === provider.name.toLowerCase()) || { is_grouped_accounts: false })["is_grouped_accounts"] === false
            )
            .map((provider) => ({
                ...provider,
                name: `${provider.name.charAt(0).toUpperCase()}${provider.name.slice(1)}`,
                balances: ((balances.find((balance) => (
                    balance.provider.toLowerCase() === provider.name.toLowerCase()
                )) || { balances: [] }).balances)
                    .map((balance) => ({
                        ...balance,
                        reload: !!balance.error ? () => reload(provider.name) : null,
                        isSuccessful: true,
                        balanceMoney: `${balance.currency !== "btc" ? toMoney(balance.balance, balance.currency) : toMoney(balance.balance.toFixed(8), "btc")} ${balance.currency.toUpperCase()}`,
                    }))
            }))
            .map((provider) => {
                if (provider.balances.length === 0) {
                    isProviderLoading = true;
                }

                return {
                    ...provider,
                    isLoading: provider.balances.length === 0,
                };
            });

        const agrupatedProviders = balances.filter((balance) => balance['is_grouped_accounts'])

        if (agrupatedProviders.length > 0) {
            let newData = [];

            agrupatedProviders.forEach((agrupated) => {
                for (let key in agrupated.balances) {
                    const value = agrupated.balances[key];
                    newData.push({
                        name: `${key.charAt(0).toUpperCase()}${key.slice(1)}`,
                        balances: value.balances,
                    })
                }
            });

            newProviders = [
                ...newProviders,
                ...newData.map((data) => ({
                    ...data,
                    balances: data.balances.map((balance) => ({
                        ...balance,
                        reload: null,
                        isSuccessful: true,
                        balanceMoney: `${balance.balance.toFixed(2)
                            .replace('.', ',')
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} ${balance.currency.toUpperCase()}`,
                    }))
                }))
                    .map((data) => (
                        {
                            ...data,
                            isLoading: data.balances.length === 0,
                        }
                    )),
            ];
        }

        setLoading(isProviderLoading)

        return newProviders;
    }, [providers, balances, errors, isStart, setLoading]);

    return {
        unsupportedProviders,
        providers: providersMemo,
        labelButton: isLoading ? '' : 'Cargar',
        getProvidersBalances,
        isDisabledButton: isLoading,
        isStart,
        isLoading,
        isProviders: providers.length > 0,
        isUnsupportedProviders: unsupportedProviders.length > 0,
        isAccess: isAccess('show_providers_balances'),
    };
};

export default useGetProvidersBalances;