import { useEffect, useState, useMemo, useCallback } from 'react';
import PricesProvider from '../Providers/Prices';

const useStatusPricesProviders = (props = {}) => {
    const { isDashboardLoad = false, isTouchButton = false } = props;
    const [ isLoading, setLoading ] = useState(false);
    const [ requests, setRequests ] = useState([]);
    const [ providers, setProviders ] = useState([]);
    
    const onGetStatusPricesProviders = useCallback(async () => {
        setLoading(true);

        try {
            const response = await PricesProvider.getStatusOfPriceProviders();
            setRequests(response.data.requests);
            setProviders(response.data.providers);
        } catch (error) {}

        setLoading(false);
    }, [ setLoading, setRequests, setProviders ]);

    const statuses = useMemo(() => {
        return providers
        .map((provider) => ({
            name: provider
                .replace(/\b\w/g, (match) => (match.toUpperCase()))
                .replace(/_/g, ' '),
            fails: requests.filter((request) => (
                request.provider.toLowerCase() === provider.toLowerCase()
            )),
        }))
        .map((provider) => ({
            ...provider,
            isFail: provider.fails.length > 0,
            failsSize: provider.fails.length,
        }));
    }, [ requests, providers ]);

    useEffect(() => {
        let isFetch = true;

        if (isFetch && isTouchButton) {
            onGetStatusPricesProviders();
            isFetch = false;
        }

        return () => {
            isFetch = false;
        }
    }, [isDashboardLoad, isTouchButton]);

    return {
        statuses,
        isLoading,
        labelButton: isLoading ? '' : 'Cargar',
        isDisabledButton: isLoading,
        onGetStatusPricesProviders,
    }
};

export default useStatusPricesProviders;