const styles = {
    saveButton: {
        backgroundColor: '#009bff',
        width: 75,
        height: '100%'
    },
    deleteButton: {
        backgroundColor: 'red',
        width: 75,
        height: '100%',
        marginLeft: 10
    },
    fieldContainer: {
        width: 225,
        textAlign: 'center'
    },
    itemContainer: {
        marginTop: 25
    },
    itemGroupContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: 20,
        marginBottom: 50
    }
};

export default styles;
