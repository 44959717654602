import { PermissionProvider } from '../Providers';

const getPermissions = async () => {
  try {
    const response = await PermissionProvider.getListPermissions();

    if (response.data) {
      return response.data;
    } else {
      console.error("ERROR inesperado al solicitar permisos al sistema -> ", response);
    }
  } catch (error) {
  }
};

const Permissions = (byRol, list) => list.map((item) => {

  const exists = byRol.find((val) => {
    return val.id === item.id
  });

  if (exists) {
    return {
      ...item,
      active: true
    }
  } else {
    return {
      ...item,
      active: false
    }
  }
});

export default {
  Permissions,
  getPermissions
};