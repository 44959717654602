import React, { useState } from 'react';
import moment from 'moment';
import { Button, Switch, TextField } from '@material-ui/core';

import useAccessRole from '../../../../hooks/useAccessRole';
import BusinessesProvider from '../../../../Providers/Businesses';
import { toMoney } from '../../../../Helpers/MoneyHelper';
import { Flag } from '../../../../Components';

import styles from './styles';
import { useAlerts, useReset } from '../../../../hooks';
import AddBalanceDialog from '../AddBalanceDialog';

moment.locale('es');
const BusinessInfo = (props) => {
	const { record, history } = props;
	const { id, attributes: {
		default_currency, assumes_recharge_commissions, first_name, last_name, country_name, email, ips_white_list, ips_black_list, balances,
		wallets_balance, is_business_transactions_report, is_business_encrypt_report, is_monthly_business_transactions_report,
		is_monthly_business_encrypt_report, is_price_update_for_master_wallet, is_trx_status_recipient_email, is_trx_status_business_email, payment_orders_service_status,
		require_final_customer
	} } = record;
	const [isIps, setIps] = useState(false);
	const [newIp, setNewIp] = useState('');
	const [addBalanceDialogIsOpen, setAddBalanceDialogIsOpen] = useState(false);

	const [isRules, setRules] = useState(false);
	const [isRechargeFees, setRechargeFees] = useState(assumes_recharge_commissions);
	const [isUpdateMaster, setUpdateMaster] = useState(is_price_update_for_master_wallet);
	const [isTrxStatusRecipientEmail, setTrxStatusRecipientEmail] = useState(is_trx_status_recipient_email);
	const [isTrxStatusBusinessEmail, setTrxStatusBusinessEmail] = useState(is_trx_status_business_email);
	const [requireFinalCustomer, setRequireFinalCustomer] = useState(require_final_customer);

	const { resetList } = useReset();

	const { isAccess } = useAccessRole();
	const { showLoading, close, showModal, errorMessage } = useAlerts();

	const changeIp = () => {
		setIps(!isIps);
	};

	const updateReport = async () => {
		try {
			await BusinessesProvider.updateReport({email, is_business_transactions_report: !is_business_transactions_report});
			resetList();
		} catch (e) { }
	};

	const updateMonthlyReport = async () => {
		try {
			await BusinessesProvider.updateMonthlyReport({email, is_monthly_business_transactions_report: !is_monthly_business_transactions_report});
			resetList();
		} catch (e) { }
	};

	const updateEncryptReport = async () => {
		try {
			await BusinessesProvider.updateEncryptReport({email, is_business_encrypt_report: !is_business_encrypt_report});
			resetList();
		} catch (e) { }
	};

	const updateEncryptMonthlyReport = async () => {
		try {
			await BusinessesProvider.updateEncryptMonthlyReport({email, is_monthly_business_encrypt_report: !is_monthly_business_encrypt_report});
			resetList();
		} catch (e) { }
	};

	const cancelBusiness = async (email) => {
		try {
			await BusinessesProvider.cancelBusiness(email);

			resetList();
		} catch (e) { }
	};

	const validateIp = (ip) => {
		return ip.match(/^(?=.*[^\.]$)((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.?){4}/g) !== null;
	};

	const addIp = async () => {

		if (validateIp(newIp)) {
			try {
				await BusinessesProvider.addIp(id, newIp);
				resetList();
			} catch (e) {

			}
		}
	};

	const addBalance = async () => {
		setAddBalanceDialogIsOpen(true);
	};

	const cancelIp = async (ip) => {
		try {
			await BusinessesProvider.cancelIp(id, ip);
			resetList();
		} catch (e) {

		}
	};

	const updateRules = async () => {
		try {
			const { isConfirmed = false } = await showModal({
				text: "¿Estás seguro que desea actualizar las reglas de negocio?",
				confirmButtonText: "Sí, actualizar!",
			  })

			if (isConfirmed) {
				showLoading();
				
				await BusinessesProvider.updateRules({
					assumes_recharge_commissions: isRechargeFees,
					is_price_update_for_master_wallet: isUpdateMaster,
					is_trx_status_recipient_email: isTrxStatusRecipientEmail,
					is_trx_status_business_email: isTrxStatusBusinessEmail,
					require_final_customer: requireFinalCustomer,
				}, id);
				
				close();

				showModal({
					title: 'Reglas de negocio actualizadas!',
					text: "",
					icon: "success",
					showCancelButton: false,
					confirmButtonColor: '#00ff9b',
					confirmButtonText: 'Entendido',
				});

				setRules(false)
			}
		} catch (error) {
			errorMessage(error && error['data'] && error['data']['message'] ? error['data']['message'] : 'Ha ocurrido un error');
			close();
		}
	}

	const updatePaymentOrdersServiceStatus = async () => {
		let newPaymentOrdersServiceStatus = payment_orders_service_status === 'payment_orders_service_active' ? 'payment_orders_service_inactive' : 'payment_orders_service_active'
		let modalText = payment_orders_service_status === 'payment_orders_service_active' ?
			'¿Estás seguro de que deseas desactivar el servicio de órdenes de pago?' :
			'¿Estás seguro de que deseas activar el servicio de órdenes de pago?'

		try {
			const { isConfirmed = false } = await showModal({
				title: 'Servicio de órdenes de pago',
				text: modalText
			})

			if(isConfirmed){
				showLoading();
				await BusinessesProvider.updatePaymentOrdersServiceStatus({payment_orders_service_status: newPaymentOrdersServiceStatus}, id);
				close();
				resetList();
			}
		} catch (e) {
			await showModal({
				title: 'No fue posible activar el servicio para este usuario',
				icon: 'error',
				text: e?.data?.message
			});
			close();
			resetList();
		}
	}

	const assignIp = async (ip) => {
		try {
			await BusinessesProvider.assingIp(id, ip);
		} catch (e) {

		}
		resetList();
	};

	const navigateToWallets = () => {
		history.push(`/wallets?filter=%7B%22business_id%22%3A%22${id}%22%7D&order=DESC&page=1&perPage=10&sort=id`);
	};

	const navigateToBusinessTransactions = () => {
		history.push(`/business_transactions?filter=%7B%22business_id%22%3A%22${id}%22%7D&order=DESC&page=1&perPage=10&sort=id`);
	};

	const renderWhiteIps = () => {
		if (isIps && ips_white_list.length > 0)
			return (
				<div style={{ padding: 20, paddingRight: 0 }}>
					<table style={{ width: '100%' }}>
						<tr>
							<th style={{ padding: 5 }}>Id</th>
							<th style={{ padding: 5 }}>Fecha de creación</th>
							<th style={{ padding: 5 }}>Fecha de actualización</th>
							<th style={{ padding: 5, color: '#00ff00' }}>IP v4</th>
							<th style={{ padding: 5, color: '#00ff00' }}>IP v6</th>
							<th>
							</th>
						</tr>
						{ips_white_list.map((ip) => (
							<tr style={{ paddingTop: 200, paddingBottom: 20 }}>
								<th style={{ padding: 5 }}>
									{ip.id}
								</th>
								<th style={{ padding: 5 }}>
									{moment.utc(ip.created_at).format('LLL')}
								</th>
								<th style={{ padding: 5 }}>
									{moment.utc(ip.updated_at).format('LLL')}
								</th>
								<th style={{ padding: 5, color: '#00ff00' }}>
									{ip.ip_v4}
								</th>
								<th style={{ padding: 5, color: '#00ff00' }}>
									{ip.ip_v6}
								</th>
								<th style={{ textAlign: 'right' }}>
									<Button size="small" style={styles.buttonRed} onClick={() => {
										cancelIp(ip.id);
									}}>
										DENEGAR
                            </Button>
								</th>
							</tr>
						))}
					</table>
				</div>
			);

	};

	const renderBlackIps = () => {
		if (isIps && ips_black_list.length > 0)
			return (
				<div style={{ padding: 20, paddingRight: 0 }}>
					<table style={{ width: '100%' }}>
						<tr style={{ paddingTop: 20, paddingBottom: 20 }}>
							<th style={{ padding: 5 }}>Id</th>
							<th style={{ padding: 5 }}>Fecha de creación</th>
							<th style={{ padding: 5 }}>Fecha de actualización</th>
							<th style={{ padding: 5, color: '#ff0000' }}>IP v4</th>
							<th style={{ padding: 5, color: '#ff0000' }}>IP v6</th>
							<th></th>
						</tr>
						{ips_black_list.map((ip) => (
							<tr style={{ paddingTop: 20, paddingBottom: 20 }}>
								<th style={{ padding: 5 }}>
									{ip.id}
								</th>
								<th style={{ padding: 5 }}>
									{moment.utc(ip.created_at).format('LLL')}
								</th>
								<th style={{ padding: 5 }}>
									{moment.utc(ip.updated_at).format('LLL')}
								</th>
								<th style={{ padding: 5, color: '#ff0000' }}>
									{ip.ip_v4}
								</th>
								<th style={{ padding: 5, color: '#ff0000' }}>
									{ip.ip_v6}
								</th>
								<th style={{ textAlign: 'right' }}>
									<Button size="small" style={styles.button} onClick={() => {
										assignIp(ip.id);
									}}>
										PERMITIR
                            </Button>
								</th>
							</tr>
						))}
					</table>
				</div>
			);
	};

	return (
		<div>
			<AddBalanceDialog 
				open={addBalanceDialogIsOpen}
				handleClose={() => setAddBalanceDialogIsOpen(false)}
				handleRefresh={resetList}
				record={record}
			/>
			<div style={styles.container}>
				<div style={{ paddingLeft: 20 }}>
					Saldo Vita
					<div style={{ fontSize: 15 }}>
						{toMoney(balances[default_currency.toLowerCase()], default_currency.toLowerCase())} {default_currency}
					</div>
				</div>
			</div>
			<div style={{ padding: 20, display: 'flex', justifyContent: 'space-between' }}>
				<div style={styles.container}>
					<div>
						<Flag record={{ attributes: { name: country_name } }} />
					</div>
					<div style={{ paddingLeft: 20 }}>
						<div style={{ fontSize: 20 }}>
							{first_name} {last_name}
						</div>
						<div>
							{email}
						</div>
					</div>
					<div style={{ paddingLeft: 20 }}>
						<Switch
							checked={isIps}
							onChange={changeIp}
							color='primary'
							inputProps={{ 'aria-label': 'primary checkbox' }}
						/>
                        IPs
                    </div>
					<div style={{ paddingLeft: 20 }}>
						<Button style={{...styles.button, ...(is_business_transactions_report ? {backgroundColor: '#FF0000'} : {})}} onClick={updateReport}>
							{is_business_transactions_report ? 'Desactivar reporte diario' : 'Activar reporte diario'}
						</Button>
					</div>
					<div style={{ paddingLeft: 20 }}>
						<Button style={{...styles.button, ...(is_business_encrypt_report ? {backgroundColor: '#FF0000'} : {})}} onClick={updateEncryptReport}>
							{is_business_encrypt_report ? 'Desactivar reporte diario encriptado' : 'Activar reporte diario encriptado'}
						</Button>
					</div>
					<div style={{ paddingLeft: 20 }}>
						<Button style={{...styles.button, ...(is_monthly_business_transactions_report ? {backgroundColor: '#FF0000'} : {})}} onClick={updateMonthlyReport}>
							{is_monthly_business_transactions_report ? 'Desactivar reporte mensual' : 'Activar reporte mensual'}
						</Button>
					</div>
					<div style={{ paddingLeft: 20 }}>
						<Button style={{...styles.button, ...(is_monthly_business_encrypt_report ? {backgroundColor: '#FF0000'} : {})}} onClick={updateEncryptMonthlyReport}>
							{is_monthly_business_encrypt_report ? 'Desactivar reporte mensual encriptado' : 'Activar reporte mensual encriptado'}
						</Button>
					</div>
					<div style={{ paddingLeft: 20 }}>
						<Button style={{...styles.button, ...(payment_orders_service_status === 'payment_orders_service_active' ? {backgroundColor: '#FF0000'} : {})}} onClick={updatePaymentOrdersServiceStatus}>
							{payment_orders_service_status !== 'payment_orders_service_active' ? 'Activar órdenes de pago' : 'Desactivar órdenes de pago' }
						</Button>
					</div>
				</div>
				<div style={styles.container}>
					{
						isAccess('add_business_balance') ?
							<>
								<div style={{ paddingLeft: 10 }}>
									<Button style={styles.button} onClick={addBalance}>
										AGREGAR BALANCE
									</Button>
								</div>
							</>
						: null
					}

					{
						isAccess('delete_business') ?
							<div style={{ paddingLeft: 10 }}>
								<Button style={styles.buttonRed} onClick={() => { cancelBusiness(email); }}>
									CANCELAR NEGOCIO
                </Button>
							</div>
						: null
					}
				</div>
			</div>
			{
				isIps ? (
					<div style={{ padding: 20, display: 'flex', justifyContent: 'space-between' }}>
						<div style={styles.container}>
							<>
								<div style={{ paddingLeft: 10 }}>
									<TextField
										style={{ width: 150 }}
										value={newIp}
										onChange={(e) => setNewIp(e.target.value)}
									/>
								</div>
								<div style={{ paddingLeft: 10 }}>
									<Button style={styles.button} onClick={addIp}>
										AGREGAR IP
                  </Button>
								</div>
							</>
						</div>
					</div>
				) : null
			}
			<div>
				{renderWhiteIps()}
				{renderBlackIps()}
			</div>
			<div style={{ padding: 25, borderTopRightRadius: 15, borderTopLeftRadius: 15, backgroundColor: 'rgba(0,0,0,0.25)' }}>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
					REGLAS DEL NEGOCIO
					<div style={{ paddingLeft: 20 }}>
						<Switch
							checked={isRules}
							onChange={() => setRules(!isRules)}
							color='primary'
							inputProps={{ 'aria-label': 'primary checkbox' }}
						/>
					</div>
					
				</div>
				{
					isRules && (
						<div style={{ display: 'flex', flexDirection: 'row', padding: 25 }}>
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 150, textAlign: 'center' }}>
									El negocio asume las comisiones de recargas
                                    <Switch
										checked={isRechargeFees}
										onChange={() => setRechargeFees(!isRechargeFees)}
										color='primary'
										inputProps={{ 'aria-label': 'primary checkbox' }}
									/>
								</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 150, textAlign: 'center' }}>
									Actualización automática de los precios para la wallet maestra
                                    <Switch
										checked={isUpdateMaster}
										onChange={() => setUpdateMaster(!isUpdateMaster)}
										color='primary'
										inputProps={{ 'aria-label': 'primary checkbox' }}
									/>
								</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 150, textAlign: 'center' }}>
									Envío de correos de cambios de estado a los clientes del business
                                    <Switch
										checked={isTrxStatusRecipientEmail}
										onChange={() => setTrxStatusRecipientEmail(!isTrxStatusRecipientEmail)}
										color='primary'
										inputProps={{ 'aria-label': 'primary checkbox' }}
									/>
								</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 150, textAlign: 'center' }}>
									Envío de correos de cambios de estado al usuario del business
                                    <Switch
										checked={isTrxStatusBusinessEmail}
										onChange={() => setTrxStatusBusinessEmail(!isTrxStatusBusinessEmail)}
										color='primary'
										inputProps={{ 'aria-label': 'primary checkbox' }}
									/>
								</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 150, textAlign: 'center' }}>
									Requiere datos del cliente final para envíos internacionales por Business API
                                    <Switch
										checked={requireFinalCustomer}
										onChange={() => setRequireFinalCustomer(!requireFinalCustomer)}
										color='primary'
										inputProps={{ 'aria-label': 'primary checkbox' }}
									/>
								</div>
							</div>
							{
								isAccess('edit_business') ?
									<div style={{ paddingTop: 25, width: '100%', textAlign: 'center' }}>
										<Button style={styles.button} onClick={updateRules}>
											ACTUALIZAR REGLAS
										</Button>
									</div>
								: null
							}
						</div>
					)
				}
			</div>
		</div>
	);
};

export default BusinessInfo;
