import {useState, useEffect} from 'react';

import banksProvider from '../Providers/Banks';

const useBanksCountry = (iso_code = '', id = '', name = '', category = '') => {
    const [banks, setBanks] = useState(id ? [{
        id, name
    }] : []);

    useEffect(() => {
        (async () => {
            if (iso_code) {
                const response = await banksProvider.getCountryCodeBanks({iso_code, category});
                setBanks(response.data.data.map(item => ({
                    id: parseInt(item.id),
                    name: item.attributes.name
                })));
            }
        })();

        return () => {};
    }, []);

    return {
        banks,
    };
};

export default useBanksCountry;
