const styles = {
    title: {
      color: "#FFFFFF",
    },
    container: {
      width: "80%",
      margin: "auto",
      marginTop: "20px",
    }
};

export default styles;