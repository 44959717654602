import React from 'react';
import {
  Show,
  TabbedShowLayout,
  Tab
} from "react-admin";
import { PersonalInformation, VitaCard } from './CustomComponents'

const CardAccountShow = (props) => {
  return (
    <Show title={`Vita Card ID ${props.id}`} {...props}>
      <TabbedShowLayout>
        <Tab label="VITA CARD" style={{ color: "#F2F2F2" }}>
          <VitaCard {...props} />
        </Tab>
        <Tab label="DATOS DEL USUARIO" style={{ color: "#F2F2F2" }}>
          <PersonalInformation {...props} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default CardAccountShow;
