import { Accessibility } from '@material-ui/icons';

import ChargeList from './ChargeList';
import ChargeShow from './ChargeShow';
import ChargeEdit from './ChargeEdit';
import ChargeCreate from './ChargeCreate';
import useAccessRole from '../../hooks/useAccessRole';

const {isAccess} = useAccessRole();

export default {
    name: 'maintenance_charges',
    icon: Accessibility,
    list: ChargeList,
    show: ChargeShow,
    edit: isAccess('edit_maintenance_charge') ? ChargeEdit : false,
    create: isAccess('create_maintenance_charge') ? ChargeCreate : false,
}
