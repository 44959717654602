import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { formatMoney } from '../../../../Helpers/MoneyHelper';

function PendingTransactionsTable({rows}) {

  const navigateToWithdrawals = (iso) => {
    switch (iso) {
      case 'btc':
        window.open(`/cryptos?tab=0`, '_blank');
        break;
      case 'usdt':
        window.open(`/cryptos?tab=2`, '_blank');
        break;
      case 'usdc':
        window.open(`/cryptos?tab=1`, '_blank');
        break;
      default:
        window.open(`/retiros?filter={"country_iso_code"%3A"${iso.toUpperCase()}"}&order=DESC&page=1&perPage=10&sort=id`, '_blank');
        break;
    }
	};

  return (
    <Paper style={{margin: "auto", marginTop: "20px"}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>País</TableCell>
            <TableCell>Bandera</TableCell>
            <TableCell>Iso</TableCell>
            <TableCell>Moneda</TableCell>
            <TableCell numeric>Cantidad</TableCell>
            <TableCell numeric>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className='click_able_row'>
          {rows && rows.map((row, id) => (
            <TableRow key={id} onClick={() => {navigateToWithdrawals(row.iso_code)}}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell>
                <img src={row.flag_url} style={{width: "30px", height: "30px"}}/>
              </TableCell>
              <TableCell>{row.iso_code.toUpperCase()}</TableCell>
              <TableCell>{row.currency}</TableCell>
              <TableCell numeric>{row.cant}</TableCell>
              <TableCell numeric><strong>${formatMoney(row.total, row.currency)}</strong></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default PendingTransactionsTable;
