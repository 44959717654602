const styles = {
    container:{
      marginTop:20,
      display:'flex',
      flexDirection:"column",
      alignItems: 'flex-start'
    },
    buttonBlue: {
        backgroundColor: '#4887de',
        color: '#000000',
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 10,
    },
};

export default styles;
