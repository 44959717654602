import React from "react";
import { Filter, TextInput, SelectInput } from "react-admin";
import { withStyles } from "@material-ui/core";

const styles = {
  helperText: {
    color: " rgba(255, 255, 255, 0.7)",
    margin: "8px 0",
    fontSize: "0.75rem",
    textAlign: "left",
    lineHeight: "1em",
  },
  dateInput: {
    margin: "8px 0 0 0",
    "&:nth-child(1)": {
      paddingRight: "5px",
    },
    "&:nth-child(2)": {
      paddingLeft: "5px",
    },
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      backgroundColor: " rgb(140, 140, 140)",
      borderRadius: "5px",
      display: "block",
    },
  },
};

const TransactionFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput
        source={"id"}
        alwaysOn
        disabled
        helperText={"Id del usuario"}
      />
      <SelectInput
        label={"Tipo"}
        source={"type_binance_transaction"}
        choices={[
          { id: 1, name: "Enviadas" },
          { id: 2, name: "Recibidas" },
        ]}
        helperText={"Fuente de operación"}
      />
    </Filter>
  );
};

export default withStyles(styles)(TransactionFilter);
