import React, {useState, useCallback, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField    from '@material-ui/core/TextField';
import {Edit} from 'react-admin';

import UserProvider from '../../../Providers/Users';
import FintocProvider from '../../../Providers/Fintoc';

import styles from './styles';
import moment from "moment";
import {toMoney} from "../../../Helpers/MoneyHelper";

const FintocEdit = (props) => {
    const {history} = props;
    const [isFetch, setFetch] = useState(false);
    const [users, setUsers] = useState(null);
    const [isUserNotFound, setUserNotFound] = useState(false);
    const [queryValue, setQueryValue] = useState('');
    const [isCreate, setCreate] = useState(false);
    const [isDelete, setDelete] = useState(false);
    const [recordActive, setRecordActive] = useState(null);
    const [userActive, setUserActive] = useState(null);
    const [isSuccessful, setSuccessful] = useState(false);

    useEffect(() => {
        if (isSuccessful) {
            setTimeout(() => {
                history.push('/fintoc?filter={"is_fintoc_unassigned"%3Atrue}');
            }, 3000)
        }
    }, [isSuccessful]);

    const getVitaUser = async () => {
        try {
            setFetch(true);
            const response = await UserProvider.getVitaUser(`?q[m]=or&q[email_eq]=${queryValue.toLowerCase()}&q[id_eq]=${queryValue.toLowerCase()}&q[document_number_eq]=${queryValue.toLowerCase()}`);
            setUsers(response.data.users.data);
            setUserNotFound(false);
        } catch {
            setUserNotFound(true);
        }
        setFetch(false);
    };

    const deleteFintoc = useCallback(async () => {
        try {
            await FintocProvider.deleteFintoc(recordActive.fintoc_id);
            setSuccessful(true);
        } catch {}
    }, [recordActive]);

    const assignRecharge = useCallback(async () => {
        try {
            await FintocProvider.assignFintocRecharge(userActive.id, recordActive.fintoc_id, recordActive.amount);
            setSuccessful(true);
        } catch (error) {
        }
    }, [userActive, recordActive]);

    const UserInfo = (user) => {
        const {record, first_name, last_name, email, default_currency, profile_verification, document_number, document_type, assign = false} = user;
        return (
            <div style={styles.container}>
                <div style={{width: '100%'}}>
                    <div style={styles.content}>
                        <div style={{width: '50%'}}>
                            <div style={styles.subtitle}>Nombres</div>
                            <div style={styles.info}>{first_name} {last_name}</div>
                        </div>
                        <div style={{width: '50%'}}>
                            <div style={styles.subtitle}>Correo</div>
                            <div style={styles.info}>{email}</div>
                        </div>
                    </div>
                    <div style={styles.content}>
                        <div style={{width: '50%'}}>
                            <div style={styles.subtitle}>Tipo de documento</div>
                            <div style={styles.info}>{document_type}</div>
                        </div>
                        <div style={{width: '50%'}}>
                            <div style={styles.subtitle}>Numero de documento</div>
                            <div style={styles.info}>{document_number}</div>
                        </div>
                    </div>
                    <div style={styles.content}>
                        <div style={{width: '50%'}}>
                            <div style={styles.subtitle}>Verificacion</div>
                            <div style={styles.info}>{profile_verification === 'accepted' ? 'Verificado' : 'Sin verificación'}</div>
                        </div>
                        <div style={{width: '50%'}}>
                            <div style={styles.subtitle}>Moneda</div>
                            <div style={styles.info}>{default_currency.toLowerCase() === 'clp' ? 'Pesos chilenos' : 'Dólar estadounidense'}</div>
                        </div>
                    </div>
                    <div style={styles.content}>
                        {!assign && default_currency.toLowerCase() === 'clp' && profile_verification === 'accepted' && record.sender_account.holder_id.toLowerCase() === document_number.toLowerCase() && (
                            <div style={{width: '50%'}}>
                                <Button size="small" style={styles.search_button} onClick={() => {
                                    setCreate(true);
                                    setUserActive(user);
                                    setRecordActive(record);
                                }}>
                                    ASIGNAR RECARGA
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const Form = (EditProps) => {
        const {record = null} = EditProps;

        if (isSuccessful) {
            return (
                <div style={styles.container}>
                    <div style={{color: '#FFFFFF', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{color: '#FFFFFF', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            ¡La operación se realizó con exito!
                        </div>
                    </div>
                </div>
            );
        }

        if (isCreate) {
            return (
                <div style={styles.container}>
                    <div style={{color: '#FFFFFF', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <UserInfo {...userActive} assign />
                        <div style={{color: '#FFFFFF', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            ¿Esta seguro que desea asignar esta recarga?
                            <div style={{paddingTop: 20}}>
                                <Button size="small" style={styles.search_button} onClick={assignRecharge}>
                                    ASIGNAR RECARGA
                                </Button>
                                <Button size="small" style={styles.cancel_button} onClick={() => {
                                    setCreate(false);
                                }}>
                                    CANCELAR
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (isDelete) {
            return (
                <div style={styles.container}>
                    <div style={{color: '#FFFFFF', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        ¿Esta seguro que desea eliminar esta recarga?
                        <div style={{paddingTop: 20}}>
                            <Button size="small" style={styles.delete_button} onClick={deleteFintoc}>
                                BORRAR RECARGA
                            </Button>
                            <Button size="small" style={styles.cancel_button} onClick={() => {
                                setDelete(false);
                            }}>
                                CANCELAR
                            </Button>
                        </div>
                    </div>
                </div>
            );
        }

        if (record) {
            return (
                <div style={styles.container}>
                    <div style={{width: '50%'}}>
                        <div style={styles.title}>Información de la recarga</div>
                        <div style={styles.content}>
                            <div style={{width: '50%'}}>
                                <div style={styles.subtitle}>ID de fintoc</div>
                                <div style={styles.info}>{record.fintoc_id}</div>
                            </div>
                            <div style={{width: '50%'}}>
                                <div style={styles.subtitle}>Fecha</div>
                                <div style={styles.info}>{moment.utc(record.post_date).format('LL')}</div>
                            </div>
                        </div>
                        <div style={styles.content}>
                            <div style={{width: '50%'}}>
                                <div style={styles.subtitle}>Tipo</div>
                                <div style={styles.info}>{record.fintoc_type}</div>
                            </div>
                            <div style={{width: '50%'}}>
                                <div style={styles.subtitle}>Referencia</div>
                                <div style={styles.info}>{record.reference_id}</div>
                            </div>
                        </div>
                        <div style={styles.content}>
                            <div style={{width: '50%'}}>
                                <div style={styles.subtitle}>Nombre del titular</div>
                                <div style={styles.info}>{record.sender_account.holder_name}</div>
                            </div>
                            <div style={{width: '50%'}}>
                                <div style={styles.subtitle}>Identificación del titular</div>
                                <div style={styles.info}>{record.sender_account.holder_id}</div>
                            </div>
                        </div>
                        <div style={styles.content}>
                            <div style={{width: '50%'}}>
                                <div style={styles.subtitle}>Monto</div>
                                <div style={styles.info}>{toMoney(
                                    record.amount,
                                    record.currency
                                )}</div>
                            </div>
                            <div style={{width: '50%'}}>
                                <div style={styles.subtitle}>Moneda</div>
                                <div style={styles.info}>{record.currency}</div>
                            </div>
                        </div>
                        <div style={styles.content}>
                            <div style={{width: '100%'}}>
                                <div style={styles.subtitle}>Monto</div>
                                <div style={styles.info}>{record.description}</div>
                            </div>
                        </div>
                    </div>
                    <div style={styles.buttons_content}>
                        {isFetch ? (
                            <div>
                                <div style={styles.subtitle}>Cargando información del usuario Vita...</div>
                            </div>
                        ) : (
                            <div>
                                {users === null ? (
                                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                        <TextField
                                            label="Correo, id o documento"
                                            value={queryValue}
                                            onChange={(event) => setQueryValue(event.target.value)}
                                            autoFocus
                                        />
                                        {queryValue !== '' && (
                                            <div style={{paddingTop: 10}}>
                                                <Button size="small" style={styles.search_button} onClick={getVitaUser}>
                                                    BUSCAR USUARIO
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        {isUserNotFound ? (
                                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                                <div style={styles.title}>El usuario no existe</div>
                                                <Button size="small" style={styles.delete_button} onClick={() => {
                                                    setDelete(true);
                                                    setRecordActive(record);
                                                }}>
                                                    BORRAR RECARGA
                                                </Button>
                                            </div>
                                        ) : (
                                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                                <Button size="small" style={styles.delete_button} onClick={() => {
                                                    setDelete(true);
                                                    setRecordActive(record);
                                                }}>
                                                    BORRAR RECARGA
                                                </Button>
                                                <div style={{paddingTop: 20, paddingBottom: 20}}>
                                                    {users.length > 0 ? (
                                                        <div>
                                                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                                                <div style={styles.title}>Información del usuario Vita</div>
                                                            </div>
                                                            {users.map((user) => {
                                                                return <UserInfo record={record} id={user.id} {...user.attributes} />
                                                            })}
                                                        </div>
                                                    ) : (
                                                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                                            <div style={styles.title}>No se encontraron usuarios</div>
                                                        </div>
                                                    )}
                                                </div>
                                                <Button size="small" style={styles.cancel_button} onClick={() => {
                                                    setUsers(null);
                                                }}>
                                                    VOLVER
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            );
        }

        return null;
    };

    return (
        <Edit
            title={`Editar fintoc ${props.id}`}
            {...props}
        >
            <Form />
        </Edit>
    );
};

export default FintocEdit;