import React from 'react';
import { List, Datagrid } from 'react-admin';

// import useModal from '../../../hooks/useModal';
// import ModalExport from '../../../Components/ModalExport';
import useAccessRole from '../../../hooks/useAccessRole';
import { TransactionFilter } from '../../Transactions/Filters';
import { Pagination } from '../../../Components';

import { BitcoinInfo } from '../components';

const WithdrawalList = (props) => {

	const { isAccess } = useAccessRole();
	// const { open, showModal, hiddeModal } = useModal();

	const RenderInfo = (dataGridProps) => {
		return (
			<BitcoinInfo {...dataGridProps} {...props} />
		);
	};

	return (
		<>
			<List
				{...props}
				title={'Lista de retiros en Bitcoin'}
				filters={<TransactionFilter isBitcoin />}
				exporter={isAccess('download_btc_withdrawal') ? () => { } : false}
				pagination={<Pagination />}
				bulkActionButtons={false}
				debounce={1000}
			>
				<Datagrid>
					<RenderInfo />
				</Datagrid>
			</List>
			{/* <ModalExport
				open={open}
				hiddeModal={hiddeModal}
			/> */}
		</>
	);
};

export default WithdrawalList;