export const COLUMS_TITLES = [
  "ID",
  "Llave ID",
  "Tipo",
  "Valor Llave",
  "Acciones",
];

export const OPTIONS_CREATE_PIX = [
  { id: 1, value: "cpf" },
  { id: 2, value: "cnpj" },
  { id: 3, value: "telefono" },
  { id: 4, value: "email" },
  { id: 5, value: "automatica" },
];
