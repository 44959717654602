import { useState, useMemo, useEffect } from 'react';
import { USER_TYPES_SELECT } from '../Components/trxLimits/utils';
import GeneralLimitsProvider from '../Providers/GeneralLimits';
import useAlerts from './useAlerts';
import useAccessRole from './useAccessRole';

const useLimitsFetch = (props = {}) => {
    const [limits, setLimits] = useState([]);

    useEffect(() => {
        let isFetch = true;

        (async () => {
            try {
                const response = await GeneralLimitsProvider.getGeneralLimits();
                const { limits: limitsResponse } = response.data;

                setLimits(limitsResponse.data.map((item) => ({
                    ...item,
                    ...item.attributes,
                    limit_amount: item.attributes.amount,
                })));

                isFetch = false;
            } catch { }
        })();

        return () => {
            isFetch = false;
        }
    });

    const hook = useAddGeneralLimit({ ...props, limits });

    return hook;
}

export const useAddGeneralLimit = (props = {}) => {
    const {
        limits = [],
        countries = [],
        newLimits = [],
        onDelete = () => { },
        onCountrySelect = () => { },
        onUserTypeSelect = () => { },
        loadGeneralLimits = () => { },
        isDisabled = false,
    } = props;

    const [country, setCountry] = useState(null);
    const [userType, setUserType] = useState(null);
    const [amount, setAmount] = useState(0);
    const { showModal, showLoading, errorMessage } = useAlerts();
    const { isAccess } = useAccessRole();

    const changeCountry = (event) => {
        const countryId = event.target.value;
        onCountrySelect(countryId);
        setCountry(countryId);
    };

    const changeUserType = (event) => {
        const userType = event.target.value;
        onUserTypeSelect(userType);
        setUserType(userType);
    };

    const changeAmount = (event) => {
        setAmount(parseFloat(event.target.value || 0));
    };

    const onSave = async () => {
        if (!!isAccess('create_general_limits')) {
            showLoading();

            try {
                await GeneralLimitsProvider.createGeneralLimits({
                    country_id: country,
                    user_type: userType,
                    amount,
                });

                showModal({
                    title: '¡Límite creado!',
                    text: "",
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: '#00ff9b',
                    confirmButtonText: 'Entendido',
                });

                onDelete();
                loadGeneralLimits();
            } catch (error) {
                errorMessage(error.data.error.message);
            }
        }
    };

    const usersTypes = useMemo(() => {
        const userTypeCounts = newLimits.reduce((counts, item) => {
            if (item.userType && (item.country === country)) {
                counts[item.userType] = (counts[item.userType] || 0) + 1;
            }
            return counts;
        }, {});

        return USER_TYPES_SELECT.map((userType) => ({
            ...userType,
            disabled: userTypeCounts[userType.id] === 1 ||
                limits.findIndex(limit => limit.user_type === userType.id && limit.country_id === country) !== -1,
        }));
    }, [newLimits, country, limits]);

    return {
        isDisabled,
        countries,
        country,
        changeCountry,
        userType,
        changeUserType,
        amount,
        changeAmount,
        onSave,
        onDelete,
        usersTypes,
        isValidForm: !!isAccess('create_general_limits') && amount > 0 && !!country && !!userType,
    }
};

export default useLimitsFetch;