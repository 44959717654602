import React from 'react';
import PropTypes  from 'prop-types';

import useCopyUrl from '../../hooks/useCopyUrl';

export const CopyURL = (props) => (
  <div>
    {
      props.isVisible && 
        <button
          style={style.button_copy}
          onClick={props.onCopy}
        >
          {props.text}
        </button> 
    }        
  </div>
);

CopyURL.propTypes = {
  text: PropTypes.string,
  isVisible: PropTypes.bool,
  onCopy: PropTypes.func,
};

CopyURL.defaultProps = {
  text: 'Copiar',
  isVisible: false,
  onCopy: () => {},
};

const HOC = (props) => {
  const hook = useCopyUrl(props);
  return <CopyURL {...hook} />;
};

HOC.propTypes = {
  record: PropTypes.shape({
    attributes: PropTypes.shape({
      encrypt_id: PropTypes.string,
    }),
  }),
};

HOC.defaultProps = {
  record: {
    attributes: {
      encrypt_id: '',
    },
  },
};

const style = {
  button_copy: {
    color: '#FFF',
    backgroundColor: '#6b6b6b',
    borderRadius: '4px',
    border: 'none',
    padding: '8px 12px',
    cursor: 'pointer'
  }
};

export default HOC;
