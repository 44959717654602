import { UPDATE, CREATE, GET_LIST } from "react-admin";

import { API_URL } from "../../../../Config/env";

const requestHandler = (type, params) => {
  switch (type) {
    case GET_LIST: {
      const { pagination: { page, perPage }, sort: { field, order} } = params;
      return {
        url: `${API_URL}/admin/crypto_addresses/?q[has_custody_transfer_flow_failed_eq]=true&page=${page}&count=${perPage}&q[s]=${field}+${order}`,
        method: 'get'
      };
    }
    default:
      return null;
  }
};

export default requestHandler;
