import React from "react";
import PropTypes from "prop-types";

import Banner from "../Banner";
import { ListBanners } from "./components";
import { useAccessRole, useAlerts, useModalComponent } from "../../../hooks";
import { HeaderButton, HeaderMenu } from "../../../Components/Comunications";

import styles from "./components/styles";
import BannerDetails from "./components/BannerDetails";

const BannerList = (props) => {
  const { history, location } = props;
  const { isAccess } = useAccessRole();
  const { errorMessage } = useAlerts();
  const { handleCloseModal, handleOpenModal, openModal } = useModalComponent();
  const {
    handleCloseModal: handleCloseDetails,
    handleOpenModal: handleOpenDetails,
    openModal: openDetailsModal,
  } = useModalComponent();

  const showBanner = (id) => {
    if (isAccess("show_banner")) {
      handleOpenDetails();
      history.push(`/banners/${id}/show_details`);
    } else {
      return errorMessage(
        "No tienes permisos para ver informacion de banners"
      );
    }
  };

  const closeModalSnackbar = () => {
    handleCloseDetails();
    history.push("/banners");
  };

  const editBanner = (id) => {
    if (isAccess("edit_banner")) {
      handleOpenModal();
      history.push(`/banners/${id}/edit`);
    } else {
      return errorMessage("No tienes permisos para editar banners");
    }
  };

  const closeModal = () => {
    handleCloseModal();
    history.push("/banners");
  };

  return (
    <section style={styles.containerList}>
      <HeaderMenu />
      <HeaderButton
        handleCreate={handleOpenModal}
        nameButton='Nuevo Banner'
      />
      {isAccess("show_banner") && (
        <ListBanners
          {...props}
          editBanner={editBanner}
          showBanner={showBanner}
        />
      )}
      {isAccess("create_banner") && openModal && (
        <Banner
          handleCloseModal={closeModal}
          open={openModal}
          history={history}
          location={location}
        />
      )}

      {isAccess("show_banner") && openDetailsModal && (
        <BannerDetails
          location={location}
          handleCloseModal={closeModalSnackbar}
          open={openDetailsModal}
        />
      )}
    </section>
  );
};

BannerList.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

BannerList.defaultProps = {
  history: {},
  location: {},
};

export default BannerList;
