const styles = {
    divisor: {
        padding: 5,
    },
    divisorTop: {
        paddingTop: 50,
    },
    titleWhite: {
        color: '#FFFFFF',
    },
    box: {
        padding: 5,
        display: 'flex',
        textAlign: 'center',
        alignContent: 'center',
        justifyContent: 'center',
    },
};

export default styles;
