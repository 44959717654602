import { apiCall } from '../../Middlewares';
import { ENDPOINTS } from '../../Config/env';

const createGeneralLimits = async (payload) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.GENERAL_LIMITS.CREATE,
        data: payload,
    });
};

const getGeneralLimits = async (payload = {}) => {
    let url = ENDPOINTS.ADMIN.GENERAL_LIMITS.GET.url;

    if (payload.count) {
        url = `${url}?page=${payload.page}&count=${payload.count}`;
    }

    if (payload.filters && payload.filters.q) {
        const query = Object.keys(payload.filters.q)
            .map(key => `q[${encodeURIComponent(key)}]=${encodeURIComponent(payload.filters.q[key])}`)
            .join('&');

        url = `${url}&${query}`;
    }

    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.GENERAL_LIMITS.GET,
        url,
    });
};

const deleteGeneralLimit = async (id) => {
    const url = ENDPOINTS.ADMIN.GENERAL_LIMITS.DELETE.url.replace(':id', id);
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.GENERAL_LIMITS.DELETE,
        url,
    });
};

const getCountriesGeneralLimit = async () => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.GENERAL_LIMITS.GET_COUNTRIES,
    });
};

const updateGeneralLimit = async (id, payload) => {
    const url = ENDPOINTS.ADMIN.GENERAL_LIMITS.UPDATE.url.replace(':id', id);
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.GENERAL_LIMITS.UPDATE,
        url,
        data: payload,
    });
};

export default {
    createGeneralLimits,
    getGeneralLimits,
    deleteGeneralLimit,
    getCountriesGeneralLimit,
    updateGeneralLimit,
};