import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import moment from 'moment';

import useAlerts from '../../../../../hooks/useAlerts';
import { CardRequestProvider } from '../../../../../Providers';
import { statusCard, cardType } from '../../../../../Config/constants';

const OtherCard = (props) => {
  const {record: {id}} = props;

  const [oldCardList, setOldCardList] = useState([]);
  const { successMessage, errorMessage } = useAlerts();

  const getOldCard = async () => {
    try {
      const response = await CardRequestProvider.getOldCard(id);
      setOldCardList(response.data.data);
    } catch (error) {
      errorMessage(error.data.error.message);
    }
  };

  useEffect(() => {
    getOldCard();
  }, []);  

  return (
    <Grid container direction="row">
      <Typography variant="body1" gutterBottom>
        <strong>OTRAS TARJETAS</strong>
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID TARJETA</TableCell>
            <TableCell>TIPO TARJETA</TableCell>
            <TableCell>TIPO USUARIO</TableCell>
            <TableCell>MONEDA</TableCell>
            <TableCell>ESTADO</TableCell>
            <TableCell>FECHA CREACIÓN</TableCell>
            <TableCell>FECHA ELIMINACIÓN</TableCell>
            <TableCell>COMENTARIOS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {oldCardList.length != 0 && oldCardList.map(card => (
            <TableRow key={card.id}>
              <TableCell>{card.id}</TableCell>
              <TableCell>{cardType[card.attributes.card_type]}</TableCell>
              <TableCell>{card.attributes.category}</TableCell>
              <TableCell>{card.attributes.currency.iso_code}</TableCell>
              <TableCell>{statusCard[card.attributes.status]}</TableCell>
              <TableCell>{moment(card.attributes.created_at).format('DD/MM/YYYY')}</TableCell>
              <TableCell>{moment(card.attributes.updated_at).format('DD/MM/YYYY')}</TableCell>
              <TableCell>{card.attributes.last_comment}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  );
};

export default OtherCard;