import { AttachMoney }  from '@material-ui/icons';

import TransactionsStatusList  from './TransactionsStatusList';
import useAccessRole from '../../hooks/useAccessRole';

const {isAccess} = useAccessRole();

export default {
    name: 'estados de las transacciones',
    icon: AttachMoney,
    list: isAccess('show_transactions_status') ? TransactionsStatusList : false,
}
