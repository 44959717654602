import BinanceNetworksList from './BinanceNetworksList';
import BinanceNetworksEdit from './BinanceNetworksEdit';
import useAccessRole from '../../hooks/useAccessRole';

const {isAccess} = useAccessRole();

export default {
  name: 'binance_networks',
  list: BinanceNetworksList,
  edit: isAccess('edit_crypto_networks') ? BinanceNetworksEdit : false,
}