import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ImageField,
  BooleanField,
} from "react-admin";
import Swal from "sweetalert2";

import { CategoryProvider } from "../../../Providers";
import useAccessRole from "../../../hooks/useAccessRole";
import { Link } from "react-router-dom";

const CategoryList = (props) => {
  const { isAccess } = useAccessRole();

  const deleteCategory = (id) => {
    Swal.fire({
      title: "Estás seguro?",
      text: "Quieres elminar esta categoría?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await CategoryProvider.deleteCategory(id);
      }

      window.location.reload();
    });
  };

  const GoServices = () => {
    return (
      <Link
        to="services"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: 120,
        }}
      >
        <button
          style={{
            position: "absolute",
            zIndex: "1",
            textAlign: "center",
            backgroundColor: "#00F5A5",
            border: "none",
            borderRadius: "4px",
            borderColor: "#fff",
            padding: "7px",
            cursor: "pointer",
            color: "white",
            marginTop: "19px",
            marginRight: "10px",
          }}
        >
          Ir a lista de convenios
        </button>
      </Link>
    );
  };

  const DeleteButton = (prop) => {
    const {
      record: { id },
    } = prop;

    if (prop.record.attributes && prop.record.attributes.total_services > 0) {
      return isAccess("edit_category") ? (
        <button
          style={{ ...buttonStyles, backgroundColor: "#BCBCBC" }}
          onClick={() => deleteCategory(id)}
          title="No se puede eliminar la categoría porque tiene servicios asociados"
          disabled
        >
          Eliminar
        </button>
      ) : (
        <span></span>
      );
    } else {
      return isAccess("edit_category") ? (
        <button style={buttonStyles} onClick={() => deleteCategory(id)}>
          Eliminar
        </button>
      ) : (
        <span></span>
      );
    }
  };

  return (
    <>
      <GoServices />
      <List
        {...props}
        title={"Lista de categorías"}
        bulkActionButtons={false}
        exporter={false}
        debounce={1000}
      >
        <Datagrid>
          <TextField source="id" label="Id" />
          <TextField source="attributes.title" label="Título" />
          <TextField source="attributes.segment" label="Segmento" />
          <ImageField
            source="attributes.icon_url"
            title="title"
            className="image-services-list"
            label="Icono"
          />
          <BooleanField
            source={"attributes.is_only_icon"}
            label={"Solo icono"}
          />
          <DeleteButton />
          <EditButton />
        </Datagrid>
      </List>
    </>
  );
};

const buttonStyles = {
  textAlign: "center",
  backgroundColor: "#00F5A5",
  border: "none",
  borderRadius: "4px",
  borderColor: "#fff",
  padding: "7px",
  cursor: "pointer",
  color: "white",
};

export default CategoryList;
