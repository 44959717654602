export const styles = {
  titleSesionDivider: {
    backgroundColor: "#565656",
    alignSelf: "end",
    marginBottom: 8,
    marginLeft: 10,
    height: 2,
  },
  titleSesionText: {
    color: "#00BB8D",
    fontSize: 16,
    fontWeight: "bold",
  },
  containerHeader: {
    backgroundColor: '#424242',
    paddingTop: 20,
    paddingBottom: 30,
  },
};
