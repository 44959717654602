import {useState, useEffect, useCallback} from 'react';

import transactionsProvider from '../Providers/Transactions/index';

const useTransferRules = () => {
    const [rules, setRules] = useState({});
    const [optionsSettings, setOptionsSettings] = useState({});
    const [countries, setCountries] = useState([]);
    const [countrySelect, setCountrySelect] = useState('');
    const [countryCreateSelect, setCountryCreateSelect] = useState('');
    const [isCreateCountry, setCreatecountry] = useState(false);

    const prepareRules = (response, isSetCountry = true, iso_code_country = '') => {
        const data = {};
        const dataCountries = [];
        Object.values(response.data).map((rule, index) => {
            if (isSetCountry && index === 0) {
                setCountrySelect(rule.iso_code.toLowerCase());
            }

            if (iso_code_country === rule.iso_code.toLowerCase()) {
                setCountrySelect(rule.iso_code.toLowerCase());
            }

            const {rules = []} = rule;
            if (rule.iso_code.toLowerCase() !== 'btc') {
                dataCountries.push({
                    label: rule.name,
                    value: rule.iso_code.toLowerCase(),
                });
            }

            if (rules) {
                rules.map((section) => {
                    const {fields = []} = section;
                    fields.map((field) => {
                        const {options = null, name} = field;
                        if (options) {
                            data[`${index}${name}`] = false;
                        }
                    })
                });
            }
        });
        setCountries(dataCountries);
        setOptionsSettings(data);
        setRules(response.data);
    };

    useEffect(() => {
        (async () => {
            try {
                const response = await transactionsProvider.getTransferRules();
                prepareRules(response);
            } catch {}
        })();

        return () => {};
    }, []);

    const changeCountryCreate = useCallback(() => {
        setCountryCreateSelect('');
        setCreatecountry(!isCreateCountry);
    }, [setCreatecountry, isCreateCountry, setCountryCreateSelect]);

    const showOptions = (key) => {
        const data = {...optionsSettings};
        data[key] = true;
        setOptionsSettings(data);
    };

    const hiddenOptions = (key) => {
        const data = {...optionsSettings};
        data[key] = false;
        setOptionsSettings(data);
    };

    const changeCountry = (event) => {
        setCountrySelect(event.target.value);
    };

    const changeCreateCountry = (event) => {
        setCountryCreateSelect(event.target.value);
    };

    const deleteField = async (id) => {
        try {
            const response = await transactionsProvider.deleteTransferRules({
               id,
               type: 'field',
            });
            prepareRules(response, false);
        } catch {}
    };

    const deleteSection = async (id) => {
        try {
            const response = await transactionsProvider.deleteTransferRules({
                id,
                type: 'section',
            });
            prepareRules(response, false);
        } catch {}
    };

    const createBankSection = async (countryIsoCode) => {
        try {
            const response = await transactionsProvider.createBankSection({
                country_iso_code: countryIsoCode
            });
            prepareRules(response, false);
        } catch {}
    };

    const isBankRule = (rule) => {
        let isExist = false;

        rule.rules.map((section) => {
            if (section.label.toLowerCase() === 'datos bancarios') {
                isExist = true;
            }
        });

        return isExist;
    };

    const createRuleForNewCountry = useCallback(async () => {
        try {
            const response = await transactionsProvider.createRuleCountry({
                country_id: countryCreateSelect
            });
            prepareRules(response, false);
            setCountryCreateSelect('');
            setCreatecountry(false);
        } catch {}
    }, [countryCreateSelect]);

    return {
        rules,
        countries,
        optionsSettings,
        countrySelect,
        countryCreateSelect,
        showOptions,
        hiddenOptions,
        changeCreateCountry,
        changeCountry,
        deleteField,
        changeCountryCreate,
        deleteSection,
        createBankSection,
        isBankRule,
        isCreateCountry,
        createRuleForNewCountry,
        onRulesFetch: (response) => prepareRules(response, false),
    };
};

export default useTransferRules;
