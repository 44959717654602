import { apiCall } from "../../Middlewares";
import { ENDPOINTS } from "../../Config/env";

const fetchProviderPayment = async (id) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.LINK_PAYMENT.GET_PROVIDERS(id),
  });
};

const updateProviderPayment = async (idCountry, providerId, data) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.LINK_PAYMENT.UPDATE_PROVIDER_RECHARGE(
      idCountry,
      providerId
    ),
    data,
  });
};

export default {
  fetchProviderPayment,
  updateProviderPayment,
};
