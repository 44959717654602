import React from 'react';
import PropTypes from 'prop-types';
import useGetProvidersBalances from '../../../hooks/useGetProvidersBalances';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
    container: {
        maxWidth: 'calc(100vw - 334px)',
    },
    root: {
      width: '100%',
      marginTop: theme.spacing.unit * 3,
      overflowX: 'auto',
    },
    table: {
      minWidth: 700,
    },
    chip: {
        margin: theme.spacing.unit,
    },
    unsupportedProviders: {
        fontSize: 12, 
        color: '#FFFFFF', 
        paddingTop: 20, 
        paddingLeft: 10,
    },
    blackIcon: {
        color: '#000000',
    },
    chipsContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    balanceContainer: {
        display: 'flex', 
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 15,
        paddingBottom: 15,
        cursor: 'pointer',
    },
    circularProgress: {
        color: '#FFFFFF',
    },
    loadingContent: {
        height: 40,
        paddingLeft: 20,
        paddingRight: 10,
        backgroundColor: '#000000',
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5, 
        color: '#FFFFFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        marginLeft: -10,
        flexDirection: 'column',
    },
    flag: {
        fontSize: '4em',
    },
    flagContainer: { 
        borderTopLeftRadius: 20, 
        borderBottomLeftRadius: 20, 
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20, 
        overflow: 'hidden', 
        width: 40,
        height: 40,
        top: 0,
        marginTop: 0,
        borderTop: 0,
        paddingTop: 0,
        backgroundColor: '#FFFFFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
        border: '2px solid #FFFFFF',
    },
    longAmountContent: {
        height: 80,
        width: 320,
        paddingLeft: 20,
        paddingRight: 10,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5, 
        color: '#FFFFFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: -10,
        flexDirection: 'column',
    },
    amountContent: {
        height: 80,
        paddingLeft: 20,
        paddingRight: 10,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5, 
        color: '#FFFFFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: -10,
        flexDirection: 'column',
    },
    errorAmount: {
        backgroundColor: 'red',
        borderRadius: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 7,
    },
    amountCont: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    loadingContent: {
        height: 40,
        paddingLeft: 20,
        paddingRight: 10,
        backgroundColor: '#000000',
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5, 
        color: '#FFFFFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        marginLeft: -10,
        flexDirection: 'column',
    },
    balanceContainer: {
        display: 'flex', 
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 15,
        paddingBottom: 15,
        cursor: 'pointer',
    },
    unsupported: {
        display: 'flex', 
        flexDirection: 'row',
        marginLeft: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 15,
        paddingBottom: 15,
        opacity: 0.5,
        cursor: 'pointer',
    },
    errorContent: {
        height: 40,
        paddingLeft: 20,
        paddingRight: 10,
        backgroundColor: '#FF0000',
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5, 
        color: '#FFFFFF',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: -10,
    },
    title: {
        marginRight: '2em',
    },
    button: {
        border: '1px white solid',
    },
    loading: {
        color: '#FFFFFF',
    },
    refresh: {
        color: '#FFFFFF',
        fontSize: 18,
    },
    flagAndCurrency: {
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
    },
    delimiter: {
        paddingTop: 5,
    },
    providerName: {
        fontSize: 15,
        fontWeight: 'bold',
    },
    cryptoFlag: {
        color: '#FFFFFF',
        fontWeight: 'bold',
    },
    flagCryptoContainer: { 
        borderTopLeftRadius: 20, 
        borderBottomLeftRadius: 20, 
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20, 
        overflow: 'hidden', 
        width: 40,
        height: 40,
        top: 0,
        marginTop: 0,
        borderTop: 0,
        paddingTop: 0,
        backgroundColor: '#FFFFFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
        border: '2px solid #FFFFFF',
    },
    chipsContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    cardContainer: {
        display: 'flex', 
        flexDirection: 'column',
    },
    smallSize: {
        fontSize: 12,
    },
    mediumSize: {
        fontSize: 17,
    },
    w20: {
        width: 20,
    },
});

export const UnsupportedProviders = withStyles(styles)((props) => (
    props.isUnsupportedProviders && (
        <>
            <div className={props.classes.unsupportedProviders}>
                Proveedores no implementados
            </div>
            <div className={props.classes.chipsContainer}>
                {
                    props.unsupportedProviders.map((provider) => (
                        <div key={`UNSUPPORTED${provider}`}>
                            <Chip
                                avatar={
                                    <Avatar>
                                        <CloseIcon className={props.classes.blackIcon} />
                                    </Avatar>
                                }
                                label={provider}
                                className={props.classes.chip}
                            />
                        </div>
                    ))
                }
            </div>
        </>
    )
));

UnsupportedProviders.propTypes = {
    isUnsupportedProviders: PropTypes.bool,
    unsupportedProviders: PropTypes.array,
};

UnsupportedProviders.defaultProps = {
    isUnsupportedProviders: false,
    unsupportedProviders: [],
};

export const BalanceItem = withStyles(styles)((props) => (
    <div 
        className={props.classes.balanceContainer} 
        title={props.error} 
        onClick={props.reload}
    >
        {
            props.isLoading && !props.isStart && (
                <div className={props.classes.loadingContent}>
                    <CircularProgress 
                        size={25} 
                        className={props.classes.loading}
                    />
                </div>
            )
        }
        {
            props.isSuccessful && (
                <div className={
                        props.isLong ? 
                            props.classes.longAmountContent : 
                            props.classes.amountContent
                    }>
                    <div className={
                            !!props.error ? 
                                props.classes.errorAmount : 
                                props.classes.amountCont
                        }>
                        <div className={props.classes.cardContainer}>
                            <b className={props.classes.mediumSize}>
                                {props.balanceMoney}
                            </b>
                        </div>
                        <div className={props.classes.smallSize}>
                            {props.name}
                        </div>
                    </div>
                </div>
            )
        }
    </div>
));

BalanceItem.propTypes = {
    name: PropTypes.string,
    currency: PropTypes.string,
    balance: PropTypes.number,
    error: PropTypes.string,
    isLong: PropTypes.bool,
    isSuccessful: PropTypes.bool,
    reload: PropTypes.func,
    isLoading: PropTypes.bool,
    isStart: PropTypes.bool,
    balanceMoney: PropTypes.string,
};

BalanceItem.defaultProps = {
    name: '',
    currency: '',
    balance: 0,
    error: '',
    isLong: false,
    isSuccessful: false,
    reload: () => {},
    isLoading: false,
    isStart: false,
    balanceMoney: '',
};

export const ProviderList = withStyles(styles)((props) => (
    <>
        {
            props.isProviders && (
                <Paper className={props.classes.root}>
                    <Table className={props.classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={props.classes.w20}>
                                    Proveedor
                                </TableCell>
                                <TableCell align="right">
                                    Balances
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                props.providers.map((provider) => (
                                    <TableRow key={`PROVIDER${provider.name}`}>
                                        <TableCell 
                                            component="th" 
                                            scope="row" 
                                            className={props.classes.providerName}
                                        >
                                            {provider.name}
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                overflow: 'visible',
                                                flexWrap: 'wrap'
                                        }}>
                                            {
                                                provider.balances.map((balance) => (
                                                    <BalanceItem 
                                                        key={`${provider.name}${balance.name}`} 
                                                        {...balance}
                                                        isStart={props.isStart}
                                                        isLong={provider.isLong}
                                                    />
                                                ))
                                            }
                                            {
                                                provider.isLoading && (
                                                    <CircularProgress 
                                                        size={14} 
                                                        className={props.classes.loading}
                                                    />
                                                )
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </Paper>
            )
        }
    </>
));

ProviderList.propTypes = {
    providers: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            balances: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    currency: PropTypes.string,
                    balance: PropTypes.number,
                })
            ),
            isLong: PropTypes.bool,
        })
    ),
    isStart: PropTypes.bool,
    isProviders: PropTypes.bool,
    isLoading: PropTypes.bool,
};

ProviderList.defaultProps = {
    providers: [],
    isStart: false,
    isProviders: false,
    isLoading: false,
};

export const GetProvidersBalances = withStyles(styles)((props) => (
    <div className={props.classes.container}>
        <span className={props.classes.title}>
            Balances de los proveedores
        </span>
        <Button 
            title="Cargar"
            onClick={props.getProvidersBalances} 
            disabled={props.isDisabledButton} 
            className={props.classes.button}
        >
            {   
                props.isLoading && (
                    <CircularProgress 
                        size={14} 
                        className={props.classes.loading}
                    />
                )
            }
            {props.labelButton}
        </Button>
        <UnsupportedProviders
            isUnsupportedProviders={props.isUnsupportedProviders}
            unsupportedProviders={props.unsupportedProviders}
        />
        <ProviderList {...props} />
    </div>
));

GetProvidersBalances.propTypes = {
    unsupportedProviders: PropTypes.array,
    providers: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            balances: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    currency: PropTypes.string,
                    balance: PropTypes.number,
                })
            ),
        })
    ),
    labelButton: PropTypes.string,
    getProvidersBalances: PropTypes.func,
    isDisabledButton: PropTypes.bool,
    isUnsupportedProviders: PropTypes.bool,
    isLoading: PropTypes.bool,
};

GetProvidersBalances.defaultProps = {
    providers: [],
    unsupportedProviders: [],
    labelButton: '',
    getProvidersBalances: () => {},
    isDisabledButton: false,
    isUnsupportedProviders: false,
    isLoading: false,
};

const GetProvidersBalancesHoc = (props) => {
    const hook = useGetProvidersBalances(props);

    if (!hook.isAccess) {
        return null;
    }

    return <GetProvidersBalances {...hook} />;
};

export default GetProvidersBalancesHoc;