import React, { useState, useEffect } from "react";
import { TextField, Button, MenuItem, LinearProgress, Typography} from '@material-ui/core';
import { CountriesProvider } from '../../../../../Providers';
import styles from "./styles";
import useAlerts from "../../../../../hooks/useAlerts";
import { TO_NUMBER, toMoney } from "../../../../../Helpers/MoneyHelper";
import { Col, Row } from "react-grid-system";
import PropTypes from 'prop-types';

const AutomaticPaymentRoutesPanel = (props) => {
  const { country_id } = props;

  const [refreshAutomaticPaymentRoutes, setRefreshAutomaticPaymentRoutes] = useState(0);
  const [automaticPaymentRoutes, setAutomaticPaymentRoutes] = useState([]);
  const [countries, setCountries] = useState([]);
  const { errorMessage } = useAlerts();

  useEffect(() => {
    const refreshAutomaticPaymentRoutes = async() =>{
      const data = await retrieveRoutes(country_id);
      setAutomaticPaymentRoutes(data);
    }

    refreshAutomaticPaymentRoutes();
  }, [country_id, refreshAutomaticPaymentRoutes]);

  useEffect(() => {
    const refreshCountries = async () => {
      const data = await retrieveCountries();
      setCountries(data);
    }

    refreshCountries();
  }, []);

  const retrieveRoutes = async (countryId) => {
    try {
      const response = await CountriesProvider.retrieveAutomaticPaymentRoutes(countryId);
      return response.data?.data?.map((item) => item.attributes);
    } catch (error) {
      errorMessage(`Error retrieving the routes: ${error.data?.message}`);
    }
  };

  const retrieveCountries = async () => {
    try {
      const response = await CountriesProvider.fetchCountriesAdmin(true, '&q[is_transfer_eq]=true');
      return response.data?.countries?.data?.map((item) => {
        return {
          id: item.id,
          ...item.attributes
        }
      });
    } catch (error) {
      errorMessage(`Error retrieving the routes: ${error.data?.message}`);
    }
  };

  const AutomaticPaymentRouteItem = (props) => {
    const { id: automatic_payment_route_id, country_destiny, max_transaction_limit_amount, payment_processor } = props;
    const [countryDestinyId, setCountryDestinyId] = useState(country_destiny?.id?.toString());
    const [countryDestiny, setCountryDestiny] = useState(country_destiny);
    const [maxTransactionLimitAmount, setMaxTransactionLimitAmount] = useState(max_transaction_limit_amount);
    const [paymentProcessor, setPaymentProcessor] = useState(payment_processor);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      setCountryDestiny(countries.find((item) => item.id === countryDestinyId?.toString()));
    }, [countryDestinyId]);

    const createRoute = async (countryId, payload) => {
      setIsLoading(true);
      try {
        await CountriesProvider.createAutomaticPaymentRoute(countryId, payload);
        setRefreshAutomaticPaymentRoutes(1 - refreshAutomaticPaymentRoutes);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        errorMessage(`Error creating the route: ${error.data?.message}`);
      }
    };

    const updateRoute = async (countryId, automaticPaymentRouteId, payload) => {
      setIsLoading(true);
      try {
        await CountriesProvider.updateAutomaticPaymentRoute(countryId, automaticPaymentRouteId, payload);
        setIsLoading(false);
        setRefreshAutomaticPaymentRoutes(1 - refreshAutomaticPaymentRoutes);
      } catch (error) {
        setIsLoading(false);
        errorMessage(`Error updating the route: ${error.data?.message}`);
      }
    };

    const deleteRoute = async (countryId, automaticPaymentRouteId) => {
      setIsLoading(true);
      try {
        await CountriesProvider.deleteAutomaticPaymentRoute(countryId, automaticPaymentRouteId);
        setIsLoading(false);
        setRefreshAutomaticPaymentRoutes(1 - refreshAutomaticPaymentRoutes);
      } catch (error) {
        setIsLoading(false);
        errorMessage(`Error deleting the route: ${error.data?.message}`);
      }
    };

    const saveData = async () => {
      const payload = {
        max_transaction_limit_amount: maxTransactionLimitAmount,
        country_destiny_id: countryDestinyId,
        payment_processor: paymentProcessor
      }

      if (automatic_payment_route_id) {
        await updateRoute(country_id, automatic_payment_route_id, payload);
      } else {
        await createRoute(country_id, payload);
      }
    };

    return <>
      <Row container style={styles.itemContainer}>
        <Col item>
          <TextField
            label={'País'}
            value={countryDestinyId || 0}
            onChange={(e) => setCountryDestinyId(e.target.value)}
            select
            style={styles.fieldContainer}
          >
            {
              countries.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                )
              })
            }
          </TextField>
        </Col>
        <Col item>
          <TextField
            label={`Monto máximo por transacción (${countryDestiny?.currency_iso_code || 'USD'})`}
            value={toMoney(maxTransactionLimitAmount, countryDestiny?.currency_iso_code || 'USD')}
            onChange={(e) => setMaxTransactionLimitAmount(TO_NUMBER(e.target.value || '0', countryDestiny?.currency_iso_code || 'USD'))}
            style={styles.fieldContainer}
          />
        </Col>
        <Col item>
          <TextField
            label={'Proveedor'}
            value={paymentProcessor || ''}
            onChange={(e) => setPaymentProcessor(e.target.value)}
            select
            style={styles.fieldContainer}
          >
            {
              countryDestiny?.payment_processors?.map((item) => {
                return (
                  <MenuItem key={item} value={item} style={{ justifyContent: 'center' }}>
                    {item?.toUpperCase()}
                  </MenuItem>
                );
              })
            }
          </TextField>
        </Col>
        <Col item>
          <Button
            onClick={() => saveData()}
            variant="contained"
            style={styles.saveButton}
          >
            Guardar
          </Button>
          {automatic_payment_route_id && (
            <Button
              onClick={() => deleteRoute(country_id, automatic_payment_route_id)}
              variant="contained"
              style={styles.deleteButton}
            >
              Eliminar
            </Button>
          )}
        </Col>
      </Row>
      {
        isLoading && (
          <Row container style={{ width: '75%', alignContent: 'center' }} >
            <Col>
              <LinearProgress color="secondary" />
            </Col>
          </Row>
        )
      }
    </>
  }

  AutomaticPaymentRouteItem.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    country_destiny: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      currency_iso_code: PropTypes.string,
      payment_processors: PropTypes.arrayOf(PropTypes.string),
    }),
    max_transaction_limit_amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    payment_processor: PropTypes.string,
  };

  const AutomaticPaymentLimitPanel = (props) => {
    const { country_id, record: { attributes: { currency_iso_code } } } = props;

    const [beneficiaryMaxMonthlyLimitAmount, setBeneficiaryMaxMonthlyLimitAmount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const retrieveBeneficairyMaxMonthlyLimitAmount = async (countryId) => {
      setIsLoading(true);
      try {
        const response = await CountriesProvider.retrieveBeneficairyMaxMonthlyLimitAmount(countryId);
        setIsLoading(false);
        return response.data?.automatic_payment_beneficiary_max_monthly_limit_amount || 0;
      } catch (error) {
        setIsLoading(false);
        errorMessage(`Error retrieving the beneficiary max monthly limit amount: ${error.data?.message}`);
      }
    };

    const updateBeneficairyMaxMonthlyLimitAmount = async (countryId) => {
      setIsLoading(true);
      try {
        const payload = { automatic_payment_beneficiary_max_monthly_limit_amount: beneficiaryMaxMonthlyLimitAmount };
        await CountriesProvider.updateBeneficairyMaxMonthlyLimitAmount(countryId, payload);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        errorMessage(`Error updating the beneficiary max monthly limit amount: ${error.data?.message}`);
      }
    };

    useEffect(() => {
      const refreshBeneficiaryMaxMonthlyLimitAmount = async () => {
        const data = await retrieveBeneficairyMaxMonthlyLimitAmount(country_id);
        setBeneficiaryMaxMonthlyLimitAmount(data);
      }

      refreshBeneficiaryMaxMonthlyLimitAmount();
    }, []);

    return <>
      <Row container style={{...styles.itemContainer, width: '50%'}}>
        <Col item>
          <TextField
            label={`Monto límite máximo mensual por beneficiario (${ currency_iso_code || 'USD' })`}
            value={toMoney(beneficiaryMaxMonthlyLimitAmount || 0, (currency_iso_code || 'USD'))}
            onChange={(e) => setBeneficiaryMaxMonthlyLimitAmount(TO_NUMBER(e.target.value, (currency_iso_code || 'USD')))}
            style={{...styles.fieldContainer, width: '400px'}}
          />
        </Col>
        <Col item>
          <Button
            onClick={() => updateBeneficairyMaxMonthlyLimitAmount(country_id)}
            variant="contained"
            style={styles.saveButton}
          >
            Guardar
          </Button>
        </Col>
      </Row>
      {
        isLoading && (
          <Row container style={{ width: '75%', alignContent: 'center', marginTop: '10px'}} >
            <Col item>
              <LinearProgress color="secondary" />
            </Col>
          </Row>
        )
      }
    </>
  };

  AutomaticPaymentLimitPanel.propTypes = {
    country_id: PropTypes.number.isRequired,
    record: PropTypes.shape({
      attributes: PropTypes.shape({
        currency_iso_code: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  };

  return <div>
    <div style={styles.itemGroupContainer}>
      <Typography>Límites</Typography>
      <AutomaticPaymentLimitPanel {...props}/>
    </div>
    <div style={styles.itemGroupContainer}>
      <Typography>Agregar ruta</Typography>
      <AutomaticPaymentRouteItem />
    </div>
    <div style={styles.itemGroupContainer}>
      <Typography>Rutas activas</Typography>
      {
        automaticPaymentRoutes.map((item) => <AutomaticPaymentRouteItem key={item.id} {...item} />)
      }
    </div>
  </div>;
}

AutomaticPaymentRoutesPanel.propTypes = {
  country_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default AutomaticPaymentRoutesPanel;