import useAlerts from './useAlerts';
import { downloadFromUrl, isImageFromUrl } from '../Utils';

const useUserLimitRequestDownload = (props = {}) => {
    const {
        full_name = '',
        address_proof = '',
        income_verification = '',
    } = props;
    const { showHTML } = useAlerts();

    const onAddressProofDownload = () => {
        downloadFromUrl(address_proof, `comprobacion_residencia_${full_name}-${Date.now()}`);
    };

    const onAddressProofView = async () => {
        if (isImageFromUrl(address_proof)) {
            showHTML({ html: `<img style="width: 100%;" src="${address_proof}" />`, width: '70%' });
        } else {
            onAddressProofDownload();
        }
    };

    const onIncomeVerificationView = async () => {
        if (isImageFromUrl(income_verification)) {
            showHTML({ html: `<img style="width: 100%;" src="${income_verification}" />`, width: '70%' });
        } else {
            onIncomeVerificationDownload();
        }
    };

    const onIncomeVerificationDownload = () => {
        downloadFromUrl(income_verification, `comprobacion_ingresos_${full_name}-${Date.now()}`);
    };

    return {
        onAddressProofDownload,
        onIncomeVerificationDownload,
        onIncomeVerificationView,
        onAddressProofView,
    }
};

export default useUserLimitRequestDownload;