import { GET_ONE } from 'react-admin';

const responseHandler = (response, type, resource) => {
  
  switch (type) {
    case GET_ONE: {
      return {
        data: response.data.marketing_message.data[0],
      };
    }
    default: {
      return {
        data: response.data.marketing_message.data,
        total: response.data['total'],
      }
    }
  }
};

export default responseHandler;