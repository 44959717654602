import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
} from "@material-ui/core";
import { TransactionProvider } from "../../../Providers";
import Swal from "sweetalert2";
import styles from "./styles";
import { TO_NUMBER, toMoney } from "../../../Helpers/MoneyHelper";
import BtcBalanceAdmin from "../../../Components/BtcBalanceAdmin";
import binanceProvider from "../../../Providers/Binance";
import UsdcWithdrawalsProvider from "../../../Providers/UsdcWithdrawals";
import { TabbedForm, FormTab } from "react-admin";
import useAccessRole from '../../../hooks/useAccessRole';

const BusinessesCreate = (props) => {
  const { history } = props;
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState(0);
  const [fee, setFee] = useState(0);
  const [network, setNetwork] = useState("");
  const [binanceNetworks, setBinanceNetworks] = useState([]);
  const {isAccess} = useAccessRole();

  const showError = () => {
    NotificationManager.error(
      "Ha ocurrido un error, por favor intente mas tarde",
      "",
      5000,
      () => {}
    );
  };

  const send = async () => {
    try {
      await TransactionProvider.bitcoinWithdrawal({
        to_address: address,
        recipient_email: email,
        total: amount,
        description: description,
        mining_fee: fee,
        network_id: network.id,
      });
      history.push("/transacciones");
    } catch (e) {
      showError();
    }

    setEmail("");
    setFee(0);
    setDescription("");
    setAddress("");
    setAmount(0);
  };

  useEffect(() => {
    getBinanceNetworks('BTC');
  }, []);

  const binanceSend = () => {
    Swal.fire({
      title: "Estás seguro?",
      text: "Recuerda que esta transacción es irreversible, los envíos en crypto a billeteras externas no podrán ser devueltos bajo ninguna circunstancia a la cuenta de origen.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await binanceProvider.binanceWithdrawal({
            address: address,
            amount: amount,
            description: description,
            network_id: network.id,
            currency: "usdt",
          });

          Swal.fire(
            "Exito!",
            `Retiro exitoso. Esta operación puede tomar unos minutos. Por favor revisar el correo para confirmación.`,
            "success"
          );

          setAddress("");
          setDescription("");
          setAmount(0);
          setNetwork("");
        } catch (error) {
          Swal.fire("Error!", `${error.data.message}`, "error");
        }
      }
    });
  };

  const circleSend = () => {
    Swal.fire({
      title: "Estás seguro?",
      text: "Recuerda que esta transacción es irreversible, los envíos en crypto a billeteras externas no podrán ser devueltos bajo ninguna circunstancia a la cuenta de origen.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await UsdcWithdrawalsProvider.circleWithdrawal({
            address: address,
            amount: amount,
            description: description,
            network_id: network.id,
            currency: "usdc",
          });

          Swal.fire(
            "Exito!",
            `Retiro exitoso. Esta operación puede tomar unos minutos. Por favor revisar el correo para confirmación.`,
            "success"
          );

          setAddress("");
          setDescription("");
          setAmount(0);
          setNetwork("");
        } catch (error) {
          Swal.fire("Error!", `${error.data.message}`, "error");
        }
      }
    });
  };

  const formatValue = (value) => toMoney(value, "BTC");
  const binanceFormatValue = (value) => toMoney(value, "USDT");
  const circleFormatValue = (value) => toMoney(value, "USDC");

  const parseValue = (value) => TO_NUMBER(value, "BTC");

  const changeAmount = (event) => {
    setAmount(parseValue(event.target.value));
  };

  const binanceChangeAmount = (event) => {
    setAmount(parseValue(event.target.value));
  };

  const changeFee = (event) => {
    setFee(parseValue(event.target.value));
  };

  const changeEmail = (event) => {
    setEmail(event.target.value);
  };

  const changeAddress = (event) => {
    setAddress(event.target.value);
  };

  const binanceChangeAddress = (event) => {
    setAddress(event.target.value);
  };

  const changeDescription = (event) => {
    setDescription(event.target.value);
  };

  const binanceChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const isEmailValid = () => {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  };

  const validateAmount = () => {
    if (fee < 0.00005 || fee > 0.01) return false;

    if (!(amount > 0)) return false;

    if (!(address && description)) return false;

    if (email) {
      if (!isEmailValid()) {
        return false;
      }
    }

    return true;
  };

  const binanceValidateAmount = () => {
    if (network) {
      let address_regex = network.attributes.address_regex;
      let withdraw_min = Number(network.attributes.withdraw_min);
      let withdraw_max = Number(network.attributes.withdraw_max);
      let regex = new RegExp(address_regex);

      if (amount > withdraw_max || amount < withdraw_min) return false;
      if (!regex.test(address)) return false;
      return true;
    }

    return false;
  };

  const renderValidationMessage = () => {
    if (fee < 0.00005 || fee > 0.01)
      return "La comisión de minado debe estar en el rango (0,00005 - 0,01)";

    if (!(amount > 0)) return "Ingrese el monto a enviar";

    if (!(address && description)) return "Ingrese los campos solicitados";

    if (email && !isEmailValid()) return "El email es invalido";

    return "";
  };

  const binanceRenderValidationMessage = () => {
    if (network) {
      let address_regex = network.attributes.address_regex;
      let withdraw_min = Number(network.attributes.withdraw_min);
      let withdraw_max = Number(network.attributes.withdraw_max);
      let regex = new RegExp(address_regex);

      if (amount > withdraw_max || amount < withdraw_min)
        return `El monto debe estar entre ${withdraw_min} y ${withdraw_max}`;
      if (!regex.test(address)) return "La dirección no es admitida por la red";
      return "";
    }
  };

  const getBinanceNetworks = async (currency) => {
    let response = await binanceProvider.getBinanceNetworks(currency);
    let data = response.data.data;
    data = data.map((network) => {
      return {
        ...network,
        id: network.id,
        name: network.attributes.name,
      };
    });

    setBinanceNetworks(data.filter((item) => item['attributes']['withdraw_fee'] !== null));
  };

  const binanceChangeNetwork = (event) => {
    let network = binanceNetworks.find(
      (item) => item.name === event.target.value
    );
    setNetwork(network);
  };

  return (
    <TabbedForm toolbar={<></>}>
      {isAccess('show_withdraw_btc') && (
        <FormTab onClick={() => getBinanceNetworks('BTC')} label="Retiro Bitcoin" style={{ color: "#FFFFFF" }}>
          <Card
            style={{
              borderRadius: 30,
              border: "3px solid white",
              margin: 50,
              width: "92%",
            }}
          >
            <NotificationContainer />

            <CardContent
              style={{
                margin: 50,
                marginRight: "30%",
                marginLeft: "30%",
              }}
            >
              <Grid container className={styles.container}>
                <Grid
                  item
                  xs={12}
                  style={{ ...styles.header, fontWeight: "bold" }}
                >
                  SOLICITUD DE RETIRO DE BITCOIN
                </Grid>
                {!network && <label style={{ color: "#FFFF" }}>Red</label>}
                <Select
                  value={network.name}
                  placeholder={"Red"}
                  onChange={binanceChangeNetwork}
                >
                  {binanceNetworks.length !== 0 &&
                    binanceNetworks.map((network) => {
                      return (
                        <MenuItem
                          value={network.name}
                        >{`${network.name} ${network.attributes.withdraw_fee}`}</MenuItem>
                      );
                    })}
                </Select>
                <Grid item xs={12} style={styles.boxCenter}>
                  <TextField
                    label="Monto"
                    value={formatValue(amount)}
                    onChange={changeAmount}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} style={styles.boxCenter}>
                  <TextField
                    label="Comisión de minado"
                    value={formatValue(fee)}
                    onChange={changeFee}
                    fullWidth
                  />
                  <div style={{ color: "#FFFFFF", fontSize: 12 }}>
                    rango 0,00005 - 0,01
                  </div>
                </Grid>
                <Grid item xs={12} style={styles.boxCenter}>
                  <TextField
                    label="Email (opcional)"
                    value={email}
                    onChange={changeEmail}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} style={styles.boxCenter}>
                  <TextField
                    label="Dirección"
                    value={address}
                    onChange={changeAddress}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} style={styles.boxCenter}>
                  <TextField
                    label="Descripción"
                    value={description}
                    onChange={changeDescription}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: "center",
                    paddingTop: 10,
                    fontSize: 15,
                    color: "#FFFFFF",
                    fontWeight: "bold",
                  }}
                >
                  TOTAL A RETIRAR
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    ...styles.boxCenter,
                    fontSize: 20,
                    color: "#FFFFFF",
                  }}
                >
                  {formatValue(fee + amount)} BTC
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    color: "#ff7f7f",
                    fontWeight: "bold",
                  }}
                >
                  {renderValidationMessage()}
                </Grid>
                <Grid item xs={12} style={styles.header}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={send}
                    disabled={!validateAmount()}
                  >
                    <div style={{ color: "#000000" }}>RETIRAR BITCOIN</div>
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
            <BtcBalanceAdmin />
          </Card>
        </FormTab>
      )}
      {isAccess('show_withdraw_binance') && (
        <FormTab onClick={() => getBinanceNetworks('USDT')} label="Retiro Binance" style={{ color: "#FFFFFF" }}>
          <Card
            style={{
              borderRadius: 30,
              border: "3px solid white",
              margin: 50,
              width: "92%",
            }}
          >
            <CardContent
              style={{
                margin: 50,
                marginRight: "30%",
                marginLeft: "30%",
              }}
            >
              <Grid container className={styles.container}>
                <Grid
                  item
                  xs={12}
                  style={{ ...styles.header, fontWeight: "bold" }}
                >
                  SOLICITUD DE RETIRO DE BINANCE
                </Grid>
                {!network && <label style={{ color: "#FFFF" }}>Red</label>}
                <Select
                  value={network.name}
                  placeholder={"Red"}
                  onChange={binanceChangeNetwork}
                >
                  {binanceNetworks.length !== 0 &&
                    binanceNetworks.map((network) => {
                      return (
                        <MenuItem
                          value={network.name}
                        >{`${network.name} ${network.attributes.withdraw_fee}`}</MenuItem>
                      );
                    })}
                </Select>
                <Grid item xs={12} style={styles.boxCenter}>
                  <TextField
                    label="Monto"
                    value={binanceFormatValue(amount)}
                    onChange={binanceChangeAmount}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} style={styles.boxCenter}>
                  <TextField
                    label="Dirección"
                    value={address}
                    onChange={binanceChangeAddress}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} style={styles.boxCenter}>
                  <TextField
                    label="Descripción"
                    value={description}
                    onChange={binanceChangeDescription}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: "center",
                    paddingTop: 10,
                    fontSize: 15,
                    color: "#FFFFFF",
                    fontWeight: "bold",
                  }}
                >
                  {network && amount
                    ? `TOTAL A RETIRAR: ${binanceFormatValue(amount)} USDT`
                    : ""}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: "center",
                    paddingTop: 10,
                    fontSize: 15,
                    color: "#FFFFFF",
                    fontWeight: "bold",
                  }}
                >
                  {network
                    ? `COSTO DE RED: ${binanceFormatValue(
                        network.attributes.withdraw_fee
                      )} USDT`
                    : ""}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: "center",
                    paddingTop: 10,
                    fontSize: 15,
                    color: "#FFFFFF",
                    fontWeight: "bold",
                  }}
                >
                  {network && amount
                    ? `TOTAL A RECIBIR: ${binanceFormatValue(
                        amount - network.attributes.withdraw_fee
                      )} USDT`
                    : ""}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    color: "#ff7f7f",
                    fontWeight: "bold",
                  }}
                >
                  {binanceRenderValidationMessage()}
                </Grid>
                <Grid item xs={12} style={styles.header}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={binanceSend}
                    disabled={!binanceValidateAmount()}
                  >
                    <div style={{ color: "#000000" }}>RETIRAR USDT</div>
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </FormTab>
      )}
      {isAccess('show_withdraw_circle') && (
        <FormTab onClick={() => getBinanceNetworks('USDC')} label="Retiro Circle" style={{ color: "#FFFFFF" }}>
          <Card
            style={{
              borderRadius: 30,
              border: "3px solid white",
              margin: 50,
              width: "92%",
            }}
          >
            <CardContent
              style={{
                margin: 50,
                marginRight: "30%",
                marginLeft: "30%",
              }}
            >
              <Grid container className={styles.container}>
                <Grid
                  item
                  xs={12}
                  style={{ ...styles.header, fontWeight: "bold" }}
                >
                  SOLICITUD DE RETIRO DE CIRCLE
                </Grid>
                {!network && <label style={{ color: "#FFFF" }}>Red</label>}
                <Select
                  value={network.name}
                  placeholder={"Red"}
                  onChange={binanceChangeNetwork}
                >
                  {binanceNetworks.length !== 0 &&
                    binanceNetworks.map((network) => {
                      return (
                        <MenuItem
                          value={network.name}
                        >{`${network.name} ${network.attributes.withdraw_fee}`}</MenuItem>
                      );
                    })}
                </Select>
                <Grid item xs={12} style={styles.boxCenter}>
                  <TextField
                    label="Monto"
                    value={circleFormatValue(amount)}
                    onChange={binanceChangeAmount}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} style={styles.boxCenter}>
                  <TextField
                    label="Dirección"
                    value={address}
                    onChange={binanceChangeAddress}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} style={styles.boxCenter}>
                  <TextField
                    label="Descripción"
                    value={description}
                    onChange={binanceChangeDescription}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: "center",
                    paddingTop: 10,
                    fontSize: 15,
                    color: "#FFFFFF",
                    fontWeight: "bold",
                  }}
                >
                  {network && amount
                    ? `TOTAL A RETIRAR: ${circleFormatValue(amount)} USDC`
                    : ""}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: "center",
                    paddingTop: 10,
                    fontSize: 15,
                    color: "#FFFFFF",
                    fontWeight: "bold",
                  }}
                >
                  {network && amount
                    ? `TOTAL A RECIBIR: ${circleFormatValue(
                        amount
                      )} USDC`
                    : ""}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    color: "#ff7f7f",
                    fontWeight: "bold",
                  }}
                >
                  {binanceRenderValidationMessage()}
                </Grid>
                <Grid item xs={12} style={styles.header}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={circleSend}
                    disabled={!binanceValidateAmount()}
                  >
                    <div style={{ color: "#000000" }}>RETIRAR USDC</div>
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </FormTab>
      )}
    </TabbedForm>
  );
};

BusinessesCreate.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default BusinessesCreate;
