import { apiCall } from "../../Middlewares";
import { ENDPOINTS } from "../../Config/env";

const fetchPixes = async () =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.PIX.GET_PIX_PROVIDERS,
  });

const createPix = async (data) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.PIX.CREATE_PIX_PROVIDER,
    data,
  });

const deletePix = async (id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.PIX.DELETE_PIX_PROVIDER(id),
    id,
  });

const returnWithdrawal = async (data) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.PIX.RETURN_WITHDRAWAL,
    data,
  });

export default {
  fetchPixes,
  createPix,
  deletePix,
  returnWithdrawal,
};
