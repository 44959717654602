import React                              from 'react';
import { Filter, SelectInput, TextInput } from 'react-admin';

import { payments_types } from '../../../../Config/constants';
import useClearFilters from '../../../../hooks/useClearFilters';

const Filters = (props) => {
  useClearFilters(props.setFilters);

  return(
    <Filter {...props}>
      <TextInput
          label={'Id'}
          source={'id'}
          alwaysOn
      />
      <TextInput
          label={'Retiro'}
          source={'transaction_id'}
          alwaysOn
      />
      <SelectInput
          label={'Tipo de pago'}
          source={'payment_type'}
          choices={payments_types}
      />
    </Filter>
)};

export default Filters;
