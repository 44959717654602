import {GET_ONE, UPDATE, CREATE, DELETE} from 'react-admin';

const responseHandler = (response, type, resource) => {
    switch (type) {
        case CREATE: {
            return {
                data: response.data.data,
            }
        }
        case UPDATE: {
            return {
                data: response.data.data,
            }
        }
        case GET_ONE: {
            const card_account = response.data.card_accounts.data[0];

            return {
                data: {
                    ...card_account,
                    attributes: {
                        ...card_account.attributes,
                        state_id: card_account.attributes.state_id ?
                            card_account.attributes.state_id.toString() :
                            null,
                    }
                },
            };
        }
        case DELETE: {
            return {
                data: null,
            };
        }
        default: {
            return {
                data: response.data.card_accounts.data,
                total: response.data['total'],
            };
        }
    }
};

export default responseHandler;
