const styles = {
  titleWhite: {
    color: '#F2F2F2',
  },
  buttonDisabled: {
    backgroundColor: '#63666b',
    color: '#000000',
  },
  buttonRed: {
    backgroundColor: '#FF0000',
    color: '#FFFFFF',
  },
  buttonBlue: {
    backgroundColor: '#4887de',
    color: '#000000',
  },
  buttonGreen: {
    backgroundColor: '#1ade9c',
    color: '#000000',
  },
  paddingButtonTop: {
    paddingTop: '20px',
    paddingBottom: '20px'
  }
};

export default styles;
