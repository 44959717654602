import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from "@material-ui/core";
import { toMoneyWithDecimals } from '../../../../Helpers/MoneyHelper';

const RenderBalances = (props) => {
  const {
    currencyName,
    balances
  } = props;

  const capitalize = (s) => {
    return s.toLowerCase().replace(/_/g, ' ').replace(/\b./g, (x) => x.toUpperCase());
  };

  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "row",
        color: "white",
        backgroundColor: '#424242',
        paddingTop: '2%',
        paddingLeft: '3%',
        paddingBottom: '1%',
        borderBottom: '1px solid rgba(50, 50, 50, 1)'
      }}
    >
      {
        Object.entries(balances).map((item) => {
          return (
            <div style={{marginRight: 30}}>
              <strong>Balance {capitalize(item[0])} {currencyName}:<br/>
              </strong>{toMoneyWithDecimals(item[1], 8)}
            </div>
          )
        })
      }
    </Grid>
  );
}

RenderBalances.defaultProps = {
  currencyName: '',
  balances: {},
};

RenderBalances.propTypes = {
  currencyName: PropTypes.string.isRequired,
  balances: PropTypes.object.isRequired
};
 
export default RenderBalances;