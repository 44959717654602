import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import Button from "@material-ui/core/Button";

import { UserFilter } from '../Filters';
import { Pagination } from '../../../Components';
// import useModal from '../../../hooks/useModal';
// import ModalExport from '../../../Components/ModalExport';
import Photo from '../../../Components/Photo';
import UserInfo from '../CustomComponents/UserInfo';
import BalanceInfo from '../CustomComponents/BalanceInfo';
import UserRules from '../CustomComponents/UserRules';
import UserButtons from '../CustomComponents/UserButtons';
import useAccessRole from '../../../hooks/useAccessRole';
import UserProvider from '../../../Providers/Users';
import { useAlerts } from '../../../hooks';

const UserList = (props) => {
	const { isAccess } = useAccessRole();
	const { confirmationModal } = useAlerts();
	// const { open, showModal, hiddeModal } = useModal();

	const getBalanceFiles = async () => {
		try {
			const { data: {message} } = await UserProvider.getBalancesFile();
			confirmationModal({title: message});
		} catch {
			confirmationModal({ title: 'Error en la descarga', icon: "error" });
		}
	};

	return (
		<>
			{isAccess('download_balances_users') && (
				<div style={{ position: 'absolute', right: 50, zIndex: 1, top: 135 }}>
					<Button
						variant="contained"
						color="primary"
						style={{ backgroundColor: "#00b7ff", right: '0', height: 30, width: 300 }}
						onClick={getBalanceFiles}
					>
							Descargar balances de usuarios
					</Button>
				</div>
			)}
			<List
				{...props}
				title={'Lista de usuarios'}
				filters={<UserFilter />}
				exporter={isAccess('download_users') ? () => {} : false}
				pagination={<Pagination />}
				bulkActionButtons={false}
				debounce={1000}
			>
				<Datagrid>
					<TextField
						source={'id'}
						label={'Id'}
					/>
					<Photo />
					<UserInfo />
					<UserRules />
					<BalanceInfo {...props} />
					<UserButtons {...props} />
					{/*

                    <VerificationUserButton
                        {...props}
                        label={'Verificación'}
                    />
                    <TransactionsView
                        {...props}
                        label={'Transacciones'}
                    />


                    */
					}
				</Datagrid>
			</List>
			{/* <ModalExport
				open={open}
				hiddeModal={hiddeModal}
			/> */}
		</>

	)
};

export default UserList;