import { apiCall } from '../../Middlewares';
import { ENDPOINTS } from '../../Config/env';

const updateReport = async (data) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BUSINESSES.UPDATE_REPORT,
        data,
    });
};

const updateEncryptReport = async (data) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BUSINESSES.UPDATE_ENCRYPT_REPORT,
        data,
    });
};

const updateMonthlyReport = async (data) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BUSINESSES.UPDATE_MONTHLY_REPORT,
        data,
    });
};

const updateEncryptMonthlyReport = async (data) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BUSINESSES.UPDATE_ENCRYPT_MONTHLY_REPORT,
        data,
    });
};

const createBusiness = async (email) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BUSINESSES.CREATE_BUSINESS,
        data: { email }
    });
};

const cancelBusiness = async (email) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BUSINESSES.CANCEL_BUSINESS,
        data: { email }
    });
};

const addIp = async (business, ip) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BUSINESSES.ADD_IP,
        data: { business, ip }
    });
};

const cancelIp = async (business, ip) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BUSINESSES.CANCEL_IP,
        data: { business, ip }
    });
};

const assingIp = async (business, ip) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BUSINESSES.ASSIGN_IP,
        data: { business, ip }
    });
};

const addBalance = async (email, currency, total, business, description) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BUSINESSES.ADD_BALANCE,
        data: { email, currency, total, business, description }
    });
};

const updateRules = async (rules, business) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BUSINESSES.UPDATE_RULES,
        data: { ...rules, business }
    });
};

const getBusiness = async () => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BUSINESSES.GET_BUSINESS,
    });
};

const updatePaymentOrdersServiceStatus = async (data, user_id) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.USERS.UPDATE_INFO_PAYMENT_LINK_USER_BUSINESS(user_id),
        data,
    })
}

const updateFinalCustomerServiceStatus = async (data) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BUSINESSES.UPDATE_FINAL_CUSTOMER_SERVICE_STATUS,
        data,
    })
}

export default {
    getBusiness,
    createBusiness,
    cancelBusiness,
    addIp,
    updateRules,
    cancelIp,
    assingIp,
    addBalance,
    updateReport,
    updateEncryptReport,
    updateMonthlyReport,
    updateEncryptMonthlyReport,
    updatePaymentOrdersServiceStatus,
    updateFinalCustomerServiceStatus
}
