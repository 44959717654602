import {useMemo, useCallback} from 'react';
import useAlerts from './useAlerts';

const useRequest = (props = {}) => {
    const limit = 100;
    const {type = "request", record = {}} = props;
    const {attributes = {}} = record;
    const {request_and_response = [{}]} = attributes;

    const {successfulCopy} = useAlerts();

    const text = useMemo(() => {
        return JSON.stringify(request_and_response?.[0]?.[type] || {});
    }, [request_and_response, type]);

    const value = useMemo(() => {
        return text.length >= limit ? `${text.substring(0, limit)}...` : text;
    }, [text, limit]);

    const isValidValue = useMemo(() => {
        return text.length >= limit;
    }, [text, limit]);

    const onCopy = useCallback(() => {
        navigator.clipboard.writeText(text);
        successfulCopy();
    }, []);

    return {
        value,
        type,
        isValidValue,
        onCopy,
    };
};

export default useRequest;