import React                    from 'react';
import { SaveButton, Toolbar }  from 'react-admin';

const PostEditToolbar = props => {
    const {hidden} = props;
    return (
        <Toolbar {...props} >
            {!hidden && (<SaveButton />)}
        </Toolbar>
    )
};

export default PostEditToolbar;
