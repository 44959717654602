import { Accessibility } from '@material-ui/icons';

import BankAccountAccessesList from './BankAccountAccessesList';
import BankAccountAccessesCreate from './BankAccountAccessesCreate';
import BankAccountAccessesEdit from './BankAccountAccessesEdit';
import useAccessRole from '../../hooks/useAccessRole';

const {isAccess} = useAccessRole();

export default {
    name: 'bank_account_accesses',
    icon: Accessibility,
    list: isAccess('show_bank_account_accesses') ? BankAccountAccessesList : false,
    create: isAccess('create_bank_account_access') ? BankAccountAccessesCreate : false,
    edit: isAccess('update_bank_account_access') ? BankAccountAccessesEdit : false,
}
