import { Person }  from '@material-ui/icons';

import UserList  from './UserList';
import UserShow  from './UserShow';
import UserEdit  from './UserEdit';
import useAccessRole from '../../hooks/useAccessRole';

const {isAccess} = useAccessRole();

export default {
    name: 'usuarios',
    icon: Person,
    list: UserList,
    show: UserShow,
    edit: isAccess('edit_users') ? UserEdit : false,
}