import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, TablePagination } from '@material-ui/core';

import useAlerts from '../../../hooks/useAlerts';
import Comment from './components/Comment';

const ListComment = (props) => {
  const { service, record: { id } } = props;
  const [comments, setComments] = useState([]);
  const [loading, isLoading] = useState(true);
  const [pagination, setPagination] = useState({
    page: 0,
    count: 5,
  });
  const [total, setTotal] = useState(0);

  const {errorMessage} = useAlerts();

  const onChangePerPage = event => {
    setPagination(prev => ({
      ...prev,
      count: event.target.value,
    }));
  };

  const onChangePage = (e, page) => {
    e.preventDefault();
    setPagination(prev => ({
      ...prev,
      page,
    }));
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await service(id, {
          page: parseInt(pagination.page) + 1,
          count: pagination.count,
        });
        setComments(response.data.data);
        setTotal(response.data.total)
        isLoading(false);
      } catch (error) {
        isLoading(false);
        errorMessage(error.message);
      }
    })();
  }, [pagination]);

  return (
    <div className='comments-container'>
      {
        comments.length > 0 && (
          <>
            {
              comments.map(comment => <Comment {...comment} />)
            }
            <TablePagination
              component="div"
              count={total}
              rowsPerPage={pagination.count}
              page={pagination.page}
              labelRowsPerPage={'Per page'}
              rowsPerPageOptions={[5, 10, 15]}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangePerPage}
            />
          </>
        )
      }
      {
        comments.length < 1 && !loading && (
          <h4 style={{ color: 'white', textAlign: 'center' }}>No existen comentarios para la transacción</h4>
        )
      }
      {
        loading && <LinearProgress size={100} color={'secondary'} />
      }
    </div>
  );
};

ListComment.propTypes = {
  service: PropTypes.func.isRequired,
};

export default ListComment;