import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import { Grid } from "@material-ui/core";

import { toMoney } from "../../../../../../Helpers/MoneyHelper";

moment.locale("es");
const SecondColumn = (props) => {
  const {
    description,
    network,
    total,
    total_fee
  } = props;

  return (
    <>
      <Grid style={{ padding: '7%' }} >
        <div style={{ fontSize: 20, fontWeight: "bold", marginButtom: '5px' }}>{"Total"}</div>
        <div style={{ fontSize: 12 }}>{toMoney(total, "USDC")}</div>
        <div style={{ fontSize: 20, fontWeight: "bold", marginTop: '10px', marginButtom: '5px' }}>{"Total fee"}</div>
        <div style={{ fontSize: 12 }}>{toMoney(total_fee, "USDC")}</div>
      </Grid>
      <Grid style={{ padding: '7%' }} >
        <div style={{ fontSize: 20, fontWeight: "bold" }}>
          Descripción
        </div>
        <div style={{ fontSize: 12 }}>{description}</div>
        <div style={{ fontSize: 20, fontWeight: "bold", marginTop: 24 }}>
          Red
        </div>
        <div style={{ fontSize: 12 }}>{network}</div>
      </Grid>
    </>
  );
}

SecondColumn.defaultProps = {
  description: '',
  isLocked: false,
  total: 0,
  total_fee: 0,
};

SecondColumn.propTypes = {
  description: PropTypes.string.isRequired,
  network: PropTypes.string,
  total: PropTypes.number.isRequired,
  total_fee: PropTypes.number.isRequired,
};

export default SecondColumn;