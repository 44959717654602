import { apiCall } from '../../Middlewares';
import { ENDPOINTS } from '../../Config/env';

const getListPermissions = async () =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.PERMISSIONS.GET_PERMISSIONS,
  });

export default {
  getListPermissions,
};