import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-grid-system';
import { Card } from '@material-ui/core';
import { BooleanInput, FormDataConsumer, TextInput, NumberInput } from 'react-admin';

const WireCreator = (props) => (
  <>
    {
      props.record.attributes.is_wire_configuration_create && (
        <Container style={styles.container}>
          <BooleanInput
            source={'attributes.is_wire_configuration'}
            label={'Envío WIRE'}
          />
          <FormDataConsumer>
            {({ formData }) => formData.attributes.is_wire_configuration && (
              <Card>
                <Container style={styles.container}>
                  <Row>
                    <Col xs={3}>
                      <NumberInput
                        source={'attributes.wire_configuration.fixed_cost'}
                        label={`Comisión (${props.record.attributes.currency_iso_code})`}
                      />
                    </Col>
                    <Col xs={3}>
                      <TextInput
                        source={'attributes.wire_configuration.primary_label'}
                        label={'Texto principal dropdown'}
                      />
                    </Col>
                    <Col xs={3}>
                      <TextInput
                        source={'attributes.wire_configuration.secondary_label'}
                        label={'Texto secundario dropdown'}
                      />
                    </Col>
                    <Col xs={3}>
                      <TextInput
                        source={'attributes.wire_configuration.estimated_time'}
                        label={'Tiempo estimado'}
                      />
                    </Col>
                  </Row>
                </Container>
              </Card>
            )}
          </FormDataConsumer>
        </Container>
      )
    }
  </>
);

const styles = {
  container: {
    width: '100%',
    maxWidth: '100%',
  },
};

WireCreator.propTypes = {
  record: PropTypes.shape({
    attributes: PropTypes.shape({
      currency_iso_code: PropTypes.string,
      is_wire_configuration_create: PropTypes.bool,
    }),
  }),
};

WireCreator.defaultProps = {
  record: {
    attributes: {
      currency_iso_code: '',
      is_wire_configuration_create: false,
    },
  },
};

export default WireCreator;