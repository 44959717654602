const styles = {
    container: {
        paddingTop: 10,
        paddingBottom: 10,
        display: 'flex',
        flexDirection: 'row',
    },
    buttonRed: {
        backgroundColor: '#FF0000',
        color: '#FFFFFF',
        paddingTop: 10,
        paddingBottom: 10,
    },
    button: {
        backgroundColor: '#00ccff',
        color: '#FFFFFF',
        paddingTop: 10,
        paddingBottom: 10,
    },
};

export default styles;
