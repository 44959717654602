import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { CircularProgress } from '@material-ui/core';
import useAccessRole from "../../../../hooks/useAccessRole";
import { BeneficiaryProvider } from "../../../../Providers";
import Swal from "sweetalert2";

const BeneficiaryButtons = (props) => {
  const {
    history,
    record: {
      id,
      attributes:{
        is_banned,
        first_name,
        last_name
      }
    },
  } = props;

  const { isAccess } = useAccessRole();
  const [isBanned, setIsBanned] = useState(is_banned || false);
  const [isLoadingBanRequest, setIsLoadingBanRequest] = useState(false);

  const goToShowBeneficiary = () => {
    history.push(`/beneficiaries/${id}/show`);
  };

  const goToEditBeneficiary = () => {
    history.push(`/beneficiaries/${id}`);
  };

  const updateBannedStatus = async (newBannedStatus) => {
    let modalParams = {};
    if(newBannedStatus){
      modalParams = {
        title: `Desea banear al beneficiario ${[first_name, last_name].join(' ')}`,
        text: `
          Esta acción impedirá que el beneficiario pueda recibir dinero, 
          y bloqueará todas sus transacciones pendientes.
        `,
      }
    }else{
      modalParams = {
        title: `Desea desbanear al beneficiario ${[first_name, last_name].join(' ')}`,
        text: `
          Esta acción permitirá que el beneficiario pueda volver a recibir dinero.
        `,
      }
    }

    const confirmationModal = await Swal.fire({
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar',
      ...modalParams
    });

    if(confirmationModal.isConfirmed){
      try {
        setIsLoadingBanRequest(true);
        const data = {
          is_banned: newBannedStatus
        };
  
        await BeneficiaryProvider.update(id, data);
        setIsBanned(newBannedStatus);
        setIsLoadingBanRequest(false);
      } catch (error) {
        setIsLoadingBanRequest(false);
        Swal.fire({
          icon: 'error',
          title: 'Algo salió mal',
          text: error.data?.error?.message || `No pudimos relizar esta acción`,
        });
      }
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingRight: 30,
      }}
    >
      {isAccess("show_beneficiaries") && (
        <div style={{ padding: 10, width: 100 }}>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#00b7ff" }}
            onClick={goToShowBeneficiary}
          >
            Ver información
          </Button>
        </div>
      )}
      {isAccess("edit_beneficiaries") ? (
        <div style={{ padding: 10, width: 100 }}>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#131313" }}
            onClick={goToEditBeneficiary}
          >
            Editar información
          </Button>
        </div>
      ) : null}
      {
        isAccess('update_beneficiaries_banned_status') && (
          <div style={{ padding: 10, width: 100 }}>
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: isBanned ? '#539985' : '#ff0033',
              }}
              onClick={() => updateBannedStatus(!isBanned)}
            >
              {
                isLoadingBanRequest ? (
                  <CircularProgress size={14} style={{ 'color': 'white' }} />
                ) : isBanned ? 'Desbanear' : 'Banear'
              }
            </Button>
          </div>
        )
      }
    </div>
  );
};

export default BeneficiaryButtons;
