import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Paper,
  Switch,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControlLabel
} from '@material-ui/core';

import useAccessRole from '../../../../hooks/useAccessRole';
import Visibility from '@material-ui/icons/Visibility';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Edit from '@material-ui/icons/Edit';
import Add from '@material-ui/icons/Add';

import {MessageProvider} from '../../../../Providers';

import createStyles from './styles';

const MarketingInfo = (props) => {
  const {record, history, hasEdit} = props;
  const {
    id,
    attributes: {
      title_en,
      title_es,
      place,
      max_messages,
      messages
    }
  } = record;

  const {isAccess} = useAccessRole();
  
  const initialMessageState = {
    active: false,
    fixed: false,
    weight_percent: 0
  };

  const [show, setShow] = useState(false);
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [listMessages, setListMessages] = useState(messages);
  const [newMessage, setNewMessage] = useState(initialMessageState);

  const {
    changeActiveStatus,
    changeFixedStatus,
    deleteMessage,
    createMessage,
    editMessage,
  } = MessageProvider;

  const styles = createStyles();

  const onShowMessages = () => {
    setShow(!show);
  }

  const changeStatusMessages = (id, active) => {
    try {
      changeActiveStatus(id).then(response => {
        if(response && response.status === 200)
          window.location.reload(false);
      });
    } catch (error) {}
  }

  const changeFixedMessages = (id) => {
    try {
      changeFixedStatus(id).then(response => {
        if(response && response.status === 200)
          window.location.reload(false);
      });
    } catch (error) {}
    
  }

  const deleteMessageFromTitle = (id) => {
    try {
      deleteMessage(id).then(response => {
        if(response && response.status === 200){
          setListMessages(
            listMessages.filter(message => {
              return message.id !== id
            })
          )
        }
      })
    } catch (error) {}
    
  }

  const handleChange = e => {
    setNewMessage({
      ...newMessage,
      [e.target.name]: e.target.value 
    })
  }

  const handleSwitchChange = e => {
    setNewMessage({
      ...newMessage,
      [e.target.name]: e.target.checked 
    })
  }

  const activeEditMessage = id => {
    setEdit(true);
    setAdd(true);
    
    setNewMessage(
      listMessages.find(message => {
        return message.id === id
      })
    )
  }

  const onGoEdit = id => {
    history.push(`/marketing_message/${id}`);
  }

  const handleSubmit = (e, id) => {
    e.preventDefault();

    try {
      if(add && !edit) {
        createMessage(id, newMessage).then(response => {
          if(response && response.status === 200) {
            setListMessages([...listMessages, response.data.message]);
            setAdd(false);
            setEdit(false);
          }
        });
      } else {
        editMessage(id, newMessage).then(response => {
          if(response && response.status === 200) {
            window.location.reload(false);
          }
        });
      }
    } catch (error) {}
    
  }

  return (
    <Paper>
      <Table>
        <TableHead style={{backgroundColor: '#303030'}}>
          <TableRow>
            <TableCell><b>ID:</b><br /> {id}</TableCell>
            <TableCell><b>Titulo español:</b><br /> {title_es}</TableCell>
            <TableCell><b>Titulo ingles:</b><br /> {title_en}</TableCell>
            <TableCell><b>Lugar:</b><br /> {place}</TableCell>
            <TableCell><b>Mensajes maximo:</b><br /> {max_messages}</TableCell>
            <TableCell>
              <Visibility className="clickable" onClick={() => onShowMessages(id)}/>
              {hasEdit ? <Edit className="clickable mx-1" onClick={() => onGoEdit(id)}/> : null}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            show && (
              <TableRow>
                <TableCell colSpan="6">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Mensaje español</TableCell>
                        <TableCell>Mensaje ingles</TableCell>
                        <TableCell>Activo</TableCell>
                        <TableCell>Fijado</TableCell>
                        <TableCell>Acción</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listMessages.length > 0 && listMessages.map(message => {
                        return (
                          <TableRow key={message.id}>
                            <TableCell>
                              {message.id}
                            </TableCell>
                            <TableCell>
                              {message.message_es}
                            </TableCell>
                            <TableCell>
                              {message.message_en}
                            </TableCell>
                            <TableCell>
                              <Switch
                                checked={message.active}
                                onChange={() => { changeStatusMessages(message.id) }}
                                color='primary'
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                              />
                            </TableCell>
                            <TableCell>
                              <Switch
                                checked={message.fixed}
                                onChange={() => { changeFixedMessages(message.id) }}
                                color='primary'
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                              />
                            </TableCell>
                            <TableCell>
                              { isAccess('delete_marketing_messages') ? <DeleteForever className="clickable" onClick={() => {deleteMessageFromTitle(message.id)}}/> : null }
                              { hasEdit ? <Edit className="clickable mx-1" onClick={() => {activeEditMessage(message.id)}}/> : null }
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow>
                        <TableCell colSpan="6">
                          {
                            !add ? 
                            isAccess('create_marketing_messages') && (
                              <div style={{display: 'flex', justifyContent: 'center', paddingTop: '20px'}}>
                                <Button variant="raised" color="primary" onClick={() => setAdd(!add)}>
                                  <Add color="secondary"/>
                                  Agregar mensaje
                                </Button>
                              </div>
                              )
                            :
                            <form
                              onSubmit={e => handleSubmit(e, edit ? newMessage.id : id)}
                            >
                              <TextField
                                label="Mensaje español"
                                name="message_es"
                                helperText="Mensaje en español para el titulo"
                                style={styles.input_fields}
                                value={newMessage.message_es}
                                onChange={handleChange}
                                required
                              />
                              <TextField
                                label="Mensaje ingles"
                                name="message_en"
                                helperText="Mensaje en ingles para el titulo"
                                style={styles.input_fields}
                                value={newMessage.message_en}
                                onChange={handleChange}
                                required
                              />
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={newMessage.active}
                                    onChange={handleSwitchChange}
                                    color='secondary'
                                    name="active"
                                  />
                                }
                                label="Activo"
                              />
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={newMessage.fixed}
                                    onChange={handleSwitchChange}
                                    color='secondary'
                                    name="fixed"
                                  />
                                }
                                label="Fijado"
                              />
                              <Button 
                                variant="extendedFab" 
                                color="primary"
                                style={{margin: '0 10px'}}
                                type="submit"
                              >
                                Guardar
                              </Button>
                              <Button 
                                variant="extendedFab" 
                                color="secondary"
                                style={{margin: '0 10px'}}
                                onClick={() => {setAdd(false); setEdit(false); setNewMessage(initialMessageState);}}
                              >
                                Cancelar
                              </Button>
                            </form>
                          }
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>

                </TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </Paper>
  );
};

export default withRouter(MarketingInfo);