import React, { useState, useEffect } from "react";
import { Typography } from '@material-ui/core';
import { CountriesProvider, BinanceProvider, CurrenciesProvider } from '../../../../../Providers';
import styles from "./styles";
import useAlerts from "../../../../../hooks/useAlerts";
import PropTypes from 'prop-types';
import AutomaticPaymentCryptoRouteItem from "./AutomaticPaymentCryptoRouteItem";

const AutomaticPaymentCryptoRoutesPanel = (props) => {
  const { country_id } = props;

  const [refreshAutomaticPaymentRoutes, setRefreshAutomaticPaymentRoutes] = useState(0);
  const [automaticPaymentRoutes, setAutomaticPaymentRoutes] = useState([]);
  const [networks, setNetworks] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const { errorMessage } = useAlerts();

  useEffect(() => {
    const refreshAutomaticPaymentRoutes = async() =>{
      const data = await retrieveRoutes(country_id);
      setAutomaticPaymentRoutes(data);
    }

    refreshAutomaticPaymentRoutes();
  }, [country_id, refreshAutomaticPaymentRoutes]);

  useEffect(() => {
    const refreshCurrencies = async () => {
      const data = await retrieveCurrencies();
      setCurrencies(data);
    }

    refreshCurrencies();
  }, []);

  useEffect(() => {
    const refreshNetworks = async () => {
      const data = await retrieveNetworks();
      setNetworks(data);
    }

    refreshNetworks();
  }, []);

  const retrieveRoutes = async (countryId) => {
    try {
      const response = await CountriesProvider.retrieveAutomaticPaymentCryptoRoutes(countryId);
      return response.data?.data?.map((item) => item.attributes);
    } catch (error) {
      errorMessage(`Error retrieving the routes: ${error.data?.message}`);
    }
  };

  const retrieveNetworks = async () => {
    try {
      const response = await BinanceProvider.getBinanceNetworks();
      return response.data?.data?.map((item) => {
        return {
          id: item.id,
          ...item.attributes
        }
      });
    } catch (error) {
      errorMessage(`Error retrieving the routes: ${error.data?.message}`);
    }
  };

  const retrieveCurrencies = async () => {
    try {
      const response = await CurrenciesProvider.getCurrenciesByIsoCodes(['BTC', 'USDT', 'USDC']);
      return response.data?.currencies?.data?.map((item) => {
        return {
          ...item.attributes,
          id: item.id
        }
      });
    } catch (error) {
      errorMessage(`Error retrieving the currencies: ${error.data?.message}`);
    }
  };

  return <div>
    <div style={styles.itemGroupContainer}>
      <Typography>Agregar ruta</Typography>
      <AutomaticPaymentCryptoRouteItem  {...{country_id, networks, currencies, refreshAutomaticPaymentRoutes, setRefreshAutomaticPaymentRoutes}} />
    </div>
    <div style={styles.itemGroupContainer}>
      <Typography>Rutas activas</Typography>
      {
        automaticPaymentRoutes.map((item) => <AutomaticPaymentCryptoRouteItem key={item.id} { ...{...item, country_id, networks, currencies, refreshAutomaticPaymentRoutes, setRefreshAutomaticPaymentRoutes} } />)
      }
    </div>
  </div>;
}

AutomaticPaymentCryptoRoutesPanel.propTypes = {
  country_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

AutomaticPaymentCryptoRoutesPanel.defaultProps = {
  country_id: 0,
};

export default AutomaticPaymentCryptoRoutesPanel;