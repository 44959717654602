import React from "react";

import { CardContent, Grid, TextField, Typography } from '@material-ui/core';

import styles from "./styles";

const PoliticallyExposedPersonInfo = (props) => {

  const {record: {attributes: {politically_exposed_person, politically_exposed_person_check}}} = props;

  return (
    <CardContent>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h4" gutterBottom style={styles.titleWhite}>
            Persona políticamente expuesta
          </Typography>
        </Grid>
        {
          (
            politically_exposed_person === null
          ) ?
          (
            <Grid container direction="row" justify="center" alignItems="center">
              <Grid item xs={6}>
                <Typography variant="h4" gutterBottom style={styles.titleWhite}>
                  No hay una persona políticamente expuesta para este usuario
                </Typography>
              </Grid>
            </Grid>
          ) :
          (
            <Grid container direction="row" justify="center" alignItems="center">
              <Grid style={styles.divisor} item xs={12} sm={5}>
                <Grid>
                  <TextField
                    label={"La persona políticamente expuesta es el mismo usuario"}
                    value={politically_exposed_person_check ? 'No' : 'Sí'}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid style={styles.divisor} item xs={12} sm={5}>
                <Grid>
                  <TextField
                    label={"Número de documento"}
                    value={politically_exposed_person ? politically_exposed_person.document_number : ''}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid style={styles.divisor} item xs={12} sm={5}>
                <Grid>
                  <TextField
                    label={"Nombre"}
                    value={politically_exposed_person ? politically_exposed_person.first_name : ''}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid style={styles.divisor} item xs={12} sm={5}>
                <Grid>
                  <TextField
                    label={"Apellido"}
                    value={politically_exposed_person ? politically_exposed_person.last_name : ''}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid style={styles.divisor} item xs={12} sm={5}>
                <Grid>
                  <TextField
                    label={"Ocupación"}
                    value={politically_exposed_person ? politically_exposed_person.job_title : ''}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )
        }
      </Grid>
    </CardContent>
  );
}

export default PoliticallyExposedPersonInfo;

