import { apiCall }                     from '../../Middlewares';
import { resolveNameResource, RESOURCES } from '../../Helpers/ResourceHelper';

import responseHandler from './responseHandler';
import requestHandler  from './requestHandler';
import Swal from 'sweetalert2';

const Rest = async (type, resource, params) => {
    const isWithdrawal = (resource === RESOURCES.retiros_label);
    const positionGlobal = (resource === RESOURCES.vita_label);
    const isBitcoin = (resource === RESOURCES.bitcoin || (params.filter && params.filter.isBitcoin !== undefined && params.filter.isBitcoin));
    const isBinance = (resource === RESOURCES.binance || (params.filter && params.filter.isBinance !== undefined && params.filter.isBinance));
    const isCircle = (resource === RESOURCES.circle || (params.filter && params.filter.isCircle !== undefined && params.filter.isCircle));

    resource = (isBitcoin || isBinance || isCircle) ? RESOURCES.transacciones : resolveNameResource(resource);

    try {
        const config = await requestHandler(type, resource, params, isWithdrawal, positionGlobal, isBitcoin, isBinance, isCircle);
        const response = await apiCall({
            authorization: true,
            ...config
        });

        return responseHandler(response, type, resource, params);
    } catch (error) {
        // For resource 'users', Promise.reject is returned by its responseHandler.
        return resource === 'users' ? responseHandler(error, type, resource, params) : Promise.reject(error);
    }
};

export const RestAdmin = async (type, resource, params) => {
    const { pagination } = params;
    const perPage = pagination ? pagination.perPage : null;

    try {
		if (perPage && parseInt(perPage) === 1000 && type === 'GET_LIST'){
            let response = {
                data: [],
                total: null
            };
            const stylesConfig = {
                customClass: {
                    subtitle: 'swal_title',
                    title: 'swal_title',
                    icon: 'swal_icon',
                    htmlContainer: 'subtitle',
                },
                background: '#303030',
                confirmButtonColor: '#00a6ff',
                denyButtonColor: 'rgb(19,19,19)'
            };

			await Swal.fire({
                title: 'Va a generar un archivo CSV con la información mostrada previamente. ¿Desea continuar?',
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonText: 'Sí',
                ...stylesConfig
            }).then(async(result) => {
                if (result.isConfirmed){
                    response = await Rest(type, resource, params);
                    
                    Swal.fire({
                        title: '¡Solicitud realizada!',
                        text: 'Te enviaremos un correo electrónico una vez este preparado el archivo.',
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        ...stylesConfig
                    });
                }
            });

            return response;
		} else {
            const response = await Rest(type, resource, params);
            return response;
        }
    } catch (error) {
        if (error && typeof error === "object" && error.hasOwnProperty("data") && typeof error.data === "object") {
            let newError = null
            
            Object.keys(error.data).forEach(key => {
                if (!newError && error.data[key] instanceof Array && error.data[key][0]) {
                    newError = {
                        status: error.status || 422,
                        message: error.data[key][0]
                    }
                }
            });

            if (newError) {
                return Promise.reject(newError);
            }

            if (error.data.hasOwnProperty("message")) {
                return Promise.reject({
                    status: error.status || 422,
                    message: error.data.message
                });
            }

            if (error.data.hasOwnProperty("error")) {
                return Promise.reject({
                    status: error.status || 422,
                    message: error.data.error
                });
            }

            if (error.data.hasOwnProperty("errors")) {
                return Promise.reject({
                    status: error.status || 422,
                    message: error.data.errors
                });
            }
        }
        
        return Promise.reject(error);
    }
};

export default Rest;
