const styles = {
    labelContainer: {
        display: 'inline-block',
        verticalAlign: 'top',
    },
    label: {
        color: "#A1A1A1",
        fontSize: "11px"
    },
    divisor: {
        padding: 20,
    },
    divisorTop: {
        paddingTop: 50,
    },
    descriptionInput: {
        marginLeft: 40,
        width: 'fit-content'
    },
    descriptionInputRight: {
        marginRight: 30,
        width: '150px'
    }
};

export default styles;
