import React from 'react';
import { Filter, TextInput } from 'react-admin';
import useClearFilters from '../../../../hooks/useClearFilters';

const MarketingFilter = (props) => {
  useClearFilters(props.setFilters);

  return (
    <Filter {...props}>
      <TextInput
        label={'Buscar'}
        source={'search_key'}
        helperText={"Busca por ID, lugar, titulo ES o EN"}
        alwaysOn
      />
    </Filter>
  );
};

export default MarketingFilter;
