import React from 'react';
import moment from 'moment';
import ReactCountryFlag from 'react-country-flag';

import { availableCountriesBeneficiariesTruora, flagsCountries } from '../../../../Config/constants';
import { NeitcomStatus } from '../../../../Components';

moment.locale('es');
const BeneficiaryInfo = (props) => {
  const { record: {
    attributes: {
      document_number,
      document_type,
      email,
      first_name,
      last_name,
      company_name,
      beneficiary_type,
      country,
      criminal_verification_status,
    },
  } } = props;

  const { neitcom, truora } = criminal_verification_status;

  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      <div style={{ paddingTop: 20 }}>
        <ReactCountryFlag countryCode={flagsCountries[country.name]} style={{fontSize: '4em'}} svg/>{country.name === 'Colombia2' ? (<span style={{fontSize: 24, marginLeft: 4}}>2</span>) : null}
      </div>
      <div style={{ textAlign: 'left', fontSize: '1rem' }}>
        {first_name && (
          <div style={{ fontWeight: 'bold' }}>
            {first_name.toUpperCase()} {last_name ? last_name.toUpperCase() : null}
          </div>
        )}
        {company_name && (
          <div style={{ fontWeight: 'bold' }}>
            {company_name.toUpperCase()}
          </div>
        )}
      </div>
      <div style={{ textAlign: 'left' }}>
        <div>
          {email}
        </div>
      </div>
      <div>
        < NeitcomStatus
          veredict={neitcom?.['veredict']}
          has_ignored_items={neitcom?.['has_ignored_items']}
          max_percentage={neitcom?.['max_percentage']}
          provider={'neitcom'}
        />
      </div>
      {
        availableCountriesBeneficiariesTruora.includes(country?.iso_code) &&
        <div>
          < NeitcomStatus
            veredict={truora?.['veredict']}
            has_ignored_items={truora?.['has_ignored_items']}
            max_percentage={truora?.['max_percentage']}
            provider={'truora'}
          />
        </div>
      }
      <div style={{ paddingTop: 20 }}>
        {document_type && (
          <>
            <strong>{document_type}.</strong><br/>
          </>
        )}
        {document_number}
      </div>
      <div style={{ paddingTop: 20 }}>
        {beneficiary_type && <strong>{beneficiary_type}</strong>}
      </div>
    </div>
  );
};

export default BeneficiaryInfo;
