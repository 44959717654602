import React, { useState, useEffect } from 'react';
import ReactCountryFlag from 'react-country-flag';
import styles from './styles';
import { FavoriteAccountsProvider } from '../../../../../../../Providers';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Button,
} from '@material-ui/core';
import CreateOrEditFavoriteAccount from '../CreateOrEditFavoriteAccount';
import { flagsCountriesName, flagsCountries } from '../../../../../../../Config/constants';

const FavoriteAccountList = (props) => {
  const [favoriteList, setFavoriteList] = useState([]);
  const [editFavorite, setEditFavorite] = useState({});
  const [action, setAction] = useState(null);
  const [pageList, setPageList] = useState({
    count: 10,
    page: 0,
    total: 0
  });
  const { account_type, record } = props;
  const location = props.location.slice(-1)
  let colums = [];

  colums.push({ id: 'id', label: 'ID' });
  colums.push({ id: 'name', label: 'Nombre' });
  colums.push({ id: 'account_alias', label: 'Alias' });
  colums.push({ id: 'email', label: 'Correo' });
  if (account_type === 'international') {
    colums.push({ id: 'account_type_bank_select', label: 'Tipo de cuenta' });
    colums.push({ id: 'bank_name', label: 'Banco' });
    colums.push({ id: 'destination_iso_code', label: 'País' });
  }
  colums.push({ id: 'action', label: 'Acción' });

  const getFavoriteAccount = async (type, userId, page, count = 0) => {
    try {
      const perPage = count == 0 ? pageList.count : count
      const resp = await FavoriteAccountsProvider.getFavoriteAccountList(
        type,
        userId,
        (page + 1),
        perPage
      );

      setPageList({
        ...pageList,
        total: resp.data.total,
        page: page,
        count: perPage
      });

      const list = resp.data.favorite_accounts.data.map((f) => f.attributes);

      setFavoriteList(list);
    } catch (e) { }
  }

  useEffect(() => {
    location === '6' && getFavoriteAccount(account_type, record.id, 0);
  }, [account_type]);

  const handleChangePage = (e, page) => {
    setPageList({
      ...pageList,
      page: page,
    });
    getFavoriteAccount(account_type, record.id, page);
  }

  const handleChangeRowsPerPage = e => {
    setPageList({
      ...pageList,
      count: e.target.value,
      page: 0
    });
    getFavoriteAccount(account_type, record.id, 0, e.target.value);
  }

  const getCountryCode = (iso_code) => flagsCountries[flagsCountriesName[iso_code.toUpperCase()]];

  const getAccountType = row => {
    let accountType = '';
    const isoCode = row.destination_iso_code;

    if (isoCode === 'us' || isoCode === 'gb')
      accountType = row.account_type_bank_select;
    else if (isoCode === 'mx')
      accountType = '------';
    else
      accountType = row.account_type_bank_select;

    return accountType;
  }

  const getBankName = bankName => bankName !== '-1' ? bankName : '------';

  const goToCreateAccount = () => {
    setAction('create')
  }

  const goToEditAccount = favoriteAccount => {
    setEditFavorite(favoriteAccount);
    setAction('edit')
  }

  return (
    <div>
      {
        action === null ?
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  {colums.map(colum => {
                    return (
                      <TableCell
                        key={colum.id}
                      >
                        {colum.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {favoriteList.map(row => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell> {row.id}</TableCell>
                      <TableCell> {row.first_name ? `${row.first_name} ${row.last_name}` : row.company_name}</TableCell>
                      <TableCell> {row.account_alias}</TableCell>
                      <TableCell> {row.email}</TableCell>
                      {account_type === 'international' ? <TableCell> {getAccountType(row)}</TableCell> : ''}
                      {account_type === 'international' ? <TableCell> {getBankName(row.bank_id_select)}</TableCell> : ''}
                      {account_type === 'international' ?
                        <TableCell>
                          <ReactCountryFlag countryCode={getCountryCode(row.destination_iso_code)} style={styles.flag} svg />
                        </TableCell>
                        : ''}
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => { goToEditAccount(row) }}
                        >
                          Editar
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={pageList.total}
              rowsPerPage={pageList.count}
              page={pageList.page}
              backIconButtonProps={{
                'aria-label': 'Atras',
              }}
              nextIconButtonProps={{
                'aria-label': 'Siguiente',
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => { goToCreateAccount() }}
            >
              Crear
            </Button>
          </Paper>
          : <CreateOrEditFavoriteAccount
            {...props}
            editFavorite={editFavorite}
            action={action}
          />}

    </div>
  );
}

export default FavoriteAccountList;