import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Card } from "@material-ui/core";
import styles from "./styles";

import Rest from "../../../Providers/Rest";
import CountrySelectHoc from "../../../Components/CountrySelect";

import HeaderStatus from "./components/Header";
import ScreenSegment from "./components/Screens/ScreenSegment";
import CuponDetails from "./components/Screens/CuponDetails";
import CuponComplete from "./components/Screens/CuponComplete";
import CuponInitial from "./components/Screens/CuponInitial";
import HeaderMenu from "../CouponsList/Components/HeaderMenu";
import useAlerts from "../../../hooks/useAlerts"

const CouponCreate = (props) => {
  const { errorMessage } = useAlerts()
  const { history } = props;
  const [firstView, setFirstView] = useState(true);
  const [secondView, setSecondView] = useState(false);
  const [thirtView, setThirtView] = useState(false);
  const [fourView, setFourView] = useState(false);
  //Sabemos si se cambio ventana y ya no hace post sino update
  const [statusCoupon, setStatusCoupon] = useState({
    firstScreen: false,
    secondScreen: false,
    thirdScreen: false,
    id: null,
  });
  //Maneja los colores del header cuando pasa de ventana
  const [colorsViews, setColorsViews] = useState({
    firstScreen: false,
    secondScreen: false,
    thirdScreen: false,
  });
  //Cuando viene por medio de edit se precarga la data y se muestra
  const [preData, setPreData] = useState({
    firstScreenData: null,
    secondScreenData: null,
    thirdScreenData: null,
  });
  // Data que se va guardando cada vez que pasa ventana en ventana
  const [saveData, setSaveData] = useState({
    firstScreenDataSaved: null,
    secondScreenDataSaved: null,
  });

  const init = () => {
    if (props.match.url === `/coupons/${props.id}`) {
      loadingData();
    }
  };

  const loadingData = async () => {
    try {
      const response = await Rest("GET_ONE", "coupons", { id: props.id });
      if (response.data) {
        const {
          data: {
            attributes: { coupon_design, segment },
          },
        } = response;

        if (coupon_design) {
          setPreData({
            firstScreenData: { data: response.data.attributes },
            secondScreenData: segment,
            thirdScreenData: coupon_design.data,
          });
          return setStatusCoupon({
            firstScreen: true,
            secondScreen: true,
            thirdScreen: true,
            id: props.id,
          });
        }

        if (segment) {
          setPreData({
            firstScreenData: { data: response.data.attributes },
            secondScreenData: segment,
            thirdScreenData: null,
          });
          return setStatusCoupon({
            firstScreen: true,
            secondScreen: true,
            thirdScreen: false,
            id: props.id,
          });
        }
        setPreData({
          firstScreenData: { data: response.data.attributes },
          secondScreenData: null,
          thirdScreenData: null,
        });
        setStatusCoupon({
          firstScreen: true,
          secondScreen: true,
          thirdScreen: false,
          id: props.id,
        });
      }
    } catch (e) {
      errorMessage(e.data.error.message)

    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendNewCoupon = async (couponDetails) => {
    let data = { couponDetails, statusView: "first" };
    data.couponDetails.identifier = data.couponDetails.identifier.trim();
    
    if (!statusCoupon.firstScreen) {
      try {
        const response = await Rest("CREATE", "coupons", data);

        if (response.data) {
          const {
            data: { id },
          } = response;
          setFirstView(false);
          setSecondView(true);
          setColorsViews({ ...colorsViews, firstScreen: true });
          setStatusCoupon({ ...statusCoupon, firstScreen: true, id });
        }
      } catch (e) {
        errorMessage(e.data.error.message)
        return;
      }
    }
    if (statusCoupon.firstScreen) {
      try {
        let data = { couponDetails, statusView: "first", id: statusCoupon.id };
        const response = await Rest("UPDATE", "coupons", data);

        if (response.data) {
          setFirstView(false);
          setSecondView(true);
          setColorsViews({ ...colorsViews, firstScreen: true });
          setStatusCoupon({ ...statusCoupon, firstScreen: true });
        }

      } catch (e) {
        errorMessage(e.data.error.message)
        return;
      }
    }
    setSaveData({
      ...saveData,
      firstScreenDataSaved: couponDetails,
    });
  };

  const sendScreenSegment = async (props) => {
    let data = {
      segment_id: props,
      coupon_id: statusCoupon.id,
      statusView: "second",
    };

    if (props) {
      try {
        const response = await Rest("UPDATE", "coupons", data);

        if (response.data) {
          setSecondView(false);
          setThirtView(true);
          setSaveData({
            ...saveData,
            secondScreenDataSaved: props,
          });
          setStatusCoupon({ ...statusCoupon, secondScreen: true });
        }

      } catch (e) {
        errorMessage(e.data.error.message)
        return;
      }
    }
    setSecondView(false);
    setThirtView(true);
    setColorsViews({ ...colorsViews, secondScreen: true });
    setStatusCoupon({ ...statusCoupon, secondScreen: true });
  };

  const sendCuponDetails = async (props) => {
    let data = {
      ...props,
      coupon_id: statusCoupon.id,
      statusView: "third",
    };

    if (!statusCoupon.thirdScreen) {
      try {
        const response = await Rest("CREATE", "coupons", data);

        if (response.data) {
          setThirtView(false);
          setFourView(true);
          setColorsViews({ ...colorsViews, thirdScreen: true });
          setStatusCoupon({ ...statusCoupon, thirdScreen: true });
        }
      } catch (e) {
        errorMessage(e.data.error.message)
        return;
      }
    }
    if (statusCoupon.thirdScreen) {
      try {
        const response = await Rest("UPDATE", "coupons", data);
        if (response.data) {
          setThirtView(false);
          setFourView(true);
          setColorsViews({ ...colorsViews, thirdScreen: true });
          setStatusCoupon({ ...statusCoupon, thirdScreen: true });
        }

      } catch (e) {
        errorMessage(e.data.error.message)
      }
    }
  };

  const backSegmentToNewCoupon = () => {
    setFirstView(true);
    setSecondView(false);
    setColorsViews({ ...colorsViews, firstScreen: false });
  };

  const backCuponDetailstoScreenSegment = () => {
    setSecondView(true);
    setThirtView(false);
    setColorsViews({ ...colorsViews, secondScreen: false });
  };

  const newCoupon = () => {
    setFirstView(true);
    setFourView(false);
    setStatusCoupon({
      firstScreen: false,
      secondScreen: false,
      thirdScreen: false,
    });

    setSaveData({
      firstScreenDataSaved: null,
      secondScreenDataSaved: null,
    });
    setPreData({
      firstScreenData: null,
      secondScreenData: null,
      thirdScreenData: null,
    });

    history.push("/coupons/create");
  };

  return (
    <>
      <HeaderMenu history={history} />

      <Card style={styles.cardContainer}>
        <HeaderStatus style={styles.headerStyles} statusCoupon={colorsViews} />
        {firstView && (
          <CuponInitial
            sendNewCoupon={sendNewCoupon}
            CountrySelectHoc={CountrySelectHoc}
            history={history}
            couponEditData={preData.firstScreenData}
            savedData={saveData.firstScreenDataSaved}
          />
        )}

        {secondView && (
          <ScreenSegment
            backSegmentToNewCoupon={backSegmentToNewCoupon}
            sendScreenSegment={sendScreenSegment}
            history={history}
            coupon={statusCoupon.id}
            couponEditData={preData.secondScreenData}
            savedData={saveData.secondScreenDataSaved}
          />
        )}
        {thirtView && (
          <CuponDetails
            sendCuponDetails={sendCuponDetails}
            backCuponDetailstoScreenSegment={backCuponDetailstoScreenSegment}
            couponEditData={preData.thirdScreenData}
          />
        )}
        {fourView && <CuponComplete history={history} newCoupon={newCoupon} />}
      </Card>
    </>
  );
};

CouponCreate.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default CouponCreate;
