import React from 'react';
import moment from 'moment'
import { List, Datagrid, TextField, FunctionField, BulkDeleteButton } from 'react-admin';

import ExportedFilter from '../../Filters/ExportedFilter';
import { Pagination } from '../../../../Components';
import GetApp from '@material-ui/icons/GetApp';

const ExportedList = (props) => {
  
  const PostBulkActionButtons = (props) => (
    <>
      <BulkDeleteButton {...props} />
    </>
  );

  return (
    <List
      {...props}
      title={'Archivos exportados'}
      exporter={false}
      filters={<ExportedFilter />}
      pagination={<Pagination />}
      bulkActionButtons={<PostBulkActionButtons {...props}/>}
      debounce={1000}
    >
      <Datagrid>
        <TextField
          source={'id'}
          label={'Id'}
        />
        <FunctionField
          label={'Nombre del archivo'}
          render={(record) => {
            return `${record.attributes.file_name}`;
          }}
        />
        <FunctionField
          label={'Fecha de creacíon'}
          source={'attributes.created_at'}
          render={(record) => {
            return `${moment.utc(record.attributes.created_at).format('DD/MM/YYYY HH:mm:ss')} UTC`;
          }}
        />
        <FunctionField
          label={'Email solicitante'}
          render={(record) => {
            return `${record.attributes.user.email}`;
          }}
        />
        <FunctionField
          label={'Tipo de exportacíon'}
          render={(record) => {
            return `${record.attributes.export_type}`;
          }}
        />
        <FunctionField
          label={'Acciones'}
          render={(record) => <a href={record.attributes.file_url} target="_blank" download={record.attributes.file_name} className="clickable"><GetApp /></a>}
        />
      </Datagrid>
    </List>
  );
};

export default ExportedList;