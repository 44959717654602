import { apiCall } from '../../Middlewares';
import { ENDPOINTS } from '../../Config/env';

const reSendCreateCardRequest = async (id) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.CARD_REQUEST.CREATE_CARD_REQUEST(id),
  });
};

const updateStatus = async (data) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.CARD_REQUEST.UPDATE_STATUS,
    data,
  });
};

const updateCurrency = async (data) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.CARD_REQUEST.UPDATE_CURRENCY,
    data,
  });
};

const getOldCard = async (id) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.CARD_REQUEST.GET_OLD_CARD(id)
  });
};

const getCardFromPomelo = async (id) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.CARD_REQUEST.GET_CARD_FROM_POMELO(id)
  });
};

export default {
  reSendCreateCardRequest,
  updateStatus,
  updateCurrency,
  getOldCard,
  getCardFromPomelo
};
