import { GET_ONE, UPDATE, GET_MANY, GET_LIST } from 'react-admin';
import Swal from 'sweetalert2';
import { standarMessage } from '../../../../Utils'

const responseHandler = (response, type) => {

    switch (type) {
        case GET_ONE: {
            let country = {};
            const users = response.data.users;

            users.included.map((include) => {
                if (include.type === 'country')
                    country = include;
                return null;
            });

            return {
                data: {
                    ...users.data[0],
                    attributes: {
                        ...users.data[0].attributes,
                        residence_country_id: users.data[0].attributes.residence_country_id ? users.data[0].attributes.residence_country_id.toString() : null,
                        country_id: users.data[0].attributes.country_id ?
                            users.data[0].attributes.country_id.toString() :
                            null,
                        document_issuing_country_id: users.data[0].attributes.document_issuing_country_id ?
                            users.data[0].attributes.document_issuing_country_id.toString() :
                            null,
                    },
                    country: country,
                    daily_limit_amount_add: users.data[0].attributes.daily_limit_amount,
                },
            };
        }
        case GET_MANY: {
            const userResponse = response.data.users;
            const { included } = userResponse;

            const payload = userResponse.data.map((user) => {
                const { attributes: { country_id } } = user;

                let country_data = {};
                (included || []).map((include) => {
                    if (country_id && include.id.toString() === country_id.toString())
                        country_data = include;
                });

                return {
                    ...user,
                    country_data,
                }
            });

            return {
                data: payload,
                total: response.data['total'],
            }
        }
        case UPDATE: {
            if (response.status % 500 < 100) {
                // Server errors do not give details about errors.
                standarMessage('Hubo un error inesperado, no pudimos actualizar el usuario', 'error', 'Error')
                return Promise.reject("ERROR AL EJECUTAR ACTUALIZACIÓN DEL USUARIO");
            } else if (response.status % 400 < 100) {
                // For status 400, the details about erros is shown by a modal.
                try {
                    let error_details = Object.entries(response.data).map(([field_name, errors]) => {
                        return `<strong>${field_name.replaceAll('_', ' ')}</strong>` + errors.map((e) => `<li>${e}</li>`);
                    }).join('</br>');

                    Swal.fire(
                        {
                            title: 'Something went wrong',
                            html: error_details,
                            icon: 'error'
                        }
                    )
                    return Promise.reject("ERROR AL EJECUTAR ACTUALIZACIÓN DEL USUARIO");
                } catch (error) {
                    // In case an error ocurrs parsing details, a modal will show a generic message. It shouldn't happen, it's just in case.
                    standarMessage('Hubo un error inesperado, no pudimos actualizar el usuario', 'error', 'Error')
                    return Promise.reject("ERROR AL EJECUTAR ACTUALIZACIÓN DEL USUARIO");
                }
            }

            standarMessage('Actualización completada', 'success', 'Bien hecho')
            return {
                data: response.data.data,
            }
        }
        case GET_LIST: {
            let users = response.data.users.data;

            return {
                data: users ? users : [],
                total: response.data['total'],
            }
        }
        default: {
            return {
                data: response.data.users.data,
                total: response.data['total'],
            }
        }
    }
};

export default responseHandler;
