import { MaskService } from "tp-react-web-masked-text";

export const COINS = {
  BTC: {
    COIN: "BTC",
    DECIMALS: 8,
  },
  USDT: {
    COIN: "USDT",
    DECIMALS: 8,
  },
  USDC: {
    COIN: "USDC",
    DECIMALS: 8,
  },
  ETH: {
    COIN: "ETH",
    DECIMALS: 4,
  },
  USD: {
    COIN: "USD",
    DECIMALS: 2,
  },
  CLP: {
    COIN: "CLP",
    DECIMALS: 0,
  },
  COP: {
    COIN: "COP",
    DECIMALS: 0,
  },
  MXN: {
    COIN: "MXN",
    DECIMALS: 2,
  },
  VES: {
    COIN: "VES",
    DECIMALS: 2,
  },
  ARS: {
    COIN: "ARS",
    DECIMALS: 0,
  },
  BOB: {
    COIN: "BOB",
    DECIMALS: 0,
  },
  AUD: {
    COIN: "AUD",
    DECIMALS: 2,
  },
  PEN: {
    COIN: "PEN",
    DECIMALS: 2,
  },
  GBP: {
    COIN: "GBP",
    DECIMALS: 2,
  },
  EUR: {
    COIN: "EUR",
    DECIMALS: 2,
  },
  DOP: {
    COIN: "DOP",
    DECIMALS: 0,
  },
  UYU: {
    COIN: "UYU",
    DECIMALS: 0,
  },
  CNY: {
    COIN: "CNY",
    DECIMALS: 0,
  },
  CRC: {
    COIN: "CRC",
    DECIMALS: 0,
  },
  BRL: {
    COIN: "BRL",
    DECIMALS: 0,
  },
  PYG: {
    COIN: "PYG",
    DECIMALS: 0,
  }
};

const OPTIONS_TEXT_INPUT_MASK = (coin = '') => {
  switch (coin) {
    case COINS.BTC.COIN: {
      return {
        precision: COINS.BTC.DECIMALS,
        separator: ",",
        delimiter: ".",
        unit: "",
        suffixUnit: "",
      };
    }
    case COINS.USDT.COIN: {
      return {
        precision: COINS.USDT.DECIMALS,
        separator: ",",
        delimiter: ".",
        unit: "",
        suffixUnit: "",
      };
    }
    case COINS.USDC.COIN: {
      return {
        precision: COINS.USDC.DECIMALS,
        separator: ",",
        delimiter: ".",
        unit: "",
        suffixUnit: "",
      };
    }
    case COINS.ETH.COIN: {
      return {
        precision: COINS.ETH.DECIMALS,
        separator: ",",
        delimiter: ".",
        unit: "",
        suffixUnit: "",
      };
    }
    case COINS.CLP.COIN: {
      return {
        precision: COINS.CLP.DECIMALS,
        separator: ",",
        delimiter: ".",
        unit: "",
        suffixUnit: "",
      };
    }
    case COINS.COP.COIN: {
      return {
        precision: COINS.COP.DECIMALS,
        separator: ",",
        delimiter: ".",
        unit: "",
        suffixUnit: "",
      };
    }
    case COINS.MXN.COIN: {
      return {
        precision: COINS.MXN.DECIMALS,
        separator: ",",
        delimiter: ".",
        unit: "",
        suffixUnit: "",
      };
    }
    case COINS.VES.COIN: {
      return {
        precision: COINS.VES.DECIMALS,
        separator: ",",
        delimiter: ".",
        unit: "",
        suffixUnit: "",
      };
    }
    case COINS.ARS.COIN: {
      return {
        precision: COINS.ARS.DECIMALS,
        separator: ",",
        delimiter: ".",
        unit: "",
        suffixUnit: "",
      };
    }
    case COINS.BOB.COIN: {
      return {
        precision: COINS.BOB.DECIMALS,
        separator: ",",
        delimiter: ".",
        unit: "",
        suffixUnit: "",
      };
    }
    case COINS.AUD.COIN: {
      return {
        precision: COINS.AUD.DECIMALS,
        separator: ",",
        delimiter: ".",
        unit: "",
        suffixUnit: "",
      };
    }
    case COINS.PEN.COIN: {
      return {
        precision: COINS.PEN.DECIMALS,
        separator: ",",
        delimiter: ".",
        unit: "",
        suffixUnit: "",
      };
    }
    case COINS.GBP.COIN: {
      return {
        precision: COINS.GBP.DECIMALS,
        separator: ",",
        delimiter: ".",
        unit: "",
        suffixUnit: "",
      };
    }
    case COINS.EUR.COIN: {
      return {
        precision: COINS.EUR.DECIMALS,
        separator: ",",
        delimiter: ".",
        unit: "",
        suffixUnit: "",
      };
    }
    case COINS.DOP.COIN: {
      return {
        precision: COINS.DOP.DECIMALS,
        separator: ",",
        delimiter: ".",
        unit: "",
        suffixUnit: "",
      };
    }
    case COINS.UYU.COIN: {
      return {
        precision: COINS.UYU.DECIMALS,
        separator: ",",
        delimiter: ".",
        unit: "",
        suffixUnit: "",
      };
    }
    case COINS.VES.COIN: {
      return {
        precision: COINS.VES.DECIMALS,
        separator: ",",
        delimiter: ".",
        unit: "",
        suffixUnit: "",
      };
    }
    case COINS.CNY.COIN: {
      return {
        precision: COINS.CNY.DECIMALS,
        separator: ",",
        delimiter: ".",
        unit: "",
        suffixUnit: "",
      };
    }
    case COINS.CRC.COIN: {
      return {
        precision: COINS.CRC.DECIMALS,
        separator: ",",
        delimiter: ".",
        unit: "",
        suffixUnit: "",
      };
    }
    case COINS.BRL.COIN: {
      return {
        precision: COINS.BRL.DECIMALS,
        separator: ",",
        delimiter: ".",
        unit: "",
        suffixUnit: "",
      };
    }
    case COINS.PYG.COIN: {
      return {
        precision: COINS.PYG.DECIMALS,
        separator: ",",
        delimiter: ".",
        unit: "",
        suffixUnit: "",
      };
    }
    default: {
      return {
        precision: COINS.USD.DECIMALS,
        separator: ",",
        delimiter: ".",
        unit: "",
        suffixUnit: "",
      };
    }
  }
};

export const WITHOUT_ROUND = (amount, n) =>
  amount.toString().match(new RegExp(`^-?\\d+(?:\\.\\d{0,${n}})?`));

export const formatMoney = (amount, currency = 'clp') => {
  amount = parseFloat(amount && amount >= 0 ? amount : 0);
  const maskConfig = OPTIONS_TEXT_INPUT_MASK(currency.toUpperCase());

  if (amount.toString().includes('e-')) {
    amount = amount.toFixed(20)
  }

  const m = amount.toString().match(new RegExp(`(\\d+\\.\\d{${maskConfig.precision}})`, 'g'));

  amount = m ? parseFloat(m[0]) : amount.valueOf();

  amount = MaskService.toMask('money', amount, maskConfig);

  return amount;
};

export const toMoney = (amount, coin = '') => {
  coin = coin.toUpperCase();
  amount = parseFloat(amount).toFixed(10);
  const isNegative = amount ? amount.toString().indexOf("-") !== -1 : false;
  const isPositive = amount ? amount.toString().indexOf("+") !== -1 : false;

  const data = WITHOUT_ROUND(
    parseFloat(amount).toFixed(10),
    coin === COINS.BTC.COIN
      ? COINS.BTC.DECIMALS
      : coin === COINS.ETH.COIN
        ? COINS.ETH.DECIMALS
        : coin === COINS.USD.COIN
          ? COINS.USD.DECIMALS
          : coin === COINS.COP.COIN
            ? COINS.COP.DECIMALS
            : coin === COINS.MXN.COIN
              ? COINS.MXN.DECIMALS
              : coin === COINS.VES.COIN
                ? COINS.VES.DECIMALS
                : coin === COINS.USDT.COIN
                  ? COINS.USDT.DECIMALS
                  : coin === COINS.USDC.COIN
                    ? COINS.USDC.DECIMALS
                    : coin === COINS.BOB.COIN
                      ? COINS.BOB.DECIMALS
                      : coin === COINS.AUD.COIN
                        ? COINS.AUD.DECIMALS
                        : coin === COINS.PEN.COIN
                          ? COINS.PEN.DECIMALS
                          : coin === COINS.GBP.COIN
                            ? COINS.GBP.DECIMALS
                            : coin === COINS.EUR.COIN
                              ? COINS.EUR.DECIMALS
                              : coin === COINS.DOP.COIN
                                ? COINS.DOP.DECIMALS
                                : coin === COINS.UYU.COIN
                                  ? COINS.UYU.DECIMALS
                                  : coin === COINS.VES.COIN
                                    ? COINS.VES.DECIMALS
                                    : coin === COINS.CNY.COIN
                                      ? COINS.CNY.DECIMALS
                                      : coin === COINS.CRC.COIN
                                        ? COINS.CRC.DECIMALS
                                        : coin === COINS.BRL.COIN
                                          ? COINS.BRL.DECIMALS
                                          : coin === COINS.PYG.COIN
                                            ? COINS.PYG.DECIMALS
                                            : COINS.CLP.DECIMALS
  );

  amount = MaskService.toMask(
    "money",
    data ? data[0] : 0,
    OPTIONS_TEXT_INPUT_MASK(coin)
  );

  return `${!isPositive ? (isNegative ? "-" : "") : ""}${amount}`;
};

export const toMoneyWithDecimals = (amount, decimals) => {
  amount = parseFloat(amount).toFixed(12);
  const isNegative = amount ? amount.toString().indexOf("-") !== -1 : false;
  const isPositive = amount ? amount.toString().indexOf("+") !== -1 : false;

  const data = WITHOUT_ROUND(parseFloat(amount).toFixed(12), decimals);

  amount = MaskService.toMask("money", data ? data[0] : 0, {
    precision: decimals,
    separator: ".",
    delimiter: ",",
    unit: "",
    suffixUnit: "",
  });

  return `${!isPositive ? (isNegative ? "-" : "") : ""}${amount}`;
};

export const TO_NUMBER = (amount, coin = '') => {
  coin = coin.toUpperCase();
  const isNegative = amount ? amount.toString().indexOf("-") !== -1 : false;
  const isPositive = amount ? amount.toString().indexOf("+") !== -1 : false;

  amount = MaskService.toRawValue(
    "money",
    amount,
    OPTIONS_TEXT_INPUT_MASK(coin)
  );

  return amount * (!isPositive ? (isNegative ? -1 : 1) : 1);
};

export const removeLeadingZerosBtc = (amountBtc) => {
  amountBtc = Array.from(amountBtc);
  const tam = amountBtc.length - 1;
  let deleteDigits = true;

  for (let i = 0; i < tam && deleteDigits; i++) {
    if (amountBtc[tam - i] !== "0") deleteDigits = false;

    if (amountBtc[tam - i] === ",") amountBtc[tam - i] = "D";

    if (amountBtc[tam - i] === "0" && deleteDigits) {
      amountBtc[tam - i] = "D";
    }
  }

  amountBtc = amountBtc.join("");
  amountBtc = amountBtc.replace(/D/g, "");
  return amountBtc;
};

export const cryptoCurrencies = [
  "btc",
  "usdt",
  "usdc"
]
