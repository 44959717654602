import { apiCall } from '../../Middlewares';
import { ENDPOINTS } from '../../Config/env';

const getListRoles = async () =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.ROLES.GET_ROLES,
  });

export default {
  getListRoles,
};