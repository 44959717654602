export const DATE_OPTIONS = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
};

export const FIELDS_EXPORTS_FILES = {
    PRICES: ['id', 'currency', 'currency_name', 'country_name', 'spot', 'fixed_cost', 'fixed_cost_transfer', 'fixed_cost_send', 'target_currency', 'fixed_cost_send', 'country_currency', 'fixed_cost_vita_card', 'sell_fee', 'buy_fee', 'sell_fee_send', 'is_apply_fixed_cost_in_withdrawal', 'is_apply_fixed_cost_in_sents', 'is_apply_fixed_cost_in_transfers', 'is_apply_fixed_cost_in_vita_card', 'buy_fee_send', 'code'],

    CONFIGURATIONS: ['id', 'btc_sell_fee',	'btc_buy_fee', 'eth_sell_fee', 'eth_buy_fee', 'created_at', 'updated_at', 'referral_system_enabled', 'btc_sell_fee_usd', 'btc_buy_fee_usd', 'eth_sell_fee_usd', 'eth_buy_fee_usd', 'daily_limit_amount_usd', 'daily_limit_amount_clp', 'usd_sell_fee_clp', 'usd_buy_fee_clp', 'usd_sell_fee_ves', 'usd_buy_fee_ves', 'usd_sell_fee_ars', 'usd_buy_fee_ars', 'usd_sell_fee_bob', 'usd_buy_fee_bob', 'usd_sell_fee_cop', 'usd_buy_fee_cop', 'usd_sell_fee_ecs', 'usd_buy_fee_ecs', 'usd_sell_fee_mxn', 'usd_buy_fee_mxn', 'usd_sell_fee_pab',	'usd_buy_fee_pab', 'usd_sell_fee_pyg', 'usd_buy_fee_pyg', 'usd_sell_fee_pen', 'usd_buy_fee_pen', 'usd_sell_fee_uyu', 'usd_buy_fee_uyu',	'payment_type', 'usd_sell_fee_usd', 'usd_buy_fee_usd', 'usd_sell_fee_gbp', 'usd_buy_fee_gbp', 'daily_transbank_recharge_limit', 'monthly_transbank_recharge_limit', 'ripple_auth_token', 'ripple_auth_token_expiration', 'usd_sell_fee_brl', 'usd_buy_fee_brl', 'usd_sell_fee_cny', 'usd_buy_fee_cny', 'usd_sell_fee_dop', 'usd_buy_fee_dop', 'usd_sell_fee_crc', 'usd_buy_fee_crc', 'minutes_update_fiat_prices', 'minutes_update_btc_prices', 'spot_precision', 'crypto_precision', 'biometric_verification', 'is_email_code_verification', 'verification_code_expiration_time'],

    SETTINGS_HEADERS: ['id', 'x_rules', 'x_residence_config', 'x_settings', 'created_at', 'updated_at'],

    CARD_ACCOUNT: ['id', 'type', 'created_at', 'status', 'provider', 'email', 'first_name', 'last_name', 'card_requests_status', 'card_requests_id', 'card_type', 'card_requests_id_created_at'],

    BUSINESS: ['id', 'type', 'first_name', 'last_name', 'country_name', 'email', 'balances', 'wallets_balance', 'default_currency', 'wallet_balance', 'assumes_recharge_commissions', 'is_price_update_for_master_wallet', 'is_trx_status_recipient_email', 'is_trx_status_business_email' ],

    REQUEST: ['id', 'type', 'response_time', 'created_at', 'provider', 'is_successful', 'target_type', 'target_id', 'url', 'method', 'successful_message', 'error_message', 'error_code', 'request_and_response'],

    TRANSACTIONS_STATUS: ['id', 'type', 'created_at', 'responsable_cambio', 'status', 'transaction_operation_type', 'description', 'transaction_id', 'id_proveedor', 'transactions_status_url'],

    SEGMENTS: ['id', 'type', 'name', 'created_at', 'status'],

    COUPONS: ['id', 'type', 'identifier', 'campaign', 'start_date', 'end_date', 'number_coupons', 'bonus_type', 'min_amount_transaction', 'status', 'amount', 'segment_id', 'segment_name', 'dest_country_name', 'origen_currency'],

    REFERRER: ['id', 'email', 'quantity'],

    DLOCAL: ['id', 'created_at', 'is_payin', 'is_payout', 'last_status_update_date', 'status', 'status_code', 'status_reason', 'amount', 'currency'],

    WALLETS: ['id', 'type', 'is_master', 'uuid', 'balance', 'default_currency', 'token'],

    BUSINESS_TRANSACTION: ['id', 'type', 'Moneda', 'Categoria', 'Estado', 'Emisor', 'Receptor', 'Orden', 'transacción_vita', 'Fee', 'Total_fee', 'Monto', 'Total', 'URL', 'IPN'],
}

export const QUERY_PARAMS = (id) =>
(
    {
        "pagination": {
            "page": 1,
            "perPage": 10
        },
        "sort": {
            "field": "id",
            "order": "DESC"
        },
        "filter": {
            "search_key": id
        }
    }
)

